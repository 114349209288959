import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Drawer,
  Stack,
  TextField,
  Typography,
  useTheme,
  FormHelperText,
  Radio,
} from "@mui/material";
import DropdownField from "../common/DropdownField";

import { useMessage } from "../snackbar/snackbar";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import AddOptions from "./AddOptions";
import SkillInput from "./SkillInput";
import TextEditor from "../common/TextEditor";
import SunTextEditor from "../common/SunTextEditor";

import { useFormik } from "formik";
import APICall from "../../utils/api";
import QuestionPreview from "../../components/libraryPage/QuestionPreview";
import { getDifficultSettingsByLevel } from "../../utils";
import LoadingScreen from "../../layouts/LoadingScreen";

import * as yup from "yup";
import CommonModal from "../modal/CommonModal";

const difficultyButtons = [
  {
    id: "667419282034414ec4383b6b",
    difficulty: "Easy",
    bgcolor: "rgba(67, 157, 98, 0.1)",
    color: "rgba(67, 157, 98, 1)",
    path: "/assets/greenfire.svg",
    selectedPath: "/assets/icons/greenFireEmpty.svg",
  },
  {
    id: "6674193e2034414ec4383b7f",
    difficulty: "Medium",
    bgcolor: "rgba(255, 151, 54, 0.1)",
    color: "rgba(255, 151, 54, 1)",
    path: "/assets/orangefire.svg",
    selectedPath: "/assets/icons/orangeFireEmpty.svg",
  },
  {
    id: "664f2c36dc36f7d80e2a4651",
    difficulty: "Hard",
    bgcolor: "rgba(249, 73, 72, 0.1)",
    color: "rgba(249, 73, 72, 1)",
    path: "/assets/libraryFire.svg",
    selectedPath: "/assets/icons/redFireEmpty.svg",
  },
];

const AddQuestionDrawer = ({
  open,
  onClose,
  drawerHeading,
  isEditQuestion,
  question,
  fetchQuestions,
  fetchLibraryStats,
  fetchFilterSkills,
  fromAssessmentFlow,
  handleUpdateAssessmentQuestion,
  getAndAddQuestion
}) => {
  const theme = useTheme();
  const [selectedDifficulty, setSelectedDifficulty] = useState("Easy");
  const [questionOpen, setQuestionOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [multipleChoice, setMultipleChoice] = useState(false);
  const [allSkills, setAllSkills] = useState([]);
  const [allTopics, setAllTopics] = useState([]);
  const [richtextBox, setRichtextBox] = useState(false);
  const message = useMessage();
  const [selectedOption, setSelectedOption] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [saveDraft, setSaveDraft] = useState([]);
  const [previewData, setPreviewData] = useState({});
  const [skillTopicsCache, setSkillTopicsCache] = React.useState({});
  const [inputValue, setInputValue] = React.useState({
    skills: "",
    topics: "",
  });
  const [options, setOptions] = useState([
    { id: 1, text: "", html: "", images: [] },
    { id: 2, text: "", html: "", images: [] },
  ]);
  const [isDataFetched, setIsDataFetched] = React.useState(false);

  const [skills, setSkills] = React.useState(
    question?.skills.map((sk) => sk._id) || []
  );
  const [topics, setTopics] = React.useState([]);
  const [value, setValue] = useState("");
  const [isDataSet, setIsDataSet] = useState(false);
  const [showUpdateInLibraryModal, setShowUpdateInLibraryModal] =
    useState(false);
  const [openMultipleSkillModal, setOpenMultipleSkillModal] = useState(false);
  const [updatedQuestion, setUpdatedQuestion] = useState({
    assignedSkill: "",
    question: {},
  });

  const handleDifficultyChange = (difficulty) => {
    setSelectedDifficulty(difficulty);
  };

  const handlePublish = async () => {
    const data = {
      ...formik.values,
    };
    data.status = "completed"; //saveDraft === true ? "draft" :
    try {
      setLoading(true);
      const res = await APICall(`/question/`, data, "post");
      message("Question published successfully", "success");
      // onClose();
      formik.resetForm();
      if (fetchQuestions) fetchQuestions();
      if (fetchLibraryStats) fetchLibraryStats();
      if (fetchFilterSkills) fetchFilterSkills();
      if (getAndAddQuestion) getAndAddQuestion(res.data._id);
    } catch (error) {
      if (error.response) {
        message(error.response.data.message, "error");
      } else if (error.request) {
        message("No response from the server. Please try again.", "error");
      } else {
        message(`Error: ${error.message}`, "error");
      }
    }
    // Clear all fields
    setLoading(false);
    setSelectedDifficulty("Easy");
    setMultipleChoice(false);
    setRichtextBox(false);
    setValue("");
    setSelectedOption([]);
    setSelectedOptions([]);
    setOptions([
      { id: 1, text: "", html: "", images: [] },
      { id: 2, text: "", html: "", images: [] },
    ]);
    setAllTopics([]);
    setAllSkills([]);
    setInputValue({
      skills: "",
      topics: "",
    });
  };

  const handleUpdate = async () => {
    const data = {
      ...formik.values,
    };
    data.status = "completed";

    if (!question._id) {
      message("Question ID is required", "error");
      return;
    }
    try {
      setLoading(true);
      const res = await APICall(`/question/${question?._id}`, data, "patch");

      message("Question updated successfully", "success");

      if (!fromAssessmentFlow) {
        setSelectedDifficulty("Easy");
        setMultipleChoice(false);
        setRichtextBox(false);
        setValue("");
        fetchFilterSkills();
        onClose();
        fetchQuestions();
        fetchLibraryStats();
      }
    } catch (error) {
      // Handle error response
      message(error.response.data.message, "error");
    }
    setLoading(false);
  };

  const handleDraft = async () => {
    const data = {
      ...formik.values,
      status: "draft",
    };

    try {
      await draftValidationSchema.validate(data, { abortEarly: false });

      setLoading(true);
      if (!isEditQuestion) {
        await APICall(`/question/draft`, data, "post");
      } else {
        await APICall(`/question/${question?._id}`, data, "patch");
      }

      message("Question saved successfully", "success");
      onClose();
      formik.resetForm();
      if (fetchQuestions) fetchQuestions();
      if (fetchLibraryStats) fetchLibraryStats();
      if (fetchFilterSkills) fetchFilterSkills();
      setSelectedDifficulty("Easy");
      setMultipleChoice(false);
      setRichtextBox(false);
      setValue("");
      setSelectedOption([]);
      setSelectedOptions([]);

      setOptions([
        { id: 1, text: "", html: "", images: [] },
        { id: 2, text: "", html: "", images: [] },
      ]);
      setInputValue({
        skills: "",
        topics: "",
      });
    } catch (error) {
      if (error.name === "ValidationError") {
        const errors = {};
        error.inner.forEach((err) => {
          errors[err.path] = err.message;
        });

        formik.setErrors(errors);
        formik.setTouched(
          Object.keys(errors).reduce((acc, field) => {
            acc[field] = true;
            return acc;
          }, {})
        );
      } else {
        message(
          error.response?.data?.message || "Something went wrong",
          "error"
        );
      }
    }

    setLoading(false);
  };

  const handleQuestionsClose = () => {
    setQuestionOpen(false);
  };

  const assignQuestionData = () => {
    const {
      type = "MCQ",
      question: questionText = "",
      multipleAnswers = false,
      score = 0,
      difficultyLevelId = "",
      difficultyLevel,
      skills = [],
      topics = [],
      questionTitle = "",
      status = "draft",
      images = [],
      isEditorEnable = false,
      answers = [],
      correctAnswers = [],
    } = question || {};

    const formValues = {
      type,
      question: questionText,
      multipleAnswers,
      score,
      difficultyLevelId: difficultyLevelId?._id,
      skills: skills.map((skill) => skill._id),
      topics: topics.map((topic) => topic._id),
      questionTitle,
      status,
      images,
      isEditorEnable,
      answers,
      correctAnswers,
    };

    setAllTopics(topics);
    setSkills(skills);
    setAllSkills(skills);
    setMultipleChoice(multipleAnswers);
    setRichtextBox(isEditorEnable);
    formik.setValues(formValues);

    const difficultyMap = {
      "664f2c36dc36f7d80e2a4651": "Hard",
      "667419282034414ec4383b6b": "Easy",
      "6674193e2034414ec4383b7f": "Medium",
    };

    if (difficultyLevel?._id) {
      handleDifficultyChange(difficultyMap[difficultyLevel._id]);
    }

    if (difficultyLevelId?._id) {
      handleDifficultyChange(difficultyMap[difficultyLevelId._id]);
    }

    if (correctAnswers.length > 0) {
      const selectedIndexes = correctAnswers.map((correctAnswerId) => {
        return (
          answers.findIndex((answer) => answer.optionId === correctAnswerId) + 1
        );
      });
      multipleAnswers
        ? setSelectedOptions(selectedIndexes)
        : setSelectedOption(selectedIndexes[0]);
    }

    setOptions(
      answers.map((answer, index) => ({
        id: index + 1,
        text: answer.option,
      }))
    );
  };

  useEffect(() => {
    if (isDataFetched && isEditQuestion === true) {
      assignQuestionData();
    }
  }, [isDataFetched, isEditQuestion, question]);

  const fetchSkills = async (skill = "") => {
    let url = `/skill?limit=10`;
    if (skill !== "") {
      url += `&query=${encodeURIComponent(skill)}`;
    }

    const res = await APICall(url);

    const selectedSkillOptions = isEditQuestion
      ? question.skills
      : allSkills.filter((skill) => skills.includes(skill._id));

    const fetchedSkills = res.data.documents;
    const combinedSkills = [
      ...selectedSkillOptions,
      ...fetchedSkills.filter(
        (fetchedSkill) =>
          !selectedSkillOptions.find(
            (selectedSkill) => selectedSkill._id === fetchedSkill._id
          )
      ),
    ];

    const newSkillTopicsCache = {};
    fetchedSkills.forEach((skill) => {
      newSkillTopicsCache[skill._id] = skill.topics;
    });
    setSkillTopicsCache((prev) => ({
      ...prev,
      ...newSkillTopicsCache,
    }));

    setAllSkills(combinedSkills);

    return combinedSkills;
  };

  const handleMultipleChoiceChange = (event) => {
    setMultipleChoice(event.target.checked);
    formik.setFieldValue("multipleAnswers", !formik.values.multipleAnswers);
  };

  const handleRichtextBoxChange = (event) => {
    setRichtextBox(event.target.checked);
    formik.setFieldValue("isEditorEnable", !formik.values.isEditorEnable);
  };

  const handleQuestionPreview = () => {
    setQuestionOpen(true);
    const filteredSkills = Object.values(allSkills).filter((skill) =>
      skills.includes(skill._id)
    );
    const filteredTopics = Object.values(allTopics).filter((topic) =>
      topics.includes(topic._id)
    );

    const difficultySettings = getDifficultSettingsByLevel("hard");
    const data = {
      ...formik.values,
      skills: filteredSkills,
      topics: filteredTopics,
    };

    setPreviewData(data);
  };

  const validationSchema = yup.object({
    question: yup.string().required("Problem Statement is required"),

    multipleAnswers: yup.boolean().notRequired(),

    answers: yup
      .array()
      .of(
        yup.object().shape({
          option: yup
            .string()
            .min(1, "Option cannot be empty")
            .required("Option is required"),
          optionId: yup
            .string()
            .min(1, "Option ID cannot be empty")
            .required("Option ID is required"),
        })
      )
      .required("Answers are required")
      .min(1, "At least one answer is required"),

    correctAnswers: yup
      .array(
        yup
          .string()
          .required()

          .min(1, "Each answer must be at least 1 character long")
      )

      .min(1, "At least one correct answer is required")
      .required("Correct Answers are required")
      .default([])

      // .test(
      //   "not-empty-strings",
      //   "Array cannot contain empty strings or undefined values",
      //   (value) => {
      //     if (!value) return false;
      //     return value.every(
      //       (item) => item !== undefined && item.trim() !== ""
      //     );
      //   }
      // )
      .when("multipleAnswers", {
        is: true,
        then: () => yup.array().min(2, "At least 2 Answers are required"),
        otherwise: () => yup.array().min(1, "At least 1 Answer is required"),
      }),

    score: yup
      .number()
      .typeError("Score must be a number")
      .min(1, "Score must be at least 1")
      .required("Score is required")
      .max(20, "Score cannot be more than 20"),

    topics: yup
      .array()
      .of(yup.string())
      .min(1, "At least one topic is required")
      .required("At least one topic is required"),

    skills: yup
      .array()
      .of(yup.string())
      .min(1, "At least one skill is required")
      .required("At least one skill is required"),

    questionTitle: yup
      .string()
      .required("Problem Title is required")
      .test(
        "max-words",
        "Problem Title cannot be more than 8 words",
        (value) => {
          if (!value) return true; // Return true if value is not provided
          const wordCount = value.trim().split(/\s+/).length; // Split by whitespace and count words
          return wordCount <= 8; // Check if word count is less than or equal to 8
        }
      ),
  });

  const draftValidationSchema = yup.object({
    questionTitle: yup
      .string()
      .required("Problem Title is required")
      .test(
        "max-words",
        "Problem Title cannot be more than 8 words",
        (value) => {
          if (!value) return true;
          const wordCount = value.trim().split(/\s+/).length;
          return wordCount <= 8;
        }
      ),
  });

  const formik = useFormik({
    initialValues: {
      type: "MCQ",

      question: "",
      multipleAnswers: false,
      answers: [{}],
      correctAnswers: [],
      score: 0,
      difficultyLevelId: "667419282034414ec4383b6b",
      topics: [],
      skills: [],
      questionTitle: "",
      status: "draft",
      images: [],
      isEditorEnable: false,
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      if (!isEditQuestion) {
        handlePublish();
      } else {
        if (fromAssessmentFlow) {
          setShowUpdateInLibraryModal(true);
        } else {
          handleUpdate();
        }
      }
    },

    enableReinitialize: true,
  });

  const handleUpdateOnlyAssessmentQuestion = async () => {
    const difficultyLevel =
      formik.values.difficultyLevelId &&
      (await APICall(
        `/difficultyLevel/${typeof formik.values.difficultyLevelId === "string"
          ? formik.values.difficultyLevelId
          : formik.values.difficultyLevelId._id
        }`
      ));

    const editedQuestion = {
      ...question,
      questionTitle: formik.values.questionTitle,
      question: formik.values.question,
      multipleAnswers: formik.values.multipleAnswers,
      score: parseInt(formik.values.score),
      ...(difficultyLevel?.data && { difficultyLevelId: difficultyLevel.data }),
      skills: allSkills.filter((skill) =>
        formik.values.skills.includes(skill._id)
      ),
      topics: allTopics.filter((topic) =>
        formik.values.topics.includes(topic._id)
      ),
      status: formik.values.status,
      images: formik.values.images,
      isEditorEnable: formik.values.isEditorEnable,
      answers: formik.values.answers,
      correctAnswers: formik.values.correctAnswers,
      type: formik.values.type,
    };

    if (editedQuestion.skills.length > 1) {
      setUpdatedQuestion((prev) => ({
        ...prev,
        question: editedQuestion,
      }));
      setOpenMultipleSkillModal(true);
    } else {
      handleUpdateAssessmentQuestion({
        question: editedQuestion,
        assignedSkill: editedQuestion.skills[0],
      });
    }
  };

  const updateCorrectAnswers = () => {
    const { answers, multipleAnswers } = formik.values;

    if (!answers || answers.length === 0) {
      return;
    }

    let correctAnswers;

    if (multipleAnswers) {
      correctAnswers = selectedOptions.map((selectedOption) => {
        const answer = answers[selectedOption - 1];
        if (answer) {
          return answer.optionId;
        }
        return null;
      });
    } else {
      const answer = answers[selectedOption - 1];
      if (answer) {
        correctAnswers = [answer.optionId];
      } else {
        correctAnswers = [];
      }
    }

    const sanitizedCorrectAnswers = correctAnswers.filter(
      (item) => item !== null && item !== undefined
    );

    formik.setFieldValue("correctAnswers", sanitizedCorrectAnswers);
  };

  return (
    <>
      <>
        {/* Update Question in Library Modal */}
        <CommonModal
          icon={"/assets/save_icon.svg"}
          open={showUpdateInLibraryModal}
          title={"Update Question in Library"}
          onSubmit={async () => {
            await handleUpdate();
            await handleUpdateOnlyAssessmentQuestion();
            setShowUpdateInLibraryModal(false);
            formik.resetForm();
          }}
          sendButtonName={"Yes"}
          cancelButtonName={"No"}
          cancelButtonAction={async () => {
            await handleUpdateOnlyAssessmentQuestion();
            setShowUpdateInLibraryModal(false);
            formik.resetForm();
          }}
          isDeleting={true}
          handleClose={() => setShowUpdateInLibraryModal(false)}
        >
          <Typography
            style={{
              backgroundColor: "#fff0e7",
              padding: "15px",
              borderRadius: "5px",
            }}
          >
            <img
              src="/assets/alert-1.svg"
              alt="Alert"
              style={{
                verticalAlign: "middle",
                marginRight: "5px",
                height: "26px",
              }}
            />
            <span>
              Would you like to update this question in the library as well?
              Selecting <strong>Yes</strong> will update the question in both
              the library and the test. Choosing <strong>No</strong> will update
              it only in the test.
            </span>
          </Typography>
        </CommonModal>

        {/* Multiple Skill Modal */}
        <CommonModal
          icon={"/assets/check.svg"}
          handleClose={() => setOpenMultipleSkillModal(false)}
          open={openMultipleSkillModal}
          title={
            "Highlight the skill you wish to associate the question with..."
          }
          onSubmit={async () => {
            handleUpdateAssessmentQuestion(updatedQuestion);
            setOpenMultipleSkillModal(false);
          }}
          sendButtonName={"Add Question"}
        >
          <Typography
            style={{
              backgroundColor: "#fff0e7",
              padding: "15px",
              borderRadius: "5px",
            }}
          >
            <strong>Q. {updatedQuestion?.question?.questionTitle}</strong>
            <br />
            <div style={{ verticalAlign: "middle", fontSize: "14px" }}>
              Suggested Skills:
              <br />
              {updatedQuestion?.question?.skills?.map((skill) => (
                <FormControlLabel
                  key={skill._id}
                  control={
                    <Radio
                      checked={
                        skill?._id === updatedQuestion?.assignedSkill?._id
                      }
                      onChange={(e) => {
                        setUpdatedQuestion((prev) => ({
                          ...prev,
                          assignedSkill: skill,
                        }));
                      }}
                      value={skill}
                      name="skill-radio-button"
                      inputProps={{ "aria-label": skill }}
                      sx={{ padding: "10px 2px " }}
                    />
                  }
                  label={skill.name}
                  sx={{ marginLeft: "0px" }}
                />
              ))}
            </div>
          </Typography>
        </CommonModal>
        <Drawer
          // variant="temporary"
          anchor="right"
          open={open}
          onClose={onClose}
          sx={{
            backdropFilter: "blur(.1875rem)",
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: { base: "100%", xss: "auto" },
            },
          }}
          BackdropProps={{
            sx: {
              backgroundColor: open ? "rgba(49, 62, 79, 0.7)" : "transparent",
            },
          }}
        >
          <Box
            sx={{
              minWidth: {
                base: "0rem",
                sm: "720px", // tablet (fixed width)
                md: "1008px", // desktop
              },
              height: "100vh",
            }}
          >
            <Box
              sx={{
                padding: ".75rem 1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: ".0625rem solid #E9EAEB",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "700",
                    color: "#363E45",
                    fontFamily: "Roboto",
                    lineHeight: "1.25rem",
                  }}
                >
                  {drawerHeading}
                </Typography>
              </Box>
              <Box onClick={onClose} sx={{ cursor: "pointer" }}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.00099 18.7008C5.8625 18.7008 5.72712 18.6597 5.61198 18.5828C5.49683 18.5058 5.40708 18.3965 5.35408 18.2685C5.30108 18.1405 5.28722 17.9997 5.31424 17.8639C5.34125 17.7281 5.40794 17.6033 5.50587 17.5053L17.5059 5.5041C17.6373 5.37356 17.8152 5.30045 18.0005 5.30078C18.1857 5.30112 18.3633 5.37487 18.4943 5.50589C18.6253 5.63691 18.6991 5.81451 18.6994 5.9998C18.6997 6.18508 18.6266 6.36295 18.4961 6.49444L6.49611 18.4957C6.43113 18.5608 6.35395 18.6124 6.26899 18.6476C6.18403 18.6828 6.09295 18.7009 6.00099 18.7008Z"
                    fill="#71777B"
                  />
                  <path
                    d="M18.001 18.7008C17.909 18.7009 17.818 18.6828 17.733 18.6476C17.648 18.6124 17.5708 18.5608 17.5059 18.4957L5.50587 6.49444C5.37534 6.36295 5.30224 6.18508 5.30258 5.9998C5.30291 5.81451 5.37666 5.63691 5.50766 5.50589C5.63867 5.37487 5.81625 5.30112 6.00152 5.30078C6.18679 5.30045 6.36464 5.37356 6.49611 5.5041L18.4961 17.5053C18.594 17.6033 18.6607 17.7281 18.6877 17.8639C18.7148 17.9997 18.7009 18.1405 18.6479 18.2685C18.5949 18.3965 18.5052 18.5058 18.39 18.5828C18.2749 18.6597 18.1395 18.7008 18.001 18.7008Z"
                    fill="#71777B"
                  />
                </svg>
              </Box>
            </Box>

            <Box
              sx={{
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "20px",
                }}
              >
                Problem Title
                <Box
                  component="span"
                  sx={{ color: "#F94948", marginLeft: "4px" }}
                >
                  *
                </Box>
              </Typography>
              <Box sx={{ marginBottom: "10px", marginTop: "4px" }}>
                <TextField
                  id="questionTitle"
                  name="questionTitle"
                  fullWidth
                  required={true}
                  value={formik.values.questionTitle}
                  defaultValue={formik.values.questionTitle}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.questionTitle &&
                    Boolean(formik.errors.questionTitle)
                  }
                  helperText={
                    formik.touched.questionTitle && formik.errors.questionTitle
                  }
                  sx={{
                    width: "100%",
                    height: "auto",
                    "& .MuiInputBase-root": {
                      height: "38px",
                    },
                  }}
                  placeholder="Enter Problem Title"
                />
              </Box>

              <Box>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "20px",
                  }}
                >
                  Problem statement
                  <Box
                    component="span"
                    sx={{ color: "#F94948", marginLeft: "4px" }}
                  >
                    *
                  </Box>
                </Typography>
                <Box sx={{ width: "100%", overflowX: "hidden" }}>
                  <SunTextEditor
                    id="question"
                    name="question"
                    fullWidth
                    required={true}
                    value={formik.values.question}
                    setValue={(value) => {
                      formik.setFieldValue("question", value);
                    }}
                    onBlur={() => formik.setFieldTouched("question", true)}
                    error={
                      formik.touched.question && Boolean(formik.errors.question)
                    }
                    helperText={
                      formik.touched.question && formik.errors.question
                    }
                    // width="1000px"
                    // width={{ base: "90%", md: "1000px" }}
                    placeholder="Enter Problem Statement"
                  />
                </Box>
              </Box>

              {formik.touched.question && formik.errors.question && (
                <FormHelperText error>{formik.errors.question}</FormHelperText>
              )}

              <Stack
                direction={{ base: "column", sm: "column", md: "row" }}
                sx={{
                  marginTop: "20px",
                }}
              >
                <Box>
                  {" "}
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "20px",
                    }}
                  >
                    Maximum Score
                    <Box
                      component="span"
                      sx={{ color: "#F94948", marginLeft: "4px" }}
                    >
                      *
                    </Box>
                  </Typography>
                  <Box sx={{ marginBottom: "10px", marginTop: "4px" }}>
                    <TextField
                      id="score"
                      name="score"
                      fullWidth
                      required={true}
                      value={formik.values.score}
                      defaultValue={formik.values.score}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        maxLength: 10,
                      }}
                      error={
                        formik.touched.score && Boolean(formik.errors.score)
                      }
                      helperText={formik.touched.score && formik.errors.score}
                      sx={{
                        width: { base: "100%", xss: "470px" },
                        height: "auto",
                        "& .MuiInputBase-root": {
                          height: "38px",
                        },
                      }}
                      placeholder="Enter Maximum Score"
                    />
                  </Box>
                </Box>
                <Stack
                  direction={"column"}
                  sx={{ flex: 1, marginLeft: { base: 0, md: 2 } }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "20px",
                      marginBottom: "4px",
                    }}
                  >
                    Difficulty<span style={{ color: "#F94948" }}> *</span>
                  </Typography>
                  <Stack
                    direction={"row"}
                    gap={"12px"}
                    sx={{
                      [theme.breakpoints.down("sm")]: {
                        flexDirection: "column",
                      },
                      height: { base: "auto", md: "38px" },
                    }}
                  >
                    {difficultyButtons.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          padding: "10px 8px",
                          // width: "fit-content",
                          borderRadius: "4px",
                          border: `1px solid ${selectedDifficulty === item.difficulty
                            ? item.color
                            : "#E8E9EE"
                            }`,
                          cursor: "pointer",
                          flexGrow: 1,
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          handleDifficultyChange(item.difficulty);
                          formik.setFieldValue("difficultyLevelId", item.id);
                        }}
                      >
                        <img
                          src={
                            selectedDifficulty === item.difficulty
                              ? item.path
                              : item.selectedPath
                          }
                          alt="difficulty"
                        />
                        <Typography
                          sx={{
                            color:
                              selectedDifficulty === item.difficulty
                                ? item.color
                                : "#363E45",
                            fontSize: "14px",
                            fontFamily: "Roboto",
                            fontWeight:
                              selectedDifficulty === item.difficulty
                                ? 600
                                : 400,
                            lineHeight: "20px",
                          }}
                        >
                          {item.difficulty}
                        </Typography>
                      </Box>
                    ))}
                  </Stack>
                  {!selectedDifficulty && (
                    <Typography sx={{ color: "#F94948", fontSize: "12px" }}>
                      Please select a difficulty
                    </Typography>
                  )}
                </Stack>
              </Stack>
              <Stack direction={"column"} gap={"16px"}>
                <Stack direction={{ sm: "column", md: "row" }} gap={"10px"}>
                  <Stack direction={"column"} gap={"4px"} flexGrow={1}>
                    <Stack direction={"row"} gap={"6px"} alignItems={"center"}>
                      <Typography
                        sx={{
                          fontFamily: "Roboto",
                          fontWeight: 500,
                          lineHeight: "20px",
                          marginTop: "4px",
                        }}
                      >
                        Choices
                      </Typography>
                      <img
                        src="/assets/icons/infoCircle.svg"
                        alt="visit"
                        width={"16px"}
                        height={"16px"}
                        style={{ marginTop: "4px" }}
                      />
                    </Stack>
                    <Typography
                      sx={{
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "#535A5F",
                      }}
                    >
                      Enter the options and mark the corresponding correct
                      answer below
                    </Typography>
                  </Stack>

                  <FormGroup
                    sx={{
                      flexDirection: "row",
                      gap: "16px",
                      marginTop: "4px",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={multipleChoice}
                          onChange={handleMultipleChoiceChange}
                        />
                      }
                      label="Multiple Choice"
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontFamily: "Roboto",
                          fontWeight: 400,
                          fontSize: "14px",
                          lineHeight: "20px",
                        },
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            sx={{ m: 1 }}
                            checked={richtextBox}
                            onChange={handleRichtextBoxChange}
                          />
                        }
                        label="Rich Text Editor"
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "20px",
                          },
                        }}
                      />
                      <img
                        src="/assets/icons/infoCircle.svg"
                        alt="visit"
                        width={"16px"}
                        height={"16px"}
                      />
                    </Box>
                  </FormGroup>
                </Stack>
                <Stack direction="column" spacing={2}>
                  <AddOptions
                    multipleChoice={multipleChoice}
                    required={true}
                    richtextBox={richtextBox}
                    formik={formik}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    updateCorrectAnswers={updateCorrectAnswers}
                    options={options}
                    setOptions={setOptions}
                  />
                </Stack>

                <SkillInput
                  allSkills={allSkills}
                  setAllSkills={setAllSkills}
                  allTopics={allTopics}
                  skills={skills}
                  setSkills={setSkills}
                  topics={topics}
                  setAllTopics={setAllTopics}
                  setTopics={setTopics}
                  formik={formik}
                  fetchSkills={fetchSkills}
                  skillTopicsCache={skillTopicsCache}
                  setIsDataFetched={setIsDataFetched}
                  isDataFetched={isDataFetched}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                />
              </Stack>
            </Box>
            {/* footer  */}

            <Box
              sx={{
                padding: "12px 20px",
                display: "flex",
                flexDirection: {
                  base: "column",
                  xs: "column",
                  sm: "column",
                  md: "row",
                },
                justifyContent: "space-between",
                borderTop: "1px solid #E9EAEB",
                boxSizing: "border-box",
                gap: "16px",
                alignItems: "flex-end",
              }}
            >
              <Stack direction={"row"} gap={"8px"}>
                <Button
                  size="small"
                  color="secondary"
                  variant="outlined"
                  onClick={() => {
                    setSaveDraft(true);
                    updateCorrectAnswers();
                    //formik.handleSubmit();
                    handleDraft();
                  }}
                  sx={{
                    padding: "10px 16px",
                    height: "34px",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "20px",
                  }}
                >
                  Save as Draft
                </Button>
                <Button
                  onClick={handleQuestionPreview}
                  size="small"
                  color="secondary"
                  variant="outlined"
                  sx={{
                    padding: "10px 16px",
                    height: "34px",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "20px",
                  }}
                >
                  Preview
                </Button>
              </Stack>
              <Stack direction={"row"} gap={"8px"}>
                <Button
                  color="secondary"
                  variant="outlined"
                  sx={{
                    padding: "10px 16px",
                    height: "34px",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "20px",
                    // opacity: "0.5",
                  }}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    padding: "10px 16px",
                    height: "34px",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "20px",
                    backgroundColor: "#00C49A",
                    fontFamily: "Roboto",
                    color: "#fff",
                  }}
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  {isEditQuestion ? "Publish Changes" : "Publish"}
                </Button>
              </Stack>
            </Box>
          </Box>
          {questionOpen && (
            <QuestionPreview
              open={questionOpen}
              onClose={handleQuestionsClose}
              question={previewData}
              difficultySettings={getDifficultSettingsByLevel(
                selectedDifficulty.toLowerCase()
              )}
            />
          )}
          {loading && <LoadingScreen />}
        </Drawer>
      </>
    </>
  );
};

export default AddQuestionDrawer;

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#FF6812",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 20 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#BBC2CC" : "#FF6812",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
