import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography, Stack, Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    gap: "12px",
    padding: "0px",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  mainWrapper: {
    borderRadius: "4px",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    padding: "12px",
    gap: "8px",
    boxShadow:
      "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
    borderRight: "4px",
    flexGrow: "1",
  },
  ContainerBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "12px",
    flexGrow: "1",
  },
  iconContainer: {
    padding: "8px",
    borderRadius: "4px",
    opacity: "0px",
    backgroundColor: theme.palette.background.gray,
  },
  textContainer: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
    textAlign: "left",
  },
  totalCount: {
    fontFamily: "Nunito",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "24px",
    textAlign: "left",
    color: theme.palette.blackButton.main,
  },
  mainHeading: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "#535A5F",
    fontWeight: 400,
    fontFamily: "Roboto",
  },
  button: {
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "Roboto",
    fontWeight: 400,
    color: theme.palette.secondary.main,
  },
}));

const CandidatesCard = ({
  totalCandidates,
  duplicates,
  recentlyAppeared,
  removeDuplicates,
  removeRecentlyAppeared,
}) => {
  const classes = useStyles();

  const CandidatesData = [
    {
      title: "Total Candidates",
      total: totalCandidates || 0,
      icon: "/assets/icons/Users.svg",
    },
    {
      title: "Duplicates",
      total: duplicates || 0,
      icon: "/assets/icons/duplicate.svg",
    },
    {
      title: "Recently Appeared",
      total: recentlyAppeared || 0,
      icon: "/assets/checklist copy.svg",
    },
  ];

  return (
    <Stack
      direction={{ sm: "column", md: "row" }}
      alignItems={"center"}
      gap={"12px"}
    >
      {CandidatesData.map((item) => (
        <Stack
          key={item.title}
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"flex-end"}
          gap={"8px"}
          alignSelf={"stretch"}
          sx={{
            padding: "12px",
            boxShadow:
              " 0px 1px 3px rgba(16, 24, 40, 0.1),0px 1px 2px rgba(16, 24, 40, 0.06)",
            borderRadius: "4px",
            backgroundColor: "#FFFFFF",
            gap: "8px",
          }}
          flexGrow={1}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={"12px"}
            flexGrow={1}
          >
            {/* icon style */}
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                width: "24px",
                height: "24px",
                backgroundColor: "rgba(56, 68, 85, 0.1)",
                borderRadius: "4px",
              }}
            >
              <Box
                component={"img"}
                src={item.icon}
                sx={{ width: "16px", height: "16px" }}
              ></Box>
            </Stack>
            <Stack direction={"column"} alignItems={"flex-start"}>
              <Typography variant={"h6"} className={classes.mainHeading}>
                {item.title}
              </Typography>
              <Typography variant={"body1"} className={classes.totalCount}>
                {item.total}
              </Typography>
            </Stack>
          </Stack>
          {item.title === "Total Candidates" ? (
            <></>
          ) : (
            item.total > 0 && (
              <Button
                className={classes.button}
                onClick={
                  item.title === "Duplicates"
                    ? removeDuplicates
                    : removeRecentlyAppeared
                }
                style={{ color: "#ff6812" }}
              >
                Remove
              </Button>
            )
          )}
        </Stack>
      ))}
    </Stack>
  );
};

export default CandidatesCard;
