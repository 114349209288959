function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = "/dashboard";
// const JOB_OPENINGS = "/job_openings";
const ASSESSMENTS = "/assessments";
const LIBRARY = "/library";
const CANDIDATES = "/candidates";
const INTERVIEWS = "/interviews";
const CALENDAR = "/interviews/recruiter_calender";
const TALENT_POOL = "/talent_pool";
const SETTINGS = "/settings";
const ACCOUNT = "/account";
const QUESTIONS = "/assessments/analytics";
const ForgetPassword = "/forget_password";
const ResetPassword = "/reset_password";
const AutoTest = "/assessments/autotest";
const CustomTest = "/assessments/customtest";
const Template_Library = "/assessments/template_library";
const ChooseQueLibrary = "/assessments/choose_question_library";
const CHATBOT = "/chatbot";
const ContactSupport = "/contactSupport";
const Round1Preview = "/roundPreview";
export const PATH_AUTH = {
  login: "/login",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  // job_openings: JOB_OPENINGS,
  assessments: ASSESSMENTS,
  library: LIBRARY,
  candidates: CANDIDATES,
  interviews: INTERVIEWS,
  calendar: CALENDAR,
  talent_pool: TALENT_POOL,
  settings: SETTINGS,
  account: ACCOUNT,
  questions: QUESTIONS,
  forget_password: ForgetPassword,
  reset_password: ResetPassword,
  auto_test: AutoTest,
  custom_test: CustomTest,
  template_library: Template_Library,
  choose_question_library: ChooseQueLibrary,
  chatbot: CHATBOT,
  contactSupport: ContactSupport,
  roundPreview: Round1Preview,
};
