import React from "react";
import Chart from "react-apexcharts";
import "./skills.css";
const SkillsChart = ({ skillQuestionData }) => {
  const allCounts = skillQuestionData?.flatMap((data) => [
    data.passedCount,
    data.intermediateCount,
    data.trainingRequiredCount,
  ]);
  const maxCount = allCounts.length > 0 ? Math.max(...allCounts) : 10;
  const yAxisMax = Math.ceil(maxCount / 10) * 10;
  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: false,
      },
    },
    xaxis: {
      categories: skillQuestionData?.map((skill) =>
        skill?.skillName
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      ),
      tickPlacement: "off",
      axisTicks: {
        show: false, // Hide the border line on the X-axis if needed
      },
      axisBorder: {
        show: true, // Hide the border line on the X-axis if needed
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    fill: {
      opacity: 1,
    },
    yaxis: {
      //   title: {
      //     text: "Points",
      //   },
      min: 0, // Set Y-axis minimum value
      max: yAxisMax,
    },
    grid: {
      show: true,
      strokeDashArray: 5, // Add dotted lines
      borderColor: "#e0e0e0", // Optional: change color if needed
    },
    colors: ["#FF9595", "#EFB02E", "#00C49A"],
    // tooltip: {
    //   enabled: false, // Disable tooltips on hover
    // },
  };

  const series = [
    {
      name: "Training Required",
      data: skillQuestionData?.map((skill) => skill?.trainingRequiredCount),
    },
    {
      name: "Intermediate",
      data: skillQuestionData?.map((skill) => skill?.intermediateCount),
    },
    {
      name: "Passed",
      data: skillQuestionData?.map((skill) => skill?.passedCount),
    },
  ];

  return (
    <div className="skills-chart">
      <Chart
        options={options}
        series={series}
        type="bar"
        width="100%"

        height="100%"
      />
    </div>
  );
};

export default SkillsChart;
