export const candidate_shortlisted_table_rows = [
  {
    name: "Alfonso Passaquindici Arcand",
    score: "98",
    interviewer: "Alfonso Passaquindici Arcand",
    job: "Javascript Developer",
    status: {
      id: 2,
      tag: "Hired",
    },
    id: 1,
  },
  {
    name: "Alfonso Passaquindici Arcand",
    score: "98",
    interviewer: "Alfonso Passaquindici Arcand",
    job: "Javascript Developer",
    status: {
      id: 0,
      tag: "R2 Scheduled",
    },
    id: 2,
  },
  {
    name: "Alfonso Passaquindici Arcand",
    score: "98",
    interviewer: "Alfonso Passaquindici Arcand",
    job: "Javascript Developer",
    status: {
      id: 2,
      tag: "Hired",
    },
    id: 3,
  },
  {
    name: "Alfonso Passaquindici Arcand",
    score: "98",
    interviewer: "Alfonso Passaquindici Arcand",
    job: "Javascript Developer",
    status: {
      id: 0,
      tag: "HR Scheduled",
    },
    id: 4,
  },
  {
    name: "Alfonso Passaquindici Arcand",
    score: "98",
    interviewer: "Alfonso Passaquindici Arcand",
    job: "Javascript Developer",
    status: {
      id: 2,
      tag: "Hired",
    },
    id: 5,
  },
  {
    name: "Gustavo Mango",
    score: "88",
    interviewer: "Gustavo Mango",
    job: "Javascript Developer",
    status: {
      id: 1,
      tag: "R1 Passed",
    },
    id: 8,
  },
  {
    name: "Roger Dokidis",
    score: "67",
    interviewer: "Roger Dokidis",
    job: "Javascript Developer",
    status: {
      id: 0,
      tag: "R1 Scheduled",
    },
    id: 16,
  },
  {
    name: "Gustavo Mango",
    score: "88",
    interviewer: "Gustavo Mango",
    job: "Javascript Developer",
    status: {
      id: 4,
      tag: "Shortlisted",
    },
    id: 9,
  },
  {
    name: "Alfonso Passaquindici Arcand",
    score: "98",
    interviewer: "Alfonso Passaquindici Arcand",
    job: "Javascript Developer",
    status: {
      id: 2,
      tag: "Hired",
    },
    id: 6,
  },
  {
    name: "Alfonso Passaquindici Arcand",
    score: "98",
    interviewer: "Alfonso Passaquindici Arcand",
    job: "Javascript Developer",
    status: {
      id: 2,
      tag: "Hired",
    },
    id: 7,
  },

  {
    name: "Gustavo Mango",
    score: "88",
    interviewer: "Gustavo Mango",
    job: "Javascript Developer",
    status: {
      id: 3,
      tag: "R1 Failed",
    },
    id: 10,
  },
  {
    name: "Gustavo Mango",
    score: "88",
    interviewer: "Gustavo Mango",
    job: "Javascript Developer",
    status: {
      id: 1,
      tag: "R1 Passed",
    },
    id: 11,
  },
  {
    name: "Gustavo Mango",
    score: "88",
    interviewer: "Gustavo Mango",
    job: "Javascript Developer",
    status: {
      id: 1,
      tag: "R2 Passed",
    },
    id: 12,
  },
  {
    name: "Gustavo Mango",
    score: "88",
    interviewer: "Gustavo Mango",
    job: "Javascript Developer",
    status: {
      id: 1,
      tag: "R1 Passed",
    },
    id: 13,
  },
  {
    name: "Gustavo Mango",
    score: "88",
    interviewer: "Gustavo Mango",
    job: "Javascript Developer",
    status: {
      id: 1,
      tag: "R1 Passed",
    },
    id: 14,
  },
  {
    name: "Roger Dokidis",
    score: "67",
    interviewer: "Roger Dokidis",
    job: "Javascript Developer",
    status: {
      id: 0,
      tag: "R2 Scheduled",
    },
    id: 15,
  },

  {
    name: "Roger Dokidis",
    score: "67",
    interviewer: "Roger Dokidis",
    job: "Javascript Developer",
    status: {
      id: 3,
      tag: "R2 Failed",
    },
    id: 17,
  },
  {
    name: "Roger Dokidis",
    score: "67",
    interviewer: "Roger Dokidis",
    job: "Javascript Developer",
    status: {
      id: 0,
      tag: "R1 Scheduled",
    },
    id: 18,
  },
  {
    name: "Roger Dokidis",
    score: "67",
    interviewer: "Roger Dokidis",
    job: "Javascript Developer",
    status: {
      id: 5,
      tag: "R1 Pending",
    },
    id: 19,
  },
  {
    name: "Roger Dokidis",
    score: "67",
    interviewer: "Roger Dokidis",
    job: "Javascript Developer",
    status: {
      id: 0,
      tag: "R1 Scheduled",
    },
    id: 20,
  },
  {
    name: "Roger Dokidis",
    score: "67",
    interviewer: "Roger Dokidis",
    job: "Javascript Developer",
    status: {
      id: 5,
      tag: "R2 Pending",
    },
    id: 21,
  },
];
export const assessments_candidate_shortlisted_table_rows = [
  {
    candidate: "Alfonso Passaquindici Arcand",
    score: "98",
    attempt: "83",
    shortlistedat: "19/03/2024 12:12:22",
    testduration: "15:36",
    id: 1,
  },
  {
    candidate: "Alfonso Passaquindici Arcand",
    score: "98",
    attempt: "89",
    interviewer: "Alfonso Passaquindici Arcand",
    shortlistedat: "15/03/2024 12:10:22",
    testduration: "10:36",
    id: 2,
  },
  {
    candidate: "Alfonso Passaquindici Arcand",
    score: "98",
    attempt: "100",
    interviewer: "Alfonso Passaquindici Arcand",
    shortlistedat: "10/04/2024 11:15:22",
    testduration: "07:30",
    id: 3,
  },
  {
    candidate: "Gustavo Mango",
    score: "88",
    attempt: "60",
    shortlistedat: "30/09/2024 01:15:35",
    testduration: "23:10",
    id: 8,
  },
  {
    candidate: "Alfonso Passaquindici Arcand",
    score: "98",
    attempt: "77",
    shortlistedat: "01/01/2024 10:10:22",
    testduration: "22:15",
    id: 5,
  },
  {
    candidate: "Gustavo Mango",
    score: "88",
    attempt: "90",
    shortlistedat: "21/12/2024 06:16:45",
    testduration: "17:20",
    id: 9,
  },
  {
    candidate: "Alfonso Passaquindici Arcand",
    score: "98",
    attempt: "83",
    shortlistedat: "22/06/2024 10:03:20",
    testduration: "15:36",
    id: 4,
  },

  {
    candidate: "Alfonso Passaquindici Arcand",
    score: "98",
    attempt: "96",
    shortlistedat: "01/02/2024 03:04:22",
    testduration: "05:50",
    id: 6,
  },
  {
    candidate: "Alfonso Passaquindici Arcand",
    score: "98",
    attempt: "90",
    shortlistedat: "19/03/2024 12:12:22",
    testduration: "15:36",
    id: 7,
  },

  {
    candidate: "Gustavo Mango",
    score: "88",
    attempt: "75",
    shortlistedat: "25/03/2024 10:10:10",
    testduration: "17:17",
    id: 10,
  },
];

export const test_admin_table_rows = [
  {
    isChecked: false,
    name: "Alfonso Arcand",
    email: "alfonso@gmail.com",
    channel: "Software Engineer",
  },
  {
    isChecked: false,
    name: "Gustavo Mango",
    email: "mango@gmail.com",
    channel: "Quality Assurance (QA)",
  },
  {
    isChecked: false,
    name: "Alfonso Arcand",
    email: "alfonso@gmail.com",
    channel: "Software Engineer",
  },
];

export const candidates_reviewed_table_rows = [
  {
    candidate: "Alfonso Passaquindici Arcand",
    score: "98",
    attempt: "83",
    shortlistedat: "19/03/2024 12:12:22",
    testduration: "15:36",
    id: 1,
  },
  {
    candidate: "Alfonso Passaquindici Arcand",
    score: "98",
    attempt: "89",
    interviewer: "Alfonso Passaquindici Arcand",
    shortlistedat: "15/03/2024 12:10:22",
    testduration: "10:36",
    id: 2,
  },
  {
    candidate: "Alfonso Passaquindici Arcand",
    score: "98",
    attempt: "100",
    interviewer: "Alfonso Passaquindici Arcand",
    shortlistedat: "10/04/2024 11:15:22",
    testduration: "07:30",
    id: 3,
  },
  {
    candidate: "Alfonso Passaquindici Arcand",
    score: "98",
    attempt: "83",
    shortlistedat: "22/06/2024 10:03:20",
    testduration: "15:36",
    id: 4,
  },
  {
    candidate: "Alfonso Passaquindici Arcand",
    score: "98",
    attempt: "77",
    shortlistedat: "01/01/2024 10:10:22",
    testduration: "22:15",
    id: 5,
  },
  {
    candidate: "Alfonso Passaquindici Arcand",
    score: "98",
    attempt: "96",
    shortlistedat: "01/02/2024 03:04:22",
    testduration: "05:50",
    id: 6,
  },
  {
    candidate: "Alfonso Passaquindici Arcand",
    score: "98",
    attempt: "90",
    shortlistedat: "19/03/2024 12:12:22",
    testduration: "15:36",
    id: 7,
  },
  {
    candidate: "Gustavo Mango",
    score: "88",
    attempt: "60",
    shortlistedat: "30/09/2024 01:15:35",
    testduration: "23:10",
    id: 8,
  },
  {
    candidate: "Gustavo Mango",
    score: "88",
    attempt: "90",
    shortlistedat: "21/12/2024 06:16:45",
    testduration: "17:20",
    id: 9,
  },
  {
    candidate: "Gustavo Mango",
    score: "88",
    attempt: "75",
    shortlistedat: "25/03/2024 10:10:10",
    testduration: "17:17",
    id: 10,
  },
];

export const candidates_passed_table_rows = [
  {
    candidate: "Alfonso Passaquindici Arcand",
    score: "98",
    attempt: "83",
    shortlistedat: "19/03/2024 12:12:22",
    testduration: "15:36",
    status: { id: 0, tag: "shortlisted" },
    cheated: true,
  },
  {
    candidate: "Alfonso Passaquindici Arcand",
    score: "98",
    attempt: "89",
    interviewer: "Alfonso Passaquindici Arcand",
    shortlistedat: "15/03/2024 12:10:22",
    testduration: "10:36",
    status: { id: 1, tag: "reviewed" },
  },
  {
    candidate: "Alfonso Passaquindici Arcand",
    score: "98",
    attempt: "100",
    interviewer: "Alfonso Passaquindici Arcand",
    shortlistedat: "10/04/2024 11:15:22",
    testduration: "07:30",
    status: { id: 0, tag: "shortlisted" },
  },
  {
    candidate: "Alfonso Passaquindici Arcand",
    score: "98",
    attempt: "83",
    shortlistedat: "22/06/2024 10:03:20",
    testduration: "15:36",
    status: { id: 1, tag: "reviewed" },
  },
  {
    candidate: "Alfonso Passaquindici Arcand",
    score: "98",
    attempt: "77",
    shortlistedat: "01/01/2024 10:10:22",
    testduration: "22:15",
    id: 5,
    status: { id: 1, tag: "reviewed" },
  },
  {
    candidate: "Alfonso Passaquindici Arcand",
    score: "98",
    attempt: "96",
    shortlistedat: "01/02/2024 03:04:22",
    testduration: "05:50",
    id: 6,
    status: { id: 2, tag: "shortlist" },
  },
  {
    candidate: "Alfonso Passaquindici Arcand",
    score: "98",
    attempt: "90",
    shortlistedat: "19/03/2024 12:12:22",
    testduration: "15:36",
    status: { id: 2, tag: "shortlist" },
  },
  {
    candidate: "Gustavo Mango",
    score: "88",
    attempt: "60",
    shortlistedat: "30/09/2024 01:15:35",
    testduration: "23:10",
    status: { id: 0, tag: "shortlisted" },
    cheated: true,
  },
  {
    candidate: "Gustavo Mango",
    score: "88",
    attempt: "90",
    shortlistedat: "21/12/2024 06:16:45",
    testduration: "17:20",
    status: { id: 1, tag: "reviewed" },
  },
  {
    candidate: "Gustavo Mango",
    score: "88",
    attempt: "75",
    shortlistedat: "25/03/2024 10:10:10",
    testduration: "17:17",
    status: { id: 2, tag: "Shortlist" },
  },
];
export const settings_channels_table_rows = [
  {
    id: 1,
    channels: "Software Engineer",
    no_users: 1,
    no_invites: 150,
  },
  {
    id: 2,
    channels: "Quality Assurance (QA)",
    no_users: 0,
    no_invites: 155,
  },
  {
    id: 3,
    channels: "Software Engineer",
    no_users: 1,
    no_invites: 150,
  },
  {
    id: 4,
    channels: "Quality Assurance (QA)",
    no_users: 0,
    no_invites: 155,
  },
  {
    id: 5,
    channels: "Software Engineer",
    no_users: 1,
    no_invites: 150,
  },
  {
    id: 6,
    channels: "Quality Assurance (QA)",
    no_users: 0,
    no_invites: 155,
  },
  {
    id: 7,
    channels: "Software Engineer",
    no_users: 1,
    no_invites: 150,
  },
  {
    id: 8,
    channels: "Quality Assurance (QA)",
    no_users: 0,
    no_invites: 155,
  },
  {
    id: 9,
    channels: "Software Engineer",
    no_users: 1,
    no_invites: 150,
  },
  {
    id: 10,
    channels: "Quality Assurance (QA)",
    no_users: 0,
    no_invites: 155,
  },
  {
    id: 4,
    channels: "Quality Assurance (QA)",
    no_users: 0,
    no_invites: 155,
  },
  {
    id: 5,
    channels: "Software Engineer",
    no_users: 1,
    no_invites: 150,
  },
  {
    id: 6,
    channels: "Quality Assurance (QA)",
    no_users: 0,
    no_invites: 155,
  },
  {
    id: 7,
    channels: "Software Engineer",
    no_users: 1,
    no_invites: 150,
  },
  {
    id: 4,
    channels: "Quality Assurance (QA)",
    no_users: 0,
    no_invites: 155,
  },
  {
    id: 5,
    channels: "Software Engineer",
    no_users: 1,
    no_invites: 150,
  },
  {
    id: 6,
    channels: "Quality Assurance (QA)",
    no_users: 0,
    no_invites: 155,
  },
  {
    id: 7,
    channels: "Software Engineer",
    no_users: 1,
    no_invites: 150,
  },
  {
    id: 5,
    channels: "Software Engineer",
    no_users: 1,
    no_invites: 150,
  },
  {
    id: 6,
    channels: "Quality Assurance (QA)",
    no_users: 0,
    no_invites: 155,
  },
];
export const settings_members_table_rows = [
  {
    id: 1,
    name: "Corey Lipshutz",
    gender: "Male",
    mobile: "+91-9874651230",
    email: "corey@company.com",
    channels: "Software Engineer",
    designation: "Senior Software Engineer",
    access: "Recruiter",
    seniority: "L2",
  },
  {
    id: 2,
    name: "Angel Philips",
    gender: "Male",
    mobile: "+91-9874651230",
    email: "angel@company.com",
    channels: "Quality Assurance (QA)",
    designation: "QA Analyst",
    access: " Library Admin",
    seniority: "L3",
  },
  {
    id: 3,
    name: "Corey Lipshutz",
    email: "corey@company.com",
    gender: "Male",
    mobile: "+91-9874651230",
    channels: "Software Engineer",
    designation: "Senior Software Engineer",
    access: "Recruiter",
    seniority: "L2",
  },
  {
    id: 4,
    name: "Angel Philips",
    gender: "Male",
    mobile: "+91-9874651230",
    email: "angel@company.com",
    channels: "Quality Assurance (QA)",
    designation: "QA Analyst",
    access: " Library Admin",
    seniority: "L3",
  },
  {
    id: 5,
    name: "Corey Lipshutz",
    gender: "Male",
    mobile: "+91-9874651230",
    email: "corey@company.com",
    channels: "Software Engineer",
    designation: "Senior Software Engineer",
    access: "Recruiter",
    seniority: "L2",
  },
  {
    id: 6,
    name: "Angel Philips",
    gender: "Male",
    mobile: "+91-9874651230",
    email: "angel@company.com",
    channels: "Quality Assurance (QA)",
    designation: "QA Analyst",
    access: " Library Admin",
    seniority: "L3",
  },
  {
    id: 7,
    name: "Corey Lipshutz",
    gender: "Male",
    mobile: "+91-9874651230",
    email: "corey@company.com",
    channels: "Software Engineer",
    designation: "Senior Software Engineer",
    access: "Recruiter",
    seniority: "L2",
  },
  {
    id: 8,
    name: "Angel Philips",
    gender: "Male",
    mobile: "+91-9874651230",
    email: "angel@company.com",
    channels: "Quality Assurance (QA)",
    designation: "QA Analyst",
    access: " Library Admin",
    seniority: "L3",
  },
  {
    id: 9,
    name: "Corey Lipshutz",
    gender: "Male",
    mobile: "+91-9874651230",
    email: "corey@company.com",
    channels: "Software Engineer",
    designation: "Senior Software Engineer",
    access: "Recruiter",
    seniority: "L2",
  },
  {
    id: 10,
    name: "Angel Philips",
    email: "angel@company.com",
    channels: "Quality Assurance (QA)",
    designation: "QA Analyst",
    access: " Library Admin",
    seniority: "L3",
  },
  {
    id: 4,
    name: "Angel Philips",
    email: "angel@company.com",
    channels: "Quality Assurance (QA)",
    designation: "QA Analyst",
    access: " Library Admin",
    seniority: "L3",
  },
  {
    id: 5,
    name: "Corey Lipshutz",
    email: "corey@company.com",
    channels: "Software Engineer",
    designation: "Senior Software Engineer",
    access: "Recruiter",
    seniority: "L2",
  },
  {
    id: 6,
    name: "Angel Philips",
    email: "angel@company.com",
    channels: "Quality Assurance (QA)",
    designation: "QA Analyst",
    access: " Library Admin",
    seniority: "L3",
  },
  {
    id: 7,
    name: "Corey Lipshutz",
    email: "corey@company.com",
    channels: "Software Engineer",
    designation: "Senior Software Engineer",
    access: "Recruiter",
    seniority: "L2",
  },
  {
    id: 8,
    name: "Angel Philips",
    email: "angel@company.com",
    channels: "Quality Assurance (QA)",
    designation: "QA Analyst",
    access: " Library Admin",
    seniority: "L3",
  },
  {
    id: 9,
    name: "Corey Lipshutz",
    email: "corey@company.com",
    channels: "Software Engineer",
    designation: "Senior Software Engineer",
    access: "Recruiter",
    seniority: "L2",
  },
];
export const settings_invites_table_rows = [
  {
    id: 1,
    channels: "Software Development",
    invites_assigned: 1,
    invites_used: 10,
    invites_remaining: 23,
  },
  {
    id: 2,
    channels: "Quality Assurance (QA)",
    invites_assigned: 3,
    invites_used: 5,
    invites_remaining: 18,
  },
  {
    id: 3,
    channels: "Software Development",
    invites_assigned: 1,
    invites_used: 10,
    invites_remaining: 23,
  },
  {
    id: 4,
    channels: "Quality Assurance (QA)",
    invites_assigned: 3,
    invites_used: 5,
    invites_remaining: 18,
  },
  {
    id: 5,
    channels: "Software Development",
    invites_assigned: 1,
    invites_used: 10,
    invites_remaining: 23,
  },
  {
    id: 6,
    channels: "Quality Assurance (QA)",
    invites_assigned: 3,
    invites_used: 5,
    invites_remaining: 18,
  },
  {
    id: 7,
    channels: "Software Development",
    invites_assigned: 1,
    invites_used: 10,
    invites_remaining: 23,
  },
  {
    id: 8,
    channels: "Quality Assurance (QA)",
    invites_assigned: 3,
    invites_used: 5,
    invites_remaining: 18,
  },
  {
    id: 9,
    channels: "Software Development",
    invites_assigned: 1,
    invites_used: 10,
    invites_remaining: 23,
  },
  {
    id: 10,
    channels: "Quality Assurance (QA)",
    invites_assigned: 3,
    invites_used: 5,
    invites_remaining: 18,
  },
];
export const settings_seniority_table_rows = [
  {
    id: 1,
    channels: "Software Development",
    seniority: ["L2", "L3"],
  },
  {
    id: 2,
    channels: "Quality Assurance (QA)",
    seniority: ["L2", "L3", "L3"],
  },
  {
    id: 3,
    channels: "Software Development",
    seniority: ["L2", "L3"],
  },
  {
    id: 4,
    channels: "Quality Assurance (QA)",
    seniority: ["L2", "L3", "L3"],
  },
  {
    id: 5,
    channels: "Software Development",
    seniority: ["L2", "L3"],
  },
  {
    id: 6,
    channels: "Quality Assurance (QA)",
    seniority: ["L2", "L3", "L3"],
  },
  {
    id: 7,
    channels: "Software Development",
    seniority: ["L2", "L3"],
  },
  {
    id: 8,
    channels: "Quality Assurance (QA)",
    seniority: ["L2", "L3", "L3"],
  },
  {
    id: 9,
    channels: "Software Development",
    seniority: ["L2", "L3"],
  },
  {
    id: 10,
    channels: "Quality Assurance (QA)",
    seniority: ["L2", "L3", "L3"],
  },
];
export const settings_designation_table_rows = [
  {
    id: 1,
    channels: "Software Development",
    seniority: ["Associate Software Engineer", "Sr. Software Engineer"],
  },
  {
    id: 2,
    channels: "Quality Assurance (QA)",
    seniority: ["QA Analyst", "Consultant QA"],
  },
  {
    id: 3,
    channels: "Software Development",
    seniority: ["Associate Software Engineer", "Sr. Software Engineer"],
  },
  {
    id: 4,
    channels: "Quality Assurance (QA)",
    seniority: ["QA Analyst", "Consultant QA"],
  },
  {
    id: 5,
    channels: "Software Development",
    seniority: ["Associate Software Engineer", "Sr. Software Engineer"],
  },
  {
    id: 6,
    channels: "Quality Assurance (QA)",
    seniority: ["QA Analyst", "Consultant QA"],
  },
  {
    id: 7,
    channels: "Software Development",
    seniority: ["Associate Software Engineer", "Sr. Software Engineer"],
  },
  {
    id: 8,
    channels: "Quality Assurance (QA)",
    seniority: ["QA Analyst", "Consultant QA"],
  },
  {
    id: 9,
    channels: "Software Development",
    seniority: ["Associate Software Engineer", "Sr. Software Engineer"],
  },
  {
    id: 10,
    channels: "Quality Assurance (QA)",
    seniority: ["QA Analyst", "Consultant QA"],
  },
];

export const settings_jobrole_table_rows = [
  {
    id: 1,
    job_role: "Software Development",
    seniority: ["Communication Skills", "Python"],
  },
  {
    id: 2,
    job_role: "Quality Assurance (QA)",
    seniority: ["Problem-Solving", "VLANs", "VPN"],
  },
  {
    id: 3,
    job_role: "Software Development",
    seniority: ["Communication Skills", "Python"],
  },
  {
    id: 4,
    job_role: "Quality Assurance (QA)",
    seniority: ["Problem-Solving", "VLANs", "VPN"],
  },
  {
    id: 5,
    job_role: "Software Development",
    seniority: ["Communication Skills", "Python"],
  },
  {
    id: 6,
    job_role: "Quality Assurance (QA)",
    seniority: ["Problem-Solving", "VLANs", "VPN"],
  },
  {
    id: 7,
    job_role: "Software Development",
    seniority: ["Communication Skills", "Python"],
  },
  {
    id: 8,
    job_role: "Quality Assurance (QA)",
    seniority: ["Problem-Solving", "VLANs", "VPN"],
  },
  {
    id: 9,
    job_role: "Software Development",
    seniority: ["Communication Skills", "Python"],
  },
  {
    id: 10,
    job_role: "Quality Assurance (QA)",
    seniority: ["Problem-Solving", "VLANs", "VPN"],
  },
];
export const settings_jobroleadditions_table_rows = [
  {
    id: 1,
    job_role: "Software Engineer",
    additions: ["Java", "Python"],
  },
  {
    id: 2,
    job_role: "Network Administrator",
    additions: ["TCP/IP", "VLANs", "VPN", "TCP/IP", "VLANs", "VPN", "VLANs"],
  },
  {
    id: 3,
    job_role: "Software Engineer",
    additions: ["Java", "Python"],
  },
  {
    id: 4,
    job_role: "Network Administrator",
    additions: ["TCP/IP", "VLANs", "VPN", "+3"],
  },
  {
    id: 5,
    job_role: "Software Engineer",
    additions: ["Java", "Python"],
  },
  {
    id: 6,
    job_role: "Network Administrator",
    additions: ["TCP/IP", "VLANs", "VPN"],
  },
  {
    id: 7,
    job_role: "Software Engineer",
    additions: ["Java", "Python"],
  },
  {
    id: 8,
    job_role: "Network Administrator",
    additions: ["TCP/IP", "VLANs", "VPN"],
  },
  {
    id: 9,
    job_role: "Software Engineer",
    additions: ["Java", "Python"],
  },
  {
    id: 10,
    job_role: "Network Administrator",
    additions: ["TCP/IP", "VLANs", "VPN"],
  },
];
export const candidate_RoundOne_table_rows = [
  {
    name: "Roger Dokidis",
    score: "67",
    interviewer: "Roger Dokidis",
    job: "Javascript Developer",
    scheduler: "Corey Lipshutz",
    status: { id: 0, tag: " Scheduled" },
  },
  {
    name: "Gustavo Mango",
    score: "88",
    interviewer: "Gustavo Mango",
    scheduler: "Angel Philips",
    job: "Javascript Developer",
    status: { id: 1, tag: " Passed" },
  },
  {
    name: "Alfonso Passaquindici Arcand",
    score: "98",
    scheduler: "Corey Lipshutz",
    interviewer: "Alfonso Passaquindici Arcand",
    job: "Javascript Developer",
    status: { id: 2, tag: " Failed" },
  },
  {
    name: "Roger Dokidis",
    score: "67",
    interviewer: "Roger Dokidis",
    scheduler: "Angel Philips",
    job: "Javascript Developer",
    status: { id: 0, tag: " Scheduled" },
  },
  {
    name: "Gustavo Mango",
    score: "88",
    scheduler: "Corey Lipshutz",
    interviewer: "Gustavo Mango",
    job: "Javascript Developer",
    status: { id: 1, tag: " Passed" },
  },
  {
    name: "Alfonso Passaquindici Arcand",
    score: "98",
    interviewer: "Alfonso Passaquindici Arcand",
    scheduler: "Angel Philips",
    job: "Javascript Developer",
    status: { id: 2, tag: " Failed" },
  },
  {
    name: "Roger Dokidis",
    score: "67",
    scheduler: "Corey Lipshutz",
    interviewer: "Roger Dokidis",
    job: "Javascript Developer",
    status: { id: 0, tag: " Scheduled" },
  },
  {
    name: "Gustavo Mango",
    score: "88",
    interviewer: "Gustavo Mango",
    scheduler: "Angel Philips",
    job: "Javascript Developer",
    status: { id: 1, tag: " Passed" },
  },
  {
    name: "Alfonso Passaquindici Arcand",
    score: "98",
    scheduler: "Corey Lipshutz",
    interviewer: "Alfonso Passaquindici Arcand",
    job: "Javascript Developer",
    status: { id: 2, tag: " Failed" },
  },
];

export const candidate_RoundTwo_table_rows = [
  {
    name: "Roger Dokidis",
    score: "67",
    r1_score: "67",
    interviewer: "Roger Dokidis",
    job: "Javascript Developer",
    scheduler: "Corey Lipshutz",
    status: { id: 0, tag: " Scheduled" },
  },
  {
    name: "Gustavo Mango",
    score: "88",
    r1_score: "67",
    interviewer: "Gustavo Mango",
    scheduler: "Angel Philips",
    job: "Javascript Developer",
    status: { id: 1, tag: " Passed" },
  },
  {
    name: "Alfonso Passaquindici Arcand",
    score: "98",
    r1_score: "70",
    scheduler: "Corey Lipshutz",
    interviewer: "Alfonso Passaquindici Arcand",
    job: "Javascript Developer",
    status: { id: 2, tag: " Failed" },
  },
  {
    name: "Roger Dokidis",
    score: "67",
    r1_score: "45",
    interviewer: "Roger Dokidis",
    scheduler: "Angel Philips",
    job: "Javascript Developer",
    status: { id: 0, tag: " Scheduled" },
  },
  {
    name: "Gustavo Mango",
    score: "88",
    r1_score: "67",
    scheduler: "Corey Lipshutz",
    interviewer: "Gustavo Mango",
    job: "Javascript Developer",
    status: { id: 1, tag: " Passed" },
  },
  {
    name: "Alfonso Passaquindici Arcand",
    score: "98",
    r1_score: "69",
    interviewer: "Alfonso Passaquindici Arcand",
    scheduler: "Angel Philips",
    job: "Javascript Developer",
    status: { id: 2, tag: " Failed" },
  },
  {
    name: "Roger Dokidis",
    score: "67",
    r1_score: "67",
    scheduler: "Corey Lipshutz",
    interviewer: "Roger Dokidis",
    job: "Javascript Developer",
    status: { id: 0, tag: " Scheduled" },
  },
  {
    name: "Gustavo Mango",
    score: "88",
    r1_score: "20",
    interviewer: "Gustavo Mango",
    scheduler: "Angel Philips",
    job: "Javascript Developer",
    status: { id: 1, tag: " Passed" },
  },
  {
    name: "Alfonso Passaquindici Arcand",
    score: "98",
    r1_score: "67",
    scheduler: "Corey Lipshutz",
    interviewer: "Alfonso Passaquindici Arcand",
    job: "Javascript Developer",
    status: { id: 2, tag: " Failed" },
  },
];

export const candidate_HrRound_table_rows = [
  {
    name: "Roger Dokidis",
    score: "67",
    r1_score: "67",
    r2_score: "84",
    interviewer: "Roger Dokidis",
    job: "Javascript Developer",
    scheduler: "Corey Lipshutz",
    status: { id: 0, tag: " Scheduled" },
  },
  {
    name: "Gustavo Mango",
    score: "88",
    r1_score: "60",
    r2_score: "84",
    interviewer: "Gustavo Mango",
    scheduler: "Angel Philips",
    job: "Javascript Developer",
    status: { id: 1, tag: " Hired" },
  },
  {
    name: "Alfonso Passaquindici Arcand",
    score: "98",
    r1_score: "12",
    r2_score: "84",
    scheduler: "Corey Lipshutz",
    interviewer: "Alfonso Passaquindici Arcand",
    job: "Javascript Developer",
    status: { id: 0, tag: " Scheduled" },
  },
  {
    name: "Roger Dokidis",
    score: "67",
    r1_score: "67",
    r2_score: "84",
    interviewer: "Roger Dokidis",
    scheduler: "Angel Philips",
    job: "Javascript Developer",
    status: { id: 0, tag: " Scheduled" },
  },
  {
    name: "Gustavo Mango",
    score: "88",
    r1_score: "23",
    r2_score: "84",
    scheduler: "Corey Lipshutz",
    interviewer: "Gustavo Mango",
    job: "Javascript Developer",
    status: { id: 0, tag: " Scheduled" },
  },
  {
    name: "Alfonso Passaquindici Arcand",
    score: "98",
    r1_score: "67",
    r2_score: "84",
    interviewer: "Alfonso Passaquindici Arcand",
    scheduler: "Angel Philips",
    job: "Javascript Developer",
    status: { id: 1, tag: " Hired" },
  },
  {
    name: "Roger Dokidis",
    score: "67",
    r1_score: "99",
    r2_score: "12",
    scheduler: "Corey Lipshutz",
    interviewer: "Roger Dokidis",
    job: "Javascript Developer",
    status: { id: 0, tag: " Scheduled" },
  },
  {
    name: "Gustavo Mango",
    score: "88",
    r1_score: "67",
    r2_score: "33",
    interviewer: "Gustavo Mango",
    scheduler: "Angel Philips",
    job: "Javascript Developer",
    status: { id: 2, tag: " Failed" },
  },
  {
    name: "Alfonso Passaquindici Arcand",
    score: "98",
    r1_score: "67",
    r2_score: "11",
    scheduler: "Corey Lipshutz",
    interviewer: "Alfonso Passaquindici Arcand",
    job: "Javascript Developer",
    status: { id: 0, tag: " Scheduled" },
  },
];

export const candidate_Hired_table_rows = [
  {
    name: "Roger Dokidis",
    score: "67",
    r2_score: "84",
    interviewer: "Roger Dokidis",
    job: "Javascript Developer",
    scheduler: "Corey Lipshutz",
    notes: "12 Dec joining date",
  },
  {
    name: "Gustavo Mango",
    score: "88",
    r2_score: "56",
    interviewer: "Gustavo Mango",
    scheduler: "Angel Philips",
    job: "Javascript Developer",
    notes: "12 Dec joining date",
  },
  {
    name: "Alfonso Passaquindici Arcand",
    score: "98",
    r2_score: "84",
    scheduler: "Corey Lipshutz",
    interviewer: "Alfonso Passaquindici Arcand",
    job: "Javascript Developer",
    notes: "12 Dec joining date",
  },
  {
    name: "Roger Dokidis",
    score: "67",
    r2_score: "84",
    interviewer: "Roger Dokidis",
    scheduler: "Angel Philips",
    job: "Javascript Developer",
    notes: "12 Dec joining date",
  },
  {
    name: "Gustavo Mango",
    score: "88",
    r2_score: "84",
    scheduler: "Corey Lipshutz",
    interviewer: "Gustavo Mango",
    job: "Javascript Developer",
    notes: "12 Dec joining date",
  },
  {
    name: "Alfonso Passaquindici Arcand",
    score: "98",
    r2_score: "84",
    interviewer: "Alfonso Passaquindici Arcand",
    scheduler: "Angel Philips",
    job: "Javascript Developer",
    notes: "12 Dec joining date",
  },
  {
    name: "Roger Dokidis",
    score: "67",
    r2_score: "84",
    scheduler: "Corey Lipshutz",
    interviewer: "Roger Dokidis",
    job: "Javascript Developer",
    notes: "12 Dec joining date",
  },
  {
    name: "Gustavo Mango",
    score: "88",
    r2_score: "84",
    interviewer: "Gustavo Mango",
    scheduler: "Angel Philips",
    job: "Javascript Developer",
    notes: "12 Dec joining date",
  },
  {
    name: "Alfonso Passaquindici Arcand",
    score: "98",
    r2_score: "84",
    scheduler: "Corey Lipshutz",
    interviewer: "Alfonso Passaquindici Arcand",
    job: "Javascript Developer",
    notes: "12 Dec joining date",
  },
];

export const question_analytics_table_rows = [
  {
    question: "Java Script Event Question",
    discription: "Lorem ipsum dolor sit amet",
    attempts: "10/10",
    correct: "2",
    incorrect: "7",
    responses: [
      { label: "A-50", answer: "correct" },
      { label: "B-15", answer: "incorrect" },
      { label: "C-50", answer: "incorrect" },
      { label: "D-50", answer: "incorrect" },
    ],
  },
  {
    question: "JavaScript Array Question",
    discription: "Consectetur adipiscing elit",
    attempts: "8/10",
    correct: "5",
    incorrect: "3",
    responses: [
      { label: "A-20", answer: "incorrect" },
      { label: "B-10", answer: "correct" },
      { label: "C-30", answer: "incorrect" },
      { label: "D-40", answer: "incorrect" },
    ],
  },
  {
    question: "JavaScript Function Question",
    discription: "Sed do eiusmod tempor incididunt",
    attempts: "7/10",
    correct: "3",
    incorrect: "4",
    responses: [
      { label: "A-15", answer: "incorrect" },
      { label: "B-20", answer: "incorrect" },
      { label: "C-25", answer: "correct" },
      { label: "D-40", answer: "incorrect" },
    ],
  },
  {
    question: "JavaScript Object Question",
    discription: "Ut labore et dolore magna aliqua",
    attempts: "9/10",
    correct: "6",
    incorrect: "3",
    responses: [
      { label: "A-25", answer: "incorrect" },
      { label: "B-10", answer: "incorrect" },
      { label: "C-40", answer: "incorrect" },
      { label: "D-25", answer: "correct" },
    ],
  },
  {
    question: "JavaScript DOM Question",
    discription: "Ut enim ad minim veniam",
    attempts: "6/10",
    correct: "4",
    incorrect: "2",
    responses: [
      { label: "A-30", answer: "correct" },
      { label: "B-20", answer: "incorrect" },
      { label: "C-25", answer: "incorrect" },
      { label: "D-25", answer: "incorrect" },
    ],
  },
  {
    question: "JavaScript Scope Question",
    discription: "Excepteur sint occaecat cupidatat non proident",
    attempts: "7/10",
    correct: "4",
    incorrect: "3",
    responses: [
      { label: "A-35", answer: "incorrect" },
      { label: "B-25", answer: "correct" },
      { label: "C-20", answer: "incorrect" },
      { label: "D-30", answer: "incorrect" },
    ],
  },
  {
    question: "JavaScript Conditional Question",
    discription:
      "Sunt in culpa qui officia deserunt mollit anim id est laborum",
    attempts: "9/10",
    correct: "5",
    incorrect: "4",
    responses: [
      { label: "A-45", answer: "incorrect" },
      { label: "B-30", answer: "incorrect" },
      { label: "C-35", answer: "correct" },
      { label: "D-40", answer: "incorrect" },
    ],
  },
  {
    question: "JavaScript Promise Question",
    discription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    attempts: "8/10",
    correct: "6",
    incorrect: "2",
    responses: [
      { label: "A-55", answer: "incorrect" },
      { label: "B-25", answer: "incorrect" },
      { label: "C-30", answer: "incorrect" },
      { label: "D-20", answer: "correct" },
    ],
  },
  {
    question: "JavaScript ES6 Question",
    discription:
      "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    attempts: "7/10",
    correct: "4",
    incorrect: "3",
    responses: [
      { label: "A-40", answer: "correct" },
      { label: "B-30", answer: "incorrect" },
      { label: "C-35", answer: "incorrect" },
      { label: "D-25", answer: "incorrect" },
    ],
  },
  {
    question: "JavaScript Async/Await Question",
    discription: "Ut enim ad minim veniam, quis nostrud exercitation ullamco",
    attempts: "9/10",
    correct: "5",
    incorrect: "4",
    responses: [
      { label: "A-50", answer: "correct" },
      { label: "B-35", answer: "incorrect" },
      { label: "C-40", answer: "incorrect" },
      { label: "D-30", answer: "incorrect" },
    ],
  },
  {
    question: "JavaScript EventListener Question",
    discription:
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
    attempts: "10/10",
    correct: "7",
    incorrect: "3",
    responses: [
      { label: "A-60", answer: "correct" },
      { label: "B-40", answer: "incorrect" },
      { label: "C-35", answer: "incorrect" },
      { label: "D-25", answer: "incorrect" },
    ],
  },
  {
    question: "JavaScript Module Question",
    discription:
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
    attempts: "8/10",
    correct: "5",
    incorrect: "3",
    responses: [
      { label: "A-70", answer: "correct" },
      { label: "B-45", answer: "incorrect" },
      { label: "C-30", answer: "incorrect" },
      { label: "D-25", answer: "incorrect" },
    ],
  },
  {
    question: "JavaScript Error Handling Question",
    discription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
    attempts: "9/10",
    correct: "6",
    incorrect: "3",
    responses: [
      { label: "A-80", answer: "correct" },
      { label: "B-40", answer: "incorrect" },
      { label: "C-35", answer: "incorrect" },
      { label: "D-25", answer: "incorrect" },
    ],
  },
];

export const interviews_table_rows = [
  {
    name: "Roger Dokidis",
    score: "67",
    interviewer: "Roger Dokidis",
    job: "Sales and Marketing",
    scheduler: "Roger Dokidis",
    Round: "R2",
    assessment: "Problem-Solving Aptitude",
    status: { id: 0, tag: "Scheduled" },
  },
  {
    name: "Gustavo Mango",
    score: "88",
    interviewer: "Gustavo Mango",
    job: "Quality Assurance (QA)",
    scheduler: "Roger Dokidis",
    Round: "R1",
    assessment: "Problem-Solving Aptitude",
    status: { id: 1, tag: "Passed" },
  },
  {
    name: "Alfonso Passaquindici ",
    score: "98",
    interviewer: "Alfonso Passaquindici Arcand",
    job: "Quality Assurance (QA)",
    scheduler: "Roger Dokidis",
    Round: "HR",
    assessment: "Problem-Solving Aptitude",
    status: { id: 3, tag: "Failed" },
  },
  {
    name: "Roger Dokidis",
    score: "67",
    interviewer: "Roger Dokidis",
    job: "Javascript Developer",
    scheduler: "Roger Dokidis",
    Round: "HR",
    assessment: "Problem-Solving Aptitude",
    status: { id: 0, tag: "Scheduled" },
  },
  {
    name: "Gustavo Mango",
    score: "88",
    interviewer: "Gustavo Mango",
    job: "Javascript Developer",
    scheduler: "Roger Dokidis",
    Round: "HR",
    assessment: "Problem-Solving Aptitude",
    status: { id: 1, tag: "Passed" },
  },
  {
    name: "Alfonso Passaquindici Arcand",
    score: "98",
    interviewer: "Alfonso Passaquindici Arcand",
    job: "Javascript Developer",
    scheduler: "Roger Dokidis",
    Round: "R1",
    assessment: "Problem-Solving Aptitude",
    status: { id: 2, tag: "Hired" },
  },
  {
    name: "Roger Dokidis",
    score: "67",
    interviewer: "Roger Dokidis",
    job: "Javascript Developer",
    scheduler: "Roger Dokidis",
    Round: "R2",
    assessment: "Problem-Solving Aptitude",
    status: { id: 0, tag: "Scheduled" },
  },
  {
    name: "Gustavo Mango",
    score: "88",
    interviewer: "Gustavo Mango",
    job: "Javascript Developer",
    scheduler: "Roger Dokidis",
    Round: "R1",
    assessment: "Problem-Solving Aptitude",
    status: { id: 1, tag: "Passed" },
  },
  {
    name: "Alfonso Passaquindici Arcand",
    score: "98",
    interviewer: "Alfonso Passaquindici Arcand",
    job: "Javascript Developer",
    scheduler: "Roger Dokidis",
    Round: "R1",
    assessment: "Problem-Solving Aptitude",
    status: { id: 3, tag: "Failed" },
  },
];

export const candidates_testtaken_table_rows = [
  {
    name: "Roger Dokidis",
    score: "67",
    email: "rogerdokidis@gmail.com",
    section_score: "MCQ - 3 | Program - 0",
    status: { id: 0, tag: "Pass", cheated: true },
    test_duration: "15:36",
  },
  {
    name: "Gustavo Mango",
    score: "88",
    email: "gustavomangos@gmail.com",
    section_score: "MCQ - 3 | Program - 0",
    status: { id: 0, tag: "Pass", cheated: false },
    test_duration: "27:40",
  },
  {
    name: "Alfonso Passaquindici Arcand",
    score: "98",
    email: "alfonsopassaquindiciarcand@gmail.com",
    section_score: "MCQ - 3 | Program - 0",
    status: { id: 1, tag: "Failed", cheated: false },
    test_duration: "15:10",
  },
  {
    name: "Marcus Franci",
    score: "87",
    email: "marcusfranci@gmail.com",
    section_score: "MCQ - 3 | Program - 0",
    status: { id: 2, tag: "Shortlisted", cheated: false },
    test_duration: "23:01",
  },
  {
    name: "Randi Levin",
    score: "58",
    email: "randylevin@gmail.com",
    section_score: "MCQ - 3 | Program - 0",
    status: { id: 3, tag: "Reviewed", cheated: false },
    test_duration: "23:09",
  },
  {
    name: "Jocelyn Kenter",
    score: "67",
    email: "jocelynkenter@gmail.com",
    section_score: "MCQ - 3 | Program - 0",
    status: { id: 0, tag: "Pass", cheated: false },
    test_duration: "16:22",
  },
  {
    name: "Miracle Dokidis",
    score: "95",
    email: "miracledokidis@gmail.com",
    section_score: "MCQ - 3 | Program - 0",
    status: { id: 0, tag: "Pass", cheated: true },
    test_duration: "26:28",
  },
  {
    name: "Giana Philips",
    score: "63",
    email: "gianaphilips@gmail.com",
    section_score: "MCQ - 3 | Program - 0",
    status: { id: 1, tag: "Failed", cheated: false },
    test_duration: "22:20",
  },
  {
    name: "Cooper Culhane",
    score: "78",
    email: "cooperculhane@gmail.com",
    section_score: "MCQ - 3 | Program - 0",
    status: { id: 2, tag: "Shortlisted", cheated: false },
    test_duration: "15:44",
  },
  {
    name: "Haylie Geidt",
    score: "94",
    email: "hayliegeidt@gmail.com",
    section_score: "MCQ - 3 | Program - 0",
    status: { id: 3, tag: "Reviewed", cheated: false },
    test_duration: "17:30",
  },
];
export const candidates_invited_table_rows = [
  {
    name: "Roger Dokidis",
    email: "rogerdokidis@gmail.com",
    invitedby: "Roger Dokidis",
    invitedon: "19/03/2024",
    expiry: "19/03/2024",
    status: { id: 0, tag: "Invited" },
  },
  {
    name: "Gustavo Mango",
    invitedby: "Gustavo Mango",
    email: "gustavomangos@gmail.com",
    invitedon: "15/03/2024",
    expiry: "15/03/2024",
    status: { id: 1, tag: "Appeared" },
  },
  {
    name: "Alfonso Passaquindici Arcand",
    invitedby: "Alfonso Passaquindici Arcand",
    email: "alfonsopassaquindiciarcand@gmail.com",
    invitedon: "10/04/2024",
    expiry: "10/04/2024",
    status: { id: 2, tag: "Expired" },
  },
  {
    name: "Marcus Franci",
    invitedby: "Marcus Franci",
    email: "marcusfranci@gmail.com",
    invitedon: "22/06/2024",
    expiry: "22/06/2024",
    status: { id: 0, tag: "Invited" },
  },
  {
    name: "Randi Levin",
    invitedby: "Randi Levin",
    email: "randylevin@gmail.com",
    invitedon: "01/01/2024",
    expiry: "01/01/2024",
    status: { id: 1, tag: "Appeared" },
  },
  {
    name: "Jocelyn Kenter",
    invitedby: "Jocelyn Kenter",
    email: "jocelynkenter@gmail.com",
    invitedon: "01/02/2024",
    expiry: "01/02/2024",
    status: { id: 1, tag: "Appeared" },
  },
  {
    name: "Miracle Dokidis",
    invitedby: "Miracle Dokidis",
    email: "miracledokidis@gmail.com",
    invitedon: "19/03/2024",
    expiry: "19/03/2024",
    status: { id: 1, tag: "Appeared" },
  },
  {
    name: "Giana Philips",
    invitedby: "Giana Philips",
    email: "gianaphilips@gmail.com",
    invitedon: "30/09/2024",
    expiry: "30/09/2024",
    status: { id: 1, tag: "Appeared" },
  },
  {
    name: "Cooper Culhane",
    invitedby: "Cooper Culhane",
    email: "cooperculhane@gmail.com",
    invitedon: "21/12/2024",
    expiry: "21/12/2024",
    status: { id: 1, tag: "Appeared" },
  },
  {
    name: "Haylie Geidt",
    invitedby: "Haylie Geidt",
    email: "hayliegeidt@gmail.com",
    invitedon: "25/03/2024",
    expiry: "25/03/2024",
    status: { id: 1, tag: "Appeared" },
  },
];
export const account_tabs = [
  { label: "Your Details", value: "yourDetails" },
  { label: "Billing, Plans & Usage", value: "billinglansUsage" },
];
export const candidate_shortlisted_tabs_one = [
  { label: "Test Details", value: "testDetails" },
  { label: "Questions", value: "questions" },
  { label: "Settings", value: "settings" },
  { label: "Candidates", value: "candidates" },
  { label: "Analytics", value: "analytics" },
  { label: "Candidate Shortlisted", value: "candidateShortListed" },
];
export const candidate_shortlisted_tabs_two = [
  { label: "Shortlisted (100)", value: "shortlisted" },
  { label: "Round 1 (50)", value: "round1" },
  { label: "Round 2 (100)", value: "round2" },
  { label: "HR Round (50)", value: "hrround" },
  { label: "Hired (50)", value: "hired" },
];
export const candidates_tabs_one = [
  { label: "Test Overview", value: "testoverview" },
  { label: "Questions", value: "questions" },
  { label: "Candidates", value: "candidates" },
  { label: "Analytics", value: "analytics" },
];
export const candidates_tabs_two = [
  { label: "Test Taken (100)", value: "testtaken" },
  { label: "Passed (80)", value: "passed" },
  { label: "Shortlisted (10)", value: "shortlisted" },
  { label: "Reviewed (10)", value: "reviewed" },
  { label: "Invited (150)", value: "invited" },
];
export const questions_tabs = [
  { label: "Test Overview", value: "testoverview" },
  { label: "Questions", value: "round1" },
  { label: "Candidates", value: "round2" },
  { label: "Analytics", value: "hrround" },
];

export const assessments_tab_list = [
  { label: "Test Overview", value: "test_overview" },
  { label: "Questions", value: "questions" },
  { label: "Candidates", value: "candidates" },
  { label: "Analytics", value: "analytics" },
];
export const assessments_analytics_tab_list = [
  { label: "Test Analytics", value: "test_analytics" },
  { label: "Questions Analytics", value: "question_analytics" },
  { label: "Skill Analytics", value: "skill_analytics" },
];

export const interview_upload_tabs = [
  { label: "Images", value: "images" },
  { label: "Video", value: "video" },
];

export const interview_questions_tabs = [
  { label: "Main Skills", value: "mainSkills" },
  { label: "Additional Skills", value: "additionalSkills" },
  { label: "Psychological Skills", value: "psychologicalSkills" },
];

export const question_table_one = [
  {
    id: 1,
    section: "MCQ",
    questions: 16,
    difficulties: { easy: "06", medium: "04", hard: "06" },
    score: 100,
  },
  {
    id: 2,
    section: "Programming",
    questions: 20,
    difficulties: { easy: "06", medium: "04", hard: "06" },
    score: 200,
  },
];
export const questions_overview = [
  {
    id: 1,
    type: "Multiple Choice Questions",
    questions: 16,
    difficulties: { easy: "06", medium: "04", hard: "06" },
    score: 100,
  },
  {
    id: 2,
    type: "Programming",
    questions: 20,
    difficulties: { easy: "06", medium: "04", hard: "06" },
    score: 200,
  },
];
export const questions_skillset = [
  {
    id: 1,
    skills: "JavaScript",
    type: "MCQ",
    questions: 16,
    difficulties: { easy: "06", medium: "04", hard: "06" },
    score: 100,
  },
  {
    id: 2,
    skills: "React",
    type: "Programming",
    questions: 20,
    difficulties: { easy: "06", medium: "04", hard: "06" },
    score: 200,
  },
  {
    id: 3,
    skills: "AngularJS",
    type: "MCQ",
    questions: 20,
    difficulties: { easy: "06", medium: "04", hard: "06" },
    score: 200,
  },
];

export const test_admins_data = [
  {
    id: 1,
    name: "Alita Jones",
    email: "alitajones@gmail.com",
    dept: "Front-End Dev.",
  },
  {
    id: 2,
    name: "Mila Kudrow",
    email: "milakudrow@gmail.com",
    dept: "Backend Dev.",
  },
  {
    id: 3,
    name: "Miles Dokidis",
    email: "milesdokidis@gmail.com",
    dept: "Data Engineering",
  },
  {
    id: 4,
    name: "Hela Athens",
    email: "helaathens@gmail.com",
    dept: "Fullstack",
  },
];
export const questions_list = [
  {
    id: 1,
    question: "Q1. What are Java Clusters?",
    type: "MCQ",
    score: "05",
  },
  {
    id: 2,
    question:
      "Q2. Sed iaculis, tortor sed feugiat luctus, nulla diam posuere sapien?",
    type: "Programming",
    score: "10",
  },
  {
    id: 1,
    question: "Q3. Aenean sit amet interdum nisl, porta vehicula enim?",
    type: "MCQ",
    score: "05",
  },
];
export const question_table_two = [
  {
    id: 1,
    skill: "Java",
    section: ["Programming(02)", "MCQ(04)"],
    questions: 8,
    score: 100,
  },
  {
    id: 2,
    skill: "Angular",
    section: ["Programming(04)", "MCQ(04)"],
    questions: 8,
    score: 100,
  },
  {
    id: 3,
    skill: "MongoDB",
    section: ["Programming(05)", "MCQ(05)"],
    questions: 10,
    score: 100,
  },
];

export const assesmentData = [
  {
    testTitle: "Javascript Loops Test",
    published: false,
    on: true,
    publisher: "Sumith Krishnan",
    invited: "400",
    attempted: "128",
    shortlisted: "10",
  },
  {
    testTitle: "Technical Skills Assessment",
    published: true,
    on: true,
    publisher: "John Doe",
    invited: "350",
    attempted: "200",
    shortlisted: "15",
  },
  {
    testTitle: "Programming Proficiency Evaluation",
    published: true,
    on: false,
    publisher: "Jane SmitFh",
    invited: "500",
    attempted: "150",
    shortlisted: "20",
  },
  {
    testTitle: "Database Management Proficiency Test",
    published: false,
    on: false,
    publisher: "Alice Johnson",
    invited: "300",
    attempted: "100",
    shortlisted: "8",
  },
];

export const statsCarddata = [
  {
    title: "Total Assesment",
    resultScore: "4048",
    icon: "/assets/reportAnalytics (1).svg",
  },
  {
    title: "Total Invited",
    resultScore: "15718",
    icon: "/assets/mailForward.svg",
  },
  {
    title: "Total Appeared",
    resultScore: "13426",
    icon: "/assets/checklist.svg",
  },
  {
    title: "Total Hired",
    resultScore: "02",
    icon: "/assets/userCheck_Filled.svg",
  },
];

export const interviews_tabs = [
  { label: "All (10)", value: "all" },
  { label: "Scheduled (03)", value: "scheduled" },
  { label: "Pending (05)", value: "pending" },
  { label: "In Progress (05)", value: "inprogress" },
  { label: "Cancelled (02)", value: "cancelled" },
];

export const candidate_pool_test_time_analysis = [
  {
    lable: "Test Invite Time",

    time: "Aug 19 2022, 03:05:26 PM IST",
  },
  {
    lable: "Test Start Time",

    time: "Aug 19 2022, 03:05:26 PM IST",
  },
  {
    lable: "Test End Time",

    time: "Aug 19 2022, 03:05:26 PM IST",
  },
  {
    lable: "Test Duration",

    time: "Aug 19 2022, 03:05:26 PM IST",
  },
];

export const candidate_details_profile = [
  {
    icn: "./assets/candidate_details_profile_1.svg",

    lable: "Candidate rank",

    number: "#1",
  },

  {
    icn: "./assets/candidate_details_profile_2.svg",

    lable: "Candidate Score",

    number: "5/10",
  },
  {
    icn: "./assets/candidate_details_profile_3.svg",

    lable: "Duration (mins)",

    number: "19",
  },
  {
    icn: "./assets/candidate_details_profile_4.svg",

    lable: "Test Attempted",

    number: "100%",
  },
  {
    icn: "./assets/candidate_details_profile_5.svg",

    lable: "Snapshots Taken",

    number: "41",
  },
  {
    icn: "./assets/candidate_details_profile_6.svg",

    lable: "Tabs switched",

    number: "4",
  },
  {
    icn: "./assets/candidate_details_profile_7.svg",

    lable: "Plagiarism (%)",

    number: "Not Found",
  },
];

export const candidate_details_about = [
  {
    icn: "./assets/candidate_details_about_1.svg",

    label: "craigbergson@gmail.com",
  },

  {
    icn: "./assets/candidate_details_about_2.svg",

    label: "+91-9876543210",
  },
  {
    icn: "./assets/candidate_details_about_3.svg",

    label: "123 Main Street, Cityville, State",
  },
  {
    icn: "./assets/candidate_details_about_4.svg",

    label: "Mumbai University",
  },
  {
    icn: "./assets/candidate_details_about_5.svg",

    label: "India",
  },
  {
    icn: "./assets/candidate_details_about_6.svg",

    label: "18 years",
  },
  {
    icn: "./assets/candidate_details_about_7.svg",

    label: " https://portfolio.com",
  },
  {
    icn: "./assets/candidate_details_about_8.svg",

    label: "@username.git",
  },
];

export const can_details_row_data = [
  {
    skills: "Java",

    attempted: "5/10",

    marks: "10",

    skore: "99",
  },
  {
    skills: "Python",

    attempted: "5/10",

    marks: "10",

    skore: "99",
  },
  {
    skills: "JavaScript",

    attempted: "5/10",

    marks: "10",

    skore: "99",
  },
  {
    skills: "Ruby",

    attempted: "5/10",

    marks: "10",

    skore: "99",
  },
  {
    skills: "C++",

    attempted: "5/10",

    marks: "10",

    skore: "99",
  },
];

export const asessmentFilterData = [
  {
    label: "Creation Date",
    isSearchBar: false,
    checkBox: [
      "Select All",
      "Last Week",
      "Last Month",
      "Last Quarter",
      "Last Year",
    ],
  },
  {
    label: "Channel",
    isSearchBar: false,
    checkBox: ["Select All", "Software Developer", "Quality Assurance"],
  },
  {
    label: "Recruiter",
    isSearchBar: false,
    checkBox: ["Select All", "Rutika", "Amol", "Malav"],
  },
  {
    label: "Test Status",
    isSearchBar: false,
    checkBox: [
      "Select All",
      "Published (261)",
      "Completed (1520)",
      "Draft (620)",
    ],
  },
  {
    label: "Test Type",
    isSearchBar: false,
    checkBox: ["Select All", "Private (261)", "Public (1520)"],
  },
  {
    label: "Invite Status",
    isSearchBar: false,
    checkBox: ["Select All", "Invite Only (500)"],
  },
];

export const shortlistedFilterData = [
  {
    label: "Score",
    isSearchBar: false,
    checkBox: [
      "Select All",
      "Recommended (High to Low)",
      "Greater than 70% ",
      "Greater than 50%",
      "Less than 50%",
    ],
  },
  {
    label: "Job Role",
    isSearchBar: false,
    checkBox: [
      "Select All",
      "Javascript Developer",
      "React Developer",
      "Java Developer",
      "Python Developer",
      "Node Developer",
    ],
  },
  {
    label: "Status",
    isSearchBar: false,
    checkBox: [
      "Selected All",
      "ShortListed",
      "Round 1 Schedule",
      "Round 1 Passed",
      "Round 1 Pending",
      "Round 1 Failed",
      "Round 2 Schedule",
      "Round 2 Passed",
      "Round 2 Pending",
      "Round 2 Failed",
      "HR Round Schedule",
      "HR Round Passed",
      "HR Round Pending",
      "HR Round Failed",
      "Hired",
    ],
  },
];

export const TalentPoolFilterData = [
  {
    label: "Channel",
    isSearchBar: false,
    checkBox: [
      "Select All",
      "Software Developer",
      "Quality Assurance",
      "Sales and Marketing",
      "Business Analyst",
      "Human Resource",
    ],
  },
  {
    label: "Job Role",
    isSearchBar: true,
    checkBox: [
      "Select All",
      "Software Engineer",
      "Quality Analyst",
      "HR",
      "Sales",
      "Business Analyst",
    ],
  },
  {
    label: "Year of Experience",
    isSearchBar: false,
    checkBox: ["Select All", "0-1", "1-2", "2-3", "3-4", "4-5", "5 and above"],
  },
  {
    label: "Status",
    isSearchBar: false,
    checkBox: ["Selected All", "Available", "Not Available"],
  },
];

export const CandidateFilterData = [
  {
    label: "Channel",
    isSearchBar: false,
    checkBox: [
      "Select All",
      "Software Developer",
      "Quality Assurance",
      "Sales and Marketing",
      "Business Analyst",
      "Human Resource",
    ],
  },
  {
    label: "Test Name",
    isSearchBar: false,
    checkBox: [
      "Select All",
      "Javascript Developer",
      "React Developer",
      "Java Developer",
      "Python Developer",
      "Node Developer",
    ],
  },
  {
    label: "Score",
    isSearchBar: false,
    checkBox: [
      "Select All",
      "Greater than 70%",
      "Greater than 50%",
      "Less than 50%",
    ],
  },
  {
    label: "Status",
    isSearchBar: false,
    checkBox: [
      "Selected All",
      "Passed",
      "Failed",
      "Shortlisted",
      "Reviewed",
      "Invited",
    ],
  },
  {
    label: "Invited By",
    isSearchBar: false,
    checkBox: ["Select All", "Rutika", "Amol", "Malav"],
  },
];

export const libraryFilterData = [
  {
    label: "Source",
    isSearchBar: false,
    checkBox: ["Select All", "Hire360 Library", "My Library ", "Draft"],
  },
  {
    label: "Skills",
    isSearchBar: true,
    checkBox: [
      "Select All",
      "Pyhton",
      "Java ",
      "MongoDB",
      "Postgres",
      "React Native",
    ],
  },
  {
    label: "Topics",
    isSearchBar: true,
    checkBox: ["Select All", "Array", "String", "Object", "Function", "DOM"],
  },
  {
    label: "Difficulty",
    isSearchBar: false,
    checkBox: ["Select All", "Easy", "Medium", "Hard"],
  },
  {
    label: "Question Type",
    isSearchBar: false,
    checkBox: ["Select All", "MCQ", "Programming"],
  },
];

export const hire360LibraryData = [
  {
    difficulty: "Easy",
    category: "library",
    label: "Javascript code problem",
    decs: "You are given a Go program that performs basic math operations. The program accepts two numbers and an arithmetic operator as inputs and returns the result of the operation.",
    bgcolor: "rgba(67, 157, 98, 0.1)",
    color: "rgba(67, 157, 98, 1)",
    path: "/assets/greenfire.svg",
  },
  {
    difficulty: "Medium",
    category: "hire360",
    label: "Javascript code problem",
    decs: "You are given a Go program that performs basic math operations. The program accepts two numbers and an arithmetic operator as inputs and returns the result of the operation.",
    bgcolor: "rgba(255, 151, 54, 0.1)",
    color: "rgba(255, 151, 54, 1)",
    path: "/assets/orangefire.svg",
  },
  {
    difficulty: "Hard",
    category: "draft",
    label: "Javascript code problem",
    decs: "You are given a Go program that performs basic math operations. The program accepts two numbers and an arithmetic operator as inputs and returns the result of the operation.",
    bgcolor: "rgba(249, 73, 72, 0.1)",
    color: "rgba(249, 73, 72, 1)",
    path: "/assets/libraryFire.svg",
  },
  {
    difficulty: "Hard",
    category: "library",
    label: "Javascript code problem",
    decs: "You are given a Go program that performs basic math operations. The program accepts two numbers and an arithmetic operator as inputs and returns the result of the operation.",
    bgcolor: "rgba(249, 73, 72, 0.1)",
    color: "rgba(249, 73, 72, 1)",
    path: "/assets/libraryFire.svg",
  },
  {
    difficulty: "Hard",
    category: "library",
    label: "Javascript code problem",
    decs: "You are given a Go program that performs basic math operations. The program accepts two numbers and an arithmetic operator as inputs and returns the result of the operation.",
    bgcolor: "rgba(249, 73, 72, 0.1)",
    color: "rgba(249, 73, 72, 1)",
    path: "/assets/libraryFire.svg",
  },
  {
    difficulty: "Hard",
    category: "hire360",
    label: "Javascript code problem",
    decs: "You are given a Go program that performs basic math operations. The program accepts two numbers and an arithmetic operator as inputs and returns the result of the operation.",
    bgcolor: "rgba(249, 73, 72, 0.1)",
    color: "rgba(249, 73, 72, 1)",
    path: "/assets/libraryFire.svg",
  },
  {
    difficulty: "Hard",
    category: "library",
    label: "Javascript code problem",
    decs: "You are given a Go program that performs basic math operations. The program accepts two numbers and an arithmetic operator as inputs and returns the result of the operation.",
    bgcolor: "rgba(249, 73, 72, 0.1)",
    color: "rgba(249, 73, 72, 1)",
    path: "/assets/libraryFire.svg",
  },
];

export const Gender = [
  { value: "Male" },
  { value: "Female" },
  { value: "Other" },
];
export const CountryCode = [
  { value: "+91" },
  { value: "+12" },
  { value: "+82" },
];
export const DesignationList = [
  { value: "Sales Manager" },
  { value: "Tech lead" },
  { value: "Project Manager" },
  { value: "Systems Analyst" },
  { value: "Technical Manager" },
  { value: "DevOps Engineer" },
];
export const ExperienceList = [
  { value: "Fresher(<1 year)" },
  { value: "Senior(+5 years)" },
];

export const ScheduleData = [
  {
    candidateName: "Wilson Philips",
    email: "wilsonphilips@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: "Shortlisted",
    testScore: 98,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Vignesh Vijayan",
    email: "vigneshvijayan@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: "R1 Scheduled",
    testScore: 93,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Mansi Dilkhush",
    email: "carterrosser@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: "R1 Passed",
    testScore: 92,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Kaiya Aminoff",
    email: "kaylynnlevin@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: "R2 Passed",
    testScore: 80,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Kaiya Aminoff",
    email: "kiannarhielmadsen@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: "R2 Scheduled",
    testScore: 89,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Kaiya Aminoff",
    email: "rogerworkman@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: "HR Scheduled",
    testScore: 90,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Kaiya Aminoff",
    email: "emersoncurtis@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: "HR Passed",
    testScore: 90,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Wilson Philips",
    email: "wilsonphilips@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: "Hired",
    testScore: 98,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Wilson Philips",
    email: "wilsonphilips@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: "R1 Failed",
    testScore: 98,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Wilson Philips",
    email: "wilsonphilips@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: "R2 Pending",
    testScore: 98,
    avtar: "/assets/people.png",
  },
];

export const Round1ScheduleData = [
  {
    candidateName: "Vignesh Vijayan",
    email: "vigneshvijayan@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: " Scheduled",
    testScore: 93,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Mansi Dilkhush",
    email: "carterrosser@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: " Passed",
    testScore: 92,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Kaiya Aminoff",
    email: "kaylynnlevin@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: "Failed",
    testScore: 80,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Kaiya Aminoff",
    email: "kiannarhielmadsen@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: " Scheduled",
    testScore: 89,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Kaiya Aminoff",
    email: "rogerworkman@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: " Scheduled",
    testScore: 90,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Kaiya Aminoff",
    email: "emersoncurtis@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: " Passed",
    testScore: 90,
    avtar: "/assets/people.png",
  },
];

export const Round2ScheduleData = [
  {
    candidateName: "Vignesh Vijayan",
    email: "vigneshvijayan@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: " Scheduled",
    testScore: 93,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Mansi Dilkhush",
    email: "carterrosser@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: "Failed",
    testScore: 92,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Kaiya Aminoff",
    email: "kaylynnlevin@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: " Passed",
    testScore: 80,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Kaiya Aminoff",
    email: "kiannarhielmadsen@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: " Scheduled",
    testScore: 89,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Kaiya Aminoff",
    email: "rogerworkman@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: "Failed",
    testScore: 90,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Kaiya Aminoff",
    email: "emersoncurtis@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: " Passed",
    testScore: 90,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Wilson Philips",
    email: "wilsonphilips@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: " Scheduled",
    testScore: 98,
    avtar: "/assets/people.png",
  },
];

export const HRScheduleData = [
  {
    candidateName: "Vignesh Vijayan",
    email: "vigneshvijayan@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: " Scheduled",
    testScore: 93,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Mansi Dilkhush",
    email: "carterrosser@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: " Hired",
    testScore: 92,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Kaiya Aminoff",
    email: "kaylynnlevin@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: " Hired",
    testScore: 80,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Kaiya Aminoff",
    email: "kiannarhielmadsen@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: "Failed",
    testScore: 89,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Kaiya Aminoff",
    email: "rogerworkman@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: " Scheduled",
    testScore: 90,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Kaiya Aminoff",
    email: "emersoncurtis@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: " Hired",
    testScore: 90,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Wilson Philips",
    email: "wilsonphilips@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    round: " Hired",
    testScore: 98,
    avtar: "/assets/people.png",
  },
];

export const HiredScheduleData = [
  {
    candidateName: "Vignesh Vijayan",
    email: "vigneshvijayan@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    testScore: 93,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Mansi Dilkhush",
    email: "carterrosser@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    testScore: 92,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Kaiya Aminoff",
    email: "kaylynnlevin@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    testScore: 80,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Kaiya Aminoff",
    email: "kiannarhielmadsen@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    testScore: 89,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Kaiya Aminoff",
    email: "rogerworkman@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    testScore: 90,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Kaiya Aminoff",
    email: "emersoncurtis@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    testScore: 90,
    avtar: "/assets/people.png",
  },
  {
    candidateName: "Wilson Philips",
    email: "wilsonphilips@gmail.com",
    role: "Javascript Developer",
    category: "Software Development",
    personName: "Sumith Krishnan",
    testScore: 98,
    avtar: "/assets/people.png",
  },
];

export const TestTakenData = [
  {
    candidateName: "Wilson Philips",
    testScore: 98,
    duration: "30 ",
    score: "50",
    avtar: "/assets/people.png",
    submittedOn: "16 May 2024 | 06:56 PM",
    status: "passed",
  },
  {
    candidateName: "Jane Doe",
    testScore: 85,
    duration: "45 ",
    score: "40",
    avtar: "/assets/people.png",
    submittedOn: "10 May 2024 | 02:30 PM",
    status: "passed",
  },
  {
    candidateName: "John Smith",
    testScore: 90,
    duration: "35 ",
    score: "45",
    avtar: "/assets/people.png",
    submittedOn: "12 May 2024 | 11:15 AM",
    status: "reviewed",
  },
  {
    candidateName: "Emily Johnson",
    testScore: 88,
    duration: "40 ",
    score: "42",
    avtar: "/assets/people.png",
    submittedOn: "14 May 2024 | 09:45 AM",
    status: "shortlisted",
  },
  {
    candidateName: "Michael Brown",
    testScore: 92,
    duration: "50 ",
    score: "48",
    avtar: "/assets/people.png",
    submittedOn: "18 May 2024 | 04:20 PM",
    status: "reviewed",
  },
  {
    candidateName: "Jessica Davis",
    testScore: 80,
    duration: "25 ",
    score: "38",
    avtar: "/assets/people.png",
    submittedOn: "20 May 2024 | 01:10 PM",
    status: "passed",
  },
  {
    candidateName: "David Wilson",
    testScore: 95,
    duration: "55 ",
    score: "49",
    avtar: "/assets/people.png",
    submittedOn: "22 May 2024 | 05:50 PM",
    status: "reviewed",
  },
  {
    candidateName: "Sarah Miller",
    testScore: 87,
    duration: "30 ",
    score: "41",
    avtar: "/assets/people.png",
    submittedOn: "24 May 2024 | 03:25 PM",
    status: "shortlisted",
  },
  {
    candidateName: "Chris Lee",
    testScore: 82,
    duration: "20 ",
    score: "39",
    avtar: "/assets/people.png",
    submittedOn: "26 May 2024 | 07:40 AM",
    status: "passed",
  },
  {
    candidateName: "Laura Martinez",
    testScore: 89,
    duration: "45 ",
    score: "44",
    avtar: "/assets/people.png",
    submittedOn: "28 May 2024 | 06:30 PM",
    status: "reviewed",
  },
];

export const PassedData = [
  {
    candidateName: "Wilson Philips",
    testScore: 98,
    duration: "30 ",
    score: "50",
    avtar: "/assets/people.png",
    submittedOn: "16 May 2024 | 06:56 PM",
    pending:
      '<img src="/assets/arrow-left.svg" alt="arrow" style="margin-right: 4px;" /> Move to Review',
    shortlist: "Shortlist",
  },
  {
    candidateName: "Jane Doe",
    testScore: 85,
    duration: "45 ",
    score: "40",
    avtar: "/assets/people.png",
    submittedOn: "10 May 2024 | 02:30 PM",
    pending:
      '<img src="/assets/arrow-left.svg" alt="arrow" style="margin-right: 4px;" /> Move to Review',
    shortlist: "Shortlist",
  },
  {
    candidateName: "John Smith",
    testScore: 90,
    duration: "35 ",
    score: "45",
    avtar: "/assets/people.png",
    submittedOn: "12 May 2024 | 11:15 AM",
    status: "reviewed",
  },
  {
    candidateName: "Emily Johnson",
    testScore: 88,
    duration: "40 ",
    score: "42",
    avtar: "/assets/people.png",
    submittedOn: "14 May 2024 | 09:45 AM",
    status: "shortlisted",
  },
  {
    candidateName: "Michael Brown",
    testScore: 92,
    duration: "50 ",
    score: "48",
    avtar: "/assets/people.png",
    submittedOn: "18 May 2024 | 04:20 PM",
    status: "reviewed",
  },
  {
    candidateName: "Jessica Davis",
    testScore: 80,
    duration: "25 ",
    score: "38",
    avtar: "/assets/people.png",
    submittedOn: "20 May 2024 | 01:10 PM",
    pending:
      '<img src="/assets/arrow-left.svg" alt="arrow" style="margin-right: 4px;" /> Move to Review',
    shortlist: "Shortlist",
  },
  {
    candidateName: "David Wilson",
    testScore: 95,
    duration: "55 ",
    score: "49",
    avtar: "/assets/people.png",
    submittedOn: "22 May 2024 | 05:50 PM",
    status: "reviewed",
  },
  {
    candidateName: "Sarah Miller",
    testScore: 87,
    duration: "30 ",
    score: "41",
    avtar: "/assets/people.png",
    submittedOn: "24 May 2024 | 03:25 PM",
    status: "shortlisted",
  },
  {
    candidateName: "Chris Lee",
    testScore: 82,
    duration: "20 ",
    score: "39",
    avtar: "/assets/people.png",
    submittedOn: "26 May 2024 | 07:40 AM",
    pending:
      '<img src="/assets/arrow-left.svg" alt="arrow" style="margin-right: 4px;" /> Move to Review',
    shortlist: "Shortlist",
  },
  {
    candidateName: "Laura Martinez",
    testScore: 89,
    duration: "45 ",
    score: "44",
    avtar: "/assets/people.png",
    submittedOn: "28 May 2024 | 06:30 PM",
    status: "reviewed",
  },
];

export const ShortlistData = [
  {
    candidateName: "Wilson Philips",
    testScore: 98,
    duration: "30 ",
    score: "50",
    avtar: "/assets/people.png",
    submittedOn: "16 May 2024 | 06:56 PM",
    pending:
      '<img src="/assets/arrow-left.svg" alt="arrow" style="margin-right: 4px;" /> Move to Pass',
    status: "Passed",
  },
  {
    candidateName: "Jane Doe",
    testScore: 85,
    duration: "45 ",
    score: "40",
    avtar: "/assets/people.png",
    submittedOn: "10 May 2024 | 02:30 PM",
    pending:
      '<img src="/assets/arrow-left.svg" alt="arrow" style="margin-right: 4px;" /> Move to Pass',
    status: "Intermediate",
  },
  {
    candidateName: "John Smith",
    testScore: 90,
    duration: "35 ",
    score: "45",
    avtar: "/assets/people.png",
    submittedOn: "12 May 2024 | 11:15 AM",
    pending:
      '<img src="/assets/arrow-left.svg" alt="arrow" style="margin-right: 4px;" /> Move to Pass',
    status: "Training Required",
  },
  {
    candidateName: "Emily Johnson",
    testScore: 88,
    duration: "40 ",
    score: "42",
    avtar: "/assets/people.png",
    submittedOn: "14 May 2024 | 09:45 AM",
    pending:
      '<img src="/assets/arrow-left.svg" alt="arrow" style="margin-right: 4px;" /> Move to Pass',
    status: "Passed",
  },
  {
    candidateName: "Michael Brown",
    testScore: 92,
    duration: "50 ",
    score: "48",
    avtar: "/assets/people.png",
    submittedOn: "18 May 2024 | 04:20 PM",
    pending:
      '<img src="/assets/arrow-left.svg" alt="arrow" style="margin-right: 4px;" /> Move to Pass',
    status: "Training Required",
  },
  {
    candidateName: "Jessica Davis",
    testScore: 80,
    duration: "25 ",
    score: "38",
    avtar: "/assets/people.png",
    submittedOn: "20 May 2024 | 01:10 PM",
    pending:
      '<img src="/assets/arrow-left.svg" alt="arrow" style="margin-right: 4px;" /> Move to Pass',
    status: "Passed",
  },
  {
    candidateName: "David Wilson",
    testScore: 95,
    duration: "55 ",
    score: "49",
    avtar: "/assets/people.png",
    submittedOn: "22 May 2024 | 05:50 PM",
    pending:
      '<img src="/assets/arrow-left.svg" alt="arrow" style="margin-right: 4px;" /> Move to Pass',
    status: "Passed",
  },
  {
    candidateName: "Sarah Miller",
    testScore: 87,
    duration: "30 ",
    score: "41",
    avtar: "/assets/people.png",
    submittedOn: "24 May 2024 | 03:25 PM",
    pending:
      '<img src="/assets/arrow-left.svg" alt="arrow" style="margin-right: 4px;" /> Move to Pass',
    status: "Passed",
  },
  {
    candidateName: "Chris Lee",
    testScore: 82,
    duration: "20 ",
    score: "39",
    avtar: "/assets/people.png",
    submittedOn: "26 May 2024 | 07:40 AM",
    pending:
      '<img src="/assets/arrow-left.svg" alt="arrow" style="margin-right: 4px;" /> Move to Pass',
    status: "Passed",
  },
  {
    candidateName: "Laura Martinez",
    testScore: 89,
    duration: "45 ",
    score: "44",
    avtar: "/assets/people.png",
    submittedOn: "28 May 2024 | 06:30 PM",
    pending:
      '<img src="/assets/arrow-left.svg" alt="arrow" style="margin-right: 4px;" /> Move to Pass',
    status: "Training Required",
  },
];

export const ReviewedData = [
  {
    candidateName: "Wilson Philips",
    testScore: 98,
    duration: "30 ",
    score: "50",
    avtar: "/assets/people.png",
    submittedOn: "16 May 2024 | 06:56 PM",
    pending:
      '<img src="/assets/arrow-left.svg" alt="arrow" style="margin-right: 4px;" /> Move to Pass',
    shortlist: "Shortlist",
  },
  {
    candidateName: "Jane Doe",
    testScore: 85,
    duration: "45 ",
    score: "40",
    avtar: "/assets/people.png",
    submittedOn: "10 May 2024 | 02:30 PM",
    pending:
      '<img src="/assets/arrow-left.svg" alt="arrow" style="margin-right: 4px;" /> Move to Pass',
    shortlist: "Shortlist",
  },
  {
    candidateName: "John Smith",
    testScore: 90,
    duration: "35 ",
    score: "45",
    avtar: "/assets/people.png",
    submittedOn: "12 May 2024 | 11:15 AM",
    pending:
      '<img src="/assets/arrow-left.svg" alt="arrow" style="margin-right: 4px;" /> Move to Pass',
    shortlist: "Shortlist",
  },
  {
    candidateName: "Emily Johnson",
    testScore: 88,
    duration: "40 ",
    score: "42",
    avtar: "/assets/people.png",
    submittedOn: "14 May 2024 | 09:45 AM",
    pending:
      '<img src="/assets/arrow-left.svg" alt="arrow" style="margin-right: 4px;" /> Move to Pass',
    shortlist: "Shortlist",
  },
  {
    candidateName: "Michael Brown",
    testScore: 92,
    duration: "50 ",
    score: "48",
    avtar: "/assets/people.png",
    submittedOn: "18 May 2024 | 04:20 PM",
    pending:
      '<img src="/assets/arrow-left.svg" alt="arrow" style="margin-right: 4px;" /> Move to Pass',
    shortlist: "Shortlist",
  },
  {
    candidateName: "Jessica Davis",
    testScore: 80,
    duration: "25 ",
    score: "38",
    avtar: "/assets/people.png",
    submittedOn: "20 May 2024 | 01:10 PM",
    pending:
      '<img src="/assets/arrow-left.svg" alt="arrow" style="margin-right: 4px;" /> Move to Pass',
    shortlist: "Shortlist",
  },
  {
    candidateName: "David Wilson",
    testScore: 95,
    duration: "55 ",
    score: "49",
    avtar: "/assets/people.png",
    submittedOn: "22 May 2024 | 05:50 PM",
    pending:
      '<img src="/assets/arrow-left.svg" alt="arrow" style="margin-right: 4px;" /> Move to Pass',
    shortlist: "Shortlist",
  },
  {
    candidateName: "Sarah Miller",
    testScore: 87,
    duration: "30 ",
    score: "41",
    avtar: "/assets/people.png",
    submittedOn: "24 May 2024 | 03:25 PM",
    pending:
      '<img src="/assets/arrow-left.svg" alt="arrow" style="margin-right: 4px;" /> Move to Pass',
    shortlist: "Shortlist",
  },
  {
    candidateName: "Chris Lee",
    testScore: 82,
    duration: "20 ",
    score: "39",
    avtar: "/assets/people.png",
    submittedOn: "26 May 2024 | 07:40 AM",
    pending:
      '<img src="/assets/arrow-left.svg" alt="arrow" style="margin-right: 4px;" /> Move to Pass',
    shortlist: "Shortlist",
  },
  {
    candidateName: "Laura Martinez",
    testScore: 89,
    duration: "45 ",
    score: "44",
    avtar: "/assets/people.png",
    submittedOn: "28 May 2024 | 06:30 PM",
    pending:
      '<img src="/assets/arrow-left.svg" alt="arrow" style="margin-right: 4px;" /> Move to Pass',
    shortlist: "Shortlist",
  },
];

export const initialCheckboxData = [
  {
    label: "Shuffle Questions for each candidate",
    checked: true,
    disabled: true,
  },
  {
    label: "Take Snapshots via webcam every 30 second",
    checked: false,
    disabled: false,
  },
  {
    label: "Turn Off copy paste from external sources",
    checked: true,
    disabled: true,
  },
  {
    label: "Turn on fullscreen while test",
    checked: false,
    disabled: false,
  },
  {
    label: "Turn Off copy paste from external sources",
    checked: true,
    disabled: true,
  },
  {
    label: "Logout on leaving a test interface",
    checked: false,
    disabled: false,
  },
  {
    label: "Check multi screen attachment",
    checked: false,
    disabled: false,
  },
];

export const CandidateCheckboxData = [
  {
    label: "Name",
    checked: true,
    disabled: true,
  },
  {
    label: "Email",
    checked: true,
    disabled: true,
  },
  {
    label: "Contact Number",
    checked: false,
    disabled: false,
  },
  {
    label: "College",
    checked: false,
    disabled: false,
  },
  {
    label: "Resume",
    checked: false,
    disabled: false,
  },
];

export const TestDurationData = [
  {
    iconSrc: "/assets/icons/calendar.svg",
    label: "Duration",
    duration: "27 Nov - 29 Nov’23 | 5 - 5:30 PM",
  },
  {
    iconSrc: "/assets/icons/briefcaseBlack.svg",
    label: "Job Role",
    duration: "Software Development",
  },
  {
    iconSrc: "/assets/icons/award.svg",
    label: "Test score",
    duration: "100",
  },
  {
    iconSrc: "/assets/icons/clock.svg",
    label: "Test Time",
    duration: "30mins",
  },
  {
    iconSrc: "/assets/icons/tie.svg",
    label: "Experience",
    duration: "Fresher (<1 year)",
  },
  {
    iconSrc: "/assets/icons/checklist.svg",
    label: "Cutoff Score",
    duration: "33",
  },
];

export const instructions = [
  "Complete each section within the allocated time frame.",
  "Follow the instructions for each question carefully.",
  "Submit your responses before the deadline.",
  "Contact support if you encounter any technical issues.",
];

export const JobOpeningData = [
  {
    id: 1,
    status: "Open",
    title: "Senior Software Engineer",
    applicantName: "Davis Lipshutz",
    daysPosted: "05",
    viewCount: "05",
    assessmentCount: "05",
  },
  {
    id: 2,
    status: "Open", // 'Open' or 'Closed'
    title: "Senior Software Engineer",
    applicantName: "Davis Lipshutz",
    daysPosted: "05",
    viewCount: "05",
    assessmentCount: "05",
  },
  {
    id: 3,
    status: "Ongoing", // 'Open' or 'Closed'
    title: "Senior Software Engineer",
    applicantName: "Conrad Bashirian",
    daysPosted: "05",
    viewCount: "05",
    assessmentCount: "05",
  },
  {
    id: 4,
    status: "Closed", // 'Open' or 'Closedd'
    title: "Senior Software Engineer",
    applicantName: "Annie Heathcote",
    daysPosted: "05",
    viewCount: "05",
    assessmentCount: "05",
  },
  {
    id: 5,
    status: "Open", // 'Open' or 'Closedd'
    title: "Senior Software Engineer",
    applicantName: "Annie Heathcote",
    daysPosted: "05",
    viewCount: "05",
    assessmentCount: "05",
  },
  {
    id: 6,
    status: "Closed", // 'Open' or 'Closed'
    title: "Senior Software Engineer",
    applicantName: "Lorenzo Predovic",
    daysPosted: "05",
    viewCount: "05",
    assessmentCount: "05",
  },
];

export const jobTestData = [
  {
    id: 1,
    testTitle: "Javascript Loops Test",
    statusCounts: [
      { count: "400", text: "Invited" },
      { count: "128", text: "Attempted" },
      { count: "10", text: "Passed" },
      { count: "5%", text: "Qualified" },
    ],
    publishedStatus: {
      text: "Published",
    },
    userInfo: {
      name: "",
      time: "30mins",
      inviteStatus: "Invite Only",
      dateRange: "27 Nov - 29 Nov’23",
      timeRange: "5 -5:30 PM",
    },
  },
  {
    id: 2,
    testTitle: "Javascript Loops Test",
    statusCounts: [
      { count: "400", text: "Invited" },
      { count: "128", text: "Attempted" },
      { count: "10", text: "Passed" },
      { count: "5%", text: "Qualified" },
    ],
    publishedStatus: {
      text: "Published",
    },
    userInfo: {
      name: "Annie Heathcote",
      time: "30mins",
      inviteStatus: "Invite Only",
      dateRange: "27 Nov - 29 Nov’23",
      timeRange: "5 -5:30 PM",
    },
  },
  {
    id: 3,
    testTitle: "Javascript Loops Test",
    statusCounts: [
      { count: "400", text: "Invited" },
      { count: "128", text: "Attempted" },
      { count: "10", text: "Passed" },
      { count: "5%", text: "Qualified" },
    ],
    publishedStatus: {
      text: "Published",
    },
    userInfo: {
      name: "Lorenzo Predovic",
      time: "30mins",
      inviteStatus: "Invite Only",
      dateRange: "27 Nov - 29 Nov’23",
      timeRange: "5 -5:30 PM",
    },
  },
  {
    id: 4,
    testTitle: "Javascript Loops Test",
    statusCounts: [
      { count: "400", text: "Invited" },
      { count: "128", text: "Attempted" },
      { count: "10", text: "Passed" },
      { count: "5%", text: "Qualified" },
    ],
    publishedStatus: {
      text: "Published",
    },
    userInfo: {
      name: "Conrad Bashirian",
      time: "30mins",
      inviteStatus: "Invite Only",
      dateRange: "27 Nov - 29 Nov’23",
      timeRange: "5 -5:30 PM",
    },
  },
];

export const autotest_settings_data = [
  {
    id: 1,
    text: "Shuffle Questions for each candidate",
  },
  {
    id: 2,
    text: "Take Snapshots via webcam every 30 second",
  },
  {
    id: 3,
    text: "Take screen snapshots on tab switch",
  },
  {
    id: 4,
    text: "Turn on fullscreen while test",
  },
  {
    id: 5,
    text: "Turn Off copy paste from external sources",
  },
  {
    id: 6,
    text: "Logout on leaving a test interface",
  },
  {
    id: 7,
    text: "Check multi screen attachment",
  },
  {
    id: 8,
    text: "Release test results score immediately after test",
  },
];
export const autotest_candidates_settings_data = [
  {
    id: 1,
    text: "Name",
  },
  {
    id: 2,
    text: "Email",
  },
  {
    id: 3,
    text: "Contact Number",
  },
  {
    id: 4,
    text: "College",
  },
  {
    id: 5,
    text: "Resume",
  },
];

export const aiGeneratedQuestions = [
  {
    rowId: 1,
    question: "Question goes here",
    rating: 4,
  },
  {
    rowId: 2,
    question: "Question goes here",
    rating: 3,
  },
  {
    rowId: 3,
    question: "Question goes here",
    rating: 3,
  },
  {
    rowId: 4,
    question: "Question goes here",
    rating: 4,
  },
];
export const chatMessages = [
  {
    id: 1,
    participants: {
      sender: "Vihaan Verma",
      receiver: "You",
    },
    time: "12:45 PM",
    title: "Javascript Loops Test",
    designation: "HR",
    count: 2,
    sender: "You",
    messages: [
      {
        from: "Vihaan Verma",
        content: "We support PNGs, JPEGs, and JPGs under 5MB.",
        time: "12:45 PM",
      },
      {
        from: "Vihaan Verma",
        content: "We support PNGs, JPEGs, and JPGs under 5MB.",
        time: "12:45 PM",
      },
      {
        from: "You",
        content: "We support PNGs, JPEGs and JPGs under 5mb",
        time: "12:46 PM",
      },
    ],
  },
  {
    id: 2,
    participants: {
      sender: "Vihaan Verma",
      receiver: "You",
    },
    time: "12:45 PM",
    messages: [
      {
        from: "Vihaan Verma",
        content: "We support PNGs, JPEGs, and JPGs under 5MB.",
        time: "12:45 PM",
      },
      {
        from: "You",
        content: "Great! I'll keep that in mind.",
        time: "12:46 PM",
      },
    ],
    title: "Javascript Loops Test",
    designation: "Recruiter",
    count: 2,
  },
  {
    id: 3,
    participants: {
      sender: "Vihaan Verma",
      receiver: "You",
    },
    time: "12:45 PM",
    messages: [
      {
        from: "Vihaan Verma",
        content: "We support PNGs, JPEGs, and JPGs under 5MB.",
        time: "12:45 PM",
      },
      {
        from: "You",
        content: "Great! I'll keep that in mind.",
        time: "12:46 PM",
      },
    ],
    title: "Javascript Loops Test",
    designation: "Channel Head",
    count: 2,
  },
  {
    id: 4,
    participants: {
      sender: "Vihaan Verma",
      receiver: "You",
    },
    time: "12:45 PM",
    messages: [
      {
        from: "Vihaan Verma",
        content: "We support PNGs, JPEGs, and JPGs under 5MB.",
        time: "12:45 PM",
      },
      {
        from: "You",
        content: "Great! I'll keep that in mind.",
        time: "12:46 PM",
      },
    ],
    title: "Javascript Loops Test",
    designation: "HR",
    count: 2,
  },
  {
    id: 5,
    participants: {
      sender: "Vihaan Verma",
      receiver: "You",
    },
    time: "12:45 PM",
    messages: [
      {
        from: "Vihaan Verma",
        content: "We support PNGs, JPEGs, and JPGs under 5MB.",
        time: "12:45 PM",
      },
      {
        from: "You",
        content: "Great! I'll keep that in mind.",
        time: "12:46 PM",
      },
    ],
    title: "Javascript Loops Test",
    designation: "Interviewer",
    count: 2,
  },
  {
    id: 6,
    participants: {
      sender: "Vihaan Verma",
      receiver: "You",
    },
    time: "12:45 PM",
    messages: [
      {
        from: "Vihaan Verma",
        content: "We support PNGs, JPEGs, and JPGs under 5MB.",
        time: "12:45 PM",
      },
      {
        from: "You",
        content: "Great! I'll keep that in mind.",
        time: "12:46 PM",
      },
    ],
    title: "Javascript Loops Test",
    designation: "Interviewer",
    count: 2,
  },
  {
    id: 7,
    participants: {
      sender: "Vihaan Verma",
      receiver: "You",
    },
    time: "12:45 PM",
    messages: [
      {
        from: "Vihaan Verma",
        content: "We support PNGs, JPEGs, and JPGs under 5MB.",
        time: "12:45 PM",
      },
      {
        from: "You",
        content: "Great! I'll keep that in mind.",
        time: "12:46 PM",
      },
    ],
    title: "Javascript Loops Test",
    designation: "Interviewer",
    count: 2,
  },
  {
    id: 8,
    participants: {
      sender: "Vihaan Verma",
      receiver: "You",
    },
    time: "12:45 PM",
    messages: [
      {
        from: "Vihaan Verma",
        content: "We support PNGs, JPEGs, and JPGs under 5MB.",
        time: "12:45 PM",
      },
      {
        from: "You",
        content: "Great! I'll keep that in mind.",
        time: "12:46 PM",
      },
    ],
    title: "Javascript Loops Test",
    designation: "Interviewer",
    count: 2,
  },
  {
    id: 9,
    participants: {
      sender: "Vihaan Verma",
      receiver: "You",
    },
    time: "12:45 PM",
    messages: [
      {
        from: "Vihaan Verma",
        content: "We support PNGs, JPEGs, and JPGs under 5MB.",
        time: "12:45 PM",
      },
      {
        from: "You",
        content: "Great! I'll keep that in mind.",
        time: "12:46 PM",
      },
    ],
    title: "Javascript Loops Test",
    designation: "Interviewer",
    count: 2,
  },
  {
    id: 10,
    participants: {
      sender: "Vihaan Verma",
      receiver: "You",
    },
    time: "12:45 PM",
    messages: [
      {
        from: "Vihaan Verma",
        content: "We support PNGs, JPEGs, and JPGs under 5MB.",
        time: "12:45 PM",
      },
      {
        from: "You",
        content: "Great! I'll keep that in mind.",
        time: "12:46 PM",
      },
    ],
    title: "Javascript Loops Test",
    designation: "Interviewer",
    count: 2,
  },
];
export const RoundwiseDetailsTabs = [
  { label: "Round 1", value: 0 },
  { label: "Round 2", value: 1 },
  { label: "HR Round", value: 2 },
];

export const roles = [
  { label: "All", value: "all" },
  { label: "Recruiter", value: "recruiter" },
  { label: "Super Admin", value: "super_admin" },
  { label: "Library Admin", value: "library_admin" },
  { label: "Channel Head", value: "channel_head" },
  { label: "Interviewer", value: "interviewer" },
  { label: "HR", value: "hr" },
];

export const usersList = [
  {
    name: "Kareena Verma",
    role: "Recruiter",
    avatar: "/assets/person/person1.png",
  },
  {
    name: "Vihaan Verma",
    role: "Recruiter",
    avatar: "/assets/person/person1.png",
  },
  {
    name: "Vihaan Verma",
    role: "HR",
    avatar: "/assets/person/person1.png",
  },
  {
    name: "Kareena Verma",
    role: "HR",
    avatar: "/assets/person/person1.png",
  },
  {
    name: "Vihaan Verma",
    role: "HR",
    avatar: "/assets/person/person1.png",
  },
  {
    name: "Vihaan Verma",
    role: "HR",
    avatar: "/assets/person/person1.png",
  },
  {
    name: "Kareena Verma",
    role: "Channel Head",
    avatar: "/assets/person/person1.png",
  },
  {
    name: "Vihaan Verma",
    role: "Channel Head",
    avatar: "/assets/person/person1.png",
  },
  {
    name: "Vihaan Verma",
    role: "Channel Head",
    avatar: "/assets/person/person1.png",
  },
  {
    name: "Kareena Verma",
    role: "Super Admin",
    avatar: "/assets/person/person1.png",
  },
  {
    name: "Vihaan Verma",
    role: "Interviewer",
    avatar: "/assets/person/person1.png",
  },
  {
    name: "Vihaan Verma",
    role: "Super Admin",
    avatar: "/assets/person/person1.png",
  },
];

export const chatbot_tab_list = [
  { label: "New Pings", value: "ping", count: 5 },
  { label: "Conversations", value: "Conversations", count: 10 },
];
