// filterUtils.js
import { Box, Typography } from "@mui/material";
export const handleApplyFilter = (
  filters,
  tableParams,
  setTableParams,
  setIsFilterOpen,
  conditionFn,
  updatedFilters
) => {
  const shouldReturnNull = (filter, conditionFn) => {
    if (typeof conditionFn === "function") return conditionFn(filter);
  };

  const isUpdatedFiltersArray = Array.isArray(updatedFilters);
  const selectedFilters = isUpdatedFiltersArray
    ? updatedFilters
        .map((filter) => {
          if (shouldReturnNull(filter, conditionFn)) {
            return null;
          }
          const selectedValues = filter.checkBox
            .filter((cb) => cb.label !== "Select All" && cb.isChecked)
            .map((cb) => cb.value);
          if (selectedValues.length > 0) {
            return {
              name: filter.value,
              selectedValues,
            };
          } else {
            return null;
          }
        })
        .filter(Boolean)
    : filters
        .map((filter) => {
          if (shouldReturnNull(filter, conditionFn)) {
            return null;
          }
          const selectedValues = filter.checkBox
            .filter((cb) => cb.label !== "Select All" && cb.isChecked)
            .map((cb) => cb.value);
          if (selectedValues.length > 0) {
            return {
              name: filter.value,
              selectedValues,
            };
          } else {
            return null;
          }
        })
        .filter(Boolean);

  const newFilters =
    selectedFilters.length === 0
      ? {}
      : selectedFilters.reduce((acc, filter) => {
          acc[filter?.name] = filter.selectedValues;
          return acc;
        }, {});

  const newTableParams = {
    ...tableParams,
    filters: {
      query: tableParams.filters.query,
      ...newFilters,
    },
    pagination: {
      ...tableParams.pagination,
      page: 1,
    },
  };
  if (tableParams.filters.assessmentStatus) {
    newTableParams.filters.assessmentStatus =
      tableParams.filters.assessmentStatus;
  }
  if (
    newTableParams.filters?.source &&
    newTableParams.filters?.source.includes("draft")
  )
    if (
      newTableParams.filters?.source?.length === 2 &&
      newTableParams.filters.source.includes("hire360") &&
      newTableParams.filters.source.includes("myLibrary")
    ) {
      newTableParams.filters.source = ["all"];
    }

  setTableParams(newTableParams);

  setIsFilterOpen(false);
};

export const buildUrlWithFilters = (tableParams) => {
  let url = "";
  const filterKeys = Object.keys(tableParams.filters);
  filterKeys.forEach((key) => {
    const value = tableParams.filters[key];
    if (typeof value === "string") {
      if (
        value.length > 0 &&
        value !== "" &&
        value !== "undefined" &&
        value.toLowerCase() !== "all"
      ) {
        url += `&${key}=${encodeURIComponent(value)}`;
      }
    } else if (Array.isArray(value) && value.length > 0) {
      const filteredValues = value.filter(
        (v) => v !== "" && v !== "undefined" && v !== "all"
      );
      if (filteredValues.length > 0) {
        url += `&${key}=${encodeURIComponent(filteredValues.join(","))}`;
      }
    }
  });
  return url;
};

export const countCheckedFilters = (filters) => {
  let totalChecked = 0;
  const checkedFilters = [];

  if (!Array.isArray(filters)) {
    return { totalChecked, checkedFilters };
  }

  filters.forEach((filter) => {
    const checkedCheckBoxes = filter.checkBox.filter(
      (checkBoxItem) =>
        checkBoxItem.isChecked &&
        !(
          checkBoxItem.label === "Select All" ||
          checkBoxItem.value === "Select All"
        )
    );

    totalChecked += checkedCheckBoxes.length;

    if (checkedCheckBoxes.length > 0) {
      checkedFilters.push({
        label: filter.label,
        selectedFilters: checkedCheckBoxes.map((item) => item.label),
      });
    }
  });

  return { totalChecked, checkedFilters };
};
export const resetAllFilters = (setTableParams, filters, handleFilterClose) => {
  const newTableFilters = {
    query: "",
  };

  filters.forEach((filter) => {
    if (filter.value) {
      newTableFilters[filter.value] = [];
    }

    if (filter.checkBox) {
      filter.checkBox.forEach((item) => {
        item.isChecked = false;
      });
    }
    if (filter.searchValue !== undefined) {
      //reset searchbar
      filter.searchValue = "";
    }
  });
  setTableParams({
    filters: newTableFilters,
    pagination: {
      page: 1,
      limit: 10,
    },
  });
  handleFilterClose();
};

export function renderFilterTooltipContent(filterCount) {
  const hasFilters = filterCount?.checkedFilters?.length > 0;

  if (!hasFilters) {
    return null;
  }
  return (
    <Box>
      {filterCount?.checkedFilters?.map((filter, index) => (
        <Box key={index} mb={1}>
          <Typography color="white" fontWeight="bold">
            {filter.label}
          </Typography>
          {filter.selectedFilters.map((selected, i) => (
            <Typography color="white" key={i}>
              {selected}
            </Typography>
          ))}
        </Box>
      ))}
    </Box>
  );
}
