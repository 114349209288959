import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setAssessment } from "./assessmentSlice";

/**
 * Initial state for the ATFMetaData slice
 * @type {Object}
 */
const initialState = {
  totalMcqQuestions: 0,
  totalEasyMCQQuestions: 0,
  totalMediumMCQQuestions: 0,
  totalHardMCQQuestions: 0,

  totalProgrammingQuestions: 0,
  totalEasyProgrammingQuestions: 0,
  totalMediumProgrammingQuestions: 0,
  totalHardProgrammingQuestions: 0,

  totalNoOfQuestions: 0,
  totalEasyQuestions: 0,
  totalMediumQuestions: 0,
  totalHardQuestions: 0,

  totalSkills: 0,
  totalMcqScore: 0,
  totalProgrammingScore: 0,
  skillWiseMetaData: [],
  questionIds: [],
};

/**
 * Thunk to generate and set metadata
 * @function
 * @param {Object} payload - The payload containing skillWiseQuestions and difficultyLevels
 * @param {Array} payload.skillWiseQuestions - Array of skill-wise questions
 * @param {Array} payload.difficultyLevels - Array of difficulty levels with timings
 * @returns {Object} - The generated metadata
 */
export const generateAndSetMetaData = createAsyncThunk(
  "ATFMetaData/generateAndSetMetaData",
  async (payload, { getState, dispatch }) => {
    try {
      const { skillWiseQuestions } = payload;
      const { difficultyLevels } = getState().difficultyLevels;

      // Extract timings for each difficulty level and question type
      const timings = {
        easyMCQ: difficultyLevels.find((item) => item.level === "easy").mcqTime,
        mediumMCQ: difficultyLevels.find((item) => item.level === "medium")
          .mcqTime,
        hardMCQ: difficultyLevels.find((item) => item.level === "hard").mcqTime,
        easyProgramming: difficultyLevels.find((item) => item.level === "easy")
          .programmingTime,
        mediumProgramming: difficultyLevels.find(
          (item) => item.level === "medium"
        ).programmingTime,
        hardProgramming: difficultyLevels.find((item) => item.level === "hard")
          .programmingTime,
        easySQL: difficultyLevels.find((item) => item.level === "easy").sqlTime,
        mediumSQL: difficultyLevels.find((item) => item.level === "medium")
          .sqlTime,
        hardSQL: difficultyLevels.find((item) => item.level === "hard").sqlTime,
      };

      let testDuration = 0;
      let testScore = 0;

      // Calculate skill-wise metadata
      const skillWiseMetaData = skillWiseQuestions.map((item) => {
        const mcqQuestions = item?.mcqQuestions || {};
        const programmingQuestions = item?.programmingQuestions || {};
        const sqlQuestions = item?.sqlQuestions || {};

        // Calculate scores for each type of question
        const easyMCQScore = calculateScore(
          mcqQuestions?.easyQuestions,
          mcqQuestions?.isRandom
        );
        const mediumMCQScore = calculateScore(
          mcqQuestions?.mediumQuestions,
          mcqQuestions?.isRandom
        );
        const hardMCQScore = calculateScore(
          mcqQuestions?.hardQuestions,
          mcqQuestions?.isRandom
        );

        const easyProgrammingScore = calculateScore(
          programmingQuestions?.easyQuestions,
          programmingQuestions?.isRandom
        );
        const mediumProgrammingScore = calculateScore(
          programmingQuestions?.mediumQuestions,
          programmingQuestions?.isRandom
        );
        const hardProgrammingScore = calculateScore(
          programmingQuestions?.hardQuestions,
          programmingQuestions?.isRandom
        );

        const easySQLScore = calculateScore(
          sqlQuestions?.easyQuestions,
          sqlQuestions?.isRandom
        );
        const mediumSQLScore = calculateScore(
          sqlQuestions?.mediumQuestions,
          sqlQuestions?.isRandom
        );
        const hardSQLScore = calculateScore(
          sqlQuestions?.hardQuestions,
          sqlQuestions?.isRandom
        );

        const mcqScore = easyMCQScore + mediumMCQScore + hardMCQScore;
        const programmingScore =
          easyProgrammingScore + mediumProgrammingScore + hardProgrammingScore;
        const sqlScore = easySQLScore + mediumSQLScore + hardSQLScore;

        const totalMcqQuestions = calculateTotalQuestions(mcqQuestions);

        const totalProgrammingQuestions =
          calculateTotalQuestions(programmingQuestions);

        const totalSQLQuestions = calculateTotalQuestions(sqlQuestions);

        const totalEasyQuestions =
          calculateTotalQuestions(mcqQuestions, "easyQuestions") +
          calculateTotalQuestions(programmingQuestions, "easyQuestions") +
          calculateTotalQuestions(sqlQuestions, "easyQuestions");

        const totalMediumQuestions =
          calculateTotalQuestions(mcqQuestions, "mediumQuestions") +
          calculateTotalQuestions(programmingQuestions, "mediumQuestions") +
          calculateTotalQuestions(sqlQuestions, "mediumQuestions");

        const totalHardQuestions =
          calculateTotalQuestions(mcqQuestions, "hardQuestions") +
          calculateTotalQuestions(programmingQuestions, "hardQuestions") +
          calculateTotalQuestions(sqlQuestions, "hardQuestions");

        const totalQuestions =
          totalMcqQuestions + totalProgrammingQuestions + totalSQLQuestions;

        return {
          skill: item.skill,

          totalMcqQuestions,
          totalEasyMCQQuestions: calculateTotalQuestions(
            mcqQuestions,
            "easyQuestions"
          ),
          totalMediumMCQQuestions: calculateTotalQuestions(
            mcqQuestions,
            "mediumQuestions"
          ),
          totalHardMCQQuestions: calculateTotalQuestions(
            mcqQuestions,
            "hardQuestions"
          ),

          totalProgrammingQuestions,
          totalEasyProgrammingQuestions: calculateTotalQuestions(
            programmingQuestions,
            "easyQuestions"
          ),
          totalMediumProgrammingQuestions: calculateTotalQuestions(
            programmingQuestions,
            "mediumQuestions"
          ),
          totalHardProgrammingQuestions: calculateTotalQuestions(
            programmingQuestions,
            "hardQuestions"
          ),

          totalSQLQuestions,
          totalEasySQLQuestions: calculateTotalQuestions(
            sqlQuestions,
            "easyQuestions"
          ),
          totalMediumSQLQuestions: calculateTotalQuestions(
            sqlQuestions,
            "mediumQuestions"
          ),
          totalHardSQLQuestions: calculateTotalQuestions(
            sqlQuestions,
            "hardQuestions"
          ),

          totalEasyQuestions,
          totalMediumQuestions,
          totalHardQuestions,
          totalQuestions,

          totalMcqScore: mcqScore,
          totalProgrammingScore: programmingScore,
          totalSQLScore: sqlScore,
          totalScore: mcqScore + programmingScore + sqlScore,

          questionIds: {
            mcq: [
              ...(mcqQuestions?.easyQuestions || [])
                .filter((item) => item?._id)
                .map((item) => item._id),
              ...(mcqQuestions?.mediumQuestions || [])
                .filter((item) => item?._id)
                .map((item) => item._id),
              ...(mcqQuestions?.hardQuestions || [])
                .filter((item) => item?._id)
                .map((item) => item._id),
            ],
            programming: [
              ...(programmingQuestions?.easyQuestions || [])
                .filter((item) => item?._id)
                .map((item) => item._id),
              ...(programmingQuestions?.mediumQuestions || [])
                .filter((item) => item?._id)
                .map((item) => item._id),
              ...(programmingQuestions?.hardQuestions || [])
                .filter((item) => item?._id)
                .map((item) => item._id),
            ],
            sql: [
              ...(sqlQuestions?.easyQuestions || [])
                .filter((item) => item?._id)
                .map((item) => item._id),
              ...(sqlQuestions?.mediumQuestions || [])
                .filter((item) => item?._id)
                .map((item) => item._id),
              ...(sqlQuestions?.hardQuestions || [])
                .filter((item) => item?._id)
                .map((item) => item._id),
            ],
          },

          isMcqRandom: mcqQuestions?.isRandom || false,
          isProgrammingRandom: programmingQuestions?.isRandom || false,
          isSQLRandom: sqlQuestions?.isRandom || false,
        };
      });

      // Calculate total values for all questions
      const totalMcqQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalMcqQuestions,
        0
      );
      const totalEasyMCQQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalEasyMCQQuestions,
        0
      );
      const totalMediumMCQQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalMediumMCQQuestions,
        0
      );
      const totalHardMCQQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalHardMCQQuestions,
        0
      );

      const totalMcqScore = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalMcqScore,
        0
      );

      const totalProgrammingQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalProgrammingQuestions,
        0
      );
      const totalEasyProgrammingQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalEasyProgrammingQuestions,
        0
      );
      const totalMediumProgrammingQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalMediumProgrammingQuestions,
        0
      );
      const totalHardProgrammingQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalHardProgrammingQuestions,
        0
      );

      const totalProgrammingScore = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalProgrammingScore,
        0
      );

      const totalSQLQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalSQLQuestions,
        0
      );
      const totalEasySQLQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalEasySQLQuestions,
        0
      );
      const totalMediumSQLQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalMediumSQLQuestions,
        0
      );
      const totalHardSQLQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalHardSQLQuestions,
        0
      );

      const totalSQLScore = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalSQLScore,
        0
      );

      const totalNoOfQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalQuestions,
        0
      );

      const totalEasyQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalEasyQuestions,
        0
      );
      const totalMediumQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalMediumQuestions,
        0
      );
      const totalHardQuestions = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalHardQuestions,
        0
      );

      const questionIds = skillWiseMetaData.flatMap((item) => [
        ...item.questionIds.mcq,
        ...item.questionIds.programming,
        ...item.questionIds.sql,
      ]);
      const totalSkills = skillWiseMetaData.length;

      const result = {
        questionIds,
        totalMcqQuestions,
        totalEasyMCQQuestions,
        totalMediumMCQQuestions,
        totalHardMCQQuestions,
        totalMcqScore,
        totalProgrammingQuestions,
        totalEasyProgrammingQuestions,
        totalMediumProgrammingQuestions,
        totalHardProgrammingQuestions,
        totalProgrammingScore,
        totalSQLQuestions,
        totalEasySQLQuestions,
        totalMediumSQLQuestions,
        totalHardSQLQuestions,
        totalSQLScore,
        totalNoOfQuestions,
        totalEasyQuestions,
        totalMediumQuestions,
        totalHardQuestions,
        totalSkills,
        skillWiseMetaData,
        isAllSkillsRandom: checkIfAllSkillsAreRandom(skillWiseMetaData),
      };

      // Calculate durations for each type of question
      const easyMCQDuration = calculateTiming(
        result.totalEasyMCQQuestions,
        timings.easyMCQ
      );
      const mediumMCQDuration = calculateTiming(
        result.totalMediumMCQQuestions,
        timings.mediumMCQ
      );
      const hardMCQDuration = calculateTiming(
        result.totalHardMCQQuestions,
        timings.hardMCQ
      );

      const easyProgrammingDuration = calculateTiming(
        result.totalEasyProgrammingQuestions,
        timings.easyProgramming
      );
      const mediumProgrammingDuration = calculateTiming(
        result.totalMediumProgrammingQuestions,
        timings.mediumProgramming
      );
      const hardProgrammingDuration = calculateTiming(
        result.totalHardProgrammingQuestions,
        timings.hardProgramming
      );

      const easySQLDuration = calculateTiming(
        result.totalEasySQLQuestions,
        timings.easySQL
      );
      const mediumSQLDuration = calculateTiming(
        result.totalMediumSQLQuestions,
        timings.mediumSQL
      );
      const hardSQLDuration = calculateTiming(
        result.totalHardSQLQuestions,
        timings.hardSQL
      );

      // Sum up the durations to get the total test duration
      testDuration +=
        easyMCQDuration +
        mediumMCQDuration +
        hardMCQDuration +
        easyProgrammingDuration +
        mediumProgrammingDuration +
        hardProgrammingDuration +
        easySQLDuration +
        mediumSQLDuration +
        hardSQLDuration;

      testScore = skillWiseMetaData.reduce(
        (acc, item) => acc + item.totalScore,
        0
      );

      dispatch(
        setAssessment({
          totalScore: testScore,
          cutOff: Math.floor(testScore * 0.6),
          testDuration: testDuration,
        })
      );

      return result;
    } catch (error) {
      console.error("Error in generateAndSetMetaData thunk:", error);
      throw error;
    }
  }
);

/**
 * Thunk to update and set metadata for a specific skill
 * @function
 * @param {String} operation - The operation to be performed (add/update/delete)
 * @param {Object} selectedSkill - The skill object
 * @returns {Object} - The updated metadata
 */
export const updateAndSetMetaData = createAsyncThunk(
  "ATFMetaData/updateAndSetMetaData",
  async ({ operation, selectedSkill }, { getState, dispatch }) => {
    try {
      const state = getState().atfMetaData;
      const { difficultyLevels } = getState().difficultyLevels;

      // Extract timings for each difficulty level and question type
      const timings = {
        easyMCQ: difficultyLevels.find((item) => item.level === "easy").mcqTime,
        mediumMCQ: difficultyLevels.find((item) => item.level === "medium")
          .mcqTime,
        hardMCQ: difficultyLevels.find((item) => item.level === "hard").mcqTime,
        easyProgramming: difficultyLevels.find((item) => item.level === "easy")
          .programmingTime,
        mediumProgramming: difficultyLevels.find(
          (item) => item.level === "medium"
        ).programmingTime,
        hardProgramming: difficultyLevels.find((item) => item.level === "hard")
          .programmingTime,
        easySQL: difficultyLevels.find((item) => item.level === "easy").sqlTime,
        mediumSQL: difficultyLevels.find((item) => item.level === "medium")
          .sqlTime,
        hardSQL: difficultyLevels.find((item) => item.level === "hard").sqlTime,
      };

      if (operation === "delete") {
        // Remove the skill-wise metadata from the state
        const updatedSkillWiseMetaData = state.skillWiseMetaData.filter(
          (metaData) => metaData.skill._id !== selectedSkill.skill._id
        );

        // Calculate total values for all questions
        const aggregateValues = (key) =>
          updatedSkillWiseMetaData.reduce((acc, item) => acc + item[key], 0);

        const result = {
          skillWiseMetaData: updatedSkillWiseMetaData,
          questionIds: updatedSkillWiseMetaData.flatMap((item) => [
            ...item.questionIds.mcq,
            ...item.questionIds.programming,
            ...item.questionIds.sql,
          ]),
          totalMcqQuestions: aggregateValues("totalMcqQuestions"),
          totalEasyMCQQuestions: aggregateValues("totalEasyMCQQuestions"),
          totalMediumMCQQuestions: aggregateValues("totalMediumMCQQuestions"),
          totalHardMCQQuestions: aggregateValues("totalHardMCQQuestions"),
          totalMcqScore: aggregateValues("totalMcqScore"),
          totalProgrammingQuestions: aggregateValues(
            "totalProgrammingQuestions"
          ),
          totalEasyProgrammingQuestions: aggregateValues(
            "totalEasyProgrammingQuestions"
          ),
          totalMediumProgrammingQuestions: aggregateValues(
            "totalMediumProgrammingQuestions"
          ),
          totalHardProgrammingQuestions: aggregateValues(
            "totalHardProgrammingQuestions"
          ),
          totalProgrammingScore: aggregateValues("totalProgrammingScore"),
          totalSQLQuestions: aggregateValues("totalSQLQuestions"),
          totalEasySQLQuestions: aggregateValues("totalEasySQLQuestions"),
          totalMediumSQLQuestions: aggregateValues("totalMediumSQLQuestions"),
          totalHardSQLQuestions: aggregateValues("totalHardSQLQuestions"),
          totalSQLScore: aggregateValues("totalSQLScore"),
          totalNoOfQuestions: aggregateValues("totalQuestions"),
          totalEasyQuestions: aggregateValues("totalEasyQuestions"),
          totalMediumQuestions: aggregateValues("totalMediumQuestions"),
          totalHardQuestions: aggregateValues("totalHardQuestions"),
          totalSkills: updatedSkillWiseMetaData.length,
          isAllSkillsRandom: checkIfAllSkillsAreRandom(
            updatedSkillWiseMetaData
          ),
        };

        const totalScore =
          result.totalMcqScore +
          result.totalProgrammingScore +
          result.totalSQLScore;

        const cutOff = Math.floor(totalScore * 0.6);

        const testDuration =
          calculateTiming(result.totalEasyMCQQuestions, timings.easyMCQ) +
          calculateTiming(result.totalMediumMCQQuestions, timings.mediumMCQ) +
          calculateTiming(result.totalHardMCQQuestions, timings.hardMCQ) +
          calculateTiming(
            result.totalEasyProgrammingQuestions,
            timings.easyProgramming
          ) +
          calculateTiming(
            result.totalMediumProgrammingQuestions,
            timings.mediumProgramming
          ) +
          calculateTiming(
            result.totalHardProgrammingQuestions,
            timings.hardProgramming
          ) +
          calculateTiming(result.totalEasySQLQuestions, timings.easySQL) +
          calculateTiming(result.totalMediumSQLQuestions, timings.mediumSQL) +
          calculateTiming(result.totalHardSQLQuestions, timings.hardSQL);

        dispatch(
          setAssessment({
            totalScore: totalScore,
            cutOff: cutOff,
            testDuration: testDuration,
          })
        );

        return result;
      }

      let testDuration = 0;
      let testScore = 0;

      const mcqQuestions = selectedSkill?.mcqQuestions || {};
      const programmingQuestions = selectedSkill?.programmingQuestions || {};
      const sqlQuestions = selectedSkill?.sqlQuestions || {};

      // Calculate scores for each type of question
      const easyMCQScore = calculateScore(
        mcqQuestions?.easyQuestions,
        mcqQuestions?.isRandom
      );
      const mediumMCQScore = calculateScore(
        mcqQuestions?.mediumQuestions,
        mcqQuestions?.isRandom
      );
      const hardMCQScore = calculateScore(
        mcqQuestions?.hardQuestions,
        mcqQuestions?.isRandom
      );

      const easyProgrammingScore = calculateScore(
        programmingQuestions?.easyQuestions,
        programmingQuestions?.isRandom
      );
      const mediumProgrammingScore = calculateScore(
        programmingQuestions?.mediumQuestions,
        programmingQuestions?.isRandom
      );
      const hardProgrammingScore = calculateScore(
        programmingQuestions?.hardQuestions,
        programmingQuestions?.isRandom
      );

      const easySQLScore = calculateScore(
        sqlQuestions?.easyQuestions,
        sqlQuestions?.isRandom
      );
      const mediumSQLScore = calculateScore(
        sqlQuestions?.mediumQuestions,
        sqlQuestions?.isRandom
      );
      const hardSQLScore = calculateScore(
        sqlQuestions?.hardQuestions,
        sqlQuestions?.isRandom
      );

      const mcqScore = easyMCQScore + mediumMCQScore + hardMCQScore;
      const programmingScore =
        easyProgrammingScore + mediumProgrammingScore + hardProgrammingScore;
      const sqlScore = easySQLScore + mediumSQLScore + hardSQLScore;

      const totalMcqSkillQuestions = calculateTotalQuestions(mcqQuestions);
      const totalProgrammingSkillQuestions =
        calculateTotalQuestions(programmingQuestions);
      const totalSQLSkillQuestions = calculateTotalQuestions(sqlQuestions);
      const totalEasySkillQuestions =
        calculateTotalQuestions(mcqQuestions, "easyQuestions") +
        calculateTotalQuestions(programmingQuestions, "easyQuestions") +
        calculateTotalQuestions(sqlQuestions, "easyQuestions");
      const totalMediumSkillQuestions =
        calculateTotalQuestions(mcqQuestions, "mediumQuestions") +
        calculateTotalQuestions(programmingQuestions, "mediumQuestions") +
        calculateTotalQuestions(sqlQuestions, "mediumQuestions");
      const totalHardSkillQuestions =
        calculateTotalQuestions(mcqQuestions, "hardQuestions") +
        calculateTotalQuestions(programmingQuestions, "hardQuestions") +
        calculateTotalQuestions(sqlQuestions, "hardQuestions");
      const totalQuestions =
        totalMcqSkillQuestions +
        totalProgrammingSkillQuestions +
        totalSQLSkillQuestions;

      const questionIds = {
        mcq: [
          ...(mcqQuestions?.easyQuestions || [])
            .filter((item) => item?._id)
            .map((item) => item._id),
          ...(mcqQuestions?.mediumQuestions || [])
            .filter((item) => item?._id)
            .map((item) => item._id),
          ...(mcqQuestions?.hardQuestions || [])
            .filter((item) => item?._id)
            .map((item) => item._id),
        ],
        programming: [
          ...(programmingQuestions?.easyQuestions || [])
            .filter((item) => item?._id)
            .map((item) => item._id),
          ...(programmingQuestions?.mediumQuestions || [])
            .filter((item) => item?._id)
            .map((item) => item._id),
          ...(programmingQuestions?.hardQuestions || [])
            .filter((item) => item?._id)
            .map((item) => item._id),
        ],
        sql: [
          ...(sqlQuestions?.easyQuestions || [])
            .filter((item) => item?._id)
            .map((item) => item._id),
          ...(sqlQuestions?.mediumQuestions || [])
            .filter((item) => item?._id)
            .map((item) => item._id),
          ...(sqlQuestions?.hardQuestions || [])
            .filter((item) => item?._id)
            .map((item) => item._id),
        ],
      };
      const updatedSkillMetaData = {
        skill: selectedSkill.skill,
        totalMcqQuestions: totalMcqSkillQuestions,
        totalEasyMCQQuestions: calculateTotalQuestions(
          mcqQuestions,
          "easyQuestions"
        ),
        totalMediumMCQQuestions: calculateTotalQuestions(
          mcqQuestions,
          "mediumQuestions"
        ),
        totalHardMCQQuestions: calculateTotalQuestions(
          mcqQuestions,
          "hardQuestions"
        ),

        totalProgrammingQuestions: totalProgrammingSkillQuestions,
        totalEasyProgrammingQuestions: calculateTotalQuestions(
          programmingQuestions,
          "easyQuestions"
        ),
        totalMediumProgrammingQuestions: calculateTotalQuestions(
          programmingQuestions,
          "mediumQuestions"
        ),
        totalHardProgrammingQuestions: calculateTotalQuestions(
          programmingQuestions,
          "hardQuestions"
        ),

        totalSQLQuestions: totalSQLSkillQuestions,
        totalEasySQLQuestions: calculateTotalQuestions(
          sqlQuestions,
          "easyQuestions"
        ),
        totalMediumSQLQuestions: calculateTotalQuestions(
          sqlQuestions,
          "mediumQuestions"
        ),
        totalHardSQLQuestions: calculateTotalQuestions(
          sqlQuestions,
          "hardQuestions"
        ),

        totalEasyQuestions: totalEasySkillQuestions,
        totalMediumQuestions: totalMediumSkillQuestions,
        totalHardQuestions: totalHardSkillQuestions,
        totalQuestions: totalQuestions,
        totalMcqScore: mcqScore,
        totalProgrammingScore: programmingScore,
        totalSQLScore: sqlScore,
        totalScore: mcqScore + programmingScore + sqlScore,

        questionIds: questionIds,

        isMcqRandom: mcqQuestions?.isRandom || false,
        isProgrammingRandom: programmingQuestions?.isRandom || false,
        isSQLRandom: sqlQuestions?.isRandom || false,
      };

      let updatedSkillWiseMetaData = state.skillWiseMetaData.map((metaData) =>
        metaData.skill._id === selectedSkill.skill._id
          ? updatedSkillMetaData
          : metaData
      );

      const skillExists = state.skillWiseMetaData.some(
        (metaData) => metaData.skill._id === selectedSkill.skill._id
      );

      if (!skillExists) {
        updatedSkillWiseMetaData = [
          ...updatedSkillWiseMetaData,
          updatedSkillMetaData,
        ];
      }

      // Calculate total values for all questions
      const aggregateValues = (key) =>
        updatedSkillWiseMetaData.reduce((acc, item) => acc + item[key], 0);

      const result = {
        skillWiseMetaData: updatedSkillWiseMetaData,
        questionIds: updatedSkillWiseMetaData.flatMap((item) => [
          ...item.questionIds.mcq,
          ...item.questionIds.programming,
          ...item.questionIds.sql,
        ]),
        totalMcqQuestions: aggregateValues("totalMcqQuestions"),
        totalEasyMCQQuestions: aggregateValues("totalEasyMCQQuestions"),
        totalMediumMCQQuestions: aggregateValues("totalMediumMCQQuestions"),
        totalHardMCQQuestions: aggregateValues("totalHardMCQQuestions"),
        totalMcqScore: aggregateValues("totalMcqScore"),

        totalProgrammingQuestions: aggregateValues("totalProgrammingQuestions"),
        totalEasyProgrammingQuestions: aggregateValues(
          "totalEasyProgrammingQuestions"
        ),
        totalMediumProgrammingQuestions: aggregateValues(
          "totalMediumProgrammingQuestions"
        ),
        totalHardProgrammingQuestions: aggregateValues(
          "totalHardProgrammingQuestions"
        ),
        totalProgrammingScore: aggregateValues("totalProgrammingScore"),

        totalSQLQuestions: aggregateValues("totalSQLQuestions"),
        totalEasySQLQuestions: aggregateValues("totalEasySQLQuestions"),
        totalMediumSQLQuestions: aggregateValues("totalMediumSQLQuestions"),
        totalHardSQLQuestions: aggregateValues("totalHardSQLQuestions"),
        totalSQLScore: aggregateValues("totalSQLScore"),

        totalNoOfQuestions: aggregateValues("totalQuestions"),
        totalEasyQuestions: aggregateValues("totalEasyQuestions"),
        totalMediumQuestions: aggregateValues("totalMediumQuestions"),
        totalHardQuestions: aggregateValues("totalHardQuestions"),
        totalSkills: updatedSkillWiseMetaData.length,
        isAllSkillsRandom: checkIfAllSkillsAreRandom(updatedSkillWiseMetaData),
      };

      // Calculate durations for each type of question
      const easyMCQDuration = calculateTiming(
        result.totalEasyMCQQuestions,
        timings.easyMCQ
      );
      const mediumMCQDuration = calculateTiming(
        result.totalMediumMCQQuestions,
        timings.mediumMCQ
      );
      const hardMCQDuration = calculateTiming(
        result.totalHardMCQQuestions,
        timings.hardMCQ
      );

      const easyProgrammingDuration = calculateTiming(
        result.totalEasyProgrammingQuestions,
        timings.easyProgramming
      );
      const mediumProgrammingDuration = calculateTiming(
        result.totalMediumProgrammingQuestions,
        timings.mediumProgramming
      );
      const hardProgrammingDuration = calculateTiming(
        result.totalHardProgrammingQuestions,
        timings.hardProgramming
      );

      const easySQLDuration = calculateTiming(
        result.totalEasySQLQuestions,
        timings.easySQL
      );
      const mediumSQLDuration = calculateTiming(
        result.totalMediumSQLQuestions,
        timings.mediumSQL
      );
      const hardSQLDuration = calculateTiming(
        result.totalHardSQLQuestions,
        timings.hardSQL
      );

      // Sum up the durations to get the total test duration
      testDuration +=
        easyMCQDuration +
        mediumMCQDuration +
        hardMCQDuration +
        easyProgrammingDuration +
        mediumProgrammingDuration +
        hardProgrammingDuration +
        easySQLDuration +
        mediumSQLDuration +
        hardSQLDuration;

      testScore = updatedSkillWiseMetaData.reduce(
        (acc, item) => acc + item.totalScore,
        0
      );

      dispatch(
        setAssessment({
          totalScore: testScore,
          cutOff: Math.floor(testScore * 0.6),
          testDuration: testDuration,
        })
      );

      return result;
    } catch (error) {
      console.error("Error in updateAndSetMetaData thunk:", error);
      throw error;
    }
  }
);

const checkIfAllSkillsAreRandom = (skillWiseMetaData) => {
  if (skillWiseMetaData.length <= 0) return false;
  return skillWiseMetaData.every((skill) => {
    const hasMcqQuestions = skill.totalMcqQuestions > 0;
    const hasProgrammingQuestions = skill.totalProgrammingQuestions > 0;
    const hasSQLQuestions = skill.totalSQLQuestions > 0;

    const isMcqRandom = hasMcqQuestions ? skill.isMcqRandom : true;
    const isProgrammingRandom = hasProgrammingQuestions
      ? skill.isProgrammingRandom
      : true;
    const isSQLRandom = hasSQLQuestions ? skill.isSQLRandom : true;

    return isMcqRandom && isProgrammingRandom && isSQLRandom;
  });
};

// Helper functions
const calculateTiming = (questionsCount, timePerQuestion, isRandom) => {
  if (!questionsCount) return 0;
  if (isRandom) {
    return questionsCount.reduce(
      (acc, question) => acc + question.totalQuestions * timePerQuestion,
      0
    );
  }
  return questionsCount * timePerQuestion;
};

const calculateScore = (questions, isRandom) => {
  if (!questions) return 0;
  if (isRandom) {
    return questions.reduce(
      (acc, question) => acc + question.totalQuestions * question.score,
      0
    );
  }
  return questions.reduce((acc, question) => acc + question.score, 0);
};

const calculateTotalQuestions = (questions, type) => {
  if (!questions) return 0;

  const calculateSum = (questionsArray) => {
    return questionsArray.reduce(
      (acc, question) => acc + (question.totalQuestions || 0),
      0
    );
  };

  if (!type) {
    if (questions.isRandom) {
      return (
        calculateSum(questions?.easyQuestions) +
        calculateSum(questions?.mediumQuestions) +
        calculateSum(questions?.hardQuestions)
      );
    } else {
      const easyQuestions = questions?.easyQuestions?.length ?? 0;
      const mediumQuestions = questions?.mediumQuestions?.length ?? 0;
      const hardQuestions = questions?.hardQuestions?.length ?? 0;
      return easyQuestions + mediumQuestions + hardQuestions;
    }
  }

  if (questions.isRandom) {
    return calculateSum(questions[type]);
  }

  return questions[type]?.length ?? 0;
};

/**
 * ATFMetaData slice
 */
const ATFMetaDataSlice = createSlice({
  name: "ATFMetaData",
  initialState,
  reducers: {
    /**
     * Reset the metadata to the initial state
     * @param {Object} state - The current state
     */
    resetMetaData: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateAndSetMetaData.fulfilled, (state, action) => {
      Object.assign(state, action.payload);
    });
    builder.addCase(updateAndSetMetaData.fulfilled, (state, action) => {
      Object.assign(state, action.payload);
    });
  },
});

export const { resetMetaData } = ATFMetaDataSlice.actions;
export default ATFMetaDataSlice.reducer;
