import { combineReducers } from "@reduxjs/toolkit";
import assessmentReducer from "./slices/assessmentSlice";
import assessmentQuestionReducer from "./slices/assessmentQuestionSlice";
import ATFMetaDetaReducer from "./slices/ATFMetaDetaSlice";
import difficultyLevelsSlice from "./slices/difficultyLevelsSlice";

// Combine the individual slice reducers into a rootReducer
const rootReducer = combineReducers({
  assessment: assessmentReducer,
  assessmentQuestion: assessmentQuestionReducer,
  atfMetaData: ATFMetaDetaReducer,
  difficultyLevels: difficultyLevelsSlice,
});

export default rootReducer;
