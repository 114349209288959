import {
  Box,
  Button,
  capitalize,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Stack,
  Typography,
  useTheme,
  Tab,
  Tabs,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Avatar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CandidateCheckboxData } from "../../utils/data";
import APICall from "../../utils/api";
import { useMessage } from "../../components/snackbar/snackbar";
import { AuthContext } from "../../context/authContext";
import { DataGrid } from "@mui/x-data-grid";
import CommonModal from "../../components/modal/CommonModal";
import { makeStyles } from "@mui/styles";
import CustomTable from "../../components/common/customTable/CustomTable";
import EditTestOverview from "./EditTestOverview";
import SearchBar from "../../components/common/SearchBar";
import IOSSwitch from "../../components/IOSSwitch/IOSSwitch";
import "suneditor/dist/css/suneditor.min.css";
import DOMPurify from "dompurify";

import Iconify from "../../components/iconify/Iconify";
import { SortableItem } from "./SortableList";
import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
  closestCenter,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const CommonButton = (props) => {
  return (
    <Typography
      sx={{
        padding: "4px 8px",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20px",
        color: "#384455",
        backgroundColor: "#D3D9E2",
        borderRadius: "4px",
      }}
      {...props}
    >
      {props.children}
    </Typography>
  );
};
const columnsWidth = ["2px", "10px", "10px", "10px"];
const useStyles = makeStyles((theme) => ({
  main: {
    borderRadius: "4px",
    padding: "16px",
    background: theme.palette.background.default,
    width: "100%",
  },
  tableHeader: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    alignItems: "center",
  },
  tableName: {
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "24px",
    textWrap: "nowrap",
    marginRight: "16px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "16px",
    },
  },
  tableHeaderWrapper: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },
}));

const TestOverview = ({
  assessmentDetails,
  fetchAssessmentDetails,
  hideAndShow,
  useTemplateEdit,
}) => {
  const theme = useTheme();
  const message = useMessage();
  const classes = useStyles();
  const [checkboxData, setCheckboxData] = useState();
  const [candidatecheckbox, setCandidateCheckbox] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { authUser } = React.useContext(AuthContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [newInstruction, setNewInstruction] = useState("");
  const [showTextField, setShowTextField] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteAdminConfirmOpen, setDeleteAdminConfirmOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isEditingInstructions, setIsEditingInstructions] = useState(false);
  const [settings, setSettings] = useState([]);
  const [candidateFields, setCandidateFields] = useState([]);
  const [channelData, setChannelData] = useState([]);
  const [testAdmins, setTestAdmins] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedAdminRows, setSelectedAdminRows] = useState([]);
  const [dropDownAdminValue, setdropDowAdminValue] = useState("All");
  const [searchAdminValue, setSearchAdminValue] = useState("");
  const [initialAdminData, setinitialAdminData] = useState();
  const [channelName, setChannelName] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [EditTestOverviewToggle, setEditTestOverviewToggle] = useState(false);
  const [switchStates, setSwitchStates] = useState(
    assessmentDetails?.proctoringSettingsId?.reduce((acc, item) => {
      acc[item.setting.id] = item.active;
      return acc;
    }, {}) || {}
  );

  const [tableParams, setTableParams] = useState({
    filter: {
      search: "",
    },
    pagination: {
      page: 1,
      limit: 10,
    },
  });
  const [instructions, setInstructions] = useState(
    assessmentDetails?.instruction || []
  );

  useEffect(() => {
    if (assessmentDetails?.proctoringSettingsId) {
      setSettings(assessmentDetails.proctoringSettingsId);
    }
  }, [assessmentDetails]);

  useEffect(() => {
    if (assessmentDetails?.testAdminsId) {
      const updatedTestAdmins = assessmentDetails.testAdminsId.map(
        (item, index) => ({
          ...item,
          rowNumber: index + 1, // Adding row number starting from 1
        })
      );
      setTestAdmins(updatedTestAdmins);
    }
  }, [assessmentDetails]);
  useEffect(() => {
    if (assessmentDetails?.candidateFieldsId) {
      setCandidateFields(assessmentDetails.candidateFieldsId);
    }
  }, [assessmentDetails]);

  useEffect(() => {
    setInstructions(assessmentDetails?.instruction);
  }, [assessmentDetails]);

  const handleQuestionClose = () => {
    setEditTestOverviewToggle(false);
  };
  const updateProctoringSetting = async (id, settings) => {
    const formattedSettings = settings.map((setting) => ({
      setting: setting.setting._id,
      active: setting.active,
    }));

    const data = {
      proctoringSettingsId: formattedSettings,
    };

    try {
      const response = await APICall(`/Assessment/${id}`, data, "patch");

      return response.data;
    } catch (error) {
      console.error("Error updating proctoring setting:", error);
      throw error;
    }
  };
  const handleSwitchChange = async (event, settingId) => {
    const isActive = event.target.checked;

    const updatedSettings = settings.map((setting) =>
      setting.setting._id === settingId
        ? { ...setting, active: isActive }
        : setting
    );

    setSettings(updatedSettings);

    try {
      await updateProctoringSetting(assessmentDetails._id, updatedSettings);
    } catch (error) {
      console.error("Error updating proctoring setting:", error);
    }
  };

  const handleCandidateChange = async (event, fieldId) => {
    const isActive = event.target.checked;

    const updatedFields = candidateFields.map((field) =>
      field.field._id === fieldId ? { ...field, active: isActive } : field
    );

    setCandidateFields(updatedFields);

    try {
      await updateCandidateFields(assessmentDetails._id, updatedFields);
    } catch (error) {
      console.error("Error updating candidate field:", error);
    }
  };

  const updateCandidateFields = async (id, fields) => {
    const formattedFields = fields.map((field) => ({
      field: field.field._id,
      active: field.active,
    }));

    const data = {
      candidateFieldsId: formattedFields,
    };

    try {
      const response = await APICall(`/Assessment/${id}`, data, "patch");

      return response.data;
    } catch (error) {
      console.error("Error updating candidate fields:", error);
      throw error;
    }
  };
  const handleEditClick = () => {
    setIsEditingInstructions(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (assessmentDetails?.testAdminsId) {
      const existingIds = assessmentDetails.testAdminsId.map(
        (admin) => admin._id
      );
      setSelectedAdminRows((prevSelectedRows) => {
        const updatedRows = new Set([...existingIds, ...prevSelectedRows]);
        return Array.from(updatedRows);
      });
    }
  }, [assessmentDetails]);
  const handleAdminSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchAdminValue(searchValue);
    if (searchValue === "") {
      setTableData(initialAdminData);
    } else {
      const filteredData = tableData.filter((admin) =>
        admin.name.toLowerCase().includes(searchValue)
      );
      setTableData(filteredData);
    }
  };
  const handleAdminCheckboxChange = (rowId) => {
    setSelectedAdminRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(rowId)) {
        return prevSelectedRows.filter((id) => id !== rowId);
      } else {
        return [...prevSelectedRows, rowId];
      }
    });
    console.log(selectedAdminRows);
  };
  const areAllRowsSelected =
    tableData.length > 0 &&
    tableData.every((row) => selectedAdminRows.includes(row._id));
  const handleAdminSelectAll = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);

    if (isChecked) {
      const allRowIds = tableData.map((row) => row._id);
      setSelectedAdminRows(allRowIds);
    } else {
      setSelectedAdminRows([]);
    }
  };

  const handleConfirmOpen = async () => {
    const existingIds =
      assessmentDetails?.testAdminsId.map((admin) => admin._id) || [];
    const combinedIds = [...new Set([...existingIds, ...selectedAdminRows])];
    const data = {
      testAdminsId: combinedIds,
    };

    try {
      const response = await APICall(
        `/assessment/${assessmentDetails?._id}`,
        data,
        "patch"
      );
      message("Admin added successfully !!", "success");
      setSelectedAdminRows([]);

      fetchAssessmentDetails();
    } catch (error) {
      console.error("Error sending selected rows:", error);
    }
    setConfirmOpen(false);
  };

  const handleAdminData = async (e) => {
    try {
      let usersUrl = `/user?page=1&limit=1000`;
      if (assessmentDetails?.createdBy?.channelId) {
        usersUrl += `&channelId=${assessmentDetails?.createdBy?.channelId}`;
      }

      const usersRes = await APICall(usersUrl);
      const fetchedUsers = usersRes.data.documents;

      const filteredUsers = fetchedUsers
        .filter(
          (admin) =>
            !testAdmins.some((existingAdmin) => existingAdmin._id === admin._id)
        )
        .filter(
          (admin) => admin.role === "recruiter" || admin.role === "channel head"
        );
      setChannelName(filteredUsers[0]?.channelId?.name);
      console.log(filteredUsers);
      setinitialAdminData(filteredUsers);
      setTableData(filteredUsers);
    } catch (error) {
      console.error("Error fetching admin data", error);
    }
  };

  const handleInstructionDelete = (index) => {
    const newInstructions = instructions?.filter((_, i) => i !== index);
    setInstructions(newInstructions);
    setDeleteConfirmOpen(true);
    handleClose();
  };

  const handleAdminDelete = async (updatedAdmins) => {
    const testAdminsIds = updatedAdmins.map((admin) => admin._id);

    const data = {
      testAdminsId: testAdminsIds,
    };

    try {
      const response = await APICall(
        `/Assessment/${assessmentDetails._id}`,
        data,
        "patch"
      );

      message("Admin deleted successfully !!", "success");

      fetchAssessmentDetails();
      setSelectedAdminRows([]);
      return response.data;
    } catch (error) {
      console.error("Error removing admin", error);
      throw error;
    }
  };

  const handleConfirmDelete = () => {
    setDeleteConfirmOpen(false);
    message("Instruction deleted successfully !!", "success");
  };

  const handleConfirmAdminDelete = async () => {
    const updatedAdmins = testAdmins.filter(
      (admin) => admin._id !== selectedUser
    );

    setTestAdmins(updatedAdmins);

    try {
      await handleAdminDelete(updatedAdmins);
    } catch (error) {
      console.error("Error during admin delete operation:", error);
    } finally {
      setDeleteAdminConfirmOpen(false);
    }
  };

  const handleCancelClick = () => {
    setIsEditingInstructions(false);
    setInstructions(assessmentDetails?.instruction || []);
    setNewInstruction("");
  };

  const handleSaveClick = async () => {
    const data = {
      instruction: instructions,
    };
    try {
      const res = await APICall(
        `/assessment/${assessmentDetails?._id}`,
        data,
        "patch"
      );

      message("Instructions updated successfully !!", "success");
      fetchAssessmentDetails();
    } catch (error) {
      message(error.response.data.message, "error");
    }
    setIsEditingInstructions(false);
  };

  const handleInstructionChange = (e, index) => {
    const newInstructions = [...instructions];
    newInstructions[index] = e.target.value;
    setInstructions(newInstructions);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleCheckboxChange = (item, checkboxType) => (event) => {
    const updatedCheckboxData =
      checkboxType === "initial"
        ? initialCheckboxData.slice()
        : CandidateCheckboxData.slice();
    updatedCheckboxData.forEach((checkboxItem) => {
      if (checkboxItem.label === item.label) {
        checkboxItem.checked = event.target.checked;
      }
    });
    if (checkboxType === "initial") {
      setCheckboxData(updatedCheckboxData);
    } else {
      setCandidateCheckbox(updatedCheckboxData);
    }
  };

  const handleAddInstruction = () => {
    if (newInstruction.trim()) {
      setInstructions([...instructions, newInstruction.trim()]);
      setNewInstruction("");
      setShowTextField(true);
    }
    console.log(instructions);

    message("Instruction added successfully!", "success");
  };

  const handleAddAdmin = async () => {
    await handleAdminData();
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(assessmentDetails.link)
      .then(() => {
        message("Link copied to clipboard!", "success");
        setOpenSnackbar(true);
      })
      .catch((err) => console.error("Failed to copy: ", err));
  };

  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = (event) => {
    const { active, over } = event;

    // If there is no item over or the same item is dragged, return
    if (!over || active.id === over.id) return;

    const oldIndex = Number(active.id) - 1; // Convert to zero-based index
    const newIndex = Number(over.id) - 1; // Convert to zero-based index

    // Move the item in the instructions array
    setInstructions((items) => arrayMove(items, oldIndex, newIndex));
  };

  const TestDurationData = [
    {
      iconSrc: "/assets/icons/calendar.svg",
      label: "Duration",
      duration:
        `${new Date(assessmentDetails?.startsAt).toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        })} - ${new Date(assessmentDetails?.endsAt).toLocaleDateString(
          "en-US",
          {
            day: "2-digit",
            month: "short",
            year: "numeric",
          }
        )} | ${new Date(assessmentDetails?.startsAt).toLocaleTimeString(
          "en-US",
          {
            hour: "2-digit",
            minute: "2-digit",
          }
        )} - ${new Date(assessmentDetails?.endsAt).toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
        })}` || "Not specified",
    },
    {
      iconSrc: "/assets/icons/briefcaseBlack.svg",
      label: "Job Role",
      duration:
        capitalize(assessmentDetails?.jobRoleId?.jobRole || "") ||
        "Not specified",
    },
    {
      iconSrc: "/assets/icons/award.svg",
      label: "Test score",
      duration: assessmentDetails?.totalScore || "0",
    },
    {
      iconSrc: "/assets/icons/clock.svg",
      label: "Test Time",
      duration: `${assessmentDetails?.testDuration} mins` || "00:00",
    },
    {
      iconSrc: "/assets/icons/tie.svg",
      label: "Experience",
      duration:
        `${assessmentDetails?.experienceId?.title} (${assessmentDetails?.experienceId?.experience})` ||
        "Not specified",
    },
    {
      iconSrc: "/assets/icons/checklist.svg",
      label: "Cutoff Score",
      duration: assessmentDetails?.cutOff || "0",
    },
  ];

  const initialCheckboxData = [
    {
      label: "Shuffle Questions for each candidate",
      checked: true,
      disabled: true,
    },
    {
      label: "Take Snapshots via webcam every 30 second",
      checked: false,
      disabled: false,
    },
    {
      label: "Turn Off copy paste from external sources",
      checked: true,
      disabled: true,
    },
    {
      label: "Turn on fullscreen while test",
      checked: false,
      disabled: false,
    },
    {
      label: "Turn Off copy paste from external sources",
      checked: true,
      disabled: true,
    },
    {
      label: "Logout on leaving a test interface",
      checked: false,
      disabled: false,
    },
    {
      label: "Check multi screen attachment",
      checked: false,
      disabled: false,
    },
  ];

  const columns = [
    {
      field: "srNo",
      headerName: "Sr. no",
      maxWidth: 100,
      sortable: false,
      align: "center",
      flex: 0.5,
      renderCell: (row) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography
              component={"p"}
              sx={{ fontSize: "14px", cursor: "pointer" }}
            >
              {row?.row?.rowNumber}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      maxWidth: 300,
      sortable: false,
      align: "right",
      flex: 1,
      renderCell: (row) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              height: "100%",
            }}
          >
            <Avatar
              src={row?.userDetails?.profilePhoto}
              alt="user"
              style={{ height: "20px", width: "20px" }}
            />
            <Typography
              component={"p"}
              sx={{ fontSize: "14px", cursor: "pointer" }}
            >
              {row?.row?.name}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      maxWidth: 300,
      sortable: false,
      flex: 1,
      align: "right",
      renderCell: (row) => {
        const email = row.row?.email;
        return (
          <Typography
            component={"p"}
            sx={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              height: "100%",
              textTransform: "capitalize",
            }}
          >
            {email}
          </Typography>
        );
      },
    },
    {
      field: "access",
      headerName: "Access",
      sortable: false,
      maxWidth: 300,
      flex: 1,
      renderCell: (row) => {
        return (
          <Typography
            component={"p"}
            sx={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              height: "100%",
              textTransform: "capitalize",
              color: "#00C49A",
            }}
          >
            <Box
              sx={{
                borderRadius: "5px",
                bgcolor: "#d5f9f1",
                width: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "4px 8px",
              }}
            >
              {row.row?.role}
            </Box>
          </Typography>
        );
      },
    },
    {
      sortable: false,
      maxWidth: 100,
      flex: 1,
      renderCell: (row) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              height: "100%",
            }}
          >
            <IconButton aria-label="notify" size="small" sx={{ padding: 0 }}>
              <img
                src="/assets/icons/bellRinging.svg"
                alt="bellRinging"
                onClick={() =>
                  message("Test Admin has been notified !!", "success")
                }
                style={{
                  marginRight: "20px",
                  height: "20px",
                }}
              />
            </IconButton>
            <IconButton
              aria-label="delete"
              size="small"
              sx={{ padding: 0 }}
              onClick={() => {}}
            >
              <img
                src="/assets/delete_icon_2.svg"
                alt="delete_icon"
                onClick={() => {
                  setSelectedUser(row.row._id);
                  setDeleteAdminConfirmOpen(true);
                }}
              />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const tableBodyRowCell = (row, cellStyleProps) => {
    return [
      {
        cellStyleProps: {
          width: columnsWidth[0],
          maxWidth: columnsWidth[0],
          padding: "10px 3px",
        },
        element: (
          <Checkbox
            checked={selectedAdminRows.includes(row._id)}
            onChange={() => handleAdminCheckboxChange(row._id)}
            sx={{
              //color: selectAll ? "#ff6812" : "default",

              "&.Mui-checked": {
                color: "#ff6812 !important",
                cursor: "pointer",

                // ...tableContentCellTextStyleProps,
              },
            }}
          />
        ),
      },
      {
        cellStyleProps: {
          width: columnsWidth[0],
          maxWidth: columnsWidth[0],
          ...cellStyleProps,
        },
        element: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Avatar
              src={row?.userDetails?.profilePhoto}
              style={{ height: "20px", width: "20px" }}
            />

            {row.name}
          </Box>
        ),
      },

      {
        cellStyleProps: {
          width: columnsWidth[3],
          maxWidth: columnsWidth[3],
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row.email}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          width: columnsWidth[4],
          maxWidth: columnsWidth[4],
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {capitalize(row?.role)}
          </Typography>
        ),
      },
    ];
  };

  const tableBodyContent = () => {
    const cellStyleProps = {
      borderBottom: "1px solid #E8E9EE",
      padding: "10px 14px",
    };

    return tableData.map((row, idx) => {
      return {
        rowId: idx + 1,
        sortable: {
          name: row.name,
          email: row.email,
          channel: row.role,
        },
        rowElement: tableBodyRowCell(
          { ...row, rowId: idx + 1 },
          cellStyleProps
        ),
      };
    });
  };

  const tableHeaderTitles = [
    {
      title: (
        <input
          type="checkbox"
          checked={selectAll}
          onChange={handleAdminSelectAll}
        />
      ),
      render: (rowData) => (
        <input
          type="checkbox"
          checked={rowData.isChecked}
          onChange={(e) => handleCheckboxChange(e, rowData)}
        />
      ),
      cellStyleProps: {
        width: "0px",
      },
    },
    {
      title: "Name",
      cellStyleProps: {
        whiteSpace: "nowrap",
        width: "180px",
      },
    },

    {
      title: "Email ID",
      cellStyleProps: {
        whiteSpace: "nowrap",
        width: "200px",
      },
    },
    {
      title: "Access",
      cellStyleProps: {
        whiteSpace: "nowrap",
        width: "150px",
      },
    },
  ];

  const tableHeaderCellStyleProps = {
    color: "#363E45",
    lineHeight: "16px",
    fontSize: "12px",
    fontWeight: "500",
    fontFamily: "Roboto",
    padding: "10px 16px",
    height: "36px",
  };
  const tableContentCellTextStyleProps = {
    color: "#363E45",
    lineHeight: "20px",
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "Roboto",
    textAlign: "left",
    height: "20px",
  };
  const tableBodyRowStyleProps = {
    height: "48px",
  };
  const tableHeaderCells = () => {
    return tableHeaderTitles.map((item) => {
      return {
        element: item.title,
        cellStyleProps: item.cellStyleProps,
        isSortable: item?.isSortable ? item.isSortable : false,
        cellId: item?.cellId ? item.cellId : "",
      };
    });
  };

  return (
    <Box
      sx={{
        marginTop: "1rem",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "0px 0px 20px",
        gap: "20px",
        backgroundColor: "#FFFFFF",
        border: "1px solid #E8E9EE",
        borderRadius: "4px",
      }}
    >
      {/* Overview topcard */}
      <Stack
        direction={"column"}
        alignItems={"initial"}
        sx={{
          padding: "16px 20px",
          gap: "20px",
          alignSelf: "stretch",
          background: "#F2F4F8",
          borderRadius: "4px",
        }}
      >
        <Stack
          direction={{ base: "column", md: "row" }}
          alignItems={{ base: "flex-start", md: "center" }}
          sx={{
            padding: 0,
            gap: "16px",
            alignSelf: "stretch",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "24px",
              color: "#363E45",
              flexGrow: 1,
            }}
          >
            Test Overview
          </Typography>

          {!hideAndShow ? (
            <>
              {" "}
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={"8px"}
                flexWrap="wrap"
                sx={{ padding: 0 }}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap={"8px"}
                  sx={{ padding: 0 }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#8591A2",
                    }}
                  >
                    <CommonButton>
                      {assessmentDetails?.testType.charAt(0).toUpperCase() +
                        assessmentDetails?.testType.slice(1) || "Not specified"}
                    </CommonButton>
                  </Typography>
                </Stack>
                {assessmentDetails?.testInviteOnly && (
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    sx={{
                      padding: " 4px 8px",
                      gap: " 4px",
                      backgroundColor: "#FFF0E7",
                      borderRadius: "4px",
                    }}
                  >
                    <>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        sx={{ padding: 0 }}
                      >
                        <Box
                          component={"img"}
                          src="/assets/icons/mailForwardOrange.svg"
                          alt="avatar icon"
                          sx={{
                            width: "20px",
                            height: "20px",
                            color: "#FF6812",
                          }}
                        ></Box>
                      </IconButton>
                      <Typography
                        sx={{
                          fontFamily: "Roboto",
                          fontWeight: 400,
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#FF6812",
                        }}
                      >
                        Invite Only
                      </Typography>
                    </>
                  </Stack>
                )}
                {assessmentDetails?.librarySelection?.map((library) => (
                  <CommonButton>{library}</CommonButton>
                ))}
              </Stack>
              <Button
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px 16px 10px 12px",
                  gap: "8px",
                  border: "1px solid #FF6812",
                  borderRadius: "4px",
                  height: "30px",
                  width: "165px",
                }}
                onClick={handleCopyLink}
              >
                <Box
                  component={"img"}
                  src="/assets/icons/copyOrange.svg"
                  alt="copy icon"
                  sx={{ width: "20px", height: "15px" }}
                ></Box>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#FF6812",
                  }}
                >
                  Assessment Link
                </Typography>
              </Button>
              {authUser?.role === "recruiter" &&
                assessmentDetails?.status !== "Completed" && (
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "#00c49a",
                      height: "30px",
                      ":hover": { bgcolor: "#00c49a" },
                      color: "#ffffff",
                    }}
                    disabled={assessmentDetails?.status === "Completed"}
                    onClick={() => setEditTestOverviewToggle((prev) => !prev)}
                  >
                    <img
                      src="/assets/pencil_icon copy.svg"
                      alt="pencil_icon"
                      style={{ marginRight: "8px" }}
                    />
                    Edit
                  </Button>
                )}{" "}
            </>
          ) : (
            <>
              {" "}
              {assessmentDetails?.librarySelection?.map((library) => (
                <CommonButton>{library}</CommonButton>
              ))}
            </>
          )}
        </Stack>

        <Stack
          direction={"column"}
          alignItems={"flex-start"}
          gap={"16px"}
          sx={{ padding: 0 }}
          divider={
            <Divider
              orientation="horizontal"
              flexItem
              sx={{ borderColor: "#BBC2CC" }}
            />
          }
        >
          <div
            className="sun-editor custom-sun-editor"
            style={{
              backgroundColor: "#F2F4F8",
              borderColor: "transparent",
              border: "none",
            }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(assessmentDetails?.description || ""),
            }}
          />

          <Stack
            direction={"column"}
            alignItems={"flex-start"}
            gap={"12px"}
            sx={{ padding: 0 }}
            alignSelf={"stretch"}
          >
            <Stack
              direction={{ base: "column", sm: "row" }}
              alignItems={"center"}
              gap={"12px"}
              sx={{ padding: 0, alignSelf: "stretch" }}
              divider={<Divider orientation="vertical" flexItem />}
            >
              {!hideAndShow && (
                <>
                  {TestDurationData.slice(0, 3)?.map((event, index) => (
                    <Stack
                      key={index}
                      direction={"row"}
                      alignItems={"center"}
                      gap={"8px"}
                      sx={{
                        padding: 0,
                        alignSelf: "stretch",
                        flexGrow: 1,
                        width: { base: "100%", sm: "33.33%" },
                      }}
                    >
                      <Box
                        component={"img"}
                        src={event.iconSrc}
                        sx={{ fill: "#8591A2" }}
                      />
                      <Stack
                        direction={"column"}
                        justifyContent={"center"}
                        alignItems={"flex-start"}
                        sx={{ padding: 0 }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontSize: "12px",
                            lineHeight: "16px",
                            color: "#8C9194",
                          }}
                        >
                          {event.label}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#363E45",
                          }}
                        >
                          {event.duration}
                        </Typography>
                      </Stack>
                    </Stack>
                  ))}
                </>
              )}
            </Stack>
            <Stack
              direction={{ base: "column", sm: "row" }}
              alignItems={"center"}
              gap={"12px"}
              sx={{ padding: 0, alignSelf: "stretch" }}
              divider={<Divider orientation="vertical" flexItem />}
            >
              {!hideAndShow ? (
                <>
                  {TestDurationData.slice(3, 6)?.map((event, index) => (
                    <Stack
                      key={index}
                      direction={"row"}
                      alignItems={"center"}
                      gap={"8px"}
                      sx={{
                        padding: 0,
                        alignSelf: "stretch",
                        flexGrow: 1,
                        width: { base: "100%", sm: "33.33%" },
                      }}
                    >
                      <Box
                        component={"img"}
                        src={event.iconSrc}
                        sx={{ fill: "#8591A2" }}
                      />
                      <Stack
                        direction={"column"}
                        justifyContent={"center"}
                        alignItems={"flex-start"}
                        sx={{ padding: 0 }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontSize: "12px",
                            lineHeight: "16px",
                            color: "#8C9194",
                          }}
                        >
                          {event.label}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#363E45",
                          }}
                        >
                          {event.duration}
                        </Typography>
                      </Stack>
                    </Stack>
                  ))}
                </>
              ) : (
                <>
                  {TestDurationData.slice(1, 6)?.map((event, index) => (
                    <Stack
                      key={index}
                      direction={"row"}
                      alignItems={"center"}
                      gap={"8px"}
                      sx={{
                        padding: 0,
                        alignSelf: "stretch",
                        flexGrow: 1,
                        width: "33.33%",
                      }}
                    >
                      <Box
                        component={"img"}
                        src={event.iconSrc}
                        sx={{ fill: "#8591A2" }}
                      />
                      <Stack
                        direction={"column"}
                        justifyContent={"center"}
                        alignItems={"flex-start"}
                        sx={{ padding: 0 }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontSize: "12px",
                            lineHeight: "16px",
                            color: "#8C9194",
                          }}
                        >
                          {event.label}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#363E45",
                          }}
                        >
                          {event.duration}
                        </Typography>
                      </Stack>
                    </Stack>
                  ))}
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        direction={"column"}
        alignItems={"flex-start"}
        sx={{
          padding:
            authUser?.role === "super admin" ||
            authUser?.role === "hr" ||
            authUser.role === "recruiter"
              ? "1px 20px 20px 20px"
              : null,
          gap: "20px",
          alignSelf: "stretch",
          overflowY: "scroll",
          borderRadius: "4px",
        }}
      >
        {(authUser.role === "recruiter" && hideAndShow) ||
        authUser?.role === "super admin" ? (
          <>
            <Stack
              direction={"column"}
              alignItems={"flex-start"}
              sx={{
                padding: "0",
                gap: "24px",
                border: " 1px solid #E8E9EE",
                borderRadius: "4px",
                alignSelf: "stretch",
              }}
            >
              <Stack
                direction={"row"}
                alignItems={"flex-start"}
                sx={{
                  padding: "0px 0px 20px",
                  alignSelf: "stretch",
                  flexGrow: 1,
                }}
              >
                <FlexContainer>
                  <Stack
                    direction={"column"}
                    alignItems={"flex-start"}
                    sx={{
                      padding: " 0px 16px",
                      gap: "20px",
                      alignSelf: "stretch",
                      borderRight: " 1px solid #E8E9EE",
                      flexGrow: 1,
                    }}
                  >
                    <IconTextStack
                      src="/assets/icons/settings.svg"
                      text="Proctoring Settings"
                      sx={{ cursor: "not-allowed" }}
                    />

                    <FormGroup
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                      }}
                    >
                      {assessmentDetails?.proctoringSettingsId?.map(
                        (item, index) => (
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                checked={item.active}
                                onChange={handleCheckboxChange(item, "initial")}
                                size="small"
                                sx={{
                                  paddingY: "0",
                                  cursor: "not-allowed",
                                }}
                              />
                            }
                            label={
                              <Typography
                                sx={{
                                  fontFamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  fontSize: "16px",
                                  lineHeight: "20px",
                                  color: "#363E45",
                                  cursor: "not-allowed",
                                }}
                              >
                                {item?.setting?.setting
                                  ?.charAt(0)
                                  ?.toUpperCase() +
                                  item?.setting?.setting?.slice(1)}
                              </Typography>
                            }
                          />
                        )
                      ) || "No data"}
                    </FormGroup>
                  </Stack>
                </FlexContainer>
                <FlexContainer>
                  <Stack
                    direction={"column"}
                    alignItems={"flex-start"}
                    sx={{
                      padding: " 0px 16px",
                      gap: "20px",
                      alignSelf: "stretch",
                      flexGrow: 1,
                    }}
                  >
                    <IconTextStack
                      src="/assets/icons/userCircle.svg"
                      text="Candidate Settings"
                    />

                    <FormGroup
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                        cursor: "not-allowed",
                      }}
                    >
                      {assessmentDetails?.candidateFieldsId?.map(
                        (item, index) => (
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                checked={item.active}
                                onChange={handleCheckboxChange(
                                  item,
                                  "candidate"
                                )}
                                size="small"
                                sx={{
                                  paddingY: "0",
                                  cursor: "not-allowed",
                                }}
                              />
                            }
                            label={
                              <Typography
                                sx={{
                                  fontFamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  fontSize: "16px",
                                  lineHeight: "20px",
                                  color: "#363E45",
                                  cursor: "not-allowed",
                                }}
                              >
                                {item.field?.label?.charAt(0)?.toUpperCase() +
                                  item.field?.label?.slice(1)}
                              </Typography>
                            }
                          />
                        )
                      ) || "No data"}
                    </FormGroup>
                  </Stack>
                </FlexContainer>
              </Stack>
            </Stack>

            <Stack
              direction={"column"}
              alignItems={"flex-start"}
              sx={{
                padding: "0px 16px 12px",
                gap: "12px",
                border: "1px solid #E8E9EE",
                borderRadius: "4px",
                alignSelf: "stretch",
                flexGrow: 1,
              }}
            >
              <IconTextStack
                src="/assets/icons/checkupListBlack.svg"
                text=" Test Instructions"
              />

              <Box sx={{ padding: " 0px 20px" }}>
                <List>
                  {instructions?.map((instruction, index) => (
                    <ListItem key={index} sx={{ padding: 0, margin: 0 }}>
                      <ListItemText
                        primary={`${index + 1}. ${instruction}`}
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: 400,
                          lineHeight: "28px",
                          color: theme.palette.primary.black,
                          "&": {
                            margin: 0,
                            color: theme.palette.primary.black,
                          },
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Stack>

            {authUser.role !== "recruiter" && (
              <Stack
                direction={"column"}
                alignItems={"flex-start"}
                sx={{
                  padding: "0px 16px 12px",
                  gap: "12px",
                  border: "1px solid #E8E9EE",
                  borderRadius: "4px",
                  alignSelf: "stretch",
                  flexGrow: 1,
                }}
              >
                <IconTextStack
                  src="/assets/icons/users24.svg"
                  text="Test Admins"
                />
                <Stack
                  direction={"column"}
                  alignItems={"flex-start"}
                  sx={{
                    padding: "0px 0px 20px",
                    alignSelf: "stretch",
                    flexGrow: 1,
                  }}
                >
                  {assessmentDetails?.testAdminsId?.length > 0
                    ? assessmentDetails.testAdminsId.map((admin, index) => (
                        <ListItem key={index} sx={{ padding: 0, margin: 0 }}>
                          <ListItemText
                            primary={`${index + 1}. ${admin.name}`}
                            sx={{
                              fontFamily: "Roboto",
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "28px",
                              color: theme.palette.primary.black,
                              "&": {
                                margin: 0,
                                color: theme.palette.primary.black,
                              },
                            }}
                          />
                        </ListItem>
                      ))
                    : "No data"}
                </Stack>
              </Stack>
            )}
          </>
        ) : (
          <>
            <Box sx={{ width: "100%" }}>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                sx={{
                  marginLeft: "15px",
                  "& .Mui-selected": {
                    color: "#ff6812",
                  },
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#ff6812",
                  },
                }}
              >
                <Tab label="Proctoring & Candidate Settings" />
                <Tab label="Test Instructions" />
                {!hideAndShow && <Tab label="Test Admins" />}
              </Tabs>

              {selectedTab === 0 && (
                <Box sx={{ padding: 2 }}>
                  <Stack
                    direction={"column"}
                    alignItems={"flex-start"}
                    sx={{
                      padding: "0",
                      gap: "24px",
                      border: " 1px solid #E8E9EE",
                      borderRadius: "4px",
                      alignSelf: "stretch",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"flex-start"}
                      sx={{
                        padding: "0px 0px 20px",
                        alignSelf: "stretch",
                        flexGrow: 1,
                      }}
                    >
                      <FlexContainer>
                        <Stack
                          direction={"column"}
                          alignItems={"flex-start"}
                          sx={{
                            padding: " 0px 16px",
                            gap: "20px",
                            alignSelf: "stretch",
                            borderRight: " 1px solid #E8E9EE",
                            flexGrow: 1,
                          }}
                        >
                          <IconTextStack
                            src="/assets/icons/settings.svg"
                            text="Proctoring Settings"
                          />
                          <FormGroup
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginLeft: "10px",
                            }}
                          >
                            {settings.map((item, index) => (
                              <FormControlLabel
                                key={index}
                                control={
                                  <IOSSwitch
                                    sx={{
                                      height: "20px",
                                      width: "36px",
                                      "& .MuiSwitch-thumb": {
                                        width: "16px",
                                        height: "16px",
                                      },
                                      cursor:
                                        new Date(assessmentDetails?.startsAt) <
                                        new Date()
                                          ? "not-allowed"
                                          : "pointer",
                                    }}
                                    checked={item?.active || false}
                                    onChange={(e) =>
                                      handleSwitchChange(e, item.setting._id)
                                    }
                                    disabled={
                                      new Date(assessmentDetails?.startsAt) <
                                      new Date()
                                    }
                                  />
                                }
                                label={
                                  <Typography
                                    sx={{
                                      fontFamily: "Roboto",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "16px",
                                      lineHeight: "20px",
                                      color: "#363E45",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {item?.setting?.setting
                                      ?.charAt(0)
                                      ?.toUpperCase() +
                                      item?.setting?.setting?.slice(1)}
                                  </Typography>
                                }
                              />
                            )) || "No data"}
                          </FormGroup>
                        </Stack>
                      </FlexContainer>
                      <FlexContainer>
                        <Stack
                          direction={"column"}
                          alignItems={"flex-start"}
                          sx={{
                            padding: " 0px 16px",
                            gap: "20px",
                            alignSelf: "stretch",
                            flexGrow: 1,
                          }}
                        >
                          <IconTextStack
                            src="/assets/icons/userCircle.svg"
                            text="Candidate Settings"
                          />

                          <FormGroup
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginLeft: "10px",
                            }}
                          >
                            {candidateFields.map((item, index) => (
                              <FormControlLabel
                                key={index}
                                control={
                                  <IOSSwitch
                                    sx={{
                                      height: "20px",
                                      width: "36px",
                                      "& .MuiSwitch-thumb": {
                                        width: "16px",
                                        height: "16px",
                                      },
                                      cursor:
                                        new Date(assessmentDetails?.startsAt) <
                                        new Date()
                                          ? "not-allowed"
                                          : "pointer",
                                    }}
                                    checked={item?.active || false}
                                    onChange={(e) =>
                                      handleCandidateChange(e, item.field._id)
                                    }
                                    disabled={
                                      new Date(assessmentDetails?.startsAt) <
                                      new Date()
                                    }
                                  />
                                }
                                label={
                                  <Typography
                                    sx={{
                                      fontFamily: "Roboto",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "16px",
                                      lineHeight: "20px",
                                      color: "#363E45",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {item.field?.label
                                      ?.charAt(0)
                                      ?.toUpperCase() +
                                      item.field?.label?.slice(1)}
                                  </Typography>
                                }
                              />
                            )) || "No data"}
                          </FormGroup>
                        </Stack>
                      </FlexContainer>
                    </Stack>
                  </Stack>
                </Box>
              )}
              {selectedTab === 1 && (
                <Box sx={{ padding: 2 }}>
                  <Stack
                    direction={"column"}
                    sx={{
                      padding: "0px 16px 12px",
                      gap: "12px",
                      border: "1px solid #E8E9EE",
                      borderRadius: "4px",
                      alignSelf: "stretch",
                      flexGrow: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        borderBottom: "1px solid #e8e9ee",
                      }}
                    >
                      <IconTextStack
                        src="/assets/icons/checkupListBlack.svg"
                        text="Test Instructions"
                      />

                      {!hideAndShow ? (
                        <>
                          {!isEditingInstructions && (
                            <Button
                              variant="outlined"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                height: "30px",
                                color: "#ff6812",
                                padding: "4px 8px",
                                cursor: "pointer",
                                borderColor: "#ff6812",
                                ":hover": {
                                  bgcolor: "#e9f2f4",
                                  color: "#ff6812",
                                  borderColor: "#ff6812",
                                },
                              }}
                              onClick={handleEditClick}
                            >
                              <img
                                src="/assets/edit copy.svg"
                                alt="edit"
                                style={{ marginRight: "4px" }}
                              />
                              Edit
                            </Button>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </Box>
                    <Box sx={{ padding: "0px 20px" }}>
                      <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                      >
                        <SortableContext
                          items={instructions?.map((_, index) =>
                            (index + 1).toString()
                          )}
                          strategy={verticalListSortingStrategy}
                        >
                          <List>
                            {instructions?.map((instruction, index) => (
                              <SortableItem
                                key={index + 1}
                                id={(index + 1).toString()}
                                isEditing={isEditingInstructions}
                              >
                                <ListItem
                                  sx={{
                                    padding: 0,
                                    margin: 0,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  {isEditingInstructions ? (
                                    <TextField
                                      value={instruction}
                                      onChange={(e) => {
                                        const updatedInstructions = [
                                          ...instructions,
                                        ];
                                        updatedInstructions[index] =
                                          e.target.value;
                                        setInstructions(updatedInstructions);
                                      }}
                                      sx={{
                                        flexGrow: 1,
                                        marginBottom: "8px",
                                        marginRight: "8px",
                                        width: "80%",
                                        height: "40px",
                                        "& .MuiInputBase-input": {
                                          height: "40px",
                                          padding: "0 14px",
                                          display: "flex",
                                          alignItems: "center",
                                        },
                                      }}
                                    />
                                  ) : (
                                    <ListItemText
                                      primary={`${index + 1}. ${instruction}`}
                                      sx={{
                                        fontFamily: "Roboto",
                                        fontSize: "16px",
                                        fontWeight: 400,
                                        lineHeight: "28px",
                                        color: "#000",
                                      }}
                                    />
                                  )}
                                  {isEditingInstructions && (
                                    <IconButton
                                      edge="end"
                                      aria-label="delete"
                                      onClick={() => {
                                        const updatedInstructions =
                                          instructions.filter(
                                            (_, i) => i !== index
                                          );
                                        setInstructions(updatedInstructions);
                                      }}
                                    >
                                      <img
                                        src="/assets/delete_icon_2.svg"
                                        alt="delete_icon"
                                      />
                                    </IconButton>
                                  )}
                                </ListItem>
                              </SortableItem>
                            ))}
                          </List>
                        </SortableContext>
                      </DndContext>

                      {/* New Instruction Input Field */}
                      {isEditingInstructions && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <TextField
                            value={newInstruction}
                            onChange={(e) => setNewInstruction(e.target.value)}
                            placeholder="Add new instruction"
                            sx={{
                              width: "93%",
                              margin: "8px 10px 8px 0",
                              height: "40px",
                              "& .MuiInputBase-input": {
                                height: "40px",
                                padding: "0 14px",
                                display: "flex",
                                alignItems: "center",
                              },
                            }}
                          />
                          <Button
                            variant="contained"
                            sx={{
                              bgcolor: "#00c49a",
                              "&:hover": { bgcolor: "#00c49a" },
                              height: "40px",
                              color: "#ffffff",
                              marginLeft: "8px",
                            }}
                            onClick={handleAddInstruction}
                            disabled={!newInstruction.trim()}
                          >
                            <Iconify
                              icon="mdi:plus"
                              width={"20px"}
                              color="white"
                            />
                          </Button>
                        </Box>
                      )}
                    </Box>

                    {isEditingInstructions && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: 2,
                          marginTop: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                          }}
                        >
                          <Button
                            variant="outlined"
                            sx={{
                              borderColor: "#ff6812",
                              color: "#ff6812",
                              "&:hover": {
                                bgcolor: "#ffffff",
                                borderColor: "#ff6812",
                              },
                              height: "35px",
                            }}
                            onClick={handleCancelClick}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              bgcolor: "#00c49a",
                              "&:hover": { bgcolor: "#00c49a" },
                              height: "35px",
                              color: "#ffffff",
                            }}
                            onClick={handleSaveClick}
                          >
                            Save Changes
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Stack>
                </Box>
              )}

              {selectedTab === 2 && (
                <Box sx={{ padding: 2 }}>
                  <Stack
                    direction={"column"}
                    sx={{
                      padding: "0px 16px 12px",
                      gap: "12px",
                      border: "1px solid #E8E9EE",
                      borderRadius: "4px",
                      alignSelf: "stretch",
                      flexGrow: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        borderBottom: "1px solid #e8e9ee",
                      }}
                    >
                      <IconTextStack
                        src="/assets/icons/users24.svg"
                        text="Test Admins"
                      />
                      {!isEditing && (
                        <Button
                          variant="outlined"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            height: "30px",
                            color: "#ff6812",
                            padding: "4px 8px",
                            cursor: "pointer",
                            borderColor: "#ff6812",
                            ":hover": {
                              bgcolor: "#e9f2f4",
                              color: "#ff6812",
                              borderColor: "#ff6812",
                            },
                          }}
                          onClick={() => {
                            handleAddAdmin();
                            setConfirmOpen(true);
                          }}
                        >
                          <img
                            src="/assets/icons/userPlusorange.svg"
                            alt="user add icon"
                            style={{ marginRight: "4px" }}
                          />
                          Add
                        </Button>
                      )}
                    </Box>
                    <Box>
                      <DataGrid
                        className={classes.table}
                        rows={testAdmins}
                        disableColumnResize={false}
                        columns={columns}
                        disableColumnMenu={true}
                        disableColumnFilter={true}
                        hideFooter={true}
                        autoHeight
                        sx={{
                          "& .MuiDataGrid-cell": {
                            outline: "none !important",
                          },
                          "& .MuiDataGrid-columnHeader": {
                            outline: "none !important",
                          },
                          "& [aria-rowindex='1']": {
                            background: `${theme.palette.background.gray} !important`,
                          },
                          "& [data-field='_id']": {
                            borderRight: `1px solid ${theme.palette.primary.gray}`,
                          },
                        }}
                        getRowId={(row) => row._id}
                        localeText={{ noRowsLabel: "No Data" }}
                      />
                    </Box>
                  </Stack>
                </Box>
              )}
            </Box>
          </>
        )}
      </Stack>
      <CommonModal
        icon={"/assets/delete_icon.svg"}
        open={deleteConfirmOpen}
        title={"Confirm Delete"}
        onSubmit={handleConfirmDelete}
        sendButtonName={"Yes"}
        cancelButtonName={"No"}
        isDeleting={true}
        handleClose={() => setDeleteConfirmOpen(false)}
      >
        <Typography
          style={{
            backgroundColor: "#fff0e7",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <img
            src="/assets/alert-1.svg"
            alt="Alert"
            style={{
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "10px",
              marginBottom: "15px",
            }}
          />
          <strong>Are you certain you wish to delete this Instruction?</strong>
        </Typography>
      </CommonModal>
      <CommonModal
        icon={"/assets/delete_icon.svg"}
        open={deleteAdminConfirmOpen}
        title={"Confirm Delete"}
        onSubmit={handleConfirmAdminDelete}
        sendButtonName={"Yes"}
        cancelButtonName={"No"}
        isDeleting={true}
        handleClose={() => setDeleteAdminConfirmOpen(false)}
      >
        <Typography
          style={{
            backgroundColor: "#fff0e7",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <img
            src="/assets/alert-1.svg"
            alt="Alert"
            style={{
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "10px",
              marginBottom: "15px",
            }}
          />
          <strong>Are you certain you wish to delete this Admin?</strong>
        </Typography>
      </CommonModal>

      <CommonModal
        icon={"/assets/users.svg"}
        open={confirmOpen}
        title={"Add Test Admins"}
        onSubmit={handleConfirmOpen}
        sendButtonName={"Add Admin"}
        isScrollable={true}
        handleClose={() => {
          setConfirmOpen(false);
          setdropDowAdminValue("All");
          setSelectedAdminRows([]);
        }}
        modalActions={
          <div
            style={{
              display: "flex",
              alignItems: "center", // Align items vertically in the center
              justifyContent: "space-between", // Space between the elements
              width: "100%", // Ensure the container takes full width
            }}
          >
            <SearchBar
              placeholder="Search Admin"
              style={{ flex: 1 }} // Use flex to allow it to take available space
              value={searchAdminValue}
              onChange={handleAdminSearch}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
                flex: 1,
                justifyContent: "flex-end",
              }}
            >
              <div style={{ marginRight: "10px" }}>Channel:</div>
              <TextField
                value={channelName}
                disabled={true}
                sx={{
                  height: "37px",
                  "& .MuiOutlinedInput-root": {
                    height: "100%",
                  },
                }}
              />
            </div>
          </div>
        }
      >
        <CustomTable
          tableHeaderCells={tableHeaderCells()}
          tableHeaderCellStyleProps={tableHeaderCellStyleProps}
          tableBodyContent={tableBodyContent()}
          tableData={tableData}
          tableBodyRowStyleProps={tableBodyRowStyleProps}
          sortRows={() => {}}
          useOverflow={true}
          hideFooter
        />
      </CommonModal>

      {EditTestOverviewToggle && (
        <EditTestOverview
          open={EditTestOverviewToggle}
          onClose={handleQuestionClose}
          assessmentDetails={assessmentDetails}
          fetchAssessmentDetails={fetchAssessmentDetails}
          drawerHeading="Edit Test Overview"
        />
      )}
    </Box>
  );
};

export default TestOverview;

const IconTextStack = ({ src, text }) => (
  <Stack
    direction={"row"}
    alignItems={"center"}
    sx={{
      gap: "12px",
      borderBottom: "1px solid #E9EAEB",
      alignSelf: "stretch",
    }}
  >
    <Box
      component={"img"}
      src={src}
      alt="icon"
      width={"24px"}
      height={"24px"}
    />
    <Typography
      sx={{
        padding: "12px 0px 12px 0px",
        fontFamily: "Roboto",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "20px",
        color: "#363E45",
      }}
    >
      {text}
    </Typography>
  </Stack>
);
const FlexContainer = ({ children }) => (
  <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
    {children}
  </div>
);
