import * as React from "react";
import {
  Typography,
  Tooltip,
  Button,
  Box,
  Stack,
  TextField,
  MenuItem,
} from "@mui/material";
import CustomTable from "../common/customTable/CustomTable";
import SortingIcon from "../icons/SortingIcon";
import { useNavigate } from "react-router-dom";
import { getColorByStatus, capitalizeWords } from "../../utils";
import { getPaginationOptions } from "../../utils/pagination";
import { AuthContext } from "../../context/authContext";
import PingModal from "../pingModal";
import { usePingContext } from "../../context/pingContext";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import * as Yup from "yup";
import CommonModal from "../modal/CommonModal";
import DrawerHeader from "../common/drawer/DrawerHeader";
import DrawerSlide from "../common/drawer/DrawerSlide";
import APICall from "../../utils/api";
import dayjs from "dayjs";

const columnsWidth = ["60px", "240px", "90px", "240px", "240px", "240px"];

const tableHeaderCellStyleProps = {
  color: "#363E45",
  lineHeight: "16px",
  fontSize: "12px",
  fontWeight: "500",
  fontFamily: "Roboto",
  padding: "8px 16px",
  height: "36px",
  whiteSpace: "nowrap",
};
const tableContentCellTextStyleProps = {
  textAlign: "left",
  fontSize: "14px",
  color: "#363E45",
  fontWeight: "400",
  lineHeight: "20px",
  fontFamily: "Roboto",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
const tableBodyRowStyleProps = {
  height: "48px",
};

export default function RoundOne({
  loading,
  setLoading,
  setTableParams,
  data,
  totalDocuments,
  totalPages,
  tableParams,
  handleChangePage,
}) {
  const navigate = useNavigate();
  const { authUser } = React.useContext(AuthContext);
  const {
    openMemberModal,
    handlePingModalOpen,
    handlePingModalCloseAddOrEdit,
    handlePingClick,
  } = usePingContext();
  const [open, setOpen] = React.useState(false);
  const [successModal, setSuccessModal] = React.useState(false);
  const [scheduleModal, setScheduleModal] = React.useState(false);
  const [selectedCandidate, setSelectedCandidate] = React.useState(null);
  const [channels, setChannels] = React.useState([]);
  const [seniorityLevels, setSeniorityLevels] = React.useState([]);
  const [interviewers, setInterviewers] = React.useState([]);

  const fetchChannels = async () => {
    try {
      let url = `/channel?limit=1000`;
      const response = await APICall(url);
      setChannels(response.data.documents);
    } catch (error) {
      console.error("Error fetching channels:", error);
    }
  };

  const fetchSeniorityLevels = async (channelId) => {
    try {
      let url = `/channel/${channelId}`;
      const response = await APICall(url);
      setSeniorityLevels(response.data.seniorityLevels);
    } catch (error) {
      console.error("Error fetching seniority levels:", error);
    }
  };

  React.useEffect(() => {
    fetchChannels();
    // fetchInterviewers();
  }, []);

  const handleScheduleDrawerOpen = (candidate) => {
    setSelectedCandidate(candidate);
    formik.setValues({
      name: candidate?.candidateId?.name || "",
      email: candidate?.candidateId?.email || "",
      code: candidate?.candidateId?.code || "",
      mobile: candidate?.candidateId?.mobile || "",
      startDate: null,
      startTime: null,
      department: "",
      round: "Round 2",
      seniority: "",
      interviewer: "",
    });
    setOpen(true);
  };

  const handleConfirmSchedule = () => {
    setScheduleModal(false);
    setSuccessModal(true);
  };

  const handleScheduleDrawerClose = () => {
    setOpen(false);
    setSelectedCandidate(null);
    formik.resetForm();
  };

  const handleAvailabilitySlots = async (selectDate, startTime, endTime) => {
    if (!selectDate || !startTime || !endTime) {
      console.error("Invalid date or time");
      return;
    }

    const startDateTime = dayjs(selectDate)
      .hour(startTime.hour())
      .minute(startTime.minute());
    const endDateTime = dayjs(selectDate)
      .hour(endTime.hour())
      .minute(endTime.minute());

    const start = startDateTime.format("YYYY-MM-DDTHH:mm:ss");
    const end = endDateTime.format("YYYY-MM-DDTHH:mm:ss");

    try {
      const response = await APICall(
        `/availabilitySlots?start=${start}&end=${end}`
      );
      const availableInterviewers = response?.data?.slots?.documents
        .filter((document) => !document.timeRanges[0].isBooked)
        .map((document) => {
          return {
            interviewerId: document?.interviewerId?._id,
            name: document?.interviewerId?.name,
            slotId: document?.timeRanges[0]?._id,
          };
        });
      setInterviewers(availableInterviewers);
    } catch (error) {
      console.error("Error fetching available interviewers:", error);
    }
  };

  const handleScheduleSubmit = async (values) => {
    const {
      selectDate,
      startTime,
      endTime,
      interviewer,
      channel,
      round,
      seniority,
    } = values;

    if (!selectDate || !startTime || !endTime) {
      console.error("Invalid date or time");
      return;
    }

    const startDateTime = dayjs(selectDate)
      .hour(startTime.hour())
      .minute(startTime.minute());
    const endDateTime = dayjs(selectDate)
      .hour(endTime.hour())
      .minute(endTime.minute());

    const selectedInterviewer = interviewers.find(
      (i) => i.interviewerId === interviewer
    );

    if (!selectedInterviewer) {
      console.error("Invalid interviewer selected");
      return;
    }

    const finalPayload = {
      slotId: selectedInterviewer.slotId,
      clientId: authUser.clientId,
      scheduleAt: startDateTime.toISOString(),
      interviewerId: interviewer,
      candidateId: selectedCandidate?.candidateId?._id,
      channelId: channel,
      round: round,
      startTime: startDateTime.toISOString(),
      endTime: endDateTime.toISOString(),
      schedulerId: authUser._id,
      candidateAssessmentId: selectedCandidate?.candidateAssessmentId?._id,
      interviewLink: "https://meet.google.com/vqq-kfsy-gid?authuser=0",
      testScore: selectedCandidate?.assessmentResult?.totalObtainedScore || 0,
    };

    try {
      await APICall("/interviewer", "POST", finalPayload);
      handleScheduleDrawerClose();
      setSuccessModal(true);
    } catch (error) {
      console.error("Error scheduling interview:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      code: "",
      mobile: "",
      selectDate: null,
      startTime: null,
      endTime: null,
      channel: "",
      round: "Round 2",
      seniority: "",
      interviewer: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      code: Yup.string().required("Country code is required"),
      mobile: Yup.string()
        .matches(/^[0-9]+$/, "Mobile number must be only digits")
        .length(10, "Mobile number must be exactly 10 digits")
        .required("Mobile number is required"),
      selectDate: Yup.date().nullable().required("Select date is required"),
      startTime: Yup.date().nullable().required("Start time is required"),
      endTime: Yup.date().nullable().required("End time is required"),
      channel: Yup.string().required("Department is required"),
      seniority: Yup.string().required("Seniority level is required"),
      interviewer: Yup.string().required("Interviewer is required"),
    }),
    onSubmit: (values) => {
      handleScheduleSubmit(values);
    },
  });

  const tableHeaderTitles = [
    {
      title: "Sr. no",
      cellStyleProps: {
        borderRight: "1px solid #E8E9EE",
        width: columnsWidth[0],
      },
    },
    {
      isSortCol: true,
      colId: "candidate",
      title: (
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Typography
            sx={{ fontSize: "12px", fontWeight: "500", fontFamily: "Roboto" }}
          >
            Candidate
          </Typography>
          <SortingIcon />
        </Box>
      ),
      cellStyleProps: {
        width: columnsWidth[1],
      },
    },
    {
      title: "Channel",
      cellStyleProps: {
        width: columnsWidth[3],
      },
    },
    {
      title: "Test Score",
      cellStyleProps: {
        width: columnsWidth[3],
      },
    },
    {
      title: "Interviewer",
      cellStyleProps: {
        width: columnsWidth[3],
      },
    },
    ...(authUser?.role !== "hr" ||
    data.some((row) => row?.assessmentId?.testAssignedHrId !== authUser._id)
      ? [
          {
            title: "Scheduler",
            cellStyleProps: {
              width: columnsWidth[4],
            },
          },
        ]
      : []),
    {
      title: "Job",
      cellStyleProps: {
        width: columnsWidth[5],
      },
    },
    {
      title: "Round",
      cellStyleProps: {
        width: columnsWidth[6],
      },
    },
  ];

  const tableBodyRowCell = (row, cellStyleProps) => {
    const totalTestScore = `${row?.assessmentResult?.totalObtainedScore} / ${row?.assessmentId?.totalScore}`;

    const cells = [
      {
        cellStyleProps: {
          borderRight: "1px solid #E8E9EE",
          width: columnsWidth[0],
          maxWidth: columnsWidth[0],
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row.rowId}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          width: columnsWidth[1],
          maxWidth: columnsWidth[1],
          ...cellStyleProps,
        },
        element: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <img
              src={
                row?.candidateProfilePicture
                  ? row?.candidateProfilePicture
                  : "/assets/user.png"
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/assets/user.png";
              }}
              alt="user"
              style={{ height: "20px", width: "20px" }}
            />
            <Typography
              sx={{
                ...tableContentCellTextStyleProps,
                cursor: "pointer",
              }}
              onClick={() =>
                navigate(`/assessments/candidateDetails`, {
                  state: {
                    candidateAssessmentId: row?.candidateAssessmentId?._id,
                    assessmentId: row?.assessmentId?._id,
                    assessmentDetails: row?.assessmentId,
                  },
                })
              }
            >
              {row?.candidateId?.name}
            </Typography>
          </Box>
        ),
      },
      {
        cellStyleProps: {
          width: columnsWidth[2],
          maxWidth: columnsWidth[2],
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row?.channelDetails?.name}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          width: columnsWidth[2],
          maxWidth: columnsWidth[2],
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {totalTestScore}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          width: columnsWidth[3],
          maxWidth: columnsWidth[3],
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
              alignItems: "center",
            }}
          >
            {row?.InterviewDetails?.round2?.Interviewer?.name
              ? row?.InterviewDetails?.round2?.Interviewer?.name
              : row?.InterviewDetails?.round1?.Interviewer?.name
              ? row?.InterviewDetails?.round1?.Interviewer?.name
              : "N/A"}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          width: columnsWidth[5],
          maxWidth: columnsWidth[5],
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {capitalizeWords(row?.jobRoleDetails?.jobRole)}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          width: columnsWidth[6],
          maxWidth: columnsWidth[6],
          ...cellStyleProps,
        },
        element: getStatus(row),
      },
    ];

    if (
      authUser?.role !== "hr" ||
      row?.assessmentId?.testAssignedHrId !== authUser._id
    ) {
      cells.splice(4, 0, {
        cellStyleProps: {
          width: columnsWidth[4],
          maxWidth: columnsWidth[4],
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                display: "flex",
                cursor: "pointer",
                borderRight: "1px solid #E8E9EE",
                paddingRight: "8px",
                borderRadius: "4px",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Typography variant="body2">
                {row?.InterviewDetails?.round2?.Scheduler?.name
                  ? row?.InterviewDetails?.round2?.Scheduler?.name
                  : row?.InterviewDetails?.round1?.Scheduler?.name
                  ? row?.InterviewDetails?.round1?.Scheduler?.name
                  : "N/A"}
              </Typography>
              <Tooltip
                title={
                  authUser?.role === "hr" &&
                  row?.assessmentId?.testAssignedHrId === authUser._id &&
                  (row?.InterviewDetails?.round2?.Interviewer?.name ||
                    row?.InterviewDetails?.round1?.Interviewer?.name)
                    ? row?.InterviewDetails?.round2?.Interviewer?.name
                      ? `Ping Interviewer`
                      : `Ping Interviewer`
                    : row?.InterviewDetails?.round2?.Scheduler?.name
                    ? `Ping Scheduler`
                    : `Ping Scheduler`
                }
                arrow
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "4px",
                    paddingLeft: "4px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePingModalOpen();
                  }}
                >
                  {row?.InterviewDetails?.round1?.Scheduler?.name && (
                    <img
                      src="/assets/Vector-5.svg"
                      alt="Publisher"
                      sx={{ color: "#FF6812", marginLeft: "4px" }}
                    />
                  )}
                </Box>
              </Tooltip>
            </Typography>
          </Typography>
        ),
      });
    }

    return cells;
  };

  const tableHeaderCells = () => {
    return tableHeaderTitles.map((item) => {
      return {
        isSortCol: item?.isSortCol ? item.isSortCol : false,
        colId: item?.colId ? item.colId : "",
        element: item.title,
        cellStyleProps: item.cellStyleProps,
        isSortable: item?.isSortable ? item.isSortable : false,
        cellId: item?.cellId ? item.cellId : "",
      };
    });
  };

  const tableBodyContent = () => {
    return data?.map((row, idx) => {
      const cellStyleProps = {
        borderBottom: "1px solid #E8E9EE",
        padding: "10px 14px",
        whiteSpace: "nowrap",
      };
      return {
        sortable: {
          score: parseInt(row?.totalScore),
          candidate: row.data?.candidateId?.name,
        },
        rowId: idx + 1,
        rowElement: tableBodyRowCell(
          { ...row, rowId: idx + 1 },
          cellStyleProps
        ),
      };
    });
  };

  const getStatus = (row) => {
    return authUser?.role === "hr" &&
      row.currentStatus === "R1 Passed" &&
      row?.assessmentId?.testAssignedHrId === authUser._id ? (
      <Button
        variant="contained"
        onClick={() => handleScheduleDrawerOpen(row)}
        sx={{
          fontFamily: "Roboto",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "20px",
          color: "#fff",
          textWrap: "nowrap",
        }}
      >
        Schedule Interview for R2
      </Button>
    ) : (
      <Box
        sx={{
          display: "inline-block",
          background: getColorByStatus(row.currentStatus)?.bg,
          padding: "4px 12px",
          color: getColorByStatus(row.currentStatus)?.color,
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: "16px",
          fontFamily: "Roboto",
          borderRadius: "0.25rem",
          whiteSpace: "nowrap",
        }}
      >
        {row.currentStatus}
      </Box>
    );
  };

  return (
    <Box sx={{ background: "#fff", width: "100%", height: "100%" }}>
      <CustomTable
        loading={loading}
        tableHeaderCells={tableHeaderCells()}
        tableBodyContent={tableBodyContent()}
        tableData={data}
        sortRows={() => {}}
        tableBodyRowStyleProps={tableBodyRowStyleProps}
        tableHeaderCellStyleProps={tableHeaderCellStyleProps}
        limit={tableParams?.pagination?.limit}
        page={tableParams?.pagination?.page}
        totalPages={totalPages}
        totalDocuments={totalDocuments}
        setTableParams={setTableParams}
        handleChangePage={handleChangePage}
        rowsPerPageOptions={getPaginationOptions(
          totalDocuments,
          tableParams?.pagination?.page,
          tableParams?.pagination?.limit
        )}
      />

      <DrawerSlide toggleDrawer={open} setToggleDrawer={setOpen}>
        <DrawerHeader
          headingText="Schedule Interview"
          setToggleDrawer={setOpen}
        />
        <Stack sx={{ padding: "8px 16px 16px" }} alignSelf={"stretch"}>
          <Stack
            direction="column"
            gap={Object.keys(formik.errors).length > 0 ? "8px" : "16px"}
          >
            <Box
              sx={{
                width: "100%",
                bgcolor: "#00c49a",
                height: "50px",
                borderRadius: "6px",
                alignContent: "center",
                padding: "14px",
              }}
            >
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "24px",
                  color: "#fff",
                  fontFamily: "Nunito",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <img src="/assets/reportAnalyticWhite.svg" alt="invite" />
                {selectedCandidate?.assessmentId?.name}
              </Typography>
            </Box>
            {/* Name Fields */}
            <Box>
              <TextField
                fullWidth
                size="small"
                label={
                  <span>
                    Name
                    <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                  </span>
                }
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                sx={{ "& .MuiInputBase-root": { height: "48px" } }}
              />
            </Box>
            {/* Email Fields */}
            <Box>
              <TextField
                fullWidth
                size="small"
                label={
                  <span>
                    Email
                    <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                  </span>
                }
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                sx={{ "& .MuiInputBase-root": { height: "48px" } }}
              />
            </Box>
            {/* Mobile Number Fields */}
            <Stack direction="row" gap="16px">
              {/* Mobile Number Code Field */}
              <Box flex={0.5}>
                <TextField
                  fullWidth
                  size="small"
                  select
                  label={
                    <span>
                      Code
                      <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                    </span>
                  }
                  name="code"
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.code && Boolean(formik.errors.code)}
                  helperText={formik.touched.code && formik.errors.code}
                  sx={{ "& .MuiInputBase-root": { height: "48px" } }}
                  defaultValue={formik.values.code ? formik.values.code : "+91"}
                >
                  <MenuItem value="+1">+1 (US)</MenuItem>
                  <MenuItem value="+44">+44 (UK)</MenuItem>
                  <MenuItem value="+91">+91 (India)</MenuItem>
                </TextField>
              </Box>
              {/* Mobile Number Field */}
              <Box flex={2}>
                <TextField
                  fullWidth
                  size="small"
                  label={
                    <span>
                      Mobile Number
                      <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                    </span>
                  }
                  name="mobile"
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                  helperText={formik.touched.mobile && formik.errors.mobile}
                  sx={{ "& .MuiInputBase-root": { height: "48px" } }}
                />
              </Box>
            </Stack>
            {/* Date Fields */}
            <Stack direction="row" gap="16px">
              {/* Select Date Field */}
              <Box flex={1}>
                <DatePicker
                  label={
                    <span>
                      Select Date
                      <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                    </span>
                  }
                  value={formik.values.selectDate}
                  sx={{ width: "355px" }}
                  format="DD/MM/YYYY"
                  onChange={(value) => {
                    formik.setFieldValue("selectDate", value);
                    formik.setFieldValue("startTime", null);
                    formik.setFieldValue("endTime", null);
                    formik.setFieldValue("interviewer", "");
                    setInterviewers([]);
                  }}
                  shouldDisableDate={(date) =>
                    date < new Date().setHours(0, 0, 0, 0)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      error={
                        formik.touched.selectDate &&
                        Boolean(formik.errors.selectDate)
                      }
                      helperText={
                        formik.touched.selectDate && formik.errors.selectDate
                      }
                      sx={{ "& .MuiInputBase-root": { height: "48px" } }}
                    />
                  )}
                />
              </Box>
            </Stack>
            {/* Time Fields */}
            <Stack direction="row" gap="16px">
              {/* Start Time Field */}
              <Box flex={1}>
                <TimePicker
                  label={
                    <span>
                      Start Time
                      <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                    </span>
                  }
                  value={formik.values.startTime}
                  sx={{ width: "355px" }}
                  onChange={(value) => {
                    formik.setFieldValue("startTime", value);
                    const endTime = dayjs(value).add(1, "hour");
                    formik.setFieldValue("endTime", endTime);
                    formik.setFieldValue("interviewer", "");
                    setInterviewers([]);
                    handleAvailabilitySlots(
                      formik.values.selectDate,
                      value,
                      endTime
                    );
                  }}
                  shouldDisableTime={(timeValue, clockType) => {
                    const now = new Date();
                    const selectedDate = formik.values.selectDate
                      ? new Date(formik.values.selectDate)
                      : new Date();
                    if (clockType === "hours") {
                      return (
                        selectedDate.toDateString() === now.toDateString() &&
                        timeValue < now.getHours()
                      );
                    }
                    if (clockType === "minutes") {
                      return (
                        selectedDate.toDateString() === now.toDateString() &&
                        timeValue < now.getMinutes()
                      );
                    }
                    return false;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      error={
                        formik.touched.startTime &&
                        Boolean(formik.errors.startTime)
                      }
                      helperText={
                        formik.touched.startTime && formik.errors.startTime
                      }
                      sx={{ "& .MuiInputBase-root": { height: "48px" } }}
                      disabled={!formik.values.selectDate}
                    />
                  )}
                />
              </Box>
              {/* End Time Field */}
              <Box flex={1}>
                <TimePicker
                  label={
                    <span>
                      End Time
                      <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                    </span>
                  }
                  value={formik.values.endTime}
                  sx={{ width: "355px" }}
                  onChange={(value) => {
                    formik.setFieldValue("endTime", value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      error={
                        formik.touched.endTime && Boolean(formik.errors.endTime)
                      }
                      helperText={
                        formik.touched.endTime && formik.errors.endTime
                      }
                      sx={{ "& .MuiInputBase-root": { height: "48px" } }}
                      disabled
                    />
                  )}
                />
              </Box>
            </Stack>
            {/* Department form Fields */}
            <Box>
              <TextField
                fullWidth
                size="small"
                select
                label={
                  <span>
                    Department
                    <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                  </span>
                }
                name="channel"
                value={formik.values.channel}
                onChange={(e) => {
                  formik.handleChange(e);
                  fetchSeniorityLevels(e.target.value);
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.channel && Boolean(formik.errors.channel)}
                helperText={formik.touched.channel && formik.errors.channel}
                sx={{ "& .MuiInputBase-root": { height: "48px" } }}
              >
                {channels.map((channel) => (
                  <MenuItem key={channel._id} value={channel._id}>
                    {channel.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            {/* Round form Fields */}
            <Box>
              <TextField
                fullWidth
                size="small"
                label={
                  <span>
                    Round
                    <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                  </span>
                }
                name="round"
                value={formik.values.round}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.round && Boolean(formik.errors.round)}
                helperText={formik.touched.round && formik.errors.round}
                sx={{ "& .MuiInputBase-root": { height: "48px" } }}
                disabled
              />
            </Box>
            {/* Seniority form Fields */}
            <Box>
              <TextField
                fullWidth
                size="small"
                select
                label={
                  <span>
                    Seniority
                    <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                  </span>
                }
                name="seniority"
                value={formik.values.seniority}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.seniority && Boolean(formik.errors.seniority)
                }
                helperText={formik.touched.seniority && formik.errors.seniority}
                sx={{ "& .MuiInputBase-root": { height: "48px" } }}
              >
                {seniorityLevels.map((level) => (
                  <MenuItem key={level} value={level}>
                    {level}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            {/* Interviewer form Fields */}
            <Box>
              <Tooltip
                title={!formik.values.endTime ? "Select time range first" : ""}
              >
                <span>
                  <TextField
                    fullWidth
                    size="small"
                    select
                    label={
                      <span>
                        Interviewer
                        <span style={{ color: "red", marginLeft: "4px" }}>
                          *
                        </span>
                      </span>
                    }
                    name="interviewer"
                    value={formik.values.interviewer}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.interviewer &&
                      Boolean(formik.errors.interviewer)
                    }
                    helperText={
                      formik.touched.interviewer && formik.errors.interviewer
                    }
                    sx={{ "& .MuiInputBase-root": { height: "48px" } }}
                    disabled={!formik.values.endTime}
                  >
                    {interviewers.map((interviewer) => (
                      <MenuItem
                        key={interviewer.interviewerId}
                        value={interviewer.interviewerId}
                      >
                        {interviewer.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </span>
              </Tooltip>
            </Box>

            <Stack direction="row" gap="16px" justifyContent="flex-end">
              <Button
                variant="outlined"
                color="primary"
                sx={{ height: "40px" }}
                onClick={() => handleScheduleDrawerClose()}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                sx={{ color: "white", height: "40px" }}
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                Schedule Interview
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </DrawerSlide>

      <PingModal
        openMemberModal={openMemberModal}
        handleClose={handlePingModalCloseAddOrEdit}
        handlePingClick={handlePingClick}
        BackdropProps={{
          style: {
            backdropFilter: "blur(5px)",
            backgroundColor: "rgba(49, 62, 79, 0.2)",
          },
        }}
        modalTitle={
          authUser?.role === "hr" &&
          selectedCandidate?.assessmentId?.testAssignedHrId === authUser._id &&
          (selectedCandidate?.InterviewDetails?.round2?.Interviewer?.name ||
            selectedCandidate?.InterviewDetails?.round1?.Interviewer?.name)
            ? selectedCandidate?.InterviewDetails?.round2?.Interviewer?.name
              ? `Ping Interviewer`
              : `Ping Interviewer`
            : selectedCandidate?.InterviewDetails?.round2?.Scheduler?.name
            ? `Ping Scheduler`
            : `Ping Scheduler`
        }
      />

      <CommonModal
        icon={"/assets/mailForward.svg"}
        open={scheduleModal}
        title={"Schedule Interview"}
        sendButtonName={"Yes"}
        cancelButtonName={"No"}
        handleClose={() => {
          setScheduleModal(false);
          formik.resetForm();
        }}
        onSubmit={() => {
          handleConfirmSchedule();
          formik.resetForm();
        }}
        BackdropProps={{
          style: {
            backdropFilter: "blur(5px)",
            backgroundColor: "rgba(49, 62, 79, 0.2)",
          },
        }}
      >
        <Typography
          style={{
            backgroundColor: "#fff0e7",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <img
            src="/assets/alert-1.svg"
            alt="Alert"
            style={{
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "10px",
              marginBottom: "15px",
            }}
          />
          <strong> Are you certain you wish to Schedule Interview?</strong>
        </Typography>
      </CommonModal>

      <CommonModal
        icon={"/assets/mailForward.svg"}
        open={successModal}
        title={"Interview Scheduled"}
        showFooter={false}
        handleClose={() => setSuccessModal(false)}
        onSubmit={() => setSuccessModal(false)}
        BackdropProps={{
          style: {
            backdropFilter: "blur(5px)",
            backgroundColor: "rgba(49, 62, 79, 0.2)",
          },
        }}
      >
        <Typography
          style={{
            backgroundColor: "#f0fff0",
            padding: "15px",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <img
            src="/assets/calendar.gif"
            alt="Success"
            style={{ height: "100px", marginBottom: "15px" }}
          />
          <p>Interview scheduled successfully !!</p>
        </Typography>
      </CommonModal>
    </Box>
  );
}
