import { Navigate, useRoutes } from "react-router-dom";
import {
  Account,
  AccountPage,
  Assessments,
  Candidates,
  Dashboard,
  Interviews,
  JobDetail,
  JobOpening,
  Library,
  Login,
  Settings,
  TalentPool,
  TalentPoolCandidateDetails,
  ViewQuestions,
  ViewSkillQuestions,
  LockScreen,
  ForgetPassword,
  ResetPassword,
  AutoTest,
  CustomTest,
  RecruiterCalendar,
  SendInvites,
  ChooseQueLibrary,
  PublishedTest,
  ChatBot,
  AssessmentCandidateDetails,
  CandidatesDetails,
  ContactSupport,
  Round1Preview,
  InterviewCandidateDetails,
} from "./elements";
import DashboardLayout from "../layouts/DashboardLayout";
import { useContext, useEffect, useRef, useState } from "react";
import { breadcrumbsContext } from "../context/breadcrumbsContext";
import { PATH_DASHBOARD } from "./paths";
import ShortlistedCandidates from "../pages/ShortlistedCandidates";
import AssessmentsAnalytics from "../pages/assessments/AssessmentsAnalytics";
import CheatingDetected from "../components/cheatingDetected/CheatingDetected";
import { AuthContext } from "../context/authContext";
import { navContext } from "../context/navContext";
import TemplateLibrary from "../pages/assessments/TemplateLibrary";
import TemplateOverview from "../pages/assessments/TemplateOverview";
import { messaging } from "../utils/firebase";
import { getToken } from "firebase/messaging";
export default function Router() {
  const { isLoggedIn, authUser } = useContext(AuthContext);
  const { toggleDrawer, setToggleDrawer } = useContext(navContext);
  const [breadcrumbsData, setBreadcrumbsData] = useState([
    { name: "Dashboard", href: PATH_DASHBOARD.root },
  ]);
  const hasRequestedPermission = useRef(false);
  const url = process.env.REACT_APP_NOTIFICATION_URL;

  useEffect(() => {
    const requestPermission = async () => {
      try {
        // Register the service worker
        const registration = await navigator.serviceWorker.register(
          "/firebase-messaging-sw.js",
          {
            scope: "/",
          }
        );

        if (Notification.permission !== "granted") {
          const permission = await Notification.requestPermission();
          if (permission === "granted") {
            const currentToken = await getToken(messaging, {
              vapidKey: process.env.REACT_APP_FIREBASEKEY,
              serviceWorkerRegistration: registration, // Pass the registration here
            });
            if (currentToken) {
              await fetch(`${url}/subscription`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  token: currentToken,
                  userId: authUser._id,
                }),
              });
            }
            console.log("FCM Token:", currentToken);
          } else {
            console.warn("Notification permission denied");
          }
        } else if (Notification.permission === "granted") {
          const currentToken = await getToken(messaging, {
            vapidKey: process.env.REACT_APP_FIREBASEKEY,
            serviceWorkerRegistration: registration, // Pass the registration here
          });
          if (currentToken) {
            await fetch(`${url}/subscription`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                token: currentToken,
                userId: authUser._id,
              }),
            });
          }
          console.log("FCM Token:", currentToken);
        }
      } catch (error) {
        console.error("Error in requestPermission:", error);
      }
    };

    if (isLoggedIn && authUser && !hasRequestedPermission.current) {
      hasRequestedPermission.current = true;
      requestPermission();
    }
  }, [isLoggedIn, authUser]);

  return useRoutes([
    {
      path: "login",
      element: isLoggedIn ? (
        <Navigate to="/dashboard" replace />
      ) : authUser ? (
        <LockScreen />
      ) : (
        <Login />
      ),
    },
    {
      path: "forget_password",
      element: <ForgetPassword />,
    },
    {
      path: "reset_password",
      element: <ResetPassword />,
    },
    {
      path: "/",
      element: isLoggedIn ? (
        <breadcrumbsContext.Provider
          value={{ breadcrumbsData, setBreadcrumbsData }}
        >
          <DashboardLayout />
        </breadcrumbsContext.Provider>
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        {
          element: <Navigate to={"/login"} replace />,
          index: true,
        },
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "job_openings",
          element: <JobOpening />,
        },
        {
          path: "job_openings/job_detail/:jobId",
          element: <JobDetail />,
        },
        {
          path: "assessments",
          element: <Assessments />,
        },
        {
          path: "assessments/analytics",
          element: <AssessmentsAnalytics />,
        },
        {
          path: "dashboard/autotest",
          element: <AutoTest />,
        },
        {
          path: "assessments/autotest",
          element: <AutoTest />,
        },
        {
          path: "/assessments/customtest",
          element: <CustomTest />,
        },
        {
          path: "assessments/analytics/drawer",
          element: (
            <CheatingDetected
              open={toggleDrawer}
              setOpen={setToggleDrawer}
              drawerHeading="Cheating Detected "
            />
          ),
        },
        {
          path: "library",
          element: <Library />,
        },
        {
          path: "candidates",
          element: <Candidates />,
        },
        {
          path: "assessments/shortlistedcandidates",
          element: <ShortlistedCandidates />,
        },
        {
          path: "assessments/sendinvites",
          element: <SendInvites />,
        },
        {
          path: "assessments/template_library",
          element: <TemplateLibrary />,
        },
        {
          path: "template/overview",
          element: <TemplateOverview />,
        },
        {
          path: "assessments/choose_question_library",
          element: <ChooseQueLibrary />,
        },
        {
          path: "interviews",
          element: <Interviews />,
        },
        {
          path: "interviews/candidateDetails",
          element: <InterviewCandidateDetails />,
        },
        {
          path: "interviews/recruiter_calender",
          element: <RecruiterCalendar />,
        },
        {
          path: "talent_pool",
          element: <TalentPool />,
        },
        {
          path: "settings",
          element: <Settings />,
        },
        {
          path: "account",
          element: <AccountPage />,
        },
        {
          path: "contactSupport",
          element: <ContactSupport />,
        },
        {
          path: "talent_pool/candidateDetails",
          element: <TalentPoolCandidateDetails />,
        },
        {
          path: "assessments/candidateDetails",
          element: <AssessmentCandidateDetails />,
        },
        {
          path: "candidates/candidateDetails",
          element: <CandidatesDetails />,
        },
        {
          path: "assessments/view-questions/section",
          element: <ViewQuestions />,
        },
        {
          path: "assessments/view-questions/skill",
          element: <ViewSkillQuestions />,
        },
        {
          path: "/assessment/publishedTest",
          element: <PublishedTest />,
        },
        {
          path: "chatbot",
          element: <ChatBot />,
        },
        {
          path: "roundPreview",
          element: <Round1Preview />,
        },
      ],
    },
    // {
    //   element: <CompactLayout />,
    //   children: [{ path: "404", element: <Page404 /> }],
    // },
    // { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
