import React, { useContext, useEffect, useState, createContext } from "react";
import APICall from "../utils/api";

import {
  Box,
  Typography,
  IconButton,
  capitalize,
  Badge,
  Stack,
  TextField,
  OutlinedInput,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Checkbox,
  Tooltip,
} from "@mui/material";
import SearchBar from "../components/common/SearchBar";
import TabsList from "../components/candidateShortlisted/Tabs";
import AnalyticsHeader from "../components/assessmentAnalytics/AnalyticsHeader";
import ToggleSquareIcons from "../components/common/ToggleSquareIcons";
import Shortlisted from "../components/candidateShortlisted/Shortlisted";
import RoundOne from "../components/candidateShortlisted/RoundOne";
import RoundTwo from "../components/candidateShortlisted/RoundTwo";
import HRRound from "../components/candidateShortlisted/HRRound";
import Hired from "../components/candidateShortlisted/Hired";
import SearchTextField from "../components/searchTextField/SearchTextField";

import SortListedGrid from "../components/candidateShortlisted/SortListedGrid";
import RoundOneGrid from "../components/candidateShortlisted/RoundOneGrid";
import RoundTwoGrid from "../components/candidateShortlisted/RoundTwoGrid";
import HiredGrid from "../components/candidateShortlisted/HiredGrid";
import HRRoundGrid from "../components/candidateShortlisted/HRRoundGrid";
import AssesmentFilter from "../components/assesmentPage/AssesmentFilter";
import { useMessage } from "../components/snackbar/snackbar";

import {
  buildUrlWithFilters,
  handleApplyFilter,
  countCheckedFilters,
  resetAllFilters,
} from "../utils/filter";

import { breadcrumbsContext } from "../context/breadcrumbsContext";

const ShortlistedCandidates = ({ assessmentId, candidateStats }) => {
  const [openMemberModal, setOpenMemberModal] = useState(false);

  const [shortCandidates, setShortCandidates] = useState(true);
  const [loading, setLoading] = useState(false);
  const [candidateAssessmentDetails, setCandidateAssessmentDetails] = useState(
    []
  );
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [search, setSearch] = useState("");
  const { setBreadcrumbsData } = useContext(breadcrumbsContext);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [tabStats, setTabStats] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [filterCount, setFilterCount] = useState(0);
  const [filterSelectAll, setFilterSelectAll] = useState(false);

  const message = useMessage();

  const handleCloseAddOrEdit = () => {
    setOpenMemberModal(false);
  };

  const handlePingClick = () => {
    handleCloseAddOrEdit();
    setOpenSnackbar(true);
    message("Message sent successfully !!", "success");
  };

  const handleFilterChange = (event) => {
    const {
      target: { value },
    } = event;

    const updatedFilters = typeof value === "string" ? value.split(",") : value;

    setSelectedFilters(updatedFilters);

    let filterValues = updatedFilters.map((filter) => filter.value);
    const isSelectAll = updatedFilters.some(
      (filter) => filter.label === "Select All"
    );
    if (isSelectAll) {
      if (filterSelectAll) {
        setSelectedFilters([]);
      } else {
        setSelectedFilters([]);
      }
      setFilterSelectAll(!filterSelectAll);
    } else {
      if (filterSelectAll) {
        filterValues = currentTabFilters
          .filter(
            (filter) =>
              filter.value !== "Select All" &&
              !value.some((val) => val.value === filter.value)
          )
          .map((filter) => filter.value);

        const selectedFilterObjects = currentTabFilters.filter((filter) =>
          filterValues.includes(filter.value)
        );

        setFilterSelectAll(false);
        setSelectedFilters(selectedFilterObjects);
      }
    }
    setTableParams({
      ...tableParams,
      filters: {
        status: isSelectAll ? [] : filterValues,
        ...tableParams.pagination,
        page: 1,
      },
    });
  };

  useEffect(() => {
    if (candidateStats) {
      setTabStats(candidateStats);
    }
  }, [candidateStats]);

  const [tableParams, setTableParams] = useState({
    filters: {
      query: "",
      currentStatus: "Shortlisted",
    },
    pagination: {
      page: 1,
      limit: 100,
    },
  });

  useEffect(() => {
    fetchAssessmentDetails();
    let totalChecked = countCheckedFilters(filters);
    setFilterCount(totalChecked);
  }, [assessmentId, tableParams]);

  const resetFilters = () => {
    resetAllFilters(setTableParams, filters, handleFilterClose);
  };

  let timeoutId = null;
  const handleChangePage = (newPage) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          page: newPage,
        },
      });
    }, 500);
  };

  const statusOptions = {
    Shortlisted: [
      { label: "Select All", value: "Select All" },

      { label: "Shortlisted", value: "Shortlisted" },
      { label: "Round 1 Scheduled", value: "R1 Scheduled" },
      { label: "Round 1 Pending", value: "R1 Pending" },
      { label: "Round 1 Cancelled", value: "R1 Cancelled" },
      { label: "Round 1 Passed", value: "R1 Passed" },
      { label: "Round 1 Failed", value: "R1 Failed" },
      { label: "Round 2 Scheduled", value: "R2 Scheduled" },
      { label: "Round 2 Pending", value: "R2 Pending" },
      { label: "Round 2 Cancelled", value: "R2 Cancelled" },
      { label: "Round 2 Passed", value: "R2 Passed" },
      { label: "Round 2 Failed", value: "R2 Failed" },
      { label: "HR Scheduled", value: "HR Scheduled" },
      { label: "HR Pending", value: "HR Pending" },
      { label: "HR Cancelled", value: "HR Cancelled" },
      { label: "HR Passed", value: "HR Passed" },
      { label: "HR Failed", value: "HR Failed" },
      { label: "Offered", value: "Offered" },
      { label: "Hired", value: "Hired" },
      { label: "Cancelled Hire", value: "Cancelled Hire" },
    ],
    round1: [
      { label: "Select All", value: "Select All" },

      { label: "Scheduled", value: "R1 Scheduled" },
      { label: "Pending", value: "R1 Pending" },
      { label: "Cancelled", value: "R1 Cancelled" },
      { label: "Passed", value: "R1 Passed" },
      { label: "Failed", value: "R1 Failed" },
    ],
    round2: [
      { label: "Select All", value: "Select All" },

      { label: "Scheduled", value: "R2 Scheduled" },
      { label: "Pending", value: "R2 Pending" },
      { label: "Cancelled", value: "R2 Cancelled" },
      { label: "Passed", value: "R2 Passed" },
      { label: "Failed", value: "R2 Failed" },
    ],
    HRRound: [
      { label: "Select All", value: "Select All" },
      { label: "Scheduled", value: "HR Scheduled" },
      { label: "Pending", value: "HR Pending" },
      { label: "Cancelled", value: "HR Cancelled" },
      { label: "Passed", value: "HR Passed" },
      { label: "Failed", value: "HR Failed" },
    ],
    hired: [{ label: "Hired", value: "Hired" }],
  };
  const [selectedFilters, setSelectedFilters] = React.useState([]);
  const [currentTabFilters, setCurrentTabFilters] = React.useState(
    statusOptions.Shortlisted
  );

  const filterOptions = [
    {
      label: "Score",
      isSearchBar: false,
      value: "scoreFilter",
      onCheckChange: (selection, lastSelection) => {
        setFilters((prev) => {
          const newFilters = [...prev];
          const scoreFilter = newFilters.find(
            (filter) => filter.label === "Score"
          );

          scoreFilter.checkBox.forEach((elem) => {
            if (elem.value !== lastSelection) {
              elem.isChecked = false;
            } else {
              elem.isChecked = !elem.isChecked;
            }
          });

          return newFilters;
        });
      },
      checkBox: [
        {
          label: "Recommended (High to Low)",
          value: "topToLow",
          isChecked: false,
        },
        {
          label: "Greater than 90%",
          value: "greaterThan90",
          isChecked: false,
        },

        {
          label: "Greater than 70%",
          value: "greaterThan70",
          isChecked: false,
        },
        {
          label: "Greater than 50%",
          value: "greaterThan50",
          isChecked: false,
        },
        { label: "Less than 50%", value: "lessThan50", isChecked: false },
      ],
    },

    {
      label: "Status",
      isSearchBar: false,
      value: "status",
      checkBox: statusOptions.Shortlisted,
    },
  ];

  const [filters, setFilters] = useState([...filterOptions]);

  const onHandleApplyFilter = () => {
    handleApplyFilter(
      filters,
      {
        ...tableParams,
        filters: {
          ...tableParams.filters,
          currentStatus: capitalize(currentTab),
        },
      },
      setTableParams,
      setIsFilterOpen
    );
  };

  const shortlistedTabs = [
    {
      label: `Shortlisted (${tabStats?.shortlisted})`,
      value: "Shortlisted",
    },
    {
      label: `Round 1 (${tabStats?.round1Count})`,
      value: "round1",
    },
    {
      label: `Round 2 (${tabStats?.round2Count})`,
      value: "round2",
    },

    { label: `HR Round(${tabStats?.HrRounds})`, value: "HRRound" },

    {
      label: `Hired (${tabStats?.hired})`,
      value: "hired",
    },
  ];
  const [currentTab, setCurrentTab] = useState(shortlistedTabs[0].value);

  const [gridView, setGridView] = useState(true);

  useEffect(() => {
    const activeTab = shortlistedTabs.find((tab) => tab.value === currentTab);
    const tabLabelWithoutNumbersOrParentheses = activeTab.label.replace(
      /\(.*?\)/g,
      ""
    );

    setBreadcrumbsData((breadcrumbs) => [
      ...breadcrumbs.slice(0, 2),
      { name: tabLabelWithoutNumbersOrParentheses.trim() },
    ]);
  }, [currentTab, setBreadcrumbsData]);

  const handleFilterOpen = () => {
    setIsFilterOpen(true);
  };
  const fetchAssessmentDetails = async () => {
    try {
      setLoading(true);
      let url = `/candidateAssessment?assessmentId=${assessmentId}&page=${tableParams.pagination.page}&limit=${tableParams.pagination.limit}`;
      // url += buildUrlWithFilters(tableParams);
      url += buildUrlWithFilters({
        ...tableParams,
        filters: {
          ...tableParams.filters,
          currentStatus: capitalize(currentTab),
        },
      });

      const res = await APICall(url);

      setCandidateAssessmentDetails(res.data.documents);

      const TotalDocuments = res.data.total;
      setTotalDocuments(TotalDocuments);

      setTotalPages(Math.ceil(res.data.total / tableParams.pagination.limit));

      switch (currentTab) {
        case "Shortlisted":
          setTabStats((prevStats) => ({
            ...prevStats,
            shortlisted: TotalDocuments,
          }));
          break;
        case "round1":
          setTabStats((prevStats) => ({
            ...prevStats,
            round1Count: TotalDocuments,
          }));
          break;
        case "round2":
          setTabStats((prevStats) => ({
            ...prevStats,
            round2Count: TotalDocuments,
          }));
          break;
        case "HRRound":
          setTabStats((prevStats) => ({
            ...prevStats,
            HrRounds: TotalDocuments,
          }));
          break;
        case "hired":
          setTabStats((prevStats) => ({
            ...prevStats,
            hired: TotalDocuments,
          }));
          break;
        default:
          break;
      }
    } catch (error) {
      console.error("Failed to fetch assessment details:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterClose = () => {
    setIsFilterOpen(false);
  };

  const renderContent = () => {
    return (
      <>
        {shortCandidates
          ? // If shortCandidates is true, render the relevant grid
            (() => {
              switch (currentTab) {
                case "Shortlisted":
                  return (
                    <SortListedGrid
                      setLoading={setLoading}
                      loading={loading}
                      data={candidateAssessmentDetails}
                      totalDocuments={totalDocuments}
                      totalPages={totalPages}
                      tableParams={tableParams}
                      setTableParams={setTableParams}
                    />
                  );
                case "round1":
                  return (
                    <RoundOneGrid
                      setLoading={setLoading}
                      loading={loading}
                      data={candidateAssessmentDetails}
                      totalDocuments={totalDocuments}
                      totalPages={totalPages}
                      tableParams={tableParams}
                      setTableParams={setTableParams}
                    />
                  );
                case "round2":
                  return (
                    <RoundTwoGrid
                      setLoading={setLoading}
                      loading={loading}
                      data={candidateAssessmentDetails}
                      totalDocuments={totalDocuments}
                      totalPages={totalPages}
                      tableParams={tableParams}
                      setTableParams={setTableParams}
                    />
                  );
                case "HRRound":
                  return (
                    <HRRoundGrid
                      setLoading={setLoading}
                      loading={loading}
                      data={candidateAssessmentDetails}
                      totalDocuments={totalDocuments}
                      totalPages={totalPages}
                      tableParams={tableParams}
                      setTableParams={setTableParams}
                    />
                  );
                case "hired":
                  return (
                    <HiredGrid
                      setLoading={setLoading}
                      loading={loading}
                      data={candidateAssessmentDetails}
                      totalDocuments={totalDocuments}
                      totalPages={totalPages}
                      tableParams={tableParams}
                      setTableParams={setTableParams}
                    />
                  );
                default:
                  return null;
              }
            })()
          : // If shortCandidates is false, render the alternative components
            (() => {
              switch (currentTab) {
                case "Shortlisted":
                  return (
                    <Shortlisted
                      setLoading={setLoading}
                      loading={loading}
                      data={candidateAssessmentDetails}
                      totalDocuments={totalDocuments}
                      totalPages={totalPages}
                      tableParams={tableParams}
                      setTableParams={setTableParams}
                      handleChangePage={handleChangePage}
                    />
                  );
                case "round1":
                  return (
                    <RoundOne
                      setLoading={setLoading}
                      loading={loading}
                      data={candidateAssessmentDetails}
                      totalDocuments={totalDocuments}
                      totalPages={totalPages}
                      tableParams={tableParams}
                      setTableParams={setTableParams}
                      handleChangePage={handleChangePage}
                    />
                  );
                case "round2":
                  return (
                    <RoundTwo
                      setLoading={setLoading}
                      loading={loading}
                      data={candidateAssessmentDetails}
                      totalDocuments={totalDocuments}
                      totalPages={totalPages}
                      tableParams={tableParams}
                      setTableParams={setTableParams}
                    />
                  );
                case "HRRound":
                  return (
                    <HRRound
                      loading={loading}
                      data={candidateAssessmentDetails}
                      totalDocuments={totalDocuments}
                      totalPages={totalPages}
                      tableParams={tableParams}
                      setTableParams={setTableParams}
                    />
                  );
                case "hired":
                  return (
                    <Hired
                      setLoading={setLoading}
                      loading={loading}
                      data={candidateAssessmentDetails}
                      totalDocuments={totalDocuments}
                      totalPages={totalPages}
                      tableParams={tableParams}
                      setTableParams={setTableParams}
                    />
                  );
                default:
                  return null;
              }
            })()}
      </>
    );
  };

  let searchTimeoutId = null;

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    if (searchTimeoutId) {
      clearTimeout(searchTimeoutId);
    }
    searchTimeoutId = setTimeout(() => {
      setTableParams({
        ...tableParams,
        filters: {
          ...tableParams.filters,
          query: value,
        },
        pagination: { ...tableParams.pagination, page: 1 },
      });
    }, 500);
  };

  const tableFilters = {
    query: "",
    scoreFilter: [],
    status: [],
  };
  const handleTabChange = (tabValue) => {
    if (tabValue === "Shortlisted") {
      setCurrentTabFilters(statusOptions.Shortlisted);
    } else if (tabValue === "round1") {
      setCurrentTabFilters(statusOptions.round1);
    } else if (tabValue === "round2") {
      setCurrentTabFilters(statusOptions.round2);
    } else if (tabValue === "HRRound") {
      setCurrentTabFilters(statusOptions.HRRound);
    } else if (tabValue === "hired") {
      setCurrentTabFilters(statusOptions.hired);
    } else {
      setCurrentTabFilters([]);
      console.warn(`No options available for tab: ${tabValue}`);
    }
    setSelectedFilters([]);
    setFilterSelectAll(false);
    setFilters([...filterOptions]);
    setFilters((prev) => {
      const newFilters = [...prev];
      const statusFilter = newFilters.find(
        (filter) => filter.label === "Status"
      );
      // Update the checkbox options for status filter based on the tab

      return newFilters;
    });
    setTableParams({
      ...tableParams,
      filters: {
        // ...tableParams.filters,
        tableFilters,
        currentStatus: capitalize(tabValue),
        query: "",
      },
      pagination: {
        ...tableParams.pagination,
        page: 1,
      },
    });
    setSearchValue("");

    setCurrentTab(tabValue);
    setTabStats(candidateStats);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        background: "#F2F4F8",
        display: "flex",
      }}
    >
      <Box sx={{ height: "100%", width: "100%" }}>
        <Box sx={{ height: "100%", background: "#F2F4F8", width: "100%" }}>
          <Box
            sx={{
              width: "100%",
              background: "#fff",
              padding: "2px",
            }}
          >
            <TabsList
              handleTabChange={(newValue) => handleTabChange(newValue)}
              value={currentTab}
              tabs={shortlistedTabs}
              type="secondary"
            />
            <Box
              sx={{
                display: { sm: "block", md: "flex" },
                alignItems: "center",
                justifyContent: "space-between",
                mt: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    color: "#010101",
                    fontFamily: "Nunito",
                  }}
                >
                  {`Candidates (${totalDocuments})`}
                </Typography>
                <Box
                  sx={{
                    display: { base: "none", md: "flex" },
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Box sx={{ width: { base: "100%", md: "296px" } }}>
                    <SearchBar
                      placeholder="Search Candidate"
                      value={searchValue}
                      onChange={handleSearchChange}
                    />
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { base: "column", sm: "row" },
                  alignItems: { base: "flex-end", sm: "center" },
                  gap: "10px",
                  mt: { base: "16px", md: "0px" },
                }}
              >
                <Box
                  sx={{
                    display: { base: "flex", md: "none" },
                    width: "100%",
                    gap: "16px",
                  }}
                >
                  <SearchBar
                    width="100%"
                    search={search}
                    setSearch={setSearch}
                  />
                </Box>
                {currentTab !== "hired" && (
                  <FormControl
                    size="small"
                    variant="outlined"
                    sx={{ width: { base: "100%", md: "250px" } }}
                  >
                    <InputLabel id="statusDropdown" shrink={true}>
                      Filters
                    </InputLabel>

                    <Select
                      labelId="statusDropdown"
                      id="statusDropdownFilter"
                      multiple
                      displayEmpty
                      input={<OutlinedInput label="Filters" />}
                      renderValue={(selected) => {
                        if (filterSelectAll) {
                          return "Select All";
                        }

                        if (selected.length === 0) {
                          return "Select Filters";
                        }

                        return selected.length > 0
                          ? selected
                              .filter((item) => item.label)
                              .map((item) => item.label)
                              .join(", ")
                          : "Select Filters";
                      }}
                      value={selectedFilters || []}
                      onChange={handleFilterChange}
                      sx={{ height: { base: "100%", md: "37px" } }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 48 * 4.5,
                            width: 250,
                          },
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                      }}
                    >
                      {currentTabFilters.map((filter) => (
                        <MenuItem key={filter.value} value={filter}>
                          <Checkbox
                            checked={
                              selectedFilters.includes(filter) ||
                              // selectedFilters.some(
                              //   (selected) => selected.value === "Select All"
                              // )
                              filterSelectAll === true
                            }
                          />
                          <ListItemText primary={filter.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <ToggleSquareIcons
                  setShortCandidates={setShortCandidates}
                  shortCandidates={shortCandidates}
                />
                
              </Box>
            </Box>
            <Box sx={{ mb: "16px" }}>
              {totalDocuments === 0 ? (
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    marginTop: "20px",
                  }}
                >
                  <img
                    src="/assets/NoData.svg"
                    alt="No data found"
                    style={{
                      height: "60px",
                      width: "auto",
                    }}
                  />
                  No data found
                </Typography>
              ) : (
                renderContent()
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {isFilterOpen && (
        <AssesmentFilter
          open={isFilterOpen}
          onClose={handleFilterClose}
          setFilters={setFilters}
          filters={filters}
          handleApplyFilter={onHandleApplyFilter}
          resetFilters={resetFilters}
        />
      )}
    </Box>
  );
};

export default ShortlistedCandidates;

const StyledBadge = (props) => {
  return (
    <Badge
      {...props}
      sx={{
        "& .MuiBadge-badge": {
          top: 4,
          right: 0,
          width: "16px",
          height: "16px",
          minWidth: "16px",
          minHeight: "16px",
          backgroundColor: "#F94948",
          color: "#FFFFFF",
          fontSize: "8px",
          borderRadius: "50%",
          padding: "0 4px",
          fontFamily: "Poppins",
          fontWeight: 600,
        },
      }}
    >
      {props.children}
    </Badge>
  );
};
