import React, { useState } from "react";
import {
  Box,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Alert,
  Snackbar,
  TextField,
} from "@mui/material";
import { candidates_reviewed_table_rows } from "../../utils/data";
import CustomTable from "../common/customTable/CustomTable";
import { Checkbox } from "@mui/material";
import CommonModal from "../../components/modal/CommonModal";
import { useMessage } from "../../components/snackbar/snackbar";

import SortingIcon from "../icons/SortingIcon";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { useFormik } from "formik";
import * as yup from "yup";
import { getColorByStatus } from "../../utils";

import { getPaginationOptions } from "../../utils/pagination";
import APICall from "../../utils/api";

const columnsWidth = [
  "60px",
  "60px",
  "60px",
  "60px",
  "60px",
  "60px",
  "60px",
  "60px",
];

const tableHeaderCellStyleProps = {
  color: "#363E45",
  lineHeight: "16px",
  fontSize: "12px",
  fontWeight: "500",
  fontFamily: "Roboto",
  padding: "8px 16px",
  height: "36px",
  whiteSpace: "nowrap",
};
const tableContentCellTextStyleProps = {
  textAlign: "left",
  fontSize: "14px",
  color: "#363E45",
  fontWeight: "400",
  lineHeight: "20px",
  fontFamily: "Roboto",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};
const tableBodyRowStyleProps = {
  height: "48px",
};

export default function Reviewed({
  loading,
  setLoading,
  setTableParams,
  data,
  totalDocuments,
  totalPages,
  tableParams,
  handleStatusClick,
  setSelectedRow,
  selectedRow,
  updateStatus,
  selectedCheckboxes,
  handleCheckboxChange,
  handleChangePage,
}) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const { authUser } = React.useContext(AuthContext);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const message = useMessage();

  const [openStatusModal, setOpenStatusModal] = useState("");

  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };
  const getStatus = (status) => {
    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        {status?.cheated && (
          <Tooltip
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#313E4F",
                  height: "40px",
                  padding: "8px 12px",
                  borderRadius: "0.25rem",
                },
                "& .MuiTooltip-arrow": {
                  // background: "#313E4F",
                },
              },
            }}
            placement="bottom-start"
            arrow
            sx={{ background: "#fff" }}
            title={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  cursor: "pointer",
                }}
              >
                <Typography
                  sx={{
                    color: "#E9EAEB",
                    fontFamily: "Roboto",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                  // onClick={handleCheatingClick}
                >
                  {status?.cheated
                    ? "Cheating Detected"
                    : "No Cheating Detected"}
                </Typography>
                <img src="/assets/redirect_icon_2.svg" alt="redirect icon" />
              </Box>
            }
          >
            <Box
              component="img"
              src="/assets/alert_icon.svg"
              alt="AlertIcon"
              sx={{ cursor: "pointer" }}
            />
          </Tooltip>
        )}
        <Box
          sx={{
            display: "inline-block",
            background: getColorByStatus(status.id).bg,
            padding: "4px 12px",
            color: getColorByStatus(status.id).color,
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "16px",
            fontFamily: "Roboto",
            borderRadius: "0.25rem",
            whiteSpace: "nowrap",
          }}
        >
          {status.tag}
        </Box>
      </Box>
    );
  };
  const handleViewDetails = () => {
    if (selectedRow) {
      console.log(selectedRow);
      navigate(`/assessments/candidateDetails`, {
        state: {
          candidateAssessmentId: selectedRow?.candidateAssessmentId,
          assessmentId: selectedRow?.assessmentId?._id,
          assessmentDetails: selectedRow?.assessmentId,
        },
      });
    }
    handleMenuClose();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleGetLink = () => {
    const link =
      "https://staging.dev.theeliteqa.com/candidateresult/66694b7d69dfc4fef59e9df6";
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setAlertMessage("Result Link copied to clipboard!");
        setOpen(true);
      })
      .catch(() => {
        setAlertMessage("Failed to copy the link.");
        setOpen(true);
      });
    handleMenuClose();
  };

  const handleSubmit = (values) => {
    console.log(values);
    handleCloseInviteModal(false);
    message("Invite Resent Successfully", "success");
  };

  const validationSchema = yup.object({
    cutOff: yup.string().required("CutOff is required"),
  });

  const formik = useFormik({
    initialValues: {
      cutOff: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const handleCloseInviteModal = () => {
    setOpenInviteModal(false);
  };

  const tableHeaderTitles = [
    {
      title:
        authUser?.role === "super admin" || authUser?.role === "hr" ? (
          ""
        ) : "recruiter" ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <Checkbox /> */}
          </Box>
        ) : (
          ""
        ),
      cellStyleProps: { width: "20px" },
    },
    (authUser?.role === "super admin" || authUser?.role === "hr") && {
      title: "Sr. no",
      cellStyleProps: {
        width: "20px",
      },
    },
    {
      isSortCol: true,
      colId: "candidate",
      title: (
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Typography
            sx={{ fontSize: "12px", fontWeight: "500", fontFamily: "Roboto" }}
          >
            Candidate
          </Typography>
          <SortingIcon />
        </Box>
      ),
      cellStyleProps: {},
    },
    {
      title: "Attempt %",
      cellStyleProps: {},
    },
    {
      title: "Reviewed at",
      cellStyleProps: {},
    },
    {
      isSortCol: true,
      colId: "score",
      title: (
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Typography
            sx={{ fontSize: "12px", fontWeight: "500", fontFamily: "Roboto" }}
          >
            Score
          </Typography>
          <SortingIcon />
        </Box>
      ),
      cellStyleProps: {},
    },
    {
      title: "Percentile",
      cellStyleProps: {
        width: columnsWidth[3],
      },
    },
    {
      title: "Test Duration",
      cellStyleProps: {},
    },
    {
      title: "Status",
      cellStyleProps: {
        width: "60px",
      },
    },
    {
      title: "",
      cellStyleProps: {},
    },
  ];

  const tableHeaderCells = () => {
    return tableHeaderTitles.map((item) => {
      return {
        isSortCol: item?.isSortCol ? item.isSortCol : false,
        colId: item?.colId ? item.colId : "",
        element: item.title,
        cellStyleProps: item.cellStyleProps,
        isSortable: item?.isSortable ? item.isSortable : false,
        cellId: item?.cellId ? item.cellId : "",
      };
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  const tableBodyRowCell = (row, cellStyleProps) => {
    return [
      authUser?.role === "recruiter" && {
        cellStyleProps: {
          borderRight: "1px solid #E8E9EE",
          flex: "1",
          textAlign: "center",
          ...cellStyleProps,
        },
        element: (
          <Checkbox
            sx={{ padding: "0rem", width: "24px", height: "24px" }}
            checked={selectedCheckboxes.includes(row.candidateAssessmentId)}
            onChange={() => handleCheckboxChange(row.candidateAssessmentId)}
          />
        ),
      },
      (authUser?.role === "super admin" || authUser?.role === "hr") && {
        cellStyleProps: {
          whiteSpace: "nowrap",
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row.srNo}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <img
              src={
                row?.candidateProfilePicture
                  ? row?.candidateProfilePicture
                  : "/assets/user.png"
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/assets/user.png";
              }}
              alt="user"
              style={{ height: "30px", width: "30px", borderRadius: "50%" }}
            />
            <Typography
              sx={{
                ...tableContentCellTextStyleProps,
                cursor: "pointer",
              }}
              onClick={() =>
                navigate(`/assessments/candidateDetails`, {
                  state: {
                    candidateAssessmentId: row?.candidateAssessmentId,
                    assessmentId: row?.assessmentId?._id,
                    assessmentDetails: row?.assessmentId,
                  },
                })
              }
            >
              {row.candidateId?.name}
              {row?.assessmentResult?.totalObtainedScore <
                row?.assessmentId?.cutOff &&
                row?.currentStatus !== "invited" &&
                row?.currentStatus !== "invite expired" && (
                  <Tooltip title="Failed" arrow>
                    <img
                      src="/assets/exclamation-circle.svg"
                      alt="Alert Icon"
                      style={{
                        marginRight: "4px",
                        marginLeft: "4px",
                        height: "18px",
                      }}
                    />
                  </Tooltip>
                )}
            </Typography>
          </Box>
        ),
      },
      {
        cellStyleProps: {
          flex: "1",
          whiteSpace: "nowrap",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row?.attemptedQuestions && row?.totalQuestions
              ? `${(
                  (row.attemptedQuestions / row.totalQuestions) *
                  100
                ).toFixed(2)}%`
              : "0"}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row.candidateJourney?.journey[0]?.timestamp
              ? formatDate(row.candidateJourney?.journey[2]?.timestamp)
              : "N/A"}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {`${row?.assessmentResult?.totalObtainedScore < 10 ? "0" : ""}${
              row?.assessmentResult?.totalObtainedScore
            } / ${row?.assessmentId?.totalScore}`}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row?.assessmentId?.totalScore && row?.totalObtainedScore
              ? `${(
                  (row.totalObtainedScore / row.assessmentId.totalScore) *
                  100
                ).toFixed(0)}%`
              : "0"}
          </Typography>
        ),
      },
      {
        cellStyleProps,
        element: (
          <Typography
            variant="p"
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {Math.round(row.assessmentResult?.totalTimeSpent / 60)} mins
          </Typography>
        ),
      },
      {
        cellStyleProps,
        element: getStatus({
          id: row?.currentStatus,
          tag: row?.currentStatus,
        }),
      },
      {
        cellStyleProps,
        element: (
          <IconButton onClick={(event) => handleMenuClick(event, row)}>
            <img src="/assets/three_dots_icon.svg" alt="three dots" />
          </IconButton>
        ),
      },
    ];
  };

  const tableBodyContent = () => {
    const cellStyleProps = {
      borderBottom: "1px solid #E8E9EE",
      padding: "10px 14px",
    };

    return data?.map((row, idx) => {
      return {
        sortable: { score: parseInt(row.score), candidate: row.candidate },
        rowId: row.id,
        rowElement: tableBodyRowCell({ ...row, srNo: idx + 1 }, cellStyleProps),
      };
    });
  };

  let timeoutId = null;

  const handleRowsChange = (event) => {
    setLoading(true);
    const value = event?.target?.value;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          limit: value,
        },
      });
    }, 500);
  };

  return (
    <Box sx={{ background: "#fff", width: "100%", height: "100%" }}>
      <CustomTable
        loading={loading}
        tableHeaderCells={tableHeaderCells()}
        tableHeaderCellStyleProps={tableHeaderCellStyleProps}
        tableBodyContent={tableBodyContent()}
        tableData={data}
        tableBodyRowStyleProps={tableBodyRowStyleProps}
        sortRows={() => {}}
        limit={tableParams.pagination.limit}
        page={tableParams.pagination.page}
        totalPages={totalPages}
        totalDocuments={totalDocuments}
        handleChangePage={handleChangePage}
        handleRowsChange={handleRowsChange}
        rowsPerPageOptions={getPaginationOptions(
          totalDocuments,
          tableParams.pagination.page,
          tableParams.pagination.limit
        )}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleViewDetails}>
          <img
            style={{
              height: "22px",
              width: "22px",
              marginRight: "8px",
              color: "#000",
            }}
            src="/assets/eye copy.svg"
            alt="view details"
          />
          View Details
        </MenuItem>
        <MenuItem onClick={handleGetLink}>
          <img
            style={{ marginRight: "8px" }}
            src="/assets/arrow-up.svg"
            alt="get link"
          />
          Get Result Link
        </MenuItem>
        {authUser.role === "recruiter" &&
          selectedRow?.currentStatus === "Reviewed" && (
            <MenuItem
              onClick={() => {
                handleStatusClick("Appeared");
                setAnchorEl(null);
              }}
            >
              <img
                style={{ marginRight: "8px" }}
                src="/assets/pass.svg"
                alt="failed"
              />
              {selectedRow?.assessmentResult?.totalObtainedScore >
              selectedRow?.assessmentId?.cutOff ? (
                <>Move to Pass</>
              ) : (
                <>Move to Test Taken</>
              )}{" "}
            </MenuItem>
          )}
        {authUser.role === "recruiter" &&
          selectedRow?.currentStatus === "Reviewed" && (
            <MenuItem
              onClick={() => {
                handleStatusClick("Shortlisted");
                setAnchorEl(null);
              }}
            >
              <img
                style={{ marginRight: "8px" }}
                src="/assets/icons/userCheck.svg"
                alt="shortlist"
              />
              Move to Shortlist
            </MenuItem>
          )}
      </Menu>
      <CommonModal
        icon={"/assets/success.svg"}
        handleClose={() => {
          setOpenStatusModal(false);
        }}
        open={openStatusModal}
        title={"Change Status"}
        sendButtonName={"Confirm"}
        onSubmit={updateStatus}
      >
        <Typography
          style={{
            backgroundColor: "#edf7ed",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <strong>Do you want to change this candidates status?</strong>
          <br />
          <div style={{ verticalAlign: "middle" }}></div>
        </Typography>
      </CommonModal>
    </Box>
  );
}
