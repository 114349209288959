import React, { useState, useRef } from "react";
import { Button, Box, Typography, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@emotion/react";
import Guidelines from "./Guidelines";
import CandidatesCard from "./CandidatesCard";
import BorderedFile from "./BorderedFile";
import ExcelJS from "exceljs";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    backgroundColor: theme.palette.background.default,
    border: `2px dashed ${theme.palette.secondary.main}`,
    borderRadius: "8px",
  },
  fileInput: {
    display: "none",
  },
  fileName: {
    fontSize: "14px",
    color: theme.palette.gray.main,
    fontWeight: "500",
    lineHeight: "20px",
  },
  selectedFile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "4px",
    flexGrow: "1",
  },
  fileType: {
    fontSize: "12px",
    color: theme.palette.gray.main,
    fontWeight: "400",
    lineHeight: "16px",
    fontFamily: "Roboto",
  },
}));

const FileUpload = ({ setCandidatesData }) => {
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [uploadStatus, setUploadStatus] = useState("");
  const [showCandidatesCard, setShowCandidatesCard] = useState(false);
  const [selectedInviteType, setSelectedInviteType] = useState();
  const [fileUploaded, setFileUploaded] = useState(false);

  const classes = useStyles();
  const theme = useTheme();
  const fileInputRef = useRef(null);

  const validateInviteData = (inviteData) => {
    const { candidate, email } = inviteData;

    let errors = {};
    let isValid = true;

    // Email regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const getEmailValue = (email) =>
      typeof email === "object" ? email.text : email;

    const emailValue = getEmailValue(email);
    if (!emailValue || !emailRegex.test(emailValue)) {
      errors.email = "Invalid email format";
      isValid = false;
    }

    // Check for empty values in other fields
    if (!candidate.trim()) {
      errors.name = "Candidate Name cannot be empty";
      isValid = false;
    }

    return { isValid, errors };
  };

  const handleSampleDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sample Upload Format");

    worksheet.columns = [
      { header: "Candidate", key: "candidate", width: 25 },
      { header: "Email", key: "email", width: 25 },
    ];

    worksheet.addRow({
      candidate: "Candidate Name",
      email: "example@email.com",
    });

    const fileName = "Sample_Upload_Format.xlsx";
    await workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
  };

  const handleFileUpload = async (event) => {
    console.log("File uploaded");
    try {
      const selectedFile = event.target.files[0];
      setFileName(selectedFile.name);
      setFileSize((selectedFile.size / 1024 / 1024).toFixed(2) + "MB");
      setFileUploaded(true);
      setUploadStatus("");

      const reader = new FileReader();

      reader.onload = async (e) => {
        try {
          const buffer = e.target.result;
          const workbook = new ExcelJS.Workbook();
          await workbook.xlsx.load(buffer);
          const worksheet = workbook.getWorksheet(1); // Assuming the first worksheet

          let allCandidates = [];
          let validationErrors = [];
          let candidateSet = new Set();
          let duplicateCandidates = [];

          worksheet.eachRow((row, rowNumber) => {
            if (rowNumber >= 2) {
              // Skip header row
              const inviteData = {
                id: uuidv4(), // Generate a unique ID for each candidate
                candidate: row.getCell(1).value,
                email:
                  typeof row.getCell(2).value === "object"
                    ? row.getCell(2).value.text
                    : row.getCell(2).value,
              };
              const { isValid, errors } = validateInviteData(inviteData);
              if (!isValid) {
                validationErrors.push({ ...errors, rowNumber });
              } else {
                const candidateKey = inviteData.email.toLowerCase();
                if (!candidateSet.has(candidateKey)) {
                  candidateSet.add(candidateKey);
                } else {
                  duplicateCandidates.push(inviteData);
                }
                allCandidates.push(inviteData); // Add to allCandidates regardless of uniqueness
              }
            }
          });

          setCandidatesData({
            allCandidates: allCandidates,
            validationErrors: validationErrors,
            totalCandidates: allCandidates.length,
            duplicates: duplicateCandidates.length,
            duplicateErrors: duplicateCandidates,
            candidatesSet: new Set([...candidateSet]),
            duplicateCandidates: duplicateCandidates,
          });
        } catch (error) {
          console.error("Error processing the file", error);
          setUploadStatus("Error processing the file");
        }
      };

      reader.onerror = (error) => {
        console.error("Error reading the file", error);
        setUploadStatus("Error reading the file");
      };

      reader.readAsArrayBuffer(selectedFile);
    } catch (error) {
      console.error("Error handling the file upload", error);
      setUploadStatus(
        "Error handling the file upload, Please correct the validation errors in the file"
      );
    }
  };

  const handleRemoveFile = () => {
    setFileName("");
    setFileSize("");
    setFileUploaded(false);
    setUploadStatus("");
    setCandidatesData({
      totalCandidates: 0,
      duplicates: 0,
      duplicateErrors: [],
      validationErrors: [],
      allCandidates: [],
      candidatesSet: new Set(),
    });
  };

  const handleReuploadFile = () => {
    fileInputRef?.current?.click();
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "flex-start", sm: "center" }}
        sx={{ gap: "24px" }}
        justifyContent={"space-between"}
      >
        <Typography
          sx={{
            fontSize: "16px",
            color: theme.palette.primary.black,
            lineHeight: "24px",
            fontWeight: 600,
            fontFamily: "Nunito",
          }}
        >
          Upload file
        </Typography>
        <Button
          color="gray"
          variant="outlined"
          endIcon={
            <Box
              component={"img"}
              sx={{ width: "16px", height: "16px" }}
              src={
                fileUploaded
                  ? "/assets/icons/download_1.svg"
                  : "/assets/icons/download.svg"
              } // Conditionally set the logo
            />
          }
          sx={{
            padding: "6px 12px 6px 16px",
            fontSize: "14px",
            fontWeight: 500,
            color: theme.palette.gray.dark,
            border: `1px solid ${theme.palette.gray.dark}`,
            borderRadius: "4px",
            maxWidth: "fit-content",
            fontFamily: "Roboto",
            lineHeight: "20px",
          }}
          onClick={handleSampleDownload}
          disabled={fileUploaded}
        >
          {fileUploaded ? "Uploaded File" : "Download Sample File"}
        </Button>
      </Stack>

      {fileName ? (
        <BorderedFile
          fileName={fileName}
          handleRemoveFile={handleRemoveFile}
          handleReuploadFile={handleReuploadFile}
        >
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ width: "48px", height: "48px" }}
          >
            <Box
              component={"img"}
              src={`/assets/icons/fileupload.svg`}
              sx={{ width: "30.8px", height: "38.8px" }}
            />
          </Stack>
          <Stack direction={"column"} sx={{ gap: "4px" }}>
            <Stack direction={"column"}>
              <Typography variant="body2" className={classes.fileName}>
                File Name
              </Typography>
              <Typography
                style={{
                  textDecorationLine: "underline",
                  padding: "2px 0px",
                  color: theme.palette.secondary.main,
                  fontSize: "1rem",
                  fontWeight: "500",
                  fontFamily: "Roboto",
                  lineHeight: "20px",
                }}
              >
                {fileName}
              </Typography>
            </Stack>
            <Typography className={classes.fileType}>{fileSize}</Typography>
            <input
              accept=".xls, .xlsx, .csv"
              className={classes.fileInput}
              id="file-upload"
              type="file"
              ref={fileInputRef}
              onChange={(e) => handleFileUpload(e)}
            />
          </Stack>
        </BorderedFile>
      ) : (
        <BorderedFile>
          <Box
            component={"img"}
            src={`/assets/icons/fileupload.svg`}
            sx={{ width: "30.8px", height: "38.8px" }}
          />
          <Typography variant="body2" className={classes.fileName}>
            No file selected
          </Typography>
          <input
            accept=".xls, .xlsx, .csv"
            className={classes.fileInput}
            id="file-upload"
            type="file"
            ref={fileInputRef}
            onChange={(e) => handleFileUpload(e)}
          />
          <label
            htmlFor="file-upload"
            style={{ fontWeight: "400", fontSize: "1rem" }}
          >
            <Stack
              direction={{ xs: "column", sm: "column", md: "row" }}
              alignItems={"center"}
            >
              <Button
                component="span"
                variant="text"
                style={{
                  textDecorationLine: "underline",
                  padding: "2px 4px",
                  color: theme.palette.secondary.main,
                  fontSize: "1rem",
                  fontWeight: "500",
                  borderRadius: "4px",
                  fontFamily: "Roboto",
                  lineHeight: "20px",
                }}
              >
                Click to upload
              </Button>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "500",
                  color: "#535A5F",
                  fontFamily: "Roboto",
                  lineHeight: "20px",
                }}
              >
                or Drag and Drop
              </Typography>
            </Stack>
          </label>
          <Stack sx={{ gap: "4px" }} direction={"row"}>
            <Typography className={classes.fileType}>XLSX</Typography>
            <Typography className={classes.fileType}>(max. 20MB)</Typography>
          </Stack>
        </BorderedFile>
      )}

      {uploadStatus && (
        <Typography variant="body2" color="error">
          {uploadStatus}
        </Typography>
      )}

      {!(selectedInviteType === "bulkInvite" && fileName) ? (
        <Guidelines />
      ) : (
        <>
          {/* {selectedInviteType === "bulkInvite" && !showCandidatesCard && (
            // <CandidatesCard
            //   totalCandidates={candidatesData.totalCandidates}
            //   duplicates={candidatesData.duplicates}
            //   duplicateErrors={candidatesData.duplicateErrors}
            //   validationErrors={candidatesData.validationErrors}
            //   removeDuplicates={handleRemoveDuplicates}
            // />
          )} */}
        </>
      )}
    </Box>
  );
};

export default FileUpload;
