import React, { useState, useMemo } from "react";
import CustomTable from "../../common/customTable/CustomTable";
import { Box, LinearProgress, Typography } from "@mui/material";
import {
  getPaginationOptions,
  handleChangePage,
  handleChangeRowsPerPage,
} from "../../../utils/pagination";

const columnsWidth = [
  "60px",
  "299px",
  "130px",
  "148px",
  "215px",
  "130px",
  "165px",
];

const tableHeaderCellStyleProps = {
  color: "#363E45",
  lineHeight: "16px",
  fontSize: "12px",
  fontWeight: "500",
  fontFamily: "Roboto",
  padding: "8px 16px",
  height: "36px",
  whiteSpace: "nowrap",
  bgColor: "#F2F4F8",
};
const tableContentCellTextStyleProps = {
  textAlign: "left",
  fontSize: "14px",
  color: "#363E45",
  fontWeight: "400",
  lineHeight: "20px",
  fontFamily: "Roboto",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const getColorByStatus = (status) => {
  if (status === "Passed") {
    return { bg: "#D5F9F1", color: "#004E3E" };
  } else if (status === "Intermediate") {
    return { bg: "#FBE3B1", color: "#614105" };
  }
  return { bg: "#FADCDA", color: "#733430" };
};
const tableBodyRowStyleProps = {
  height: "44px",
};

const tableHeaderTitles = [
  {
    title: "Sr. no",
    cellStyleProps: {
      width: columnsWidth[0],
    },
  },
  {
    title: "Candidate Name",
    cellStyleProps: {
      width: columnsWidth[1],
    },
  },
  {
    title: "Test Score",
    cellStyleProps: {
      width: columnsWidth[2],
    },
  },
  {
    title: "Questions Attempted",
    cellStyleProps: {
      width: columnsWidth[3],
    },
  },
  {
    title: "Correct & Incorrect Attempts",
    cellStyleProps: {
      width: columnsWidth[4],
    },
  },
  {
    title: "Marks Obtained",
    cellStyleProps: {
      width: columnsWidth[5],
      textAlign: "center",
    },
  },
  {
    title: "Status",
    cellStyleProps: {
      width: columnsWidth[6],
    },
  },
];

const tableHeaderCells = () => {
  return tableHeaderTitles.map((item) => {
    return {
      isSortCol: item?.isSortCol ? item.isSortCol : false,
      colId: item?.colId ? item.colId : "",
      element: item.title,
      cellStyleProps: item.cellStyleProps,
      isSortable: item?.isSortable ? item.isSortable : false,
      cellId: item?.cellId ? item.cellId : "",
    };
  });
};

const DataTable = ({
  candidateSkillData,
  totalDocuments,
  totalPages,
  tableParams,
  setTableParams,
}) => {
  const tableBodyRowCell = (row, cellStyleProps) => {
    return [
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row.srNo}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          flex: "1",
          whiteSpace: "nowrap",
          ...cellStyleProps,
        },
        element: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <img
              src={
                row?._id?.candidateProfilePicture
                  ? row?._id.candidateProfilePicture
                  : "/assets/user.png"
              }
              alt="user avatar"
              style={{ height: "30px", width: "30px", borderRadius: "50%" }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/assets/user.png";
              }}
            />
            <Typography
              sx={{
                ...tableContentCellTextStyleProps,
              }}
            >
              {row?._id?.name}
            </Typography>
          </Box>
        ),
      },
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {`${row?.assessmentDetails?.totalObtainedScore} / ${row?.assessmentDetails?.assessmentTotalScore}`}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {`${row?.skillDetails?.attemptedQuestions} / ${row?.skillDetails?.totalQuestions}`}
          </Typography>
        ),
      },

      {
        cellStyleProps,
        element: (
          <Box>
            <LinearProgress
              variant="determinate"
              value={(() => {
                const selectedSkill = row?.skillDetails;

                if (selectedSkill) {
                  const correctRatio =
                    selectedSkill.totalCorrect / selectedSkill.totalQuestions;

                  return Math.round(correctRatio * 100);
                }

                return 0;
              })()}
              sx={{
                bgcolor: "#FD948C",
                "& .MuiLinearProgress-bar": {
                  bgcolor: "#00C49A",
                },
              }}
            />
          </Box>
        ),
      },
      {
        cellStyleProps,
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
              textAlign: "center",
            }}
          >
            {`${row?.skillDetails?.marksObtained} / ${row?.skillDetails?.skillTotalScore}`}
          </Typography>
        ),
      },
      {
        cellStyleProps,
        element: getStatus(row?.skillDetails?.skillStatus),
      },
    ];
  };

  const tableBodyContent = () => {
    const cellStyleProps = {
      borderBottom: "1px solid #E8E9EE",
      padding: "10px 14px",
    };

    return candidateSkillData?.map((row, idx) => {
      return {
        rowElement: tableBodyRowCell({ ...row, srNo: idx + 1 }, cellStyleProps),
      };
    });
  };

  const getStatus = (status) => {
    return (
      <Box
        sx={{
          display: "inline-block",
          background: getColorByStatus(status).bg,
          padding: "2px 8px",
          color: getColorByStatus(status).color,
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: "16px",
          fontFamily: "Roboto",
          borderRadius: "0.25rem",
          whiteSpace: "nowrap",
        }}
      >
        {status}
      </Box>
    );
  };

  return (
    <Box sx={{ background: "#fff", width: "100%", height: "100%" }}>
      <CustomTable
        tableHeaderCells={tableHeaderCells()}
        tableHeaderCellStyleProps={tableHeaderCellStyleProps}
        tableBodyContent={tableBodyContent()}
        tableData={candidateSkillData}
        tableBodyRowStyleProps={tableBodyRowStyleProps}
        sortRows={() => {}}
        limit={tableParams.pagination.limit}
        page={tableParams.pagination.page}
        totalPages={totalPages}
        totalDocuments={totalDocuments}
        handleChangePage={(page) => handleChangePage(page - 1, setTableParams)}
        handleRowsChange={(limit) =>
          handleChangeRowsPerPage(limit.target.value, setTableParams)
        }
        rowsPerPageOptions={getPaginationOptions(
          totalDocuments,
          tableParams.pagination.page,
          tableParams.pagination.limit
        )}
      />
    </Box>
  );
};

export default DataTable;
