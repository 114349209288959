import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Checkbox,
  Avatar,
  capitalize,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CommonModal from "../modal/CommonModal";
import { useMessage } from "../../components/snackbar/snackbar";
import { AuthContext } from "../../context/authContext";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import APICall from "../../utils/api";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/common/SearchBar";
import CustomTable from "../../components/common/customTable/CustomTable";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    background: "#fff",
    padding: "16px",
    border: "1px solid #E8E9EE",
    borderRadius: "12px",
  },
  desktop: {
    display: "flex",
    background: "#fff",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "column", // Default value for base
    gap: "20px", // Default value for base
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      gap: "0",
      alignItems: "center ",
    },
  },
  iconBox: {
    padding: "4px 6px",
    border: "1px solid #D3D9E2",
    borderRadius: 4,
    width: 36,
    height: 32,
  },
  avatarBox: {
    // display: "flex",
    alignItems: "center",
    gap: 6,
  },
  infoBox: {
    alignItems: "center",
    gap: "8px",
  },
  statusBox: {
    background: "#FFF0E7",
    padding: "4px 8px",
    color: "#FF6812",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "20px",
    borderRadius: "0.25rem",
  },
  subtitle: {
    color: "#8591A2",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "20px",
  },
  textField: {
    border: "2px solid #E8E9EE",
    borderRadius: "4px",
  },
  placeholder: {
    fontWeight: 700,
    fontSize: "16px",
    color: "#363E45",
  },
}));
const columnsWidth = ["2px", "10px", "10px", "10px"];

function AnalyticsHeader({
  assessmentStats,
  assessment,
  hideAndShow,
  assessmentDetails,
}) {
  const classes = useStyles();
  const navigate = useNavigate();

  const [openMemberModal, setOpenMemberModal] = useState(false);
  const message = useMessage();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { authUser } = React.useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [openCloneAssessmentModal, setOpenCloneAssessmentModal] =
    useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedCloneQuestion, setSelectedCloneQuestion] = useState(null);
  const [cloneQuestion, setCloneQuestion] = useState("");
  const [isCloning, setIsCloning] = useState(false);
  const [dropDownAdminValue, setdropDowAdminValue] = useState("All");
  const [selectedAdminRows, setSelectedAdminRows] = useState([]);
  const [searchAdminValue, setSearchAdminValue] = useState("");
  const [tableData, setTableData] = useState([]);
  const [initialAdminData, setinitialAdminData] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [channelName, setChannelName] = useState("");

  const validationSchema = yup.object({
    cloneQuestion: yup.string().required("cloneQuestion is required"),
  });

  const handlePingClick = () => {
    handleCloseAddOrEdit();
    setOpenSnackbar(true);
    message("Message sent successfully !!", "success");
    setOpenMemberModal(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setDeleteConfirmOpen(true);
    handleClose();
  };

  const handleTestAdminModalOpen = () => {
    console.log("hello");
  };

  const handleAdminSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchAdminValue(searchValue);
    if (searchValue === "") {
      setTableData(initialAdminData);
    } else {
      const filteredData = tableData.filter((admin) =>
        admin.name.toLowerCase().includes(searchValue)
      );
      setTableData(filteredData);
    }
  };

  const handleCloneSubmit = async (values) => {
    setIsCloning(true);
    setOpenCloneAssessmentModal(false);
    setConfirmOpen(true);
    const data = {
      assessmentId: formik.values.assessmentId,
      assessmentTitle: formik.values.assessmentTitle,
      createdBy: formik.values.createdBy,
    };
    try {
      const res = await APICall(`/assessment/clone`, data, "post");

      message("Assessment cloned successfully !!", "success");
      setIsCloning(false);
      setConfirmOpen(false);
      handleCloseAddOrEdit();
    } catch (error) {
      message("Error Cloning this question", "error");
      setConfirmOpen(false);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const res = await APICall(`/assessment/${assessment?._id}`, {}, "delete");
      if (res.status === 204) {
        setDeleteConfirmOpen(false);
        navigate(`/assessments`);
        message("Assessment deleted successfully !!", "success");
      } else {
        message("Failed to delete");
      }
    } catch (error) {
      console.error("An error occurred while deleting the user:", error);
    }
  };

  const handleCloseAddOrEdit = () => {
    setIsEditing(false);
    setOpenCloneAssessmentModal(false);
    setCloneQuestion("");
    setSelectedCloneQuestion(null);
    formik.resetForm();
    setOpenMemberModal(false);
  };
  useEffect(() => {
    if (assessment) {
      formik.setValues({
        assessmentId: assessment._id,
        assessmentTitle: `${assessment.name} (Copy)`,
        createdBy: assessment.createdBy._id,
      });
    }
  }, [assessment]);

  const formik = useFormik({
    initialValues: {
      assessmentId: assessment?._id,
      assessmentTitle: `${assessment?.name} (Copy)` || "",
      createdBy: assessment?.createdBy._id,
    },
    validationSchema: yup.object({
      assessmentTitle: yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      handleCloneSubmit(values);
    },
  });

  const handleUseTemplate = async (templateId) => {
    try {
      const useTemplateRes = await APICall(
        `/assessment/usetemplate/${templateId}`,
        templateId,
        "post"
      );
      if (useTemplateRes && useTemplateRes.data.assessmentId) {
        navigate(`/assessments/autotest`, {
          state: {
            assessmentId: useTemplateRes.data.assessmentId,
            template: true,
            useTemplateEdit: true,
          },
        });
      } else {
        console.log("Required data not available to navigate.");
      }
    } catch (error) {
      console.error("Required data not available to navigate.", error);
    }
  };

  const getSubtitle = (text, props) => {
    return (
      <Typography className={classes.subtitle} sx={{ ...props }}>
        {text}
      </Typography>
    );
  };

  const handleAdminSelectAll = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);

    if (isChecked) {
      const allRowIds = tableData.map((row) => row._id);
      setSelectedAdminRows(allRowIds);
    } else {
      setSelectedAdminRows([]);
    }
  };

  const handleCheckboxChange = (item, checkboxType) => (event) => {
    console.log("hello");
  };

  const handleAdminCheckboxChange = (rowId) => {
    setSelectedAdminRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(rowId)) {
        return prevSelectedRows.filter((id) => id !== rowId);
      } else {
        return [...prevSelectedRows, rowId];
      }
    });
    console.log(selectedAdminRows);
  };

  const tableBodyRowCell = (row, cellStyleProps) => {
    return [
      {
        cellStyleProps: {
          width: columnsWidth[0],
          maxWidth: columnsWidth[0],
          padding: "10px 3px",
        },
        element: (
          <Checkbox
            checked={selectedAdminRows.includes(row._id)}
            onChange={() => handleAdminCheckboxChange(row._id)}
            sx={{
              //color: selectAll ? "#ff6812" : "default",

              "&.Mui-checked": {
                color: "#ff6812 !important",
                cursor: "pointer",

                // ...tableContentCellTextStyleProps,
              },
            }}
          />
        ),
      },
      {
        cellStyleProps: {
          width: columnsWidth[0],
          maxWidth: columnsWidth[0],
          ...cellStyleProps,
        },
        element: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Avatar
              src={row?.userDetails?.profilePhoto}
              style={{ height: "20px", width: "20px" }}
            />

            {row.name}
          </Box>
        ),
      },

      {
        cellStyleProps: {
          width: columnsWidth[3],
          maxWidth: columnsWidth[3],
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row.email}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          width: columnsWidth[4],
          maxWidth: columnsWidth[4],
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {capitalize(row?.role)}
          </Typography>
        ),
      },
    ];
  };

  const tableBodyContent = () => {
    const cellStyleProps = {
      borderBottom: "1px solid #E8E9EE",
      padding: "10px 14px",
    };

    return tableData.map((row, idx) => {
      return {
        rowId: idx + 1,
        sortable: {
          name: row.name,
          email: row.email,
          channel: row.role,
        },
        rowElement: tableBodyRowCell(
          { ...row, rowId: idx + 1 },
          cellStyleProps
        ),
      };
    });
  };

  const tableHeaderTitles = [
    {
      title: (
        <input
          type="checkbox"
          checked={selectAll}
          onChange={handleAdminSelectAll}
        />
      ),
      render: (rowData) => (
        <input
          type="checkbox"
          checked={rowData.isChecked}
          onChange={(e) => handleCheckboxChange(e, rowData)}
        />
      ),
      cellStyleProps: {
        width: "0px",
      },
    },
    {
      title: "Name",
      cellStyleProps: {
        whiteSpace: "nowrap",
        width: "180px",
      },
    },

    {
      title: "Email ID",
      cellStyleProps: {
        whiteSpace: "nowrap",
        width: "200px",
      },
    },
    {
      title: "Access",
      cellStyleProps: {
        whiteSpace: "nowrap",
        width: "150px",
      },
    },
  ];

  const tableHeaderCellStyleProps = {
    color: "#363E45",
    lineHeight: "16px",
    fontSize: "12px",
    fontWeight: "500",
    fontFamily: "Roboto",
    padding: "10px 16px",
    height: "36px",
  };

  const tableContentCellTextStyleProps = {
    color: "#363E45",
    lineHeight: "20px",
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "Roboto",
    textAlign: "left",
    height: "20px",
  };

  const tableBodyRowStyleProps = {
    height: "48px",
  };

  const tableHeaderCells = () => {
    return tableHeaderTitles.map((item) => {
      return {
        element: item.title,
        cellStyleProps: item.cellStyleProps,
        isSortable: item?.isSortable ? item.isSortable : false,
        cellId: item?.cellId ? item.cellId : "",
      };
    });
  };

  return (
    <>
      <Box
        className={classes.root}
        sx={{
          borderBottomLeftRadius: { base: "0", md: "12px" },
          borderBottomRightRadius: { base: "0", md: "12px" },
          borderBottom: { base: "none", md: "1px solid #E8E9EE" },
        }}
      >
        {/* Desktop Screen */}
        <Box className={classes.desktop}>
          {/* first part */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {assessment?.status === "Published" || authUser?.role === "hr" ? (
                <>
                  <Box
                    sx={{
                      height: "28px",
                      width: "28px",
                      borderRadius: "34px",
                      backgroundColor: "#FF6812",
                      zIndex: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "-9px",
                    }}
                  >
                    <img src="/assets/published.svg" alt="publish" />
                  </Box>
                  <Box
                    sx={{
                      height: "24px",
                      width: "77px",
                      borderRadius: "24px",
                      backgroundColor: "#FFF0E7",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                      Published
                    </Typography>
                  </Box>
                </>
              ) : assessment?.status === "Completed" ? (
                <>
                  <Box
                    sx={{
                      height: "28px",
                      width: "28px",
                      borderRadius: "34px",
                      backgroundColor: "#00c49a",
                      zIndex: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "-9px",
                    }}
                  >
                    <img src="/assets/published.svg" alt="publish" />
                  </Box>
                  <Box
                    sx={{
                      height: "24px",
                      width: "77px",
                      borderRadius: "24px",
                      backgroundColor: "#d5f9f1",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                      Completed
                    </Typography>
                  </Box>
                </>
              ) : null}
            </Box>

            {(authUser?.role === "super admin" || authUser?.role === "hr") && (
              <>
                <Divider
                  sx={{ display: { base: "none", md: "block" } }}
                  orientation="vertical"
                  flexItem
                />

                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#FF6812",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpenMemberModal(!openMemberModal)}
                >
                  <Tooltip title="Ping Recruiter" arrow placement="bottom">
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: "4px" }}
                    >
                      {" "}
                      {/* Ensures alignment */}
                      <img src="/assets/Vector-5.svg" alt="bellIcon" />
                      {assessment?.createdBy?.name ||
                        assessmentDetails?.createdBy?.name ||
                        "Danny Smith"}
                    </Box>
                  </Tooltip>
                </Typography>
              </>
            )}

            <Divider
              sx={{ display: { base: "none", md: "block" } }}
              orientation="vertical"
              flexItem
            />
            {authUser?.role === "recruiter" && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    padding: "0",
                  }}
                >
                  <img src="/assets/remark.svg" alt="remark" />
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    {`${assessment?.cutOff}/${assessment?.totalScore}`}
                  </Typography>
                </Box>
                <Divider
                  sx={{ display: { base: "none", md: "block" } }}
                  orientation="vertical"
                  flexItem
                />
              </>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: { base: "column", md: "row" },
                gap: "16px",
                width: { base: "100%", sm: "auto" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "700",
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                {!hideAndShow && (
                  <Tooltip title="Test Preview" arrow placement="bottom">
                    <img
                      src="/assets/Visit.svg"
                      alt="Test Preview"
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(
                          "https://www.assessment.hire360.ai",
                          "_blank"
                        );
                      }}
                    />
                  </Tooltip>
                )}

                {assessment?.name ||
                  assessmentDetails?.name ||
                  "JavaScript Test"}
              </Typography>
            </Box>
          </Box>
          {/* </Box> */}
          {/* second part */}
          <Box
            className={classes.infoBox}
            sx={{
              display: { base: "none", md: "flex" },
              gap: { base: "8px", md: "16px" },
            }}
          >
            {!hideAndShow ? (
              <>
                {" "}
                <Stack direction="row" spacing={2}>
                  {authUser?.role !== "hr" && (
                    <Box
                      sx={{
                        paddingRight: "12px",
                        borderRight: "1px solid #E8E9EE",
                      }}
                    >
                      <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                        {assessmentStats?.invited || 0}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "#535A5F",
                        }}
                      >
                        Invited
                      </Typography>
                    </Box>
                  )}
                  <Box
                    sx={{
                      paddingRight: "12px",
                      borderRight: "1px solid #E8E9EE",
                    }}
                  >
                    <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                      {assessmentStats?.appeared ||
                        assessmentDetails?.candidateStats?.appeared ||
                        0}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#535A5F",
                      }}
                    >
                      Attempted
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                      {assessmentStats?.shortlisted ||
                        assessmentDetails?.candidateStats?.shortlisted ||
                        0}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#535A5F",
                      }}
                    >
                      Shortlisted
                    </Typography>
                  </Box>
                  {authUser?.role === "recruiter" && (
                    <>
                      <Divider
                        sx={{ display: { base: "none", md: "block" } }}
                        orientation="vertical"
                        flexItem
                      />
                      <Box>
                        <Typography
                          sx={{ fontSize: "18px", fontWeight: "600" }}
                        >
                          {assessmentStats?.hired || 0}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "400",
                            color: "#535A5F",
                          }}
                        >
                          Hired
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          "&:hover": {
                            borderRadius: "50%",
                            backgroundColor: "rgba(0, 0, 0, 0.1)",
                          },
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "36px",
                          height: "36px",
                          marginTop: "18px",
                        }}
                        onClick={handleClick}
                      >
                        <img
                          src="/assets/three_dots_icon_1.svg"
                          alt="menu"
                          sx={{ marginTop: "10px" }}
                        />
                      </Box>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem
                          onClick={() => {
                            setOpenCloneAssessmentModal(true);
                            handleClose();
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <img src="/assets/clone.svg" alt="Clone" />
                            Clone
                          </Box>
                        </MenuItem>
                        {assessment?.status !== "Published" && (
                          <MenuItem
                            onClick={() => {
                              handleDelete();
                              handleClose();
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <img
                                src="/assets/delete_icon_2 copy.svg"
                                alt="delete"
                              />
                              Delete
                            </Box>
                          </MenuItem>
                        )}
                      </Menu>
                    </>
                  )}

                  {authUser?.role === "hr" && (
                    <>
                      <Box
                        sx={{
                          "&:hover": {
                            borderRadius: "50%",
                            backgroundColor: "rgba(0, 0, 0, 0.1)",
                          },
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "36px",
                          height: "36px",
                          marginTop: "18px",
                        }}
                        onClick={handleClick}
                      >
                        <img
                          src="/assets/three_dots_icon_1.svg"
                          alt="menu"
                          sx={{ marginTop: "10px" }}
                        />
                      </Box>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem
                          onClick={() => {
                            // handleAddAdmin();
                            setConfirmOpen(true);
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <img
                              src="/assets/assign.svg"
                              alt="passAssessment"
                            />
                            Pass Assessment
                          </Box>
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </Stack>
              </>
            ) : (
              <>
                {" "}
                <Button
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px 16px 10px 12px",
                    gap: "6px",
                    border: "1px solid #FF6812",
                    borderRadius: "4px",
                    height: "30px",
                    color: "#FF6812",
                    width: "180px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(`https://www.assessment.hire360.ai`, "_blank");
                  }}
                >
                  <Tooltip
                    sx={{ gap: "10px" }}
                    title="Preview Template"
                    arrow
                    placement="bottom"
                  >
                    <img src="/assets/Visit copy.svg" alt="Preview Template" />
                  </Tooltip>
                  Preview Template
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#00c49a",
                    height: "30px",
                    ":hover": { bgcolor: "#00c49a" },
                    color: "#ffffff",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleUseTemplate(assessment._id);
                  }}
                >
                  <img
                    src="/assets/templateWhite.svg"
                    style={{ marginRight: "8px" }}
                  />
                  Use Template
                </Button>{" "}
              </>
            )}
          </Box>
        </Box>
      </Box>
      {/* Tablet/Mobile Screen */}
      <Box
        className={classes.infoBox}
        sx={{
          padding: "4px 16px",
          background: "#fff",
          borderBottomLeftRadius: "12px",
          borderBottomRightRadius: "12px",
          // mt: "16px",
          display: { base: "flex", md: "none" },
          border: "1px solid #E8E9EE",
          borderTop: "none",
        }}
      >
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
        >
          {!hideAndShow ? (
            <>
              {" "}
              <Box className={classes.avatarBox}>
                <Typography
                  sx={{
                    color: "#121111",
                    fontWeight: 700,
                    fontSize: 24,
                    lineHeight: "32px",
                  }}
                >
                  {assessmentStats?.invited || 0}
                </Typography>
                {getSubtitle("Invites", { fontSize: "12px" })}
              </Box>
              <Box className={classes.avatarBox}>
                <Typography
                  sx={{
                    color: "#121111",
                    fontWeight: 700,
                    fontSize: 24,
                    lineHeight: "32px",
                  }}
                >
                  {assessmentStats?.appeared || 0}
                </Typography>
                {getSubtitle("Attempted", { fontSize: "12px" })}
              </Box>
              <Box
                className={classes.avatarBox}
                sx={{
                  borderRight: "none",
                  // display: { base: "none", md: "flex" },
                }}
              >
                <Typography
                  sx={{
                    color: "#121111",
                    fontWeight: 700,
                    fontSize: 24,
                    lineHeight: "32px",
                  }}
                >
                  {assessmentStats?.shortlisted || 0}
                </Typography>
                {getSubtitle("Shortlisted", { fontSize: "12px" })}
              </Box>
            </>
          ) : (
            <>
              <Button
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px 16px 10px 12px",
                  gap: "8px",
                  borderColor: "#ff6812",
                  "&:hover": {
                    borderColor: "#ff6812",
                  },
                  border: "1px solid #FF6812",
                  borderRadius: "4px",
                  height: "30px",
                  width: "165px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(`https://www.assessment.hire360.ai`, "_blank");
                }}
                // onClick={handleCopyLink}
              >
                <Box
                  component={"img"}
                  src="/assets/icons/copyOrange.svg"
                  alt="copy icon"
                  sx={{ width: "20px", height: "15px" }}
                ></Box>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#FF6812",
                  }}
                >
                  Preview Template
                </Typography>
              </Button>
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#00c49a",
                  height: "30px",
                  ":hover": { bgcolor: "#00c49a" },
                  color: "#ffffff",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleUseTemplate(assessment._id);
                }}
                // onClick={() => setEditTestOverviewToggle((prev) => !prev)}
              >
                <img
                  src="/assets/templateWhite"
                  style={{ marginRight: "8px" }}
                />
                Use Template
              </Button>
            </>
          )}
        </Stack>
      </Box>

      <CommonModal
        icon={"/assets/Vector-5.svg"}
        handleClose={handleCloseAddOrEdit}
        open={openMemberModal}
        title={"Ping Recruiter"}
        sendButtonName={"Ping"}
        onSubmit={handlePingClick}
      >
        <Stack gap={"4px"}>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontWeight: "400",
              fontSize: "14px",
              lineheight: "20px",
              color: "#71777B",
            }}
          >
            Enter Message
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={6}
            placeholder="Enter your message"
            className={classes.textField}
            InputProps={{
              className: classes.placeholder, // Apply placeholder styles
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                padding: "8px 12px 8px 12px",
                "& fieldset": {
                  borderColor: "#E8E9EE",
                  borderWidth: "2px",
                  borderRadius: "4px",
                },
              },
              "& .MuiInputBase-input": {
                "&::placeholder": {
                  fontWeight: 400,
                  fontSize: "16px",
                  color: "#363E45",
                  opacity: 1,
                },
              },
            }}
          />
        </Stack>
      </CommonModal>

      <CommonModal
        icon={"/assets/delete_icon.svg"}
        open={deleteConfirmOpen}
        title={"Confirm Delete"}
        onSubmit={handleConfirmDelete}
        sendButtonName={"Yes"}
        cancelButtonName={"No"}
        isDeleting={true}
        handleClose={() => setDeleteConfirmOpen(false)}
      >
        <Typography
          style={{
            backgroundColor: "#fff0e7",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <img
            src="/assets/alert-1.svg"
            alt="Alert"
            style={{
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "10px",
              marginBottom: "15px",
            }}
          />
          <strong>Are you certain you wish to delete this assessment?</strong>
          <br />
          <div style={{ verticalAlign: "middle" }}>
            This action will permanently delete the assessment and all related
            data.
          </div>
        </Typography>
      </CommonModal>

      <CommonModal
        icon={"/assets/clone.svg"}
        handleClose={() => setOpenCloneAssessmentModal(false)}
        open={openCloneAssessmentModal}
        title={`Clone Assessment`}
        sendButtonName={"Clone"}
        onSubmit={formik.handleSubmit}
      >
        <TextField
          sx={{ marginBottom: "16px" }}
          fullWidth
          size="small"
          id="cloneAssessment"
          label="Clone Assessment Name"
          name="assessmentTitle"
          value={formik.values.assessmentTitle}
          onChange={formik.handleChange}
          error={
            formik.touched.assessmentTitle &&
            Boolean(formik.errors.assessmentTitle)
          }
          helperText={
            formik.touched.assessmentTitle && formik.errors.assessmentTitle
          }
          required
        />
      </CommonModal>

      <CommonModal
        icon={"/assets/clone.svg"}
        handleClose={() => setConfirmOpen(false)}
        open={confirmOpen}
        title={"Clone Assessment"}
        showFooter={false}
      >
        <Typography
          style={{
            backgroundColor: "#edf7ed",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <img
            src="/assets/success.svg"
            alt="Alert"
            style={{
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "10px",
              marginBottom: "15px",
            }}
          />
          <strong>Cloning Your Assessment</strong>
          <br />
          <div style={{ verticalAlign: "middle" }}>
            As we proceed with the cloning process, <br /> you will see the
            Cloned Assessment in your card after it is successfully cloned.
          </div>
        </Typography>
      </CommonModal>

      <CommonModal
        icon={"/assets/users.svg"}
        open={confirmOpen}
        title={"Add Test Admins"}
        onSubmit={handleTestAdminModalOpen}
        sendButtonName={"Add Admin"}
        isScrollable={true}
        handleClose={() => {
          setConfirmOpen(false);
          setdropDowAdminValue("All");
          setSelectedAdminRows([]);
        }}
        modalActions={
          <div
            style={{
              display: "flex",
              alignItems: "center", // Align items vertically in the center
              justifyContent: "space-between", // Space between the elements
              width: "100%", // Ensure the container takes full width
            }}
          >
            <SearchBar
              placeholder="Search Admin"
              style={{ flex: 1 }} // Use flex to allow it to take available space
              value={searchAdminValue}
              onChange={handleAdminSearch}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
                flex: 1,
                justifyContent: "flex-end",
              }}
            >
              <div style={{ marginRight: "10px" }}>Channel:</div>
              <TextField
                value={channelName}
                disabled={true}
                sx={{
                  height: "37px",
                  "& .MuiOutlinedInput-root": {
                    height: "100%",
                  },
                }}
              />
            </div>
          </div>
        }
      >
        <CustomTable
          tableHeaderCells={tableHeaderCells()}
          tableHeaderCellStyleProps={tableHeaderCellStyleProps}
          tableBodyContent={tableBodyContent()}
          tableData={tableData}
          tableBodyRowStyleProps={tableBodyRowStyleProps}
          sortRows={() => {}}
          useOverflow={true}
          hideFooter
        />
      </CommonModal>
    </>
  );
}

export default AnalyticsHeader;
