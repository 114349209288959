import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import TabsList from "../../components/candidateShortlisted/Tabs";
import Analytics from "../../components/assessmentAnalytics/Analytics";
import Candidates from "./Candidates";
import Questions from "./Questions";
import { breadcrumbsContext } from "../../context/breadcrumbsContext";
import { PATH_DASHBOARD } from "../../routes/paths";
import AnalyticsHeader from "../../components/assessmentAnalytics/AnalyticsHeader";
import TestOverview from "./TestOverview";
import APICall from "../../utils/api";
import { useTheme } from "@mui/material/styles";
import { AuthContext } from "../../context/authContext";
import ShortlistedCandidates from "../ShortlistedCandidates";

function AssessmentsAnalytics() {
  const { setBreadcrumbsData } = useContext(breadcrumbsContext);
  const { authUser } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  // const { assessmentId } = location.state;
  const [assessmentDetails, setAssessmentDetails] = useState();
  const [section, setSection] = useState("section_wise");
  const [topics, setTopics] = useState("all");

  const [assessmentId, setAssessmentId] = useState(null);
  const [testTaken, setTestTaken] = useState(null);

  useEffect(() => {
    if (location.state && location.state.assessmentId) {
      setAssessmentId(location.state.assessmentId);
    } else {
      const queryParams = new URLSearchParams(location.search);
      const idFromQueryParams = queryParams.get("actionId");
      const testTakenueryParams = queryParams.get("testTaken");
      if (idFromQueryParams) {
        setAssessmentId(idFromQueryParams);
      }
      if (testTakenueryParams) {
        setTestTaken(testTakenueryParams);
      }
    }
  }, [location]);

  const handleTopicsChange = (event) => {
    const { value } = event.target;
    if (event.target.name === "section") {
      setSection(value);
    } else if (event.target.name === "topics") {
      setTopics(value);
    }
  };

  const fetchAssessmentDetails = async () => {
    try {
      const res = await APICall(`/assessment/${assessmentId}`);
      setAssessmentDetails(res.data);
    } catch (error) {
      console.error("Failed to fetch assessment details:", error);
    }
  };

  const tabNames = {
    testoverview: "Test Overview",
    questions: "Questions",
    candidates: "Candidates",
    analytics: "Analytics",
  };

  if (authUser?.role === "super admin" || authUser?.role === "hr") {
    tabNames.shortlisted = "Shortlisted";
  }

  // Define all possible tab items
  const allTabItems = [
    { label: "Test Overview", value: "testoverview" },
    ...(authUser?.role !== "hr"
      ? [
          {
            label: `Questions (${assessmentDetails?.totalNoOfQuestions || 0})`,
            value: "questions",
          },
          { label: "Analytics", value: "analytics" },
          {
            label: `Candidates (${
              assessmentDetails?.candidateStats?.invited || 0
            })`,
            value: "candidates",
          },
        ]
      : []),
    ...(authUser?.role === "super admin" || authUser?.role === "hr"
      ? [
          {
            label: `Shortlisted (${
              assessmentDetails?.candidateStats?.shortlisted || 0
            })`,
            value: "shortlisted",
          },
        ]
      : []),
  ];

  const [currentTab, setCurrentTab] = React.useState(allTabItems[0].value);

  useEffect(() => {
    if (location.state && location.state.tabValue) {
      setCurrentTab(location.state.tabValue);
      location.state.tabValue = null;
    }
  }, [location.state]);

  useEffect(() => {
    if (testTaken) {
      setCurrentTab("candidates");
    }
  }, [testTaken]);

  useEffect(() => {
    setBreadcrumbsData([
      { name: "Assessments", href: PATH_DASHBOARD.assessments },
      {
        name: assessmentDetails?.name,
        href: "/assessments/analytics",
        state: {
          tabValue: "testoverview",
        },
      },
      { name: tabNames[currentTab] },
    ]);
  }, [currentTab, assessmentDetails, assessmentId]);

  useEffect(() => {
    if (assessmentId) {
      fetchAssessmentDetails();
    }
  }, [assessmentId]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        background: "#F2F4F8",
        display: "flex",
      }}
    >
      <Box sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            height: "100%",
            background: "#F2F4F8",
            width: "100%",
          }}
        >
          <Box sx={{ my: "10px" }}>
            <AnalyticsHeader
              assessmentStats={assessmentDetails?.candidateStats}
              assessment={assessmentDetails}
            />
          </Box>

          <Box
            sx={{
              width: "100%",
              margin: "16px 0",
              background: "#fff",
              padding: "0.5rem 1rem",
              border: "1px solid #E8E9EE",
              borderRadius: "0.25rem",
              marginTop: "6px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-between",
                gap: "16px",
              }}
            >
              <TabsList
                value={currentTab}
                tabs={allTabItems}
                handleTabChange={setCurrentTab}
              />
              {currentTab === "questions" && authUser.role === "recruiter" && (
                <Stack direction={"row"} gap={"16px"}>
                  <SelectComponent
                    value={section}
                    onChange={handleTopicsChange}
                    options={[
                      { value: "section_wise", label: "Section Wise" },
                      { value: "skill_wise", label: "Skills Wise" },
                    ]}
                    name="section"
                  />
                  <SelectComponent
                    value={topics}
                    onChange={handleTopicsChange}
                    options={[
                      { value: "all", label: "All" },
                      { value: "mcq", label: "MCQ" },
                      { value: "programming", label: "Programming" },
                      { value: "sql", label: "SQL" },
                    ]}
                    name="topics"
                  />
                </Stack>
              )}
            </Box>
            <Box>
              {currentTab === "testoverview" && (
                <TestOverview
                  assessmentDetails={assessmentDetails}
                  fetchAssessmentDetails={fetchAssessmentDetails}
                />
              )}
              {currentTab === "questions" && (
                <Questions assessmentId={assessmentId} topic={topics} />
              )}
              {currentTab === "candidates" && (
                <Candidates
                  assessmentId={assessmentId}
                  candidateStats={assessmentDetails?.candidateStats}
                  fetchAssessment={fetchAssessmentDetails}
                />
              )}
              {currentTab === "analytics" && (
                <Analytics assessmentId={assessmentId} />
              )}
              {currentTab === "shortlisted" && (
                <ShortlistedCandidates
                  assessmentId={assessmentId}
                  candidateStats={assessmentDetails?.candidateStats}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default AssessmentsAnalytics;

const SelectComponent = ({ value, onChange, options, name }) => {
  const theme = useTheme();
  return (
    <FormControl sx={{ minWidth: "154px" }}>
      <Select
        value={value}
        onChange={onChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        sx={{
          height: "40px",
        }}
        name={name}
        color="secondary"
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{
              "&.Mui-selected": {
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.secondary.main,
              },
              "&:hover": {
                backgroundColor: theme.palette.secondary.light,
              },
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
