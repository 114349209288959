import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

import { Box, Collapse, Link, Typography } from "@mui/material";
import NavItem from "./NavItem";
import useActiveLink from "../hooks/useActiveLink";

// ----------------------------------------------------------------------

NavList.propTypes = {
  data: PropTypes.object,
  depth: PropTypes.number,
  hasChild: PropTypes.bool,
};

export default function NavList({ data, depth, hasChild }) {
  const { pathname } = useLocation();
  const authUser = localStorage.getItem("authUser")
    ? JSON.parse(localStorage.getItem("authUser"))
    : null;

  const { active, isExternalLink } = useActiveLink(data.path);
  const [open, setOpen] = useState(active);
  const [expand, setExpand] = useState(false);

  const [isCalendarInterviewsActive, setIsCalendarInterviewsActive] =
    useState(true);

  const handleToggle = () => {
    setOpen(!open);
    if (pathname.includes("/interviews")) {
      setExpand(!expand);
    } else {
      setExpand(false);
    }
  };
  const handleInterviewsToggle = () => {
    if (pathname.includes("/interviews")) {
      setExpand(!expand);
    }
  };

  useEffect(() => {
    if (!active) {
      handleClose();
    }
    pathname === "/interviews/recruiter_calender"
      ? setIsCalendarInterviewsActive(true)
      : setIsCalendarInterviewsActive(false);
  }, [pathname]);

  const handleClose = () => {
    setOpen(false);
  };

  const InterviewsOption = ({}) => {
    const itemData = { ...data, path: data.child[0].path };
    return (
      <Box
        sx={{
          border:
            active && expand ? "1px solid #F2F4F8" : "1px solid transparent",
          borderRadius: "4px",
          overflow: "hidden",
        }}
      >
        <NavItem
          item={itemData}
          depth={depth}
          open={open}
          active={active}
          isExternalLink={isExternalLink}
          onClick={() => {
            handleToggle();
            if (pathname.includes("interviews")) {
              handleInterviewsToggle();
            }
            // setExpand(!expand);
          }}
          isExpanded={expand}
          role={authUser?.role}
        />
        {/* calendar */}
        {expand && active && (
          <Link component={RouterLink} to={data.child[0].path} underline="none">
            <Box
              sx={{
                padding: "8px 16px",
                height: "40px",
                backgroundColor: isCalendarInterviewsActive
                  ? "#D5F9F1"
                  : "transparent",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                position: "relative",
                overflow: "hidden",
                cursor: "pointer",
              }}
            >
              {isCalendarInterviewsActive ? (
                <Box
                  sx={{
                    width: "8px",
                    height: "8px",
                    backgroundColor: "#009D7B",
                    borderRadius: "50%",
                  }}
                />
              ) : (
                <Box
                  sx={{
                    position: "absolute",
                    top: "0px",
                    left: "20px",
                    width: "18px",
                    height: "40px",
                    borderLeft: "1px solid #E8E9EE",
                  }}
                >
                  <Box
                    sx={{
                      width: "17px",
                      height: "20px",
                      borderBottom: "1px solid #E8E9EE",
                    }}
                  ></Box>
                </Box>
              )}

              <Typography
                sx={{
                  color: isCalendarInterviewsActive ? "#009D7B" : "#363E45",
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontWeight: isCalendarInterviewsActive ? "600" : "400",
                  fontFamily: "Nunito",
                  padding: "2px 8px",
                  paddingLeft: isCalendarInterviewsActive ? "10px" : "28px",
                }}
              >
                {data.child[0].title}
              </Typography>
            </Box>
          </Link>
        )}
        {/* all interviews */}
        {expand && active && (
          <Link component={RouterLink} to={data.child[1].path} underline="none">
            <Box
              sx={{
                padding: "8px 16px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                position: "relative",
                overflow: "hidden",
                backgroundColor: !isCalendarInterviewsActive
                  ? "#D5F9F1"
                  : "transparent",
                cursor: "pointer",
              }}
            >
              {!isCalendarInterviewsActive ? (
                <Box
                  sx={{
                    width: "8px",
                    height: "8px",
                    backgroundColor: "#009D7B",
                    borderRadius: "50%",
                  }}
                />
              ) : (
                <Box
                  sx={{
                    position: "absolute",
                    top: "-2px",
                    left: "20px",
                    width: "18px",
                    height: "21px",
                    borderLeft: "1px solid #E8E9EE",
                    borderBottom: "1px solid #E8E9EE",
                  }}
                />
              )}

              <Typography
                sx={{
                  color: !isCalendarInterviewsActive ? "#009D7B" : "#363E45",
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontWeight: !isCalendarInterviewsActive ? "600" : "400",
                  fontFamily: "Nunito",
                  padding: "0px 8px",
                  paddingLeft: !isCalendarInterviewsActive ? "10px" : "24px",
                }}
              >
                {data.child[1].title}
              </Typography>
            </Box>
          </Link>
        )}
      </Box>
    );
  };

  return (
    <>
      {/* for interviews of super admin role */}
      {(authUser?.role === "recruiter" || authUser?.role === "hr") &&
      data.title === "Interviews" ? (
        <InterviewsOption />
      ) : (
        <NavItem
          item={data}
          depth={depth}
          open={open}
          active={active}
          isExternalLink={isExternalLink}
          onClick={handleToggle}
          role={authUser?.role}
        />
      )}

      {hasChild && (
        <Collapse in={open} unmountOnExit>
          <NavSubList data={data.children} depth={depth} />
        </Collapse>
      )}
    </>
  );
}

NavSubList.propTypes = {
  data: PropTypes.array,
  depth: PropTypes.number,
};

function NavSubList({ data, depth }) {
  return (
    <>
      {data.map((list) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={depth + 1}
          hasChild={!!list.children}
        />
      ))}
    </>
  );
}
