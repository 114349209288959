import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import TabsList from "../candidateShortlisted/Tabs";
import { assessments_analytics_tab_list } from "../../utils/data";
import TestAnalytics from "./TestAnalytics";
import QuestionsAnalytics from "./QuestionsAnalytics";
import { breadcrumbsContext } from "../../context/breadcrumbsContext";
import { useLocation } from "react-router-dom";
import APICall from "../../utils/api";
import SkillAnalytics from "./skillAnalytics/SkillAnalytics";

function Analytics({ assessmentId }) {
  const [value, setTabValue] = React.useState(
    assessments_analytics_tab_list[0]?.value
  );
  const { setBreadcrumbsData } = useContext(breadcrumbsContext);

  useEffect(() => {
    const currentTab = assessments_analytics_tab_list.find(
      (tab) => tab.value === value
    );

    setBreadcrumbsData((breadcrumbs) => [
      ...breadcrumbs.slice(0, 3), // Keep the first two levels of breadcrumbs
      { name: currentTab.label },
    ]);
  }, [value, setBreadcrumbsData]);

  return (
    <Box>
      {/* tabs */}
      <TabsList
        tabs={assessments_analytics_tab_list}
        handleTabChange={setTabValue}
        value={value}
        type={"secondary"}
      />
      {value === "test_analytics" && (
        <TestAnalytics assessmentId={assessmentId} />
      )}
      {value === "question_analytics" && (
        <QuestionsAnalytics assessmentId={assessmentId} />
      )}
      {value === "skill_analytics" && (
        <SkillAnalytics assessmentId={assessmentId} />
      )}
    </Box>
  );
}

export default Analytics;
