import React from "react";
import { Tabs, Tab, Box } from "@mui/material";

const TabsList = ({
  tabs,
  handleTabChange,
  height,
  value,
  type = "primary",
}) => {
  const color = type === "primary" ? "#00C49A" : "#ff6812";
  const justifyContent = type === "primary" ? "flex-start" : "space-between";
  const bgColor = type === "primary" ? "none" : "#fff0e7";
  const indicatorColor = type === "primary" ? color : "none";

  const handleChange = (event, newValue) => {
    handleTabChange(newValue);
  };

  return (
    <Box
      sx={{
        background: type === "secondary" ? "#F9F9F9" : "transparent",
        borderBottom:
          type === "secondary" ? "2px solid #E8E9EE" : "1px solid #E8E9EE",
        marginTop: type === "secondary" ? "8px" : 0,
        width: "100%",
        boxShadow:
          type === "secondary" ? "0px 2px 4px rgba(0, 0, 0, 0.1)" : "none",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor={indicatorColor}
        aria-label="secondary tabs example"
        sx={{
          minHeight: height,
          height: height,
          overflow: "auto",
          "& .MuiTabs-flexContainer": {
            justifyContent,
          },
          "& .MuiTabs-scroller": {
            overflowX: "auto !important",
            width: "100%",
          },
          "& .MuiTab-root": {
            lineHeight: "20px",
            fontSize: "14px",
            fontWeight: "400",
            color: "#71777B",
            borderBottom: "none",
            flex: type === "secondary" ? "1 1 20%" : "0 1 auto",
            "&.Mui-selected": {
              color,
              borderBottom: `1px solid ${color}`,
              fontWeight: "600",
              backgroundColor: bgColor,
            },
          },
        }}
      >
        {tabs?.map((tab) => (
          <Tab
            key={tab.value}
            value={tab.value}
            label={tab.label}
            sx={{
              textTransform: "capitalize",
              minHeight: height,
              height: height,
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default TabsList;
