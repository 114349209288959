import React, { Suspense, lazy, useState, useEffect } from "react";
import LoadingScreen from "../layouts/LoadingScreen";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

export const Dashboard = Loadable(lazy(() => import("../pages/Dashboard")));
export const JobOpening = Loadable(lazy(() => import("../pages/JobOpening")));
export const Assessments = Loadable(
  lazy(() => import("../pages/assessments/Assessments"))
);
export const Library = Loadable(lazy(() => import("../pages/Library")));
export const Candidates = Loadable(lazy(() => import("../pages/Candidates")));
export const Interviews = Loadable(lazy(() => import("../pages/Interviews")));
export const RecruiterCalendar = Loadable(
  lazy(() => import("../pages/interviews/RecruiterCalendar"))
);
export const TalentPool = Loadable(lazy(() => import("../pages/TalentPool")));

export const AssessmentCandidateDetails = Loadable(
  // lazy(() => import("../pages/assessments/AssessmentsAnalytics"))
  lazy(() => import("../pages/TalentPoolSection"))
);
export const InterviewCandidateDetails = Loadable(
  // lazy(() => import("../pages/assessments/AssessmentsAnalytics"))
  lazy(() => import("../pages/TalentPoolSection"))
);

export const CandidatesDetails = Loadable(
  lazy(() => import("../pages/TalentPoolSection"))
);

export const Round1Preview = Loadable(
  lazy(() => import("../pages/Round1Preview"))
);

export const Settings = Loadable(lazy(() => import("../pages/Settings")));
export const Login = Loadable(lazy(() => import("../pages/auth/Login")));
export const Account = Loadable(lazy(() => import("../pages/Account")));
export const ContactSupport = Loadable(
  lazy(() => import("../pages/ContactSupport"))
);
export const AccountPage = Loadable(lazy(() => import("../pages/AccountPage")));
export const ShortlistedCandidates = Loadable(
  lazy(() => import("../pages/ShortlistedCandidates"))
);
export const ViewQuestions = Loadable(
  lazy(() => import("../pages/ViewQuestions"))
);
export const ViewSkillQuestions = Loadable(
  lazy(() => import("../pages/ViewSkillQuestions"))
);
export const TalentPoolCandidateDetails = Loadable(
  lazy(() => import("../pages/TalentPoolSection"))
);
export const Questions = Loadable(lazy(() => import("../pages/Questions")));
export const LockScreen = Loadable(
  lazy(() => import("../pages/auth/LockScreen"))
);
export const JobDetail = Loadable(lazy(() => import("../pages/JobDetail")));
export const ForgetPassword = Loadable(
  lazy(() => import("../pages/auth/ForgetPassword"))
);
export const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);
export const AutoTest = Loadable(
  lazy(() => import("../pages/assessments/AutoTest"))
);
export const CustomTest = Loadable(
  lazy(() => import("../pages/assessments/CustomTest"))
);
export const SendInvites = Loadable(lazy(() => import("../pages/SendInvites")));

export const ChooseQueLibrary = Loadable(
  lazy(() => import("../pages/ChooseQueLibrary"))
);

export const PublishedTest = Loadable(
  lazy(() => import("../pages/PublishTest"))
);
export const ChatBot = Loadable(lazy(() => import("../pages/ChatBot")));
