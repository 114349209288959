import {
  Box,
  Stack,
  Typography,
  Button,
  Tooltip,
  TextField,
  MenuItem,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { HiOutlineBriefcase } from "react-icons/hi";
import { LuUsers } from "react-icons/lu";
import { styled } from "@mui/system";
import { getColorByStatus, capitalizeWords } from "../../utils";
import PingModal from "../pingModal";
import { AuthContext } from "../../context/authContext";
import { usePingContext } from "../../context/pingContext";
import DrawerSlide from "./drawer/DrawerSlide";
import DrawerHeader from "./drawer/DrawerHeader";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import * as Yup from "yup";
import CommonModal from "../modal/CommonModal";
import APICall from "../../utils/api";
import dayjs from "dayjs";

const CandidateName = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "20px",
  fontFamily: "Roboto",
  color: "#313131",
}));

const CandidateEmail = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "14px",
  fontFamily: "Roboto",
  color: "#535A5F",
  height: "16px",
}));

const Round2CandidateCard = ({ data }) => {
  const { authUser } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const {
    openMemberModal,
    handlePingModalOpen,
    handlePingModalCloseAddOrEdit,
    handlePingClick,
  } = usePingContext();
  const [open, setOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [scheduleModal, setScheduleModal] = useState(false);
  const [interviewers, setInterviewers] = useState([]);
  const [channels, setChannels] = useState([]);
  const [seniorityLevels, setSeniorityLevels] = useState([]);

  const handleScheduleDrawer = () => {
    setOpen(true);
  };

  const handleConfirmSchedule = () => {
    setScheduleModal(false);
    setOpen(false);
    setSuccessModal(true);
  };

  const fetchSeniorityLevels = async (channelId) => {
    try {
      let url = `/channel/${channelId}`;
      const response = await APICall(url);
      setSeniorityLevels(response.data.seniorityLevels);
    } catch (error) {
      console.error("Error fetching seniority levels:", error);
    }
  };

  const handleAvailabilitySlots = async (selectDate, startTime, endTime) => {
    if (!selectDate || !startTime || !endTime) {
      console.error("Invalid date or time");
      return;
    }

    const startDateTime = dayjs(selectDate)
      .hour(startTime.hour())
      .minute(startTime.minute());
    const endDateTime = dayjs(selectDate)
      .hour(endTime.hour())
      .minute(endTime.minute());

    const start = startDateTime.format("YYYY-MM-DDTHH:mm:ss");
    const end = endDateTime.format("YYYY-MM-DDTHH:mm:ss");

    try {
      const response = await APICall(
        `/availabilitySlots?start=${start}&end=${end}`
      );
      const availableInterviewers = response?.data?.slots?.documents
        .filter((document) => !document.timeRanges[0].isBooked)
        .map((document) => {
          return {
            interviewerId: document?.interviewerId?._id,
            name: document?.interviewerId?.name,
            slotId: document?.timeRanges[0]?._id,
          };
        });
      setInterviewers(availableInterviewers);
    } catch (error) {
      console.error("Error fetching available interviewers:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: data?.candidateId.name || "",
      email: data?.candidateId.email || "",
      code: data?.candidateId.code || "",
      mobile: data?.candidateId.mobile || "",
      selectDate: null,
      startTime: null,
      endTime: null,
      channel: "",
      round: "HR Round",
      seniority: "",
      interviewer: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      code: Yup.string().required("Country code is required"),
      mobile: Yup.string()
        .matches(/^[0-9]+$/, "Mobile number must be only digits")
        .length(10, "Mobile number must be exactly 10 digits")
        .required("Mobile number is required"),
      selectDate: Yup.date().nullable().required("Select date is required"),
      startTime: Yup.date().nullable().required("Start time is required"),
      endTime: Yup.date().nullable().required("End time is required"),
      channel: Yup.string().required("Department is required"),
      seniority: Yup.string().required("Seniority level is required"),
      interviewer: Yup.string().required("Interviewer is required"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (e) => {
    console.log(e, "Values");
    setScheduleModal(true);
    setSuccessModal(false);
  };

  const handleModelClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  return (
    <Stack
      direction={{ xs: "column", lg: "row" }}
      alignItems={"center"}
      sx={{
        padding: "16px",
        backgroundColor: "#FFFFFF",
        gap: "12px",
        border: "1px solid #E8E9EE",
        borderRadius: "4px",
        minHeight: "100px",
      }}
    >
      <Stack
        direction={"column"}
        alignItems={"flex-start"}
        gap={"12px"}
        flexGrow={1}
        sx={{ padding: 0, gap: "12px" }}
      >
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"12px"}
        >
          <Box
            component={"img"}
            src={data?.candidateProfilePicture}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/assets/user.png";
            }}
            sx={{ borderRadius: "50%" }}
            width={"40px"}
            height={"40px"}
          />

          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            gap="4px"
            sx={{ gap: "4px" }}
          >
            <CandidateName
              onClick={() =>
                navigate(`/assessments/candidateDetails`, {
                  state: {
                    candidateAssessmentId: data?.candidateAssessmentId,
                    assessmentId: data?.assessmentId?._id,
                    assessmentDetails: data?.assessmentId,
                  },
                })
              }
              style={{ cursor: "pointer", textDecoration: "none" }}
            >
              {data?.candidateId?.name}
            </CandidateName>

            <CandidateEmail>{data?.candidateId?.email}</CandidateEmail>
          </Stack>
        </Stack>

        <Stack direction={"row"} alignItems={"flex-start"} gap={"12px"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={"8px"}
            sx={{ textWrap: "nowrap" }}
          >
            <Box
              width={"16px"}
              height={"16px"}
              color={"#69778A"}
              sx={{ color: "#69778A" }}
            >
              <HiOutlineBriefcase size={16} color="#69778A" />
            </Box>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: " 12px",
                lineHeight: "16px",
                color: "#535A5F",
              }}
            >
              {capitalizeWords(data?.jobRoleDetails?.jobRole)}
            </Typography>
          </Stack>
          {data?.currentStatus !== "R2 Passed" && (
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={"8px"}
              sx={{ textWrap: "nowrap" }}
            >
              <Tooltip
                title={
                  authUser?.role === "hr" &&
                  data?.assessmentId?.testAssignedHrId === authUser._id
                    ? "Interviewed by"
                    : "Scheduled by"
                }
                placement="bottom"
                arrow
              >
                <Box width={"16px"} height={"16px"}>
                  <LuUsers size={16} color="#69778A" />
                </Box>
              </Tooltip>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#535A5F",
                }}
              >
                {authUser?.role === "hr" &&
                data?.assessmentId?.testAssignedHrId === authUser._id &&
                (data?.InterviewDetails?.round2?.Interviewer?.name ||
                  data?.InterviewDetails?.round1?.Interviewer?.name ||
                  data?.InterviewDetails?.round2?.Scheduler?.name ||
                  data?.InterviewDetails?.round1?.Scheduler?.name) ? (
                  <>
                    {data?.InterviewDetails?.round2?.Interviewer?.name
                      ? data.InterviewDetails.round2.Interviewer.name
                      : data?.InterviewDetails?.round1?.Interviewer?.name
                      ? data.InterviewDetails.round1.Interviewer.name
                      : data?.InterviewDetails?.round2?.Scheduler?.name
                      ? data.InterviewDetails.round2.Scheduler.name
                      : data?.InterviewDetails?.round1?.Scheduler?.name}
                  </>
                ) : (
                  <>
                    {data?.InterviewDetails?.round2?.Scheduler?.name
                      ? data.InterviewDetails.round2.Scheduler.name
                      : data?.InterviewDetails?.round1?.Scheduler?.name}
                  </>
                )}
              </Typography>
              <Tooltip
                title={
                  authUser?.role === "hr" &&
                  data?.assessmentId?.testAssignedHrId === authUser._id &&
                  (data?.InterviewDetails?.round2?.Interviewer?.name ||
                    data?.InterviewDetails?.round1?.Interviewer?.name)
                    ? data?.InterviewDetails?.round2?.Interviewer?.name
                      ? `Ping Interviewer`
                      : `Ping Interviewer`
                    : "Ping Scheduler"
                }
                arrow
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePingModalOpen();
                  }}
                >
                  <img src="/assets/Vector-5.svg" alt="Publisher" />
                </Box>
              </Tooltip>
            </Stack>
          )}
        </Stack>
      </Stack>

      {/* right section */}
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={"10px"}
        // divider={<Divider orientation="vertical" flexItem />}
      >
        <Stack
          direction={"column"}
          alignItems={"flex-start"}
          gap={"2px"}
          sx={{ padding: "10px", borderRight: "1px solid #E8E9EE" }}
        >
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "32px",
              color: "#333333",
            }}
          >
            {data?.assessmentId?.totalScore && data?.totalObtainedScore
              ? (
                  (data.totalObtainedScore / data.assessmentId.totalScore) *
                  100
                ).toFixed(0)
              : "0"}
            %
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "16px",
              color: "#535A5F",
              textWrap: "nowrap",
            }}
          >
            Test Score
          </Typography>
        </Stack>

        <Stack
          direction={"row"}
          alignItems={"flex-start"}
          gap={"2px"}
          justifyContent={"center"}
          sx={{
            padding: "4px 12px",
            gap: "4px",
            backgroundColor:
              authUser?.role === "hr" && data?.currentStatus === "R2 Passed"
                ? "inherit"
                : getColorByStatus(data?.currentStatus).bg,
            borderRadius: "20px",
          }}
        >
          <Tooltip
            title={data?.rejectionReason ? data.rejectionReason : ""}
            arrow
          >
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
                color:
                  authUser?.role !== "hr"
                    ? getColorByStatus(data?.currentStatus).color
                    : "inherit",
                textWrap: "nowrap",
              }}
            >
              <>
                {authUser?.role === "hr" &&
                data?.currentStatus === "R2 Passed" &&
                data?.assessmentId?.testAssignedHrId === authUser._id ? (
                  <Button
                    variant="contained"
                    onClick={handleScheduleDrawer}
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#fff",
                      textWrap: "nowrap",
                    }}
                  >
                    Schedule Interview for HR
                  </Button>
                ) : data?.currentStatus === "R2 Passed" ? (
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: getColorByStatus("R2 Passed").color,
                      backgroundColor: getColorByStatus("R2 Passed").bg,
                      padding: "8px 18px",
                      borderRadius: "20px",
                      textAlign: "center",
                    }}
                  >
                    R2 Passed
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: getColorByStatus(data?.currentStatus).color,
                      backgroundColor: getColorByStatus(data?.currentStatus).bg,
                      padding: "4px 8px",
                      borderRadius: "20px",
                      textAlign: "center",
                    }}
                  >
                    {data?.currentStatus}
                  </Typography>
                )}

                {/* Drawer component */}
                <DrawerSlide toggleDrawer={open} setToggleDrawer={setOpen}>
                  <DrawerHeader
                    headingText="Schedule Interview"
                    setToggleDrawer={setOpen}
                  />
                  <Stack
                    sx={{ padding: "8px 16px 16px" }}
                    alignSelf={"stretch"}
                  >
                    <Stack
                      direction="column"
                      gap={
                        Object.keys(formik.errors).length > 0 ? "8px" : "16px"
                      }
                    >
                      <Box
                        sx={{
                          width: "100%",
                          bgcolor: "#00c49a",
                          height: "50px",
                          borderRadius: "6px",
                          alignContent: "center",
                          padding: "14px",
                        }}
                      >
                        <Typography
                          sx={{
                            whiteSpace: "nowrap",
                            fontSize: "16px",
                            fontWeight: 600,
                            lineHeight: "24px",
                            color: "#fff",
                            fontFamily: "Nunito",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <img
                            src="/assets/reportAnalyticWhite.svg"
                            alt="invite"
                          />
                          {data?.assessmentId?.name}
                        </Typography>
                      </Box>
                      <Box>
                        <TextField
                          fullWidth
                          size="small"
                          label={
                            <span>
                              Name
                              <span style={{ color: "red", marginLeft: "4px" }}>
                                *
                              </span>
                            </span>
                          }
                          name="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.name && Boolean(formik.errors.name)
                          }
                          helperText={formik.touched.name && formik.errors.name}
                          sx={{ "& .MuiInputBase-root": { height: "48px" } }}
                        />
                      </Box>
                      <Box>
                        <TextField
                          fullWidth
                          size="small"
                          label={
                            <span>
                              Email
                              <span style={{ color: "red", marginLeft: "4px" }}>
                                *
                              </span>
                            </span>
                          }
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                          sx={{ "& .MuiInputBase-root": { height: "48px" } }}
                        />
                      </Box>
                      <Stack direction="row" gap="16px">
                        <Box flex={0.5}>
                          <TextField
                            fullWidth
                            size="small"
                            select
                            label={
                              <span>
                                Code
                                <span
                                  style={{ color: "red", marginLeft: "4px" }}
                                >
                                  *
                                </span>
                              </span>
                            }
                            name="code"
                            value={formik.values.code}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.code && Boolean(formik.errors.code)
                            }
                            helperText={
                              formik.touched.code && formik.errors.code
                            }
                            sx={{ "& .MuiInputBase-root": { height: "48px" } }}
                            defaultValue={
                              formik.values.code ? formik.values.code : "+91"
                            }
                          >
                            <MenuItem value="+1">+1 (US)</MenuItem>
                            <MenuItem value="+44">+44 (UK)</MenuItem>
                            <MenuItem value="+91">+91 (India)</MenuItem>
                          </TextField>
                        </Box>
                        <Box flex={2}>
                          <TextField
                            fullWidth
                            size="small"
                            label={
                              <span>
                                Mobile Number
                                <span
                                  style={{ color: "red", marginLeft: "4px" }}
                                >
                                  *
                                </span>
                              </span>
                            }
                            name="mobile"
                            value={formik.values.mobile}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.mobile &&
                              Boolean(formik.errors.mobile)
                            }
                            helperText={
                              formik.touched.mobile && formik.errors.mobile
                            }
                            sx={{ "& .MuiInputBase-root": { height: "48px" } }}
                          />
                        </Box>
                      </Stack>
                      <Stack direction="row" gap="16px">
                        <Box flex={1}>
                          <DatePicker
                            label={
                              <span>
                                Select Date
                                <span
                                  style={{ color: "red", marginLeft: "4px" }}
                                >
                                  *
                                </span>
                              </span>
                            }
                            value={formik.values.selectDate}
                            sx={{ width: "355px" }}
                            format="DD/MM/YYYY"
                            onChange={(value) => {
                              formik.setFieldValue("selectDate", value);
                              formik.setFieldValue("startTime", null);
                              formik.setFieldValue("endTime", null);
                              formik.setFieldValue("interviewer", "");
                              setInterviewers([]);
                            }}
                            shouldDisableDate={(date) =>
                              date < new Date().setHours(0, 0, 0, 0)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                size="small"
                                error={
                                  formik.touched.selectDate &&
                                  Boolean(formik.errors.selectDate)
                                }
                                helperText={
                                  formik.touched.selectDate &&
                                  formik.errors.selectDate
                                }
                                sx={{
                                  "& .MuiInputBase-root": { height: "48px" },
                                }}
                              />
                            )}
                          />
                        </Box>
                      </Stack>
                      <Stack direction="row" gap="16px">
                        <Box flex={1}>
                          <TimePicker
                            label={
                              <span>
                                Start Time
                                <span
                                  style={{ color: "red", marginLeft: "4px" }}
                                >
                                  *
                                </span>
                              </span>
                            }
                            value={formik.values.startTime}
                            sx={{ width: "355px" }}
                            onChange={(value) => {
                              formik.setFieldValue("startTime", value);
                              const endTime = dayjs(value).add(1, "hour");
                              formik.setFieldValue("endTime", endTime);
                              formik.setFieldValue("interviewer", "");
                              setInterviewers([]);
                              handleAvailabilitySlots(
                                formik.values.selectDate,
                                value,
                                endTime
                              );
                            }}
                            shouldDisableTime={(timeValue, clockType) => {
                              const now = new Date();
                              const selectedDate = formik.values.selectDate
                                ? new Date(formik.values.selectDate)
                                : new Date();
                              if (clockType === "hours") {
                                return (
                                  selectedDate.toDateString() ===
                                    now.toDateString() &&
                                  timeValue < now.getHours()
                                );
                              }
                              if (clockType === "minutes") {
                                return (
                                  selectedDate.toDateString() ===
                                    now.toDateString() &&
                                  timeValue < now.getMinutes()
                                );
                              }
                              return false;
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                size="small"
                                error={
                                  formik.touched.startTime &&
                                  Boolean(formik.errors.startTime)
                                }
                                helperText={
                                  formik.touched.startTime &&
                                  formik.errors.startTime
                                }
                                sx={{
                                  "& .MuiInputBase-root": { height: "48px" },
                                }}
                                disabled={!formik.values.selectDate}
                              />
                            )}
                          />
                        </Box>
                        <Box flex={1}>
                          <TimePicker
                            label={
                              <span>
                                End Time
                                <span
                                  style={{ color: "red", marginLeft: "4px" }}
                                >
                                  *
                                </span>
                              </span>
                            }
                            value={formik.values.endTime}
                            sx={{ width: "355px" }}
                            onChange={(value) => {
                              formik.setFieldValue("endTime", value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                size="small"
                                error={
                                  formik.touched.endTime &&
                                  Boolean(formik.errors.endTime)
                                }
                                helperText={
                                  formik.touched.endTime &&
                                  formik.errors.endTime
                                }
                                sx={{
                                  "& .MuiInputBase-root": { height: "48px" },
                                }}
                                disabled
                              />
                            )}
                          />
                        </Box>
                      </Stack>
                      <Box>
                        <TextField
                          fullWidth
                          size="small"
                          select
                          label={
                            <span>
                              Department
                              <span style={{ color: "red", marginLeft: "4px" }}>
                                *
                              </span>
                            </span>
                          }
                          name="channel"
                          value={formik.values.channel}
                          onChange={(e) => {
                            formik.handleChange(e);
                            fetchSeniorityLevels(e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.channel &&
                            Boolean(formik.errors.channel)
                          }
                          helperText={
                            formik.touched.channel && formik.errors.channel
                          }
                          sx={{ "& .MuiInputBase-root": { height: "48px" } }}
                        >
                          {channels.map((channel) => (
                            <MenuItem key={channel._id} value={channel._id}>
                              {channel.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                      <Box>
                        <TextField
                          fullWidth
                          size="small"
                          label={
                            <span>
                              Round
                              <span style={{ color: "red", marginLeft: "4px" }}>
                                *
                              </span>
                            </span>
                          }
                          name="round"
                          value={formik.values.round}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.round && Boolean(formik.errors.round)
                          }
                          helperText={
                            formik.touched.round && formik.errors.round
                          }
                          sx={{ "& .MuiInputBase-root": { height: "48px" } }}
                          disabled
                        />
                      </Box>
                      <Box>
                        <TextField
                          fullWidth
                          size="small"
                          select
                          label={
                            <span>
                              Seniority
                              <span style={{ color: "red", marginLeft: "4px" }}>
                                *
                              </span>
                            </span>
                          }
                          name="seniority"
                          value={formik.values.seniority}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.seniority &&
                            Boolean(formik.errors.seniority)
                          }
                          helperText={
                            formik.touched.seniority && formik.errors.seniority
                          }
                          sx={{ "& .MuiInputBase-root": { height: "48px" } }}
                        >
                          {seniorityLevels.map((level) => (
                            <MenuItem key={level} value={level}>
                              {level}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                      <Box>
                        <Tooltip
                          title={
                            !formik.values.endTime
                              ? "Select time range first"
                              : ""
                          }
                        >
                          <span>
                            <TextField
                              fullWidth
                              size="small"
                              select
                              label={
                                <span>
                                  Interviewer
                                  <span
                                    style={{ color: "red", marginLeft: "4px" }}
                                  >
                                    *
                                  </span>
                                </span>
                              }
                              name="interviewer"
                              value={formik.values.interviewer}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.interviewer &&
                                Boolean(formik.errors.interviewer)
                              }
                              helperText={
                                formik.touched.interviewer &&
                                formik.errors.interviewer
                              }
                              sx={{
                                "& .MuiInputBase-root": { height: "48px" },
                              }}
                              disabled={!formik.values.endTime}
                            >
                              {interviewers.map((interviewer) => (
                                <MenuItem
                                  key={interviewer.interviewerId}
                                  value={interviewer.interviewerId}
                                >
                                  {interviewer.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </span>
                        </Tooltip>
                      </Box>

                      <Stack
                        direction="row"
                        gap="16px"
                        justifyContent="flex-end"
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          sx={{ height: "40px" }}
                          onClick={() => handleModelClose()}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          color="secondary"
                          sx={{ color: "white", height: "40px" }}
                          onClick={() => {
                            formik.handleSubmit();
                          }}
                        >
                          Schedule Interview
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                </DrawerSlide>
              </>
            </Typography>
          </Tooltip>
        </Stack>
        <CommonModal
          icon={"/assets/mailForward.svg"}
          open={scheduleModal}
          title={"Schedule Interview"}
          sendButtonName={"Yes"}
          cancelButtonName={"No"}
          handleClose={() => setScheduleModal(false)}
          onSubmit={() => {
            handleConfirmSchedule();
            formik.resetForm();
          }}
        >
          <Typography
            style={{
              backgroundColor: "#fff0e7",
              padding: "15px",
              borderRadius: "5px",
            }}
          >
            <img
              src="/assets/alert-1.svg"
              alt="Alert"
              style={{
                verticalAlign: "middle",
                marginRight: "10px",
                marginTop: "10px",
                marginBottom: "15px",
              }}
            />
            <strong> Are you certain you wish to Schedule Interview?</strong>
          </Typography>
        </CommonModal>

        <CommonModal
          icon={"/assets/mailForward.svg"}
          open={successModal}
          title={"Interview Scheduled"}
          showFooter={false}
          handleClose={() => setSuccessModal(false)}
          onSubmit={() => setSuccessModal(false)}
        >
          <Typography
            style={{
              backgroundColor: "#f0fff0",
              padding: "15px",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <img
              src="/assets/calendar.gif"
              alt="Success"
              style={{ height: "100px", marginBottom: "15px" }}
            />
            <p>Interview scheduled successfully !!</p>
          </Typography>
        </CommonModal>

        <PingModal
          openMemberModal={openMemberModal}
          handleClose={handlePingModalCloseAddOrEdit}
          handlePingClick={handlePingClick}
          modalTitle={
            authUser?.role === "hr" &&
            data?.assessmentId?.testAssignedHrId === authUser._id &&
            (data?.InterviewDetails?.round2?.Interviewer?.name ||
              data?.InterviewDetails?.round1?.Interviewer?.name)
              ? data?.InterviewDetails?.round2?.Interviewer?.name
                ? `Ping Interviewer ${data.InterviewDetails.round2.Interviewer.name}`
                : `Ping Interviewer ${data.InterviewDetails.round1.Interviewer.name}`
              : data?.InterviewDetails?.round2?.Scheduler?.name
              ? `Ping Scheduler ${data?.InterviewDetails?.round2?.Scheduler?.name}`
              : `Ping Scheduler ${data?.InterviewDetails?.round1?.Scheduler?.name}`
          }
        />
      </Stack>
    </Stack>
  );
};

export default Round2CandidateCard;
