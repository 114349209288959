import {
  Box,
  Tooltip,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import React, { useState, useMemo } from "react";
import TabsList from "../../candidateShortlisted/Tabs";
import DashboardSelect from "../../dashboardPage/DashboardSelect";
import { TbTableExport } from "react-icons/tb";
import theme from "../../../theme/theme";
import DataTable from "./DataTable";
import SkillsChart from "./SkillChart";
import TestAttempted from "./TestAttempted";
import useResponsive from "../../../hooks/useResponsive";
import CommonModal from "../../modal/CommonModal";
import APICall from "../../../utils/api";
import { useMessage } from "../../../components/snackbar/snackbar";
import LoadingScreen from "../../../layouts/LoadingScreen";
import { useFormik } from "formik";
import * as yup from "yup";

const menuItems = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "Passed",
    label: "Passed",
  },
  {
    value: "Intermediate",
    label: "Intermediate",
  },
  {
    value: "Training Required",
    label: "Training Required",
  },
];

const Card = ({ title, body, fixedHeight = false }) => {
  return (
    <Box
      sx={{
        border: "1px solid #EBEBEB",
        borderRadius: "4px",
        width: "100%",
        height: { base: `${fixedHeight ? "410px" : "auto"}`, sm: "410px" },
        boxShadow: "0px 0px 4px 0px #0000001A",
      }}
    >
      <Box
        sx={{
          height: "48px",
          borderBottom: "1px solid #EBEBEB",
          padding: "16px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "17px",
            color: "#333333",
          }}
        >
          {title}
        </Typography>
      </Box>
      {/* body */}
      <Box sx={{ display: "flex" }}>{body}</Box>
    </Box>
  );
};

const SecondCardBody = ({ skillQuestionData, formikValues, currentTab }) => {
  const trainingRequiredTotal = skillQuestionData?.reduce(
    (sum, skill) => sum + skill.trainingRequiredCount,
    0
  );
  const intermediateTotal = skillQuestionData?.reduce(
    (sum, skill) => sum + skill.intermediateCount,
    0
  );
  const passedTotal = skillQuestionData?.reduce(
    (sum, skill) => sum + skill.passedCount,
    0
  );
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          height: "56px",
          width: "100%",
          padding: "1rem",
          display: "flex",
          alignItems: "center",
          gap: { base: "32px", xss: "64px" },
          bgcolor: "#F7F7F7",
        }}
      >
        {[
          {
            bg: "#FF9595",
            t1: "Training Required",
            t2: trainingRequiredTotal || 0,
          },
          { bg: "#EFB02E", t1: "Intermediate", t2: intermediateTotal || 0 },
          { bg: "#00C49A", t1: "Passed", t2: passedTotal || 0 },
        ].map((item) => {
          return (
            <Box
              sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
              key={item.bg}
            >
              <Box
                sx={{
                  width: "4px",
                  height: "32px",
                  bgcolor: item.bg,
                  borderRadius: "4px",
                }}
              />
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "#686868",
                  }}
                >
                  {item.t1}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: 700,
                    fontSize: "14px",
                    lineHeight: "18px",
                    color: "#333",
                  }}
                >
                  {item.t2}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box>
        <SkillsChart skillQuestionData={skillQuestionData} />
      </Box>
    </Box>
  );
};

const SkillAnalytics = ({ assessmentId }) => {
  const message = useMessage();
  const [skillQuestionData, setSkillQuestionData] = useState([]);
  const [candidateSkillData, setCandidateSkillData] = useState([]);
  const [documentsCount, setDocumentsCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [skillCutoffScores, setSkillCutoffScores] = useState({
    passingScore: 0,
    intermediateScore: 0,
    trainingRequiredScore: 0,
  });

  const [previewMode, setPreviewMode] = useState(false);

  const [tableParams, setTableParams] = useState({
    filters: {
      status: "all",
    },
    pagination: {
      page: 1,
      limit: 10,
    },
  });

  const isLargeScreen = useResponsive("up", "lg");

  const allTabItems = skillQuestionData?.map((skill) => ({
    label: skill.skillName.charAt(0).toUpperCase() + skill.skillName.slice(1),
    value: skill.skillId.toLowerCase(),
  }));
  const [currentTab, setCurrentTab] = useState(allTabItems?.[0]?.value || "");
  const [editScoreOpen, setEditScoreOpen] = useState(false);

  const skillCounts = skillQuestionData?.find(
    (skill) => skill.skillId.toLowerCase() === currentTab.toLowerCase()
  );

  const updateCutOff = async () => {
    let updateData = {};

    updateData.passingCutOffPercent = formik.values.passingCutOffPercent;

    updateData.intermediateCutOffPercent =
      formik.values.intermediateCutOffPercent;

    updateData.trainingRequiredCutOffPercent =
      formik.values.trainingRequiredCutOffPercent;
    updateData.skillId = currentTab;
    try {
      const res = await APICall(
        `/assessment/updateSkillAnalytics/${assessmentId}`,
        updateData,
        "patch"
      );
      fetchSkillAnalyticsGraphs();
      fetchSkillAnalytics();
      message("Cutoff Percentage updated successfully !!", "success");
    } catch (error) {
      message(error.message, "error");
    }
    setEditScoreOpen(false);
    setPreviewMode(false);
  };

  const handleEditScore = () => {
    setEditScoreOpen(true);
  };

  const cancelCutoffUpdate = () => {
    setEditScoreOpen(false);
    setPreviewMode(false);
    formik.setFieldValue(
      "passingCutOffPercent",
      skillQuestionData.passingCutOffPercent
    );
    formik.setFieldValue(
      "intermediateCutOffPercent",
      skillQuestionData.intermediateCutOffPercent
    );
    formik.setFieldValue(
      "trainingRequiredCutOffPercent",
      skillQuestionData.trainingRequiredCutOffPercent
    );
  };

  const validationSchema = yup.object({
    passingCutOffPercent: yup
      .number()
      .required("CutOff % is required")
      .min(0, "CutOff % cannot be negative")
      .max(100, "CutOff % cannot be more than 100")
      .typeError("Must be a number"),

    intermediateCutOffPercent: yup
      .number()
      .required("CutOff % is required")
      .min(0, "CutOff % cannot be negative")
      .max(100, "CutOff % cannot be more than 100")
      .typeError("Must be a number")
      .test(
        "is-less-than-passing",
        "CutOff must be less than Passing CutOff",
        function (value) {
          const { passingCutOffPercent } = this.parent;
          return value < passingCutOffPercent;
        }
      ),

    trainingRequiredCutOffPercent: yup
      .number()
      .required("CutOff % is required")
      .min(0, "CutOff % cannot be negative")
      .max(100, "CutOff % cannot be more than 100")
      .typeError("Must be a number")
      .test(
        "is-less-than-intermediate",
        "CutOff must be less than Intermediate CutOff",
        function (value) {
          const { intermediateCutOffPercent } = this.parent;
          return value < intermediateCutOffPercent;
        }
      ),
  });
  const formik = useFormik({
    initialValues: {
      passingCutOffPercent: 0,
      intermediateCutOffPercent: 0,
      trainingRequiredCutOffPercent: 0,
      totalScore: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      updateCutOff(values);
    },
  });

  const fetchSkillAnalyticsGraphs = async () => {
    let url = `/assessment/skillAnalyticsGraphs/${assessmentId}`;
    if (previewMode) {
      url += `?previewPassingCutOff=${formik.values.passingCutOffPercent}`;
      url += `&previewIntermediateCutOff=${formik.values.intermediateCutOffPercent}`;
      url += `&previewTrainingCutOff=${formik.values.trainingRequiredCutOffPercent}`;
      url += `&previewSkillId=${currentTab}`;
    }
    try {
      const res = await APICall(url);
      setSkillQuestionData(res.data.documents);
    } catch (error) {
      message(error.message, "error");
    }
  };
  const fetchSkillAnalytics = async () => {
    let url = `/assessment/skillAnalytics/${assessmentId}?skillId=${currentTab}&page=${tableParams.pagination.page}&limit=${tableParams.pagination.limit}`;
    if (
      tableParams.filters.status !== "all" &&
      tableParams.filters.status !== ""
    ) {
      url += `&status=${tableParams.filters.status}`;
    }
    if (previewMode) {
      url += `&previewPassingCutOff=${formik.values.passingCutOffPercent}`;
      url += `&previewIntermediateCutOff=${formik.values.intermediateCutOffPercent}`;
      url += `&previewTrainingCutOff=${formik.values.trainingRequiredCutOffPercent}`;
    }

    try {
      setLoading(true);
      const res = await APICall(url);
      setCandidateSkillData(res.data.documents);
      setDocumentsCount(res.data.total);

      setTotalPages(Math.ceil(res.data.total / tableParams.pagination.limit));
    } catch (error) {
      message(error.message, "error");
    }

    setLoading(false);
  };

  const handleModalClose = () => {
    setEditScoreOpen(false);
    setPreviewMode(false);
    const selectedSkill = skillQuestionData.find(
      (skill) => skill.skillId === currentTab
    );
    formik.setFieldValue(
      "passingCutOffPercent",
      selectedSkill?.cutOffs?.passingCutOffPercent
    );
    formik.setFieldValue(
      "intermediateCutOffPercent",
      selectedSkill?.cutOffs?.intermediateCutOffPercent
    );
    formik.setFieldValue(
      "trainingRequiredCutOffPercent",
      selectedSkill?.cutOffs?.trainingRequiredCutOffPercent
    );
  };

  React.useEffect(() => {
    fetchSkillAnalyticsGraphs();
  }, []);

  React.useEffect(() => {
    if (previewMode) {
      fetchSkillAnalyticsGraphs();
      fetchSkillAnalytics();
    }
  }, [previewMode]);

  React.useEffect(() => {
    if (currentTab !== "") fetchSkillAnalytics();
  }, [currentTab]);

  React.useEffect(() => {
    if (tableParams.filters.status !== "" && currentTab !== "")
      fetchSkillAnalytics();
  }, [tableParams]);

  React.useEffect(() => {
    const selectedSkill = skillQuestionData.find(
      (skill) => skill.skillId === currentTab
    );

    if (selectedSkill) {
      const { skillTotalScore, cutOffs } = selectedSkill;

      let passingScore, intermediateScore, trainingRequiredScore;
      if (previewMode) {
        passingScore =
          (skillTotalScore * formik.values.passingCutOffPercent) / 100;
        intermediateScore =
          (skillTotalScore * formik.values.intermediateCutOffPercent) / 100;
        trainingRequiredScore =
          (skillTotalScore * formik.values.trainingRequiredCutOffPercent) / 100;
      } else {
        passingScore = (skillTotalScore * cutOffs.passingCutOffPercent) / 100;
        intermediateScore =
          (skillTotalScore * cutOffs.intermediateCutOffPercent) / 100;
        trainingRequiredScore =
          (skillTotalScore * cutOffs.trainingRequiredCutOffPercent) / 100;
      }

      setSkillCutoffScores({
        passingScore,
        intermediateScore,
        trainingRequiredScore,
        skillTotalScore,
      });
      if (!previewMode) {
        formik.setFieldValue("totalScore", skillTotalScore);
        formik.setFieldValue(
          "passingCutOffPercent",
          cutOffs.passingCutOffPercent
        );
        formik.setFieldValue(
          "intermediateCutOffPercent",
          cutOffs.intermediateCutOffPercent
        );
        formik.setFieldValue(
          "trainingRequiredCutOffPercent",
          cutOffs.trainingRequiredCutOffPercent
        );
      }
    }
  }, [currentTab, skillQuestionData, previewMode]);

  React.useEffect(() => {
    if (
      allTabItems?.length > 0 &&
      !allTabItems.some((tab) => tab.value === currentTab)
    ) {
      setCurrentTab(allTabItems[0].value);
      setPreviewMode(false);
    }
  }, [allTabItems, currentTab]);

  return (
    <Box
      sx={{
        padding: "1rem 0rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "cener",
          gap: "1rem",
          flexDirection: isLargeScreen ? "row" : "column",
        }}
      >
        <Card
          title="Skill / Question Attempt Ratio"
          body={<TestAttempted skillQuestionData={skillQuestionData} />}
        />
        <Card
          fixedHeight={true}
          title={
            previewMode ? "Skill Proficiency (In Preview)" : "Skill Proficiency"
          }
          body={
            <SecondCardBody
              skillQuestionData={skillQuestionData}
              formikValues={formik.values}
              currentTab={currentTab}
            />
          }
        />
      </Box>
      {allTabItems && allTabItems.length > 0 && (
        <TabsList
          value={currentTab}
          tabs={allTabItems}
          handleTabChange={setCurrentTab}
        />
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: isLargeScreen ? "center" : "flex-start",
          justifyContent: "space-between",
          width: "100%",
          flexDirection: isLargeScreen ? "row" : "column",
          gap: isLargeScreen ? "0" : "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            flexWrap: "wrap",
          }}
        >
          {[
            {
              img: "/assets/circlecheck_1.svg",
              bg: "#439D621A",
              t1: skillCounts?.passedCount,
              t2: "Passed",
            },
            {
              img: "/assets/circleminus_1.svg",
              bg: "#F9E8A480",
              t1: skillCounts?.intermediateCount,

              t2: "Intermediate",
            },
            {
              img: "/assets/circleminus.svg",
              bg: "#FFF0E7",
              t1: skillCounts?.trainingRequiredCount,

              t2: "Training Required",
            },
          ].map((item) => {
            return (
              <Box
                key={item.bg}
                sx={{
                  border: "1px solid #E8E9EE",
                  padding: "8px 12px",
                  borderRadius: "4px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <Box
                  sx={{
                    bgcolor: item.bg,
                    padding: "4px",
                    borderRadius: "4px",
                    height: "24px",
                    width: "24px",
                  }}
                >
                  <img src={item.img} alt="circle" />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Nunito",
                      fontWeight: 700,
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#363E45",
                    }}
                  >
                    {item.t1}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "20px",
                      color: "#636A75",
                    }}
                  >
                    {item.t2}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
        {previewMode && (
          <Box
            sx={{
              border: "1px solid #E8E9EE",
              padding: "8px 12px",
              borderRadius: "4px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <Box
              sx={{
                padding: "4px",
                borderRadius: "4px",
                height: "24px",
                width: "24px",
              }}
            >
              <img src={"/assets/edit_3.svg"} alt="circle" />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Nunito",
                  fontWeight: 700,
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#363E45",
                }}
              >
                {"In Preview Mode"}
              </Typography>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Box sx={{ width: "150px" }}>
            <DashboardSelect
              menuItems={menuItems}
              handleChange={(value) => {
                setTableParams({
                  ...tableParams,
                  filters: { ...tableParams.filters, status: value },
                  pagination: { ...tableParams.pagination, page: 1 },
                });
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {tableParams.filters.status !== "all" &&
              tableParams.filters.status !== "" && (
                <>
                  <img src="/assets/remark.svg" alt="remark" />
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#363E45",
                    }}
                  >
                    {tableParams.filters.status && (
                      <>
                        {tableParams.filters.status === "Passed" && (
                          <div>
                            {`${skillCutoffScores.passingScore} / ${skillCutoffScores.skillTotalScore}`}
                          </div>
                        )}

                        {tableParams.filters.status === "Intermediate" && (
                          <div>
                            {`${skillCutoffScores.intermediateScore} / ${skillCutoffScores.skillTotalScore}`}
                          </div>
                        )}
                        {tableParams.filters.status === "Training Required" && (
                          <div>
                            {`${skillCutoffScores.trainingRequiredScore} / ${skillCutoffScores.skillTotalScore}`}
                          </div>
                        )}
                      </>
                    )}
                  </Typography>
                  <Box
                    sx={{
                      marginLeft: "2px",
                      marginTop: "2px",
                      cursor: "pointer",
                      display: "inline-block",
                    }}
                    onClick={handleEditScore}
                  >
                    <img src="/assets/edit_3.svg" alt="edit" />
                  </Box>
                </>
              )}
          </Box>
          <Tooltip title="Export" arrow>
            <span>
              <TbTableExport
                size={22}
                color={theme.palette.secondary.main}
                fontWeight={400}
              />
            </span>
          </Tooltip>
        </Box>
      </Box>
      <DataTable
        candidateSkillData={candidateSkillData}
        currentTab={currentTab}
        totalDocuments={documentsCount}
        totalPages={totalPages}
        tableParams={tableParams}
        setTableParams={setTableParams}
      />
      {loading && <LoadingScreen />}
      <CommonModal
        icon={"/assets/icons/briefcaseBlack.svg"}
        open={editScoreOpen}
        title={`Edit Cutoff Percentage`}
        onSubmit={() => {
          formik.handleSubmit();
        }}
        sendButtonName={"Update and Save"}
        cancelButtonName={"Cancel"}
        handleClose={handleModalClose}
        showInviteCancelButton={true}
        inviteCancelButtonText={"Preview"}
        inviteCancel={() => {
          setPreviewMode(true);
          setEditScoreOpen(false);
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          {[
            {
              label: "Passing Threshold ≥",
              icon: "/assets/circlecheck_1.svg",
              id: "cutOff",
              name: "passingCutOffPercent",
              value: formik.values.passingCutOffPercent,
              helperText:
                "Percentage must be Equal to or Greater than this CutOff.",
              onChange: formik.handleChange,
              error:
                formik.touched.passingCutOffPercent &&
                formik.errors.passingCutOffPercent,
            },
            {
              label: "Intermediate Threshold ≥",
              icon: "/assets/circleminus_1.svg",
              id: "intermediateCutOff",
              name: "intermediateCutOffPercent",
              value: formik.values.intermediateCutOffPercent,
              helperText:
                "Percentage must be Equal to or Greater than this CutOff.",
              onChange: (e) => {
                const value = parseFloat(e.target.value) || 0;
                formik.handleChange(e);
                const trainingRequiredValue = Math.max(value - 1, 0);
                formik.setFieldValue(
                  "trainingRequiredCutOffPercent",
                  trainingRequiredValue
                );
              },
              error:
                formik.touched.intermediateCutOffPercent &&
                formik.errors.intermediateCutOffPercent,
            },
            {
              label: "Training Required Threshold <",
              icon: "/assets/circleminus.svg",
              id: "trainingRequired",
              name: "trainingRequiredCutOffPercent",
              value: formik.values.trainingRequiredCutOffPercent,
              helperText:
                "Percentage will be Less than the Intermediate threshold.",
              onChange: formik.handleChange,
              error:
                formik.touched.trainingRequiredCutOffPercent &&
                formik.errors.trainingRequiredCutOffPercent,
              disabled: true,
            },
          ].map((field, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <img src={field.icon} alt={field.label} />
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: 400,
                      lineHeight: "20px",
                    }}
                  >
                    {field.label}
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#6b7280",
                    fontSize: "12px",
                    marginTop: "4px",
                  }}
                >
                  {field.helperText}
                </Typography>
              </Box>

              <TextField
                sx={{
                  width: "150px",
                  alignSelf: "flex-end",
                }}
                size="small"
                id={field.id}
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                error={Boolean(field.error)}
                disabled={field.disabled}
              />
              {/* {field.error && (
                <Typography
                  sx={{
                    color: "red",
                    fontSize: "12px",
                    marginTop: "4px",
                  }}
                >
                  {field.error}
                </Typography>
              )} */}
            </Box>
          ))}
        </Box>
      </CommonModal>
    </Box>
  );
};

export default SkillAnalytics;
