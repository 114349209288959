import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  generateAndSetMetaData,
  updateAndSetMetaData,
} from "./ATFMetaDetaSlice";

const initialState = {
  assessmentId: null,
  skills: [
    {
      skill: {},
      mcqQuestions: {
        easyQuestions: [],
        mediumQuestions: [],
        hardQuestions: [],
      },
      programmingQuestions: {
        easyQuestions: [],
        mediumQuestions: [],
        hardQuestions: [],
      },
    },
  ],
};
// Thunk to add skill and generate metadata
export const addSkillAndGenerateMetaData = createAsyncThunk(
  "assessmentQuestion/addSkillAndGenerateMetaData",
  async (skill, { getState, dispatch }) => {
    try {
      const state = getState();
      const skills = [...state.assessmentQuestion.skills, skill];

      // Dispatch updateAndSetMetaData with the added skill
      await dispatch(
        updateAndSetMetaData({ operation: "add", selectedSkill: skill })
      );
      return skills;
    } catch (error) {
      console.error("Error in addSkillAndGenerateMetaData thunk:", error);
      throw error;
    }
  }
);

// Thunk to update skill and generate metadata
export const updateSkillAndGenerateMetaData = createAsyncThunk(
  "assessmentQuestion/updateSkillAndGenerateMetaData",
  async ({ index, skill }, { getState, dispatch }) => {
    try {
      const state = getState();
      const skills = [...state.assessmentQuestion.skills];

      if (skills[index]) {
        skills[index] = {
          ...skills[index],
          ...skill,
        };
      }

      // Dispatch updateAndSetMetaData with the updated skill
      await dispatch(
        updateAndSetMetaData({ operation: "update", selectedSkill: skill })
      );
      return skills;
    } catch (error) {
      console.error("Error in updateSkillAndGenerateMetaData thunk:", error);
      throw error;
    }
  }
);

// Thunk to remove skill and generate metadata
export const removeSkillAndGenerateMetaData = createAsyncThunk(
  "assessmentQuestion/removeSkillAndGenerateMetaData",
  async (index, { getState, dispatch }) => {
    try {
      const state = getState();
      const skills = [...state.assessmentQuestion.skills];
      const removedSkill = skills.splice(index, 1)[0]; // Extract the removed skill

      await dispatch(
        updateAndSetMetaData({
          operation: "delete",
          selectedSkill: removedSkill,
        })
      );
      return skills;
    } catch (error) {
      console.error("Error in removeSkillAndGenerateMetaData thunk:", error);
      throw error;
    }
  }
);

// Thunk to add question and generate metadata
export const addQuestionAndGenerateMetaData = createAsyncThunk(
  "assessmentQuestion/addQuestionAndGenerateMetaData",
  async ({ skillId, question }, { getState, dispatch }) => {
    const state = getState();
    const skills = [...state.assessmentQuestion.skills];
    const difficulty = question.difficultyLevel.level;
    const type = question.type;

    const skillIndex = skills.findIndex((skill) => skill.skill._id === skillId);

    if (skillIndex === -1) {
      const selectedSkill = question.skills.find(
        (skill) => skill._id === skillId
      );
      const newSkill = {
        skill: selectedSkill,
        [`${type.toLowerCase()}Questions`]: {
          easyQuestions: [],
          mediumQuestions: [],
          hardQuestions: [],
        },
      };
      newSkill[`${type.toLowerCase()}Questions`][`${difficulty}Questions`].push(
        question
      );

      skills.push(newSkill);

      await dispatch(
        updateAndSetMetaData({ operation: "add", selectedSkill: newSkill })
      );
      return skills;
    } else {
      const updatedSkill = JSON.parse(JSON.stringify(skills[skillIndex]));

      const questionTypeKey = `${type.toLowerCase()}Questions`;

      if (!updatedSkill[questionTypeKey]) {
        updatedSkill[questionTypeKey] = {
          easyQuestions: [],
          mediumQuestions: [],
          hardQuestions: [],
        };
      }

      updatedSkill[questionTypeKey][`${difficulty}Questions`].push(question);

      const updatedSkills = skills.map((skill, index) =>
        index === skillIndex ? updatedSkill : skill
      );

      await dispatch(
        updateAndSetMetaData({
          operation: "update",
          selectedSkill: updatedSkill,
        })
      );

      return updatedSkills;
    }
  }
);

// Thunk to remove question and generate metadata
export const removeQuestionAndGenerateMetaData = createAsyncThunk(
  "assessmentQuestion/removeQuestionAndGenerateMetaData",
  async (
    { skillIndex, questionType, difficulty, questionId },
    { getState, dispatch }
  ) => {
    const state = getState();
    const skills = [...state.assessmentQuestion.skills];
    if (skills[skillIndex]) {
      skills[skillIndex] = {
        ...skills[skillIndex],
        [`${questionType}Questions`]: {
          ...skills[skillIndex][`${questionType}Questions`],
          [`${difficulty}Questions`]: skills[skillIndex][
            `${questionType}Questions`
          ][`${difficulty}Questions`].filter(
            (question) => question._id !== questionId
          ),
        },
      };
    }
    if (!hasAnyQuestions(skills[skillIndex])) {
      const removedSkill = skills.splice(skillIndex, 1)[0]; // Extract the removed skill
      // Dispatch updateAndSetMetaData with the removed skill
      await dispatch(
        updateAndSetMetaData({
          operation: "delete",
          selectedSkill: removedSkill,
        })
      );
    } else {
      // Dispatch updateAndSetMetaData with the updated skill
      await dispatch(
        updateAndSetMetaData({
          operation: "update",
          selectedSkill: skills[skillIndex],
        })
      );
    }
    return skills;
  }
);

//Thunk to remove multiple questions and generate metadata
export const removeMultipleQuestionsAndGenerateMetaData = createAsyncThunk(
  "assessmentQuestion/removeMultipleQuestionsAndGenerateMetaData",
  async ({ skillIndex, questions }, { getState, dispatch }) => {
    try {
      const state = getState();
      const skills = [...state.assessmentQuestion.skills];
      let updatedSkill = { ...skills[skillIndex] };

      const findQuestionIndex = (questions, questionId) => {
        return questions.findIndex((question) => question._id === questionId);
      };

      const removeQuestionFromSkill = (skill, question) => {
        const { questionType, difficulty, questionId } = question;
        const questionIndex = findQuestionIndex(
          skill[`${questionType.toLowerCase()}Questions`][
            `${difficulty}Questions`
          ],
          questionId
        );
        if (questionIndex !== -1) {
          const updatedQuestions = skill[
            `${questionType.toLowerCase()}Questions`
          ][`${difficulty}Questions`].filter((q) => q._id !== questionId);
          return {
            ...skill,
            [`${questionType.toLowerCase()}Questions`]: {
              ...skill[`${questionType.toLowerCase()}Questions`],
              [`${difficulty}Questions`]: updatedQuestions,
            },
          };
        }
        return skill;
      };

      const updateSkillMetaData = async (operation, skill) => {
        try {
          await dispatch(
            updateAndSetMetaData({
              operation,
              selectedSkill: skill,
            })
          );
        } catch (error) {
          console.error("Error in updateSkillMetaData:", error);
          throw error;
        }
      };

      try {
        questions.forEach((question) => {
          updatedSkill = removeQuestionFromSkill(updatedSkill, question);
        });

        if (!hasAnyQuestions(updatedSkill)) {
          const removedSkill = skills.splice(skillIndex, 1)[0];
          await updateSkillMetaData("delete", removedSkill);
        } else {
          skills[skillIndex] = updatedSkill;
          await updateSkillMetaData("update", updatedSkill);
        }
      } catch (error) {
        console.error("Error in removing questions from skill:", error);
        throw error;
      }

      return skills;
    } catch (error) {
      console.error(
        "Error in removeMultipleQuestionsAndGenerateMetaData:",
        error
      );
      throw error;
    }
  }
);

//Thunk to remove difficulty level and generate metadata
export const removeDifficultyLevelAndGenerateMetaData = createAsyncThunk(
  "assessmentQuestion/removeDifficultyLevelAndGenerateMetaData",
  async (
    { skillIndex, questionType, difficulty, skillId },
    { getState, dispatch }
  ) => {
    const state = getState();
    const skills = [...state.assessmentQuestion.skills];

    if (skills[skillIndex]) {
      let updatedSkill = { ...skills[skillIndex] };

      const removeDifficultyFromType = (type) => {
        updatedSkill = {
          ...updatedSkill,
          [`${type}Questions`]: {
            ...updatedSkill[`${type}Questions`],
            [`${difficulty}Questions`]: [],
          },
        };
      };

      if (questionType === "All") {
        ["mcq", "programming", "sql"].forEach((type) =>
          removeDifficultyFromType(type)
        );
      } else {
        removeDifficultyFromType(questionType.toLowerCase());
      }

      if (!hasAnyQuestions(updatedSkill)) {
        const removedSkill = skills.splice(skillIndex, 1)[0];
        await dispatch(
          updateAndSetMetaData({
            operation: "delete",
            selectedSkill: removedSkill,
          })
        );
      } else {
        skills[skillIndex] = updatedSkill;
        await dispatch(
          updateAndSetMetaData({
            operation: "update",
            selectedSkill: updatedSkill,
          })
        );
      }
    }
    return skills;
  }
);

// Thunk to update question score and generate metadata
export const updateQuestionScoreAndGenerateMetaData = createAsyncThunk(
  "assessmentQuestion/updateQuestionScoreAndGenerateMetaData",
  async (
    { skillIndex, questionType, difficulty, questionId, score },
    { getState, dispatch }
  ) => {
    try {
      const state = getState();
      const skills = [...state.assessmentQuestion.skills];
      if (skills[skillIndex]) {
        const updatedQuestions = skills[skillIndex][`${questionType}Questions`][
          `${difficulty}Questions`
        ].map((question) =>
          question._id === questionId ? { ...question, score } : question
        );

        skills[skillIndex] = {
          ...skills[skillIndex],
          [`${questionType}Questions`]: {
            ...skills[skillIndex][`${questionType}Questions`],
            [`${difficulty}Questions`]: updatedQuestions,
          },
        };
      }

      // Dispatch updateAndSetMetaData with the updated skill
      await dispatch(
        updateAndSetMetaData({
          operation: "update",
          selectedSkill: skills[skillIndex],
        })
      );
      return skills;
    } catch (error) {
      console.error("Error in updateQuestionScoreAndGenerateMetaData:", error);
      throw error;
    }
  }
);

//Thunk to update question and generate metadata
export const updateQuestionAndGenerateMetaData = createAsyncThunk(
  "assessmentQuestion/updateQuestionAndGenerateMetaData",
  async ({ oldQuestion, updatedQuestion }, { getState, dispatch }) => {
    try {
      const state = getState();
      const skills = [...state.assessmentQuestion.skills];

      const findQuestionIndex = (questions, questionId) => {
        return questions.findIndex((question) => question._id === questionId);
      };

      const removeQuestionFromSkill = (skill, question, type, difficulty) => {
        const questionIndex = findQuestionIndex(
          skill[type][difficulty],
          question._id
        );
        if (questionIndex !== -1) {
          const updatedQuestions = skill[type][difficulty].filter(
            (q) => q._id !== question._id
          );
          return {
            ...skill,
            [type]: {
              ...skill[type],
              [difficulty]: updatedQuestions,
            },
          };
        }
        return skill;
      };

      const addQuestionToSkill = (skill, question, type, difficulty) => {
        const updatedQuestions = [
          ...(skill[type]?.[difficulty] || []),
          question,
        ];
        return {
          ...skill,
          [type]: {
            ...skill[type],
            [difficulty]: updatedQuestions,
          },
        };
      };

      const updateQuestionInSkill = (
        skill,
        updatedQuestion,
        type,
        difficulty
      ) => {
        const questionIndex = findQuestionIndex(
          skill[type][difficulty],
          updatedQuestion._id
        );
        if (questionIndex !== -1) {
          const updatedQuestions = skill[type][difficulty].map((q, index) =>
            index === questionIndex ? updatedQuestion : q
          );
          return {
            ...skill,
            [type]: {
              ...skill[type],
              [difficulty]: updatedQuestions,
            },
          };
        }
        return skill;
      };

      const updateSkillMetaData = async (operation, skill) => {
        try {
          await dispatch(
            updateAndSetMetaData({
              operation,
              selectedSkill: skill,
            })
          );
        } catch (error) {
          console.error("Error in updateSkillMetaData:", error);
          throw error;
        }
      };

      const oldSkillIndex = skills.findIndex(
        (skill) => skill.skill._id === oldQuestion.assignedSkill._id
      );
      const newSkillIndex = skills.findIndex(
        (skill) => skill.skill._id === updatedQuestion.assignedSkill._id
      );

      const oldType = `${oldQuestion.question.type.toLowerCase()}Questions`;
      const newType = `${updatedQuestion.question.type.toLowerCase()}Questions`;
      const oldDifficulty = `${oldQuestion.question.difficultyLevelId.level}Questions`;
      const newDifficulty = `${updatedQuestion.question.difficultyLevelId.level}Questions`;

      if (oldSkillIndex !== -1) {
        let oldSkill = skills[oldSkillIndex];
        if (
          oldQuestion.assignedSkill._id === updatedQuestion.assignedSkill._id &&
          oldQuestion.question.type === updatedQuestion.question.type &&
          oldQuestion.question.difficultyLevelId.level ===
            updatedQuestion.question.difficultyLevelId.level
        ) {
          oldSkill = updateQuestionInSkill(
            oldSkill,
            updatedQuestion.question,
            oldType,
            oldDifficulty
          );
          skills[oldSkillIndex] = oldSkill;
          await updateSkillMetaData("update", oldSkill);
          return skills;
        } else {
          oldSkill = await removeQuestionFromSkill(
            oldSkill,
            oldQuestion.question,
            oldType,
            oldDifficulty
          );

          if (
            oldQuestion.assignedSkill._id !==
              updatedQuestion.assignedSkill._id &&
            !hasAnyQuestions(oldSkill)
          ) {
            const removedSkill = skills.splice(oldSkillIndex, 1)[0];
            await updateSkillMetaData("delete", removedSkill);
          } else {
            skills[oldSkillIndex] = oldSkill;
            await updateSkillMetaData("update", oldSkill);
          }
        }
      }

      if (newSkillIndex === -1) {
        const newSkill = {
          skill: updatedQuestion.assignedSkill,
          mcqQuestions: {
            easyQuestions: [],
            mediumQuestions: [],
            hardQuestions: [],
          },
          programmingQuestions: {
            easyQuestions: [],
            mediumQuestions: [],
            hardQuestions: [],
          },
          sqlQuestions: {
            easyQuestions: [],
            mediumQuestions: [],
            hardQuestions: [],
          },
        };
        const updatedNewSkill = addQuestionToSkill(
          newSkill,
          updatedQuestion.question,
          newType,
          newDifficulty
        );
        skills.push(updatedNewSkill);
        await updateSkillMetaData("add", updatedNewSkill);
      } else {
        let newSkill = skills[newSkillIndex];

        newSkill = addQuestionToSkill(
          newSkill,
          updatedQuestion.question,
          newType,
          newDifficulty
        );
        skills[newSkillIndex] = newSkill;
        await updateSkillMetaData("update", newSkill);
      }

      return skills;
    } catch (error) {
      console.error("Error in updateQuestionAndGenerateMetaData:", error);
      throw error;
    }
  }
);

// Helper function to check if a skill has any questions
const hasAnyQuestions = (skill) => {
  const questionTypes = [
    "mcqQuestions",
    "programmingQuestions",
    "sqlQuestions",
  ];
  const difficulties = ["easyQuestions", "mediumQuestions", "hardQuestions"];

  for (const type of questionTypes) {
    for (const difficulty of difficulties) {
      if (
        skill[type] &&
        skill[type][difficulty] &&
        skill[type][difficulty].length > 0
      ) {
        return true;
      }
    }
  }
  return false;
};

const assessmentQuestionSlice = createSlice({
  name: "assessmentQuestion",
  initialState,
  reducers: {
    setAssessmentId: (state, action) => {
      state.assessmentId = action.payload;
    },
    setAssessmentQuestions: (state, action) => {
      Object.assign(state, action.payload);
    },
    resetAssessmentQuestions: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addSkillAndGenerateMetaData.fulfilled, (state, action) => {
        state.skills = action.payload;
      })
      .addCase(updateSkillAndGenerateMetaData.fulfilled, (state, action) => {
        state.skills = action.payload;
      })
      .addCase(removeSkillAndGenerateMetaData.fulfilled, (state, action) => {
        state.skills = action.payload;
      })
      .addCase(addQuestionAndGenerateMetaData.fulfilled, (state, action) => {
        state.skills = action.payload;
      })
      .addCase(removeQuestionAndGenerateMetaData.fulfilled, (state, action) => {
        state.skills = action.payload;
      })
      .addCase(
        updateQuestionScoreAndGenerateMetaData.fulfilled,
        (state, action) => {
          state.skills = action.payload;
        }
      )
      .addCase(updateQuestionAndGenerateMetaData.fulfilled, (state, action) => {
        state.skills = action.payload;
      })
      .addCase(
        removeDifficultyLevelAndGenerateMetaData.fulfilled,
        (state, action) => {
          state.skills = action.payload;
        }
      )
      .addCase(
        removeMultipleQuestionsAndGenerateMetaData.fulfilled,
        (state, action) => {
          state.skills = action.payload;
        }
      );
  },
});

export const {
  setAssessmentQuestions,
  setAssessmentId,
  resetAssessmentQuestions,
} = assessmentQuestionSlice.actions;

export default assessmentQuestionSlice.reducer;
