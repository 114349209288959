// create difficultyLevelsSlice.js file
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  difficultyLevels: [],
};

const difficultyLevelsSlice = createSlice({
  name: "difficultyLevels",
  initialState,
  reducers: {
    setDifficultyLevels: (state, action) => {
      console.log("setDifficultyLevels", action.payload);

      state.difficultyLevels = action.payload;
    },
  },
});

export const { setDifficultyLevels } = difficultyLevelsSlice.actions;
export default difficultyLevelsSlice.reducer;
