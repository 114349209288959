import React from "react";
import { Stack, Typography, TextField } from "@mui/material";
import CommonModal from "../modal/CommonModal";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  textField: {
    border: "2px solid #E8E9EE",
    borderRadius: "4px",
  },
  placeholder: {
    fontWeight: 700,
    fontSize: "16px",
    color: "#363E45",
  },
  backdrop: {
    backdropFilter: "blur(5px)",
    backgroundColor: "rgba(49, 62, 79, 0.2)",
  },
}));

const PingModal = ({
  openMemberModal,
  handleClose,
  handlePingClick,
  modalTitle,
}) => {
  const classes = useStyles();

  return (
    <CommonModal
      icon={"/assets/Vector-5.svg"}
      handleClose={handleClose}
      open={openMemberModal}
      title={modalTitle || "Ping Recruiter"}
      sendButtonName={"Ping"}
      onSubmit={handlePingClick}
      BackdropProps={{
        className: classes.backdrop,
      }}
    >
      <Stack gap={"4px"}>
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontWeight: "400",
            fontSize: "14px",
            lineheight: "20px",
            color: "#71777B",
          }}
        >
          Enter Message
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={6}
          placeholder="Enter your message"
          className={classes.textField}
          InputProps={{
            className: classes.placeholder, // Apply placeholder styles
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              padding: "8px 12px 8px 12px",
              "& fieldset": {
                borderColor: "#E8E9EE",
                borderWidth: "2px",
                borderRadius: "4px",
              },
            },
            "& .MuiInputBase-input": {
              "&::placeholder": {
                fontWeight: 400,
                fontSize: "16px",
                color: "#363E45",
                opacity: 1,
              },
            },
          }}
        />
      </Stack>
    </CommonModal>
  );
};

export default PingModal;
