import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { Box, Tooltip, Link, ListItemText, Typography } from "@mui/material";
import { StyledItem, StyledIcon, StyledDotIcon } from "./styles";
import Iconify from "../components/iconify/Iconify";
import ChevronDownIcon from "../components/icons/ChevronDownIcon";
import { useCallback } from "react";
import ChevronUpIcon from "../components/icons/ChevronUpIcon";

// ----------------------------------------------------------------------

NavItem.propTypes = {
  open: PropTypes.bool,
  active: PropTypes.bool,
  item: PropTypes.object,
  depth: PropTypes.number,
  isExternalLink: PropTypes.bool,
};

export default function NavItem({
  item,
  depth,
  open,
  active,
  isExternalLink,
  isExpanded = false,
  role = null,
  ...other
}) {
  const { title, path, icon, info, children, disabled, caption, activeIcon } =
    item;

  const subItem = depth !== 1;

  const getIcon = useCallback(() => {
    if (role === "recruiter" || role === "hr") {
      const fill = active ? "#FF6812" : "#71777B";
      return isExpanded && active ? (
        <ChevronUpIcon fill={fill} />
      ) : (
        <ChevronDownIcon fill={fill} />
      );
    }
    return null;
  }, [active, isExpanded]);
  const renderContent = (
    <StyledItem
      depth={depth}
      active={active}
      disabled={disabled}
      caption={!!caption}
      {...other}
      sx={{
        borderRadius: title === "Interviews" ? "0px" : "4px",
        marginBottom: title === "Interviews" ? "0px" : "2px",
      }}
    >
      <StyledIcon>{active ? activeIcon : icon}</StyledIcon>

      {subItem && (
        <StyledIcon>
          <StyledDotIcon active={active && subItem} />
        </StyledIcon>
      )}
      <ListItemText
        sx={{ fontSize: "16px", lineHeight: "20px" }}
        primary={`${title}`}
        secondary={
          caption && (
            <Tooltip title={`${caption}`} placement="top-start">
              <span>{`${caption}`}</span>
            </Tooltip>
          )
        }
        primaryTypographyProps={{
          noWrap: true,
          component: "span",
          fontSize: "16px",
          variant: active ? "subtitle2" : "body2",
        }}
        secondaryTypographyProps={{
          noWrap: true,
          variant: "caption",
        }}
      />

      {title === "Interviews" && getIcon()}

      {info && (
        <Box component="span" sx={{ lineHeight: 0 }}>
          {info}
        </Box>
      )}

      {!!children && (
        <Iconify
          width={16}
          icon={
            open ? "eva:arrow-ios-downward-fill" : "eva:arrow-ios-forward-fill"
          }
          sx={{ ml: 1, flexShrink: 0 }}
        />
      )}
    </StyledItem>
  );

  const renderItem = () => {
    // ExternalLink
    if (isExternalLink)
      return (
        <Link href={path} target="_blank" rel="noOpener" underline="none">
          {renderContent}
        </Link>
      );

    // Has child
    if (children) {
      return renderContent;
    }

    // Default
    return (
      <Link component={RouterLink} to={path} underline="none">
        {renderContent}
      </Link>
    );
  };

  return renderItem();
}
