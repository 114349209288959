import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getColorByStatus } from "../../utils";
import { styled } from "@mui/system";
import Tooltip from "@mui/material/Tooltip";
import { useMessage } from "../../components/snackbar/snackbar";
import { current } from "@reduxjs/toolkit";
import Drawer from "@mui/material/Drawer";
import CheatingDetected from "../cheatingDetected/CheatingDetected";

const CandidateName = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "20px",
  fontFamily: "Roboto",
  color: "#313131",
}));

const TestTakenCandidateCard = ({
  data,
  testTaken,
  selectedRow,
  setSelectedRow,
  updateStatus,
  handleStatusClick,
  currentTab,
}) => {
  const handleMenuClick = (event, row) => {
    setSelectedRow(row);
  };
  const message = useMessage();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerHeading, setDrawerHeading] = useState("");
  const navigate = useNavigate();

  const handleCheatingClick = () => {
    // setDrawerHeading("View Snapshot");
    setDrawerOpen(true);
  };

  const getStatus = (status) => {
    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        {/* {status?.cheated && (
          <Tooltip
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#313E4F",
                  height: "40px",
                  padding: "8px 12px",
                  borderRadius: "0.25rem",
                },
                "& .MuiTooltip-arrow": {
                  // background: "#313E4F",
                },
              },
            }}
            placement="bottom-start"
            arrow
            sx={{ background: "#fff" }}
            title={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  cursor: "pointer",
                }}
              >
                <Typography
                  sx={{
                    color: "#E9EAEB",
                    fontFamily: "Roboto",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                  onClick={handleCheatingClick}
                >
                  {status?.cheated
                    ? "Cheating Detected"
                    : "No Cheating Detected"}
                </Typography>
                <img src="/assets/redirect_icon_2.svg" alt="redirect icon" />
              </Box>
            }
          >
            <Box
              component="img"
              src="/assets/alert_icon.svg"
              alt="AlertIcon"
              sx={{ cursor: "pointer" }}
            />
          </Tooltip>
        )} */}
        <Box
          sx={{
            display: "inline-block",
            background: getColorByStatus(status.id).bg,
            padding: "4px 12px",
            color: getColorByStatus(status.id).color,
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "16px",
            fontFamily: "Roboto",
            borderRadius: "0.25rem",
            whiteSpace: "nowrap",
          }}
        >
          {status.tag}
        </Box>
      </Box>
    );
  };

  const getAdjustedStatus = (data) => {
    if (
      currentTab === "invited" &&
      data?.currentStatus !== "Appeared" &&
      data?.currentStatus !== "Invited" &&
      data?.currentStatus !== "Invite Expired"
    ) {
      return "Appeared";
    } else if (
      currentTab === "appeared" &&
      data?.currentStatus !== "Appeared" &&
      data?.currentStatus !== "Shortlisted" &&
      data?.currentStatus !== "Reviewed"
    ) {
      return "Shortlisted";
    } else if (currentTab === "passed") {
      // Return "Shortlisted" if currentStatus is neither "Reviewed" nor "Appeared"
      if (
        data?.currentStatus !== "Reviewed" &&
        data?.currentStatus !== "Appeared"
      ) {
        return "Shortlisted";
      }
    }

    if (data?.currentStatus === "Appeared" && currentTab !== "invited") {
      return data?.assessmentResult?.totalObtainedScore >=
        data?.assessmentId?.cutOff
        ? "Passed"
        : "Failed";
    }

    return data?.currentStatus;
  };

  useEffect(() => console.log(currentTab, "Tab"), [currentTab]);

  const formatDate = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      const dateOptions = { day: "2-digit", month: "short", year: "numeric" };
      const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };

      const formattedDate = date.toLocaleDateString("en-GB", dateOptions);
      const [day, month, year] = formattedDate.split(" ");
      const formattedDateCorrect = `${parseInt(day)} ${
        month.charAt(0).toUpperCase() + month.slice(1)
      } ${year}`;

      const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

      const formattedDateTime = `${formattedDateCorrect} - ${formattedTime}`;
      return formattedDateTime;
    }
  };

  const handleGetLink = () => {
    const link =
      "https://staging.dev.theeliteqa.com/candidateresult/66694b7d69dfc4fef59e9df6";
    navigator.clipboard
      .writeText(link)
      .then(() => {
        message("Result Link copied to clipboard!", "success");
      })
      .catch(() => {
        message("Failed to copy the link.", "error");
      });
  };

  return (
    <Stack
      direction={{ xs: "column", lg: "row" }}
      alignItems={{ xs: "flex-start", lg: "center" }}
      sx={{
        padding: "16px",
        backgroundColor: "#FFFFFF",
        gap: "12px",
        border: "1px solid #E8E9EE",
        borderRadius: "4px",
        maxHeight: { xs: "auto", lg: "80px" },
      }}
    >
      {/* left section */}
      <Stack
        direction={"column"}
        alignItems={"flex-start"}
        gap={"12px"}
        flexGrow={1}
        sx={{ padding: 0 }}
      >
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"12px"}
        >
          <Box
            component={"img"}
            src={
              data?.candidateProfilePicture
                ? data?.candidateProfilePicture
                : "/assets/user.png"
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/assets/user.png";
            }}
            sx={{ borderRadius: "50%" }}
            width={"40px"}
            height={"40px"}
          />
          <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"flex-start"}
            gap={"4px"}
          >
            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"2px"}
            >
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  console.log(data?.candidateId?._id);
                  if (
                    data?.currentStatus !== "Invite Expired" &&
                    data?.currentStatus !== "Invited"
                  ) {
                    navigate(`/assessments/candidateDetails`, {
                      state: {
                        candidateAssessmentId: data?.candidateAssessmentId,
                        assessmentId: data?.assessmentId?._id,
                        assessmentDetails: data?.assessmentId,
                      },
                    });
                  }
                }}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  pointerEvents:
                    data.currentStatus !== "Invited" &&
                    data.currentStatus !== "Invite Expired"
                      ? "auto"
                      : "none",
                }}
              >
                {data?.assessmentResult?.totalObtainedScore <
                  data?.assessmentId?.cutOff &&
                  data?.currentStatus !== "invited" &&
                  data?.currentStatus !== "invite expired" && (
                    <Tooltip title="Failed" arrow>
                      <img
                        src="/assets/exclamation-circle.svg"
                        alt="Alert Icon"
                        style={{
                          marginRight: "4px",
                          marginLeft: "4px",
                          height: "18px",
                        }}
                      />
                    </Tooltip>
                  )}
                <CandidateName
                  sx={{
                    paddingRight: "10px",
                    cursor: "pointer",
                  }}
                >
                  {data?.candidateId.name}
                </CandidateName>
                {data?.currentStatus !== "Invited" &&
                  data?.currentStatus !== "Invite Expired" && (
                    <>
                      <Tooltip title="Copy Result Link" arrow>
                        <Typography
                          sx={{
                            borderLeft: "1px solid #E8E9EE",
                            display: "flex",
                            alignItems: "center",
                            color: "#68727f",
                            fontSize: "0.900rem",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleGetLink();
                          }}
                        >
                          <img
                            src="/assets/link.svg"
                            alt="icon"
                            style={{
                              marginRight: "5px",
                              marginLeft: "8px",
                              height: "15px",
                            }}
                          />
                        </Typography>
                      </Tooltip>
                      <Divider
                        orientation="vertical"
                        sx={{
                          marginRight:
                            (currentTab === "shortlisted" &&
                              data?.currentStatus === "Shortlisted") ||
                            (currentTab === "reviewed" &&
                              data?.currentStatus === "Reviewed") ||
                            (currentTab === "passed" &&
                              data?.currentStatus === "Appeared")
                              ? "0"
                              : "4px",
                          height: "16px",
                        }}
                      />
                    </>
                  )}
              </Link>

              {(currentTab === "appeared" || currentTab === "passed") && (
                <Stack display="flex" gap={1}>
                  {data?.assessmentResult?.isCheatingDetected && (
                    <Tooltip
                      PopperProps={{
                        sx: {
                          "& .MuiTooltip-tooltip": {
                            display: "flex",
                            alignItems: "center",
                            bgcolor: "#313E4F",
                            height: "40px",
                            padding: "8px 12px",
                            borderRadius: "0.25rem",
                          },
                          "& .MuiTooltip-arrow": {
                            // background: "#313E4F",
                          },
                        },
                      }}
                      placement="bottom-start"
                      arrow
                      sx={{ background: "#fff" }}
                      title={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#E9EAEB",
                              fontFamily: "Roboto",
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "20px",
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCheatingClick();
                            }}
                          >
                            Cheating detected
                          </Typography>
                          <img
                            src="/assets/redirect_icon_2.svg"
                            alt="redirect icon"
                          />
                        </Box>
                      }
                    >
                      <img src="/assets/alert_icon.svg" alt="AlertIcon" />
                    </Tooltip>
                  )}
                </Stack>
              )}

              {data?.currentStatus !== "Invited" &&
                data?.currentStatus !== "Invite Expired" &&
                (() => {
                  let displayStatus;

                  // Check if the component should render based on the current tab and status
                  if (
                    (currentTab === "reviewed" &&
                      data?.currentStatus === "Reviewed") ||
                    (currentTab === "shortlisted" &&
                      data?.currentStatus === "Shortlisted") ||
                    (currentTab === "passed" &&
                      data?.currentStatus === "Appeared")
                  ) {
                    return null; // Don't render the component
                  }

                  // Logic for the "invited" tab: Show only "Passed", "Failed", or "Appeared"
                  if (currentTab === "invited") {
                    // if (data?.currentStatus === "Appeared") {
                    //   displayStatus =
                    //     data?.assessmentResult?.totalObtainedScore >=
                    //     data?.assessmentId?.cutOff
                    //       ? "Passed"
                    //       : "Failed";
                    // } else {
                    displayStatus = "Appeared"; // Default to "Appeared"
                  }
                  // Logic for the "passed" tab: Show only "Passed" if score passes the cutoff, otherwise "Shortlisted"
                  else if (currentTab === "passed") {
                    if (data?.currentStatus === "Appeared") {
                      displayStatus =
                        data?.assessmentResult?.totalObtainedScore >=
                        data?.assessmentId?.cutOff
                          ? "Passed"
                          : "Shortlisted"; // If not passed, show "Shortlisted"
                    } else if (
                      ["Reviewed", "Shortlisted"].includes(data?.currentStatus)
                    ) {
                      displayStatus = data?.currentStatus; // Show "Reviewed" or "Shortlisted"
                    } else {
                      displayStatus = "Shortlisted"; // Default to "Shortlisted" for any other status
                    }
                  }
                  // Logic for the "appeared" tab: Show "Shortlisted", "Reviewed", "Passed", or "Failed"
                  else if (currentTab === "appeared") {
                    if (data?.currentStatus === "Appeared") {
                      displayStatus =
                        data?.assessmentResult?.totalObtainedScore >=
                        data?.assessmentId?.cutOff
                          ? "Passed"
                          : "Failed";
                    } else if (
                      ["Shortlisted", "Reviewed"].includes(data?.currentStatus)
                    ) {
                      displayStatus = data?.currentStatus;
                    } else {
                      displayStatus = "Shortlisted"; // Default to "Shortlisted" for other statuses
                    }
                  }
                  // For other tabs, show the current status directly
                  else {
                    displayStatus = data?.currentStatus;
                  }

                  return (
                    <Typography
                      sx={{
                        background: getColorByStatus(getAdjustedStatus(data))
                          .bg,
                        color: getColorByStatus(getAdjustedStatus(data)).color,
                        padding: "2px 8px",
                        borderRadius: "4px",
                        fontSize: "0.900rem",
                        marginLeft: "2px",
                      }}
                    >
                      {displayStatus}
                    </Typography>
                  );
                })()}

              {((currentTab === "passed" &&
                (data?.currentStatus === "Shortlisted" ||
                  data?.currentStatus === "Appeared")) ||
                (currentTab === "appeared" &&
                  data?.currentStatus === "Appeared" &&
                  data?.assessmentResult?.totalObtainedScore <
                    data?.assessmentId?.cutOff)) && (
                <div
                  style={{
                    color: "#ff6812",
                    fontSize: "0.900rem",
                    display: "flex",
                    alignItems: "center",
                    padding: "5px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setSelectedRow(data);

                    handleStatusClick("Reviewed", data);
                  }}
                >
                  {" "}
                  {!(
                    currentTab === "shortlisted" &&
                    data?.currentStatus === "Shortlisted"
                  ) &&
                    !(
                      currentTab === "reviewed" &&
                      data?.currentStatus === "Reviewed"
                    ) &&
                    !(
                      currentTab === "passed" &&
                      data?.currentStatus === "Appeared"
                    ) && (
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                          marginRight: "5px",
                        }}
                      />
                    )}
                  Move to Reviewed
                  <img
                    src="/assets/arrow-left.svg"
                    alt="arrow"
                    style={{
                      marginRight: "4px",
                      transform: "scale(0.8) rotate(0.5turn)",
                    }}
                  />
                </div>
              )}
              {((currentTab === "shortlisted" &&
                data?.currentStatus === "Shortlisted") ||
                (currentTab === "reviewed" &&
                  data?.currentStatus === "Reviewed")) &&
                data?.currentStatus !== "Passed" && (
                  <div
                    style={{
                      color: "#ff6812",
                      fontSize: "0.900rem",
                      display: "flex",
                      alignItems: "center",
                      padding: "5px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setSelectedRow(data);

                      handleStatusClick("Appeared", data);
                    }}
                  >
                    {!(
                      currentTab === "shortlisted" &&
                      data?.currentStatus === "Shortlisted"
                    ) &&
                      !(
                        currentTab === "reviewed" &&
                        data?.currentStatus === "Reviewed"
                      ) &&
                      !(
                        currentTab === "passed" &&
                        data?.currentStatus === "Appeared"
                      ) && (
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{
                            paddingRight: "5px",
                          }}
                        />
                      )}
                    <img
                      src="/assets/arrow-left.svg"
                      alt="Arrow Icon"
                      style={{ marginRight: "4px", paddingLeft: "2px" }}
                    />
                    {data?.assessmentResult?.totalObtainedScore >
                    data?.assessmentId?.cutOff ? (
                      <>Move to Pass</>
                    ) : (
                      <>Move to Test Taken</>
                    )}
                  </div>
                )}
            </Stack>
            {data.currentStatus !== "Invited" &&
              data.currentStatus !== "Invite Expired" && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "#68727f",
                      fontSize: "0.900rem",
                      paddingRight: "10px",
                      marginRight: "10px",
                    }}
                  >
                    <img
                      src="/assets/Vector-9.svg"
                      alt="icon"
                      style={{ marginRight: "8px" }}
                    />
                    Submitted on:{" "}
                    {formatDate(data?.assessmentResult?.testSubmittedOn)}
                  </Typography>
                </div>
              )}
            {/* {currentTab === "passed" && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "#68727f",
                    fontSize: "0.900rem",
                    paddingRight: "10px",
                    marginRight: "10px",
                  }}
                >
                  <img
                    src="/assets/Vector-9.svg"
                    alt="icon"
                    style={{ marginRight: "8px" }}
                  />
                  Finished at: {formatDate(data?.testSubmittedOn)}
                </Typography>
              </div>
            )} */}
            {data.currentStatus === "Invited" && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "#68727f",
                    fontSize: "0.900rem",
                    paddingRight: "10px",
                    marginRight: "10px",
                  }}
                >
                  <img
                    src="/assets/Vector-9.svg"
                    alt="icon"
                    style={{ marginRight: "8px" }}
                  />
                  Invited At:{" "}
                  {data?.invitationDetails?.sentAt
                    ? formatDate(data.invitationDetails.sentAt)
                    : "N/A"}
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      mx: 1,
                    }}
                  />
                  Expires on:{" "}
                  {data?.invitationDetails?.validUpto
                    ? formatDate(data.invitationDetails.validUpto)
                    : "N/A"}{" "}
                </Typography>
              </div>
            )}
          </Stack>
        </Stack>
      </Stack>

      {/* right section */}
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={"12px"}
        divider={<Divider orientation="vertical" flexItem />}
        sx={{ width: { xs: "100%", lg: "auto" } }}
      >
        {currentTab === "shortlisted" &&
          (data?.currentStatus === "Shortlisted" ||
            data?.currentStatus === "Appeared" ||
            data?.currentStatus === "Passed") && (
            <Button
              variant="contained"
              onClick={() => {
                setSelectedRow(data);
                handleStatusClick("Reviewed", data);
              }}
              sx={{
                borderRadius: "4px",
                color: "white",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "16px",
                textTransform: "none",
                padding: "6px 12px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "16px",
                  color: "white",
                  textWrap: "nowrap",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src="/assets/icons/userPlusWhite.svg"
                  alt="check"
                  style={{ marginRight: "4px" }}
                />
                Reviewed
              </Typography>
            </Button>
          )}

        {((currentTab === "passed" || currentTab === "reviewed") &&
          (data?.currentStatus === "Reviewed" ||
            data?.currentStatus === "Appeared")) ||
        (currentTab === "appeared" &&
          data?.assessmentResult?.totalObtainedScore <
            data?.assessmentId?.cutOff &&
          data?.currentStatus === "Appeared") ? (
          <>
            <Button
              variant="contained"
              onClick={() => {
                setSelectedRow(data);
                handleStatusClick("Shortlisted", data);
              }}
              sx={{
                borderRadius: "4px",
                color: "white",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "16px",
                textTransform: "none",
                padding: "6px 12px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "16px",
                  color: "white",
                  textWrap: "nowrap",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src="/assets/icons/userPlusWhite.svg"
                  alt="check"
                  style={{ marginRight: "4px" }}
                />
                Shortlist
              </Typography>
            </Button>
          </>
        ) : null}

        {(data?.currentStatus === "Invited" ||
          data.currentStatus === "Invite Expired") && (
          <Typography
            sx={{
              background: getColorByStatus(getAdjustedStatus(data)).bg,
              float: "right",
              color: getColorByStatus(getAdjustedStatus(data)).color,
              padding: "2px 8px",
              borderRadius: "4px",
              display: "inline-block",
              fontSize: "0.900rem",
            }}
          >
            {data?.currentStatus}
          </Typography>
        )}
        {data.currentStatus !== "Invited" &&
          data.currentStatus !== "Invite Expired" && (
            <Stack
              direction={"row"}
              alignItems={"center"}
              divider={<Divider orientation="vertical" flexItem />}
              sx={{ gap: 2, padding: "0" }}
            >
              <Stack
                direction={"column"}
                alignItems={"center"}
                gap={"2px"}
                sx={{ padding: "0" }}
              >
                <Typography
                  sx={{
                    fontFamily: "Nunito",
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "32px",
                    color: "#333333",
                  }}
                >
                  {`${
                    data?.assessmentResult?.totalObtainedScore < 10 ? "0" : ""
                  }${data?.assessmentResult?.totalObtainedScore} / ${
                    data?.assessmentId?.totalScore
                  }`}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "#535A5F",
                    textWrap: "nowrap",
                  }}
                >
                  Score
                </Typography>
              </Stack>

              <Stack
                direction={"column"}
                alignItems={"center"}
                gap={"2px"}
                sx={{ padding: "0" }}
              >
                <Typography
                  sx={{
                    fontFamily: "Nunito",
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "32px",
                    color: "#333333",
                  }}
                >
                  {data?.assessmentId?.totalScore && data?.totalObtainedScore
                    ? (
                        (data.totalObtainedScore /
                          data.assessmentId.totalScore) *
                        100
                      ).toFixed(0)
                    : "0"}
                  %
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "#535A5F",
                    textWrap: "nowrap",
                  }}
                >
                  Percentile
                </Typography>
              </Stack>

              <Stack
                direction={"column"}
                alignItems={"center"}
                gap={"2px"}
                sx={{ padding: "0" }}
              >
                <Typography
                  sx={{
                    fontFamily: "Nunito",
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "32px",
                    color: "#333333",
                  }}
                >
                  {Math.round(data?.assessmentResult?.totalTimeSpent / 60)} mins
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "#535A5F",
                    textWrap: "nowrap",
                  }}
                >
                  Duration
                </Typography>
              </Stack>
            </Stack>
          )}
      </Stack>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(!drawerOpen)}
        PaperProps={{
          sx: { width: "400px" },
        }}
      >
        <Box sx={{ padding: "16px" }}>
          <Typography variant="h6">{drawerHeading}</Typography>

          <CheatingDetected
            open={drawerOpen}
            setOpen={setDrawerOpen}
            drawerHeading="View Snapshots"
            data={data}
          />
        </Box>
      </Drawer>
    </Stack>
  );
};

export default TestTakenCandidateCard;
