import * as React from "react";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import { Grid, Autocomplete, Box, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import APICall from "../../utils/api";
import debounce from "lodash/debounce";
import CircleCheck from "../icons/CircleCheck";

const useStyles = makeStyles({
  hiddenTags: {
    "& .MuiAutocomplete-tag": {
      display: "none",
    },
  },
});

function SkillInput({
  formik,
  allSkills,
  allTopics,
  setSkills,
  setTopics,
  skills,
  topics,
  setAllSkills,
  setAllTopics,
  fetchSkills,
  skillTopicsCache,
  isDataFetched,
  setIsDataFetched,
  inputValue,
  setInputValue,
}) {
  const classes = useStyles();
  const [inputs, setInputs] = React.useState({
    skills: "",
    topics: "",
  });

  const [searchTopics, setSearchTopics] = React.useState([[]]);

  React.useEffect(() => {
    const skillIds = Array.isArray(formik.values.skills)
      ? formik.values.skills.map((skill) => (skill._id ? skill._id : skill))
      : [];
    const topicIds = Array.isArray(formik.values.topics)
      ? formik.values.topics.map((topic) => (topic._id ? topic._id : topic))
      : [];

    setSkills(skillIds);
    setTopics(topicIds);
  }, [formik.values.skills, formik.values.topics]);

  const handleSkillChange = (event, newValue) => {
    if (newValue) {
      const selectedSkillIds = newValue.map((option) => option.value);

      const newAssociatedTopics = selectedSkillIds.reduce((acc, skillId) => {
        const topics = skillTopicsCache[skillId] || [];
        return [...acc, ...topics];
      }, []);

      const mergedTopics = [...allTopics, ...newAssociatedTopics];

      const uniqueTopics = Array.from(
        new Set(mergedTopics.map((topic) => topic._id))
      ).map((id) => mergedTopics.find((topic) => topic._id === id));

      setAllTopics(uniqueTopics);
      console.log(555);

      const mergedCurrentTopics = [...topics, ...newAssociatedTopics];
      const uniqueCurrentTopics = Array.from(
        new Set(mergedCurrentTopics.map((topic) => topic._id))
      ).map((id) => mergedCurrentTopics.find((topic) => topic._id === id));

      setTopics(uniqueCurrentTopics);
    }

    formik.setFieldValue(
      "skills",
      newValue.map((option) => option.value)
    );
  };

  const handleTopicChange = (event, selectedValues) => {
    const selectedTopicIds = selectedValues.map((item) => item.value);

    const newTopics = selectedTopicIds.filter(
      (topicId) => !topics.includes(topicId)
    );

    if (newTopics.length > 0) {
      const updatedTopics = [...topics, ...newTopics];

      formik.setFieldValue("topics", updatedTopics);
      setTopics(updatedTopics);
    }

    const topicsToAdd = searchTopics.filter(
      (topic) =>
        selectedTopicIds.includes(topic._id) &&
        !allTopics.some((existingTopic) => existingTopic._id === topic._id)
    );

    if (topicsToAdd.length > 0) {
      setAllTopics((prevTopics) => [...prevTopics, ...topicsToAdd]);
      console.log(666);
    }
  };

  const isSkillsEmpty = !skills || skills.length === 0;

  React.useEffect(() => {
    const fetchAllData = async () => {
      await fetchSkills();
      await fetchTopics();
      setIsDataFetched(true);
    };

    fetchAllData();
  }, []);

  const debouncedFetchSkills = React.useMemo(
    () => debounce(fetchSkills, 200),
    [allSkills, skills]
  );

  const fetchTopics = async (query) => {
    let url = `/topic?page=1&limit=10`;
    if (query) {
      url += `&query=${query}`;
    }
    const res = await APICall(url);
    setSearchTopics(res.data.documents);
  };

  const debouncedFetchTopics = React.useMemo(
    () => debounce(fetchTopics, 200),
    [allTopics, topics]
  );

  const toggleTopicSelection = (topicId) => {
    setTopics((prevTopics) => {
      if (prevTopics.includes(topicId)) {
        const updatedTopics = prevTopics.filter((id) => id !== topicId);
        formik.setFieldValue("topics", updatedTopics);
        return updatedTopics;
      } else {
        const updatedTopics = [...prevTopics, topicId];
        formik.setFieldValue("topics", updatedTopics);
        return updatedTopics;
      }
    });
  };

  const toggleSkillSelection = (skillId) => {
    setSkills((prevSkills) => {
      if (prevSkills.includes(skillId)) {
        // Remove the skill if already selected
        const updatedSkills = prevSkills.filter((id) => id !== skillId);
        formik.setFieldValue("skills", updatedSkills);
        return updatedSkills;
      } else {
        // Add the skill if not selected
        const updatedSkills = [...prevSkills, skillId];
        formik.setFieldValue("skills", updatedSkills);
        return updatedSkills;
      }
    });
  };

  const handleInputChange = (type, event, newInputValue, reason) => {
    const fieldId = event.target.id;

    if (reason === "input") {
      setInputValue((prev) => ({
        ...prev,
        [fieldId]: newInputValue,
      }));
      if (newInputValue.trim() !== "" && fieldId === "skills") {
        debouncedFetchSkills(newInputValue);
      }
      if (newInputValue.trim() !== "" && fieldId === "topics") {
        debouncedFetchTopics(newInputValue);
      }
    } else if (reason === "clear") {
      setInputValue((prev) => ({
        ...prev,
        [fieldId]: null,
      }));

      if (type === "skills") {
        fetchSkills();

        setSkills([]);
        setTopics([]);
        setAllTopics([]);
        console.log(111);
      }
      if (type === "topics") {
        fetchTopics();
        setTopics([]);
        setAllTopics([]);
      }
    }
  };

  const handleDelete = (itemId, type) => {
    if (type === "skills") {
      const newSkills = skills.filter((id) => id !== itemId);

      setSkills(newSkills);
      formik.setFieldValue("skills", newSkills);

      const deletedSkill = allSkills.find((skill) => skill._id === itemId);

      if (deletedSkill && deletedSkill.topics) {
        const associatedTopicIds = deletedSkill.topics.map((topic) =>
          typeof topic === "string" ? topic : topic._id
        );

        const newAllTopics = allTopics.filter(
          (topic) => !associatedTopicIds.includes(topic._id)
        );
        setAllTopics(newAllTopics);
        console.log(222);
      }
      if (newSkills.length === 0) {
        formik.setFieldValue("topics", []);
        setTopics([]);
        setAllTopics([]);
        console.log(333);
      }
    } else {
      const newTopics = topics.filter((id) => id !== itemId);
      setTopics(newTopics);
      formik.setFieldValue("topics", newTopics);
    }
  };

  return (
    <Grid container spacing={2}>
      {["skills", "topics"].map((type, index) => {
        const options = type === "skills" ? allSkills : searchTopics;
        const selectedItems = type === "skills" ? skills : topics;
        const isDisabled = type === "topics" && isSkillsEmpty;

        return (
          <Grid item md={6} sm={12} key={index} sx={{ width: "400px" }}>
            <Autocomplete
              size="small"
              multiple
              freeSolo
              id={type}
              options={options.map((item) => ({
                label: item.name,
                value: item._id,
              }))}
              disabled={isDisabled}
              value={options
                .filter((item) => selectedItems.includes(item._id))
                .map((item) => ({
                  label: item.name,
                  value: item._id,
                }))}
              onChange={(event, newValue) => {
                if (type === "skills") {
                  handleSkillChange(event, newValue);
                } else {
                  handleTopicChange(event, newValue);
                }
              }}
              inputValue={inputValue[type]}
              onInputChange={(event, newInputValue, reason) => {
                handleInputChange(type, event, newInputValue, reason);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={type.charAt(0).toUpperCase() + type.slice(1)}
                  error={formik.touched[type] && Boolean(formik.errors[type])}
                  helperText={formik.touched[type] && formik.errors[type]}
                />
              )}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              className={classes.hiddenTags}
            />

            {/* {selectedItems.map((itemId) => {
                const item = options.find((option) => option._id === itemId);
                return (
                  item &&
                  type !== "topics" && (
                    <Chip
                      key={itemId}
                      label={item.name}
                      onDelete={() => handleDelete(itemId, type)}
                      deleteIcon={<Close />}
                      sx={{
                        backgroundColor: "#D5F9F1",
                        borderRadius: "4px",
                        padding: "4px 8px",
                        color: "#004E3E",
                      }}
                    />
                  )
                );
              })} */}
            <Box
              sx={{
                marginTop: "6px",
                display: "flex",
                flexWrap: "wrap",
                gap: "8px",
                maxHeight: "150px",
                overflowY: "auto",
                overflowX: "hidden",
                width: "100%",
              }}
            >
              {type === "skills" &&
                selectedItems.map((itemId) => {
                  const item = options.find((option) => option._id === itemId);
                  return (
                    item && (
                      <Box
                        key={item._id}
                        sx={{
                          height: "30px",
                          border: `1px solid ${
                            selectedItems.includes(item._id)
                              ? "#009D7B"
                              : "#BBC2CC"
                          }`,
                          bgcolor: selectedItems.includes(item._id)
                            ? "#D5F9F1"
                            : "#fff",
                          borderRadius: "4px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "2px",
                          padding: "4px 8px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          type === "skills"
                            ? handleDelete(item._id, "skills")
                            : ""
                        }
                      >
                        {selectedItems.includes(item._id) && (
                          <CircleCheck fill="#009D7B" />
                        )}
                        <Typography
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "20px",
                            color: selectedItems.includes(item._id)
                              ? "#009D7B"
                              : "#8591A2",
                          }}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                    )
                  );
                })}
              {type === "topics" &&
                allTopics.length > 0 &&
                allTopics.map((topic) => {
                  return (
                    <Box
                      key={topic._id}
                      sx={{
                        height: "30px",
                        border: `1px solid ${
                          selectedItems.includes(topic._id)
                            ? "#009D7B"
                            : "#BBC2CC"
                        }`,
                        bgcolor: selectedItems.includes(topic._id)
                          ? "#D5F9F1"
                          : "#fff",
                        borderRadius: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "2px",
                        padding: "4px 8px",
                        cursor: "pointer",
                      }}
                      onClick={() => toggleTopicSelection(topic._id)}
                    >
                      {selectedItems.includes(topic._id) && (
                        <CircleCheck fill="#009D7B" />
                      )}
                      <Typography
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "20px",
                          color: selectedItems.includes(topic._id)
                            ? "#009D7B"
                            : "#8591A2",
                        }}
                      >
                        {topic.name}
                      </Typography>
                    </Box>
                  );
                })}
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default SkillInput;
